import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Grid, Tab, Tabs } from '@material-ui/core';

import { PropertiesTable } from './components';
import TabPanel from 'components/TabPanel';
import { TranslationContext } from 'contexts/TranslationContext';
import UserHistory from 'components/UserHistory';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

function a11yProps(a11yIndex) {
  return {
    id: `full-width-tab-${a11yIndex}`,
    'aria-controls': `full-width-tabpanel-${a11yIndex}`
  };
}

const ContactedProperties = () => {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar color="default" position="static" elevation={0}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
        >
          <Tab
            classes={{ selected: classes.tabSelected }}
            label={t('profile-info')}
            {...a11yProps(0)}
          />
          <Tab
            classes={{ selected: classes.tabSelected }}
            label={t('profile-history')}
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <PropertiesTable alreadyHadAction={true} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {tabIndex === 1 && <UserHistory />}
      </TabPanel>
    </div>
  );
};

export default ContactedProperties;
