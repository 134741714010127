import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const AlreadyInAgencySubForm = ({ updateProp }) => {
  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sm={12}>
        <TextField
          id="agency-name"
          label="Nom de l'agence"
          fullWidth
          onChange={e => updateProp('alreadyInAgency', e.target.value.trim())}
        />
      </Grid>
    </Grid>
  );
};

export default AlreadyInAgencySubForm;