import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Box from '@material-ui/core/Box';
import { RendezVous, AutoEval as AutoEvalForm } from './components';

import './FormPopUp.css';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  tabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white !important'
  },
  button: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  dateLabel: {
    fontSize: 'x-small'
  }
}));

const FormPopUp = () => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = React.useState(false);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  function a11yProps(a11yIndex) {
    return {
      id: `full-width-tab-${a11yIndex}`,
      'aria-controls': `full-width-tabpanel-${a11yIndex}`
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography variant="inherit">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div key={'FormPopUp'} className={classes.root}>
      <Typography align="center" variant="h3">
        Avez vous obtenu un rendez-vous?
      </Typography>
      <br />
      <br />
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            classes={{ selected: classes.tabSelected }}
            label="Non"
            icon={<CancelIcon />}
            {...a11yProps(0)}
          />
          <Tab
            classes={{ selected: classes.tabSelected }}
            label="Oui"
            icon={<CheckCircleOutlineIcon />}
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <React.Fragment>
        <TabPanel value={tabIndex} index={0}>
          <AutoEvalForm />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <RendezVous />
        </TabPanel>
      </React.Fragment>
    </div>
  );
};
export default FormPopUp;
