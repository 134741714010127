import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Divider, Grid, TextField } from '@material-ui/core';
import { TranslationContext } from 'contexts/TranslationContext';
import CheckIcon from '@material-ui/icons/Check';
import { AuthContext } from 'contexts/AuthContext';
import { saveContactForm } from 'myApi';
import { CustomizedSnackBarContext } from 'contexts/CustomizedSnackBarContext';
import { LoaderContext } from 'contexts/LoaderContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  buttonSuccess: {
    backgroundColor: theme.palette.success.main
  },
  ...theme.common,
}));

const Contact = () => {
  const classes = useStyles();

  const { myUserInfo } = React.useContext(AuthContext);
  const { t } = React.useContext(TranslationContext);
  const { errorMessage } = React.useContext(CustomizedSnackBarContext);
  const { showLoader, hideLoader } = React.useContext(LoaderContext);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [values, setValues] = React.useState({
    userId: myUserInfo.id,
    email: myUserInfo.email,
    name: myUserInfo.name,
    message: "",
    subject: "",
  });

  React.useEffect(() => {
    hideLoader();
    return () => showLoader();
  }, []);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);

    saveContactForm({ ...values })
    .then(_ => {
      // Reset values
      setValues({
        userId: myUserInfo.id,
        email: myUserInfo.email,
        name: myUserInfo.name,
        message: "",
        subject: "",
      });

      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    })
    .catch(_ =>  errorMessage(t("common-an-error-occured")))
    .finally(_ => setLoading(false));
  };
  
  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={9}
          md={9}
          xl={9}
          sm={9}
          xs={12}
        >
          <Card
            className={clsx(classes.root)}
          >
            <form
              autoComplete="off"
              onSubmit={onSubmit}
            >
              <CardHeader
                title={t("contact-title")}
                subheader={t("contact-subtitle")}
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      disabled={loading}
                      fullWidth
                      label={t("contact-subject")}
                      margin="dense"
                      name="subject"
                      onChange={handleChange}
                      required
                      value={values.subject}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      disabled={loading}
                      fullWidth
                      label={t("contact-message")}
                      margin="dense"
                      name="message"
                      onChange={handleChange}
                      required
                      multiline
                      rows={5}
                      value={values.message}
                      placeholder={t("contact-message")}
                      variant="outlined"
                    />
                  </Grid>
                  
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  endIcon={success === true ? <CheckIcon /> : null}
                  className={success === true ? classes.buttonSuccess : ''}
                >
                  {success ? t("common-sent") : t("common-send")}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;