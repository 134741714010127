import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SectionHomeScreen from './components/SectionHomeScreen';
import SectionCards from './components/SectionCards';
import SectionFooter from './components/SectionFooter';
import SectionContact from './components/SectionContact';

const useStyles = makeStyles(theme => ({
  root: {
  },
  ...theme.common,
}));

const Home = () => {
  const classes = useStyles();
  
  return (
    <Grid
      container 
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <SectionHomeScreen />
      <SectionCards />
      <SectionContact />
      <SectionFooter />
    </Grid>
  );
};

export default Home;