import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import Loader from './assets/work-in-progesss.gif';
import { AuthContext } from 'contexts/AuthContext';
import { Stats } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const { myUserInfo } = useContext(AuthContext);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Stats />
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={8}>
                      <Typography variant="h3">
                        Bienvenue {myUserInfo?.name}
                      </Typography>
                      <br />
                      <Typography variant="h6">
                        Dans le but de t'améliorer, remplis ton tableau de
                        board. Des outils pour apprendre à mieux gérer les
                        objections des clients te serons transmise très bientôt
                        !
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <img src="./assets/dashboard_mascot.svg" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <Card>
                <CardContent>WAZZZAAA</CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>WAZZZAAA</CardContent>
              </Card>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
