import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { AuthContext } from 'contexts/AuthContext';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  button: {
    marginTop: "15px",
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 460
  }
}));

const SuspendedAccount = () => {
  const classes = useStyles();
  
  const { logOut } = React.useContext(AuthContext);
  const [redirect, setRedirect] = React.useState(false);

  const logOutAndRedirect = () => {
    logOut();
    setRedirect(true);
  }

  if (redirect) {
    return <Redirect to={'/sign-in'} />;
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant="h1">
              Votre compte n'est plus actif
            </Typography>
            <Typography variant="subtitle1">
              Retard de paiement ? Compte suspendu ?
            </Typography>
            <Typography variant="subtitle2">
              <a href="mailto:info@hometracker.be?cc=imad@benim.be&subject=Suspended-Account%20Contact">Contactez-nous pour plus d'information.</a>
            </Typography>
            <Typography className={classes.button}>
              <Button variant="contained" color="primary" onClick={logOutAndRedirect}>
                Déconnexion
              </Button>
            </Typography>
            <img
              alt="Under development"
              className={classes.image}
              src="/images/undraw_resume_folder_2_arse.svg"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SuspendedAccount;
