import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Grid, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { PropertyCard } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    backgroundColor: "#f4f6f8",
    marginTop: theme.spacing(2)
  },
  pagination: {
    backgroundColor: "#fff",
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)",
  }
}));

const PropertyListAsCard = ({ rows, rowsPerPage, columns, page, setPage, setRowsPerPage, propertyCount }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.content}>
        <Grid
          container
          spacing={3}
        >
          {rows.map(property => (
            <Grid
              item
              key={property.id}
              lg={4}
              md={6}
              xs={12}
            >
              <PropertyCard id={"properties-table-tr-" + property.id} property={property} />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={classes.pagination}>
        <IconButton
          disabled={page === 0}
          onClick={_ => setPage(page - 1)}
        >
          <ChevronLeftIcon />
        </IconButton>

        <FormControl variant="standard">
          <Select
            value={rowsPerPage}
            onChange={event => {
              setRowsPerPage(+event.target.value);
              setPage(0);
              window.scrollTo(0, 0);
            }}
          >
            {[6, 12, 24].map(t => 
              <MenuItem key={t} value={t}>{t}</MenuItem>
            )}
          </Select>
        </FormControl>
        &nbsp;&nbsp;
        <Typography variant="caption">
          {page*rowsPerPage}-{rowsPerPage * (page + 1)} of {propertyCount}
        </Typography>

        <IconButton
          disabled={page*rowsPerPage+rowsPerPage >= propertyCount}
          onClick={_ => {
            setPage(page + 1);
            window.scrollTo(0, 0);
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </div>
    </React.Fragment>
  );
};

export default PropertyListAsCard;