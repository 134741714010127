import React from 'react';
import { Redirect } from 'react-router-dom';
import {AuthContext} from 'contexts/AuthContext';
import PrivateRoute from './PrivateRoute';

const RightProtectedPrivateRoute = ({
  component,
  layout,
  hasRight,
  ...rest
}) => {
  const { initialized, tokenUser, myUserInfo } = React.useContext(AuthContext);

  // Used to wait for the context to find if we have token or not
  if (initialized === false || myUserInfo === null) {
    return null;
  }

  if (tokenUser && myUserInfo) {
    if (hasRight(myUserInfo)) {
      return (
        <PrivateRoute
          component={component}
          layout={layout}
          {...rest}
        />
      );
    }

    // ToDo(ibe): maybe create 'NOT AUTHORIZED' page
    return <Redirect to={'/not-found'} />;
  }

  return <Redirect to={'/sign-in'} />;
};

export default RightProtectedPrivateRoute;