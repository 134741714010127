const Communes = [
  { "type": "C","niscode": "64076", "name": "Faimes", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6448212024,5.24274083844], "name_fr": "Faimes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "32010", "name": "Koekelare", "arrondissement_nis_code": "32000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0890321424,2.96384396636], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "45062", "name": "Horebeke", "arrondissement_nis_code": "45000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8321363087,3.69449281374], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62015", "name": "Beyne-Heusay", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6284057582,5.66156647276], "name_fr": "Beyne-Heusay", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63049", "name": "Malmedy", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4314625019,6.04160333416], "name_fr": "Malmedy", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "41082", "name": "Erpe-Mere", "arrondissement_nis_code": "41000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9215224242,3.95196670356], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61068", "name": "Villers-le-Bouillet", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.593360501,5.24280407484], "name_fr": "Villers-le-Bouillet", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63067", "name": "Saint-Vith", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.2868639004,6.12579554805], "name_fr": "Saint-Vith", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92006", "name": "Assesse", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.3754674446,4.9929843494], "name_fr": "Assesse", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21014", "name": "Saint-Josse-ten-Noode", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8538125225,4.36927906907], "name_fr": "Saint-Josse-ten-Noode", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13001", "name": "Arendonk", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.334623417,5.08544784427], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "31006", "name": "Damme", "arrondissement_nis_code": "31000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.2521877638,3.32738245752], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "82032", "name": "Vielsalm", "arrondissement_nis_code": "82000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.2780976085,5.89333232049], "name_fr": "Vielsalm", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25122", "name": "Ramillies", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6567037285,4.88608250967], "name_fr": "Ramillies", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "42004", "name": "Buggenhout", "arrondissement_nis_code": "42000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.011843313,4.19901288758], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11037", "name": "Rumst", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0938300387,4.42108559051], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24038", "name": "Herent", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9106096202,4.64656337527], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11001", "name": "Aartselaar", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1318359439,4.38252238767], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61043", "name": "Nandrin", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5171472831,5.40069207351], "name_fr": "Nandrin", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25124", "name": "Walhain", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6312142403,4.69728141283], "name_fr": "Walhain", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52025", "name": "Gerpinnes", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3474687374,4.52778516001], "name_fr": "Gerpinnes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "46013", "name": "Kruibeke", "arrondissement_nis_code": "46000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1578505879,4.29179745381], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "43005", "name": "Eeklo", "arrondissement_nis_code": "43000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.194318117,3.56232889002], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "33021", "name": "Poperinge", "arrondissement_nis_code": "33000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8610275198,2.69151681375], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "42006", "name": "Dendermonde", "arrondissement_nis_code": "42000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0275882146,4.09061274755], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34002", "name": "Anzegem", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8307177565,3.45390415629], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56049", "name": "Merbes-le-Château", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3209552174,4.19453381245], "name_fr": "Merbes-le-Château", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61039", "name": "Marchin", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4674072122,5.23951190672], "name_fr": "Marchin", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63075", "name": "Stoumont", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4082159042,5.79846354076], "name_fr": "Stoumont", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13010", "name": "Grobbendonk", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1797862196,4.7391253829], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53082", "name": "Colfontaine", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4058761608,3.84418438434], "name_fr": "Colfontaine", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61010", "name": "Burdinne", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5826012318,5.0994852453], "name_fr": "Burdinne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63088", "name": "Plombières", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.7256161424,5.94908768311], "name_fr": "Plombières", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91072", "name": "Houyet", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.18605496,5.00288820453], "name_fr": "Houyet", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23023", "name": "Galmaarden", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7528180829,3.98801722179], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71002", "name": "As", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.0086447977,5.58477082686], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51068", "name": "Silly", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.6486711232,3.95900656389], "name_fr": "Silly", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "85045", "name": "Virton", "arrondissement_nis_code": "85000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.5712049789,5.57225552917], "name_fr": "Virton", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23024", "name": "Gooik", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7809482859,4.09585663083], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91005", "name": "Anhée", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.3187726705,4.80999044988], "name_fr": "Anhée", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11029", "name": "Mortsel", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1746358473,4.46103851513], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23094", "name": "Zaventem", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8712613035,4.48652178852], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92087", "name": "Mettet", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.3231938156,4.67121958002], "name_fr": "Mettet", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92048", "name": "Fosses-la-Ville", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.3932197055,4.68104078571], "name_fr": "Fosses-la-Ville", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63080", "name": "Waimes", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4624329536,6.12468410746], "name_fr": "Waimes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91064", "name": "Havelange", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.3611761324,5.24816030272], "name_fr": "Havelange", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53046", "name": "Lens", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5704602119,3.91148451679], "name_fr": "Lens", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73022", "name": "Heers", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.7536789986,5.31191946415], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "31004", "name": "Blankenberge", "arrondissement_nis_code": "31000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.3002382577,3.14030213017], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12007", "name": "Bornem", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0952536794,4.24268965682], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53044", "name": "Jurbise", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5252340913,3.90119037094], "name_fr": "Jurbise", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "85046", "name": "Habay", "arrondissement_nis_code": "85000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.7350045662,5.61710707593], "name_fr": "Habay", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "45064", "name": "Maarkedal", "arrondissement_nis_code": "45000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.7951841708,3.64110852446], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "93056", "name": "Philippeville", "arrondissement_nis_code": "93000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.1692123261,4.58785914964], "name_fr": "Philippeville", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63040", "name": "La Calamine", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.717459393,6.02345277241], "name_fr": "La Calamine", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "31022", "name": "Oostkamp", "arrondissement_nis_code": "31000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.119340648,3.23033372459], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64025", "name": "Fexhe-le-Haut-Clocher", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6636534453,5.40207985973], "name_fr": "Fexhe-le-Haut-Clocher", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92142", "name": "Gembloux", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.5543091628,4.70911958704], "name_fr": "Gembloux", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24066", "name": "Lubbeek", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.882005148,4.82892863877], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "83034", "name": "Marche-en-Famenne", "arrondissement_nis_code": "83000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.2092885672,5.34363658762], "name_fr": "Marche-en-Famenne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62120", "name": "Flémalle", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5970344757,5.44131174083], "name_fr": "Flémalle", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "37012", "name": "Ruiselede", "arrondissement_nis_code": "37000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0551262302,3.38226522468], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "33037", "name": "Zonnebeke", "arrondissement_nis_code": "33000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8665124962,3.00364521631], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53028", "name": "Frameries", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3899614651,3.8862042696], "name_fr": "Frameries", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84077", "name": "Libramont-Chevigny", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.9461860279,5.42240779426], "name_fr": "Libramont-Chevigny", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "58002", "name": "Binche", "arrondissement_nis_code": "58000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.408687615,4.1771933679], "name_fr": "Binche", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11057", "name": "Malle", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2932728773,4.71352098481], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63089", "name": "Thimister-Clermont", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6629333243,5.86981265321], "name_fr": "Thimister-Clermont", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71024", "name": "Herk-de-Stad", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.9373556768,5.17778229519], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "36006", "name": "Hooglede", "arrondissement_nis_code": "36000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.989426853,3.08229140988], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56022", "name": "Erquelinnes", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3039230528,4.13858609631], "name_fr": "Erquelinnes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71045", "name": "Nieuwerkerken", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.8795296436,5.20503637202], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "55040", "name": "Soignies", "arrondissement_nis_code": "55000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5661432178,4.03833957252], "name_fr": "Soignies", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25110", "name": "Waterloo", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.7097651013,4.40352205091], "name_fr": "Waterloo", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72004", "name": "Bree", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.1418969397,5.63248419188], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "55086", "name": "Manage", "arrondissement_nis_code": "55000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4931777123,4.2391161465], "name_fr": "Manage", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61031", "name": "Huy", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5087993838,5.2224486074], "name_fr": "Huy", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11021", "name": "Hove", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1472117322,4.48231379135], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "41027", "name": "Herzele", "arrondissement_nis_code": "41000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8704347175,3.89246609797], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "38025", "name": "Veurne", "arrondissement_nis_code": "38000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0465934717,2.66471372157], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24009", "name": "Bertem", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8538371071,4.60970893096], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23060", "name": "Opwijk", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9624761254,4.18077502467], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25005", "name": "Beauvechain", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.7761499023,4.76465863277], "name_fr": "Beauvechain", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "45061", "name": "Wortegem-Petegem", "arrondissement_nis_code": "45000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8483518072,3.52757572395], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24033", "name": "Haacht", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9640210098,4.64121414533], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64015", "name": "Braives", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6153155858,5.1494756112], "name_fr": "Braives", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "85009", "name": "Étalle", "arrondissement_nis_code": "85000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.6639160684,5.60896516419], "name_fr": "Étalle", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11002", "name": "Antwerpen", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2607695445,4.36945434774], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25031", "name": "Genappe", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6101664309,4.45611893207], "name_fr": "Genappe", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24130", "name": "Zoutleeuw", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8350868341,5.10117442474], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61048", "name": "Ouffet", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4447510923,5.465862968], "name_fr": "Ouffet", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52075", "name": "Les Bons Villers", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5278816206,4.44817931411], "name_fr": "Les Bons Villers", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91030", "name": "Ciney", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.2694719117,5.1226141197], "name_fr": "Ciney", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63004", "name": "Baelen", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5830440041,6.04488465071], "name_fr": "Baelen", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62060", "name": "Juprelle", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.7153509656,5.54627369582], "name_fr": "Juprelle", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73032", "name": "Hoeselt", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.8451129121,5.47242297535], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13019", "name": "Lille", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2557462004,4.83419924609], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "85011", "name": "Florenville", "arrondissement_nis_code": "85000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.7077059275,5.2755351268], "name_fr": "Florenville", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63046", "name": "Limbourg", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6113356952,5.93717501465], "name_fr": "Limbourg", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13008", "name": "Geel", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1664684266,4.9847343346], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61003", "name": "Amay", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5529546035,5.31158214554], "name_fr": "Amay", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23016", "name": "Dilbeek", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8532927705,4.233825427], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "36015", "name": "Roeselare", "arrondissement_nis_code": "36000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9368351969,3.13356653621], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64075", "name": "Wasseiges", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6178719179,5.02434919996], "name_fr": "Wasseiges", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "36010", "name": "Ledegem", "arrondissement_nis_code": "36000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8718274929,3.14923220287], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "32006", "name": "Houthulst", "arrondissement_nis_code": "32000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9699977528,2.8964535704], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91103", "name": "Onhaye", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.2602378324,4.81223471265], "name_fr": "Onhaye", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "33039", "name": "Heuvelland", "arrondissement_nis_code": "33000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.7755010921,2.83031153457], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "85039", "name": "Tintigny", "arrondissement_nis_code": "85000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.6859497727,5.48445085203], "name_fr": "Tintigny", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84009", "name": "Bertrix", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.8539736894,5.24627667954], "name_fr": "Bertrix", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25120", "name": "Orp-Jauche", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6899093398,4.96329118215], "name_fr": "Orp-Jauche", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13029", "name": "Olen", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1634716225,4.88561515247], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64065", "name": "Saint-Georges-sur-Meuse", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5967079482,5.36058645958], "name_fr": "Saint-Georges-sur-Meuse", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71020", "name": "Halen", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.9499992559,5.09427777934], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13036", "name": "Retie", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2670716018,5.07679083672], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91054", "name": "Gedinne", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [49.9844369071,4.90276649654], "name_fr": "Gedinne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62096", "name": "Seraing", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5891620472,5.51585473448], "name_fr": "Seraing", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "33016", "name": "Messines", "arrondissement_nis_code": "33000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.7597745612,2.89754802086], "name_fr": "Messines", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57094", "name": "Leuze-en-Hainaut", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5972670571,3.62877973875], "name_fr": "Leuze-en-Hainaut", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53070", "name": "Saint-Ghislain", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4872721078,3.8009463501], "name_fr": "Saint-Ghislain", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11040", "name": "Schoten", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2641727194,4.50819842274], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34022", "name": "Kortrijk", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8000295431,3.26455393087], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11004", "name": "Boechout", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1656852317,4.51687944892], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25107", "name": "Villers-la-Ville", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.562403499,4.52669015919], "name_fr": "Villers-la-Ville", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62003", "name": "Ans", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6827725947,5.50611102075], "name_fr": "Ans", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "45041", "name": "Renaix", "arrondissement_nis_code": "45000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.7497077549,3.60758904742], "name_fr": "Renaix", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57095", "name": "Mont-de-l'Enclus", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.7381353765,3.50456071547], "name_fr": "Mont-de-l'Enclus", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34040", "name": "Waregem", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.880617704,3.4025030532], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56078", "name": "Thuin", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.324255001,4.3107780431], "name_fr": "Thuin", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61041", "name": "Modave", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4734215737,5.30184833344], "name_fr": "Modave", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11005", "name": "Boom", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0935608982,4.37289596533], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72003", "name": "Bocholt", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.1870089394,5.56388106055], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21001", "name": "Anderlecht", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8289835408,4.29290421593], "name_fr": "Anderlecht", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44020", "name": "Gavere", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.928939511,3.6854993637], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12021", "name": "Lier", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1183617302,4.57390515433], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13044", "name": "Vorselaar", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2176661059,4.77289615253], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91141", "name": "Yvoir", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.3252950018,4.94069509607], "name_fr": "Yvoir", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62079", "name": "Oupeye", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.7211109809,5.64163549477], "name_fr": "Oupeye", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53020", "name": "Dour", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3873578918,3.78697862305], "name_fr": "Dour", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57093", "name": "Brunehaut", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5271272247,3.39505171881], "name_fr": "Brunehaut", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53039", "name": "Hensies", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.435795891,3.71366069699], "name_fr": "Hensies", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24104", "name": "Tervuren", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8216409711,4.5243569052], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "36007", "name": "Ingelmunster", "arrondissement_nis_code": "36000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9214594499,3.26068518426], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51008", "name": "Beloeil", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5325009972,3.68861992463], "name_fr": "Belœil", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44045", "name": "Moerbeke", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1822464866,3.94506582741], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63073", "name": "Stavelot", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4164879473,5.95800166732], "name_fr": "Stavelot", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21004", "name": "Bruxelles", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8729850613,4.37523414913], "name_fr": "Bruxelles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23044", "name": "Liedekerke", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8665208045,4.09303703558], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13031", "name": "Oud-Turnhout", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3207380232,5.00437120563], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11035", "name": "Ranst", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1927939502,4.59062515593], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63012", "name": "Bullange", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.3960370665,6.31387601237], "name_fr": "Bullange", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63079", "name": "Verviers", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5849789514,5.86678622235], "name_fr": "Verviers", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24062", "name": "Leuven", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8821872973,4.70591561557], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57062", "name": "Pecq", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.685154318,3.35678683626], "name_fr": "Pecq", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64047", "name": "Lincent", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.7214407932,5.02758626846], "name_fr": "Lincent", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44084", "name": "Aalter", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0952591981,3.44723679289], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44019", "name": "Evergem", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1433154501,3.70818614661], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25112", "name": "Wavre", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.7157995365,4.59325589624], "name_fr": "Wavre", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44085", "name": "Lievegem", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1270515354,3.5875447731], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "45035", "name": "Oudenaarde", "arrondissement_nis_code": "45000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.853521149,3.62098939211], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52021", "name": "Fleurus", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4863676472,4.53922134527], "name_fr": "Fleurus", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52022", "name": "Fontaine-l'Évêque", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.407359948,4.32180026076], "name_fr": "Fontaine-l'Évêque", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56005", "name": "Beaumont", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.2293103662,4.25922842795], "name_fr": "Beaumont", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92035", "name": "Éghezée", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.5866122248,4.89388930281], "name_fr": "Éghezée", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23009", "name": "Biévène", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7106656817,3.9321753019], "name_fr": "Biévène", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23064", "name": "Pepingen", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7442332088,4.14762593023], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "35006", "name": "Ichtegem", "arrondissement_nis_code": "35000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.1126731001,3.03080701577], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44012", "name": "De Pinte", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9858241187,3.66934272013], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51067", "name": "Enghien", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.6776161511,4.03011403526], "name_fr": "Enghien", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62122", "name": "Trooz", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5681059883,5.70728003618], "name_fr": "Trooz", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "41048", "name": "Ninove", "arrondissement_nis_code": "41000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8260388203,4.00479247295], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24007", "name": "Begijnendijk", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [51.0070032617,4.7846210833], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23099", "name": "Kraainem", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8453900786,4.47134745774], "name_fr": "Kraainem", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84043", "name": "Neufchâteau", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.8510376253,5.43337229192], "name_fr": "Neufchâteau", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25123", "name": "Rebecq", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6714984956,4.13038115754], "name_fr": "Rebecq", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "42023", "name": "Waasmunster", "arrondissement_nis_code": "42000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1186535723,4.07487216245], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44052", "name": "Oosterzele", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9388374039,3.79714613701], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23027", "name": "Halle", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7263957367,4.23697752748], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24020", "name": "Diest", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [51.0014179233,5.06911169816], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13053", "name": "Laakdal", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0809527495,5.0059703471], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "35002", "name": "Bredene", "arrondissement_nis_code": "35000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.2367098231,2.97738115791], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92137", "name": "Sambreville", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.4465497861,4.62074387162], "name_fr": "Sambreville", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63086", "name": "Trois-Ponts", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.3565558145,5.88429012435], "name_fr": "Trois-Ponts", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51017", "name": "Ellezelles", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.7206193368,3.69907376009], "name_fr": "Ellezelles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63038", "name": "Jalhay", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5308347056,5.96647444497], "name_fr": "Jalhay", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64034", "name": "Hannut", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6660227617,5.07310782915], "name_fr": "Hannut", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "31012", "name": "Jabbeke", "arrondissement_nis_code": "31000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.1886293096,3.09635844365], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23047", "name": "Machelen", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.902620936,4.44413555125], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "43014", "name": "Sint-Laureins", "arrondissement_nis_code": "43000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.2599609529,3.58019918603], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73098", "name": "Wellen", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.842649282,5.32342256147], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12014", "name": "Heist-op-den-Berg", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0716410169,4.73603765868], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25105", "name": "Tubize", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6901766427,4.19055843771], "name_fr": "Tubize", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57018", "name": "Celles", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.7037021721,3.44906023048], "name_fr": "Celles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84016", "name": "Daverdisse", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.0041760902,5.06648324802], "name_fr": "Daverdisse", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64056", "name": "Oreye", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.7232618941,5.34730055143], "name_fr": "Oreye", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24134", "name": "Scherpenheuvel-Zichem", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.998084774,4.96856599635], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91059", "name": "Hamois", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.3370251961,5.13960468321], "name_fr": "Hamois", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62011", "name": "Bassenge", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.7655731631,5.61004448963], "name_fr": "Bassenge", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23033", "name": "Hoeilaart", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.761033461,4.45082018071], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23104", "name": "Lennik", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8132603748,4.16369937846], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "58001", "name": "La Louvière", "arrondissement_nis_code": "58000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4734947955,4.15667215421], "name_fr": "La Louvière", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "85034", "name": "Saint-Léger", "arrondissement_nis_code": "85000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.6182368553,5.6764538693], "name_fr": "Saint-Léger", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62100", "name": "Sprimont", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5191962658,5.66638788979], "name_fr": "Sprimont", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "37020", "name": "Ardooie", "arrondissement_nis_code": "37000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9816322459,3.20310202003], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57097", "name": "Comines-Warneton", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.7515686705,2.93037052948], "name_fr": "Comines-Warneton", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21003", "name": "Berchem-Sainte-Agathe", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8646581702,4.29391769346], "name_fr": "Berchem-Sainte-Agathe", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "37015", "name": "Tielt", "arrondissement_nis_code": "37000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0064730981,3.36380040463], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62093", "name": "Saint-Nicolas", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6321800668,5.52644133493], "name_fr": "Saint-Nicolas", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "85047", "name": "Rouvroy", "arrondissement_nis_code": "85000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.5354391207,5.48383715537], "name_fr": "Rouvroy", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11023", "name": "Kapellen", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3339531929,4.44378683938], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92140", "name": "Jemeppe-sur-Sambre", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.4717026936,4.67619345127], "name_fr": "Jemeppe-sur-Sambre", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13006", "name": "Dessel", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2415180165,5.12213547039], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23098", "name": "Drogenbos", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7934959914,4.30904064677], "name_fr": "Drogenbos", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44040", "name": "Melle", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9969685253,3.79629834637], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "45059", "name": "Brakel", "arrondissement_nis_code": "45000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.797947051,3.75799926237], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56051", "name": "Momignies", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.0025546625,4.21754075291], "name_fr": "Momignies", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63087", "name": "Burg-Reuland", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.203531441,6.09301123916], "name_fr": "Burg-Reuland", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "45063", "name": "Lierde", "arrondissement_nis_code": "45000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8054740551,3.83645215934], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71004", "name": "Beringen", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.0654696792,5.23606636339], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73006", "name": "Bilzen", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.8720943489,5.53363669307], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53053", "name": "Mons", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.452419086,3.97193721992], "name_fr": "Mons", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "31043", "name": "Knokke-Heist", "arrondissement_nis_code": "31000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.3307144029,3.31082217781], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34042", "name": "Zwevegem", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.7869514957,3.37255541631], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52018", "name": "Farciennes", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4344393375,4.54694155588], "name_fr": "Farciennes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64029", "name": "Geer", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6696166932,5.18142002398], "name_fr": "Geer", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73040", "name": "Kortessem", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.8530736787,5.40588893204], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71016", "name": "Genk", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.9671739204,5.49492488092], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "46003", "name": "Beveren", "arrondissement_nis_code": "46000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.2540962868,4.23653454536], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "35029", "name": "De Haan", "arrondissement_nis_code": "35000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.2588793647,3.04723245099], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92114", "name": "Sombreffe", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.5266684412,4.60269834213], "name_fr": "Sombreffe", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21016", "name": "Uccle", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.7898712424,4.3613646756], "name_fr": "Uccle", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11053", "name": "Wuustwezel", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3940469017,4.60037150771], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62032", "name": "Esneux", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5376006627,5.56887084254], "name_fr": "Esneux", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "36019", "name": "Staden", "arrondissement_nis_code": "36000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9537505104,3.0189096618], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25044", "name": "Ittre", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6424665166,4.25751311248], "name_fr": "Ittre", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64063", "name": "Remicourt", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6856782143,5.34121826198], "name_fr": "Remicourt", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73028", "name": "Herstappe", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.7259675594,5.42540946559], "name_fr": "Herstappe", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34013", "name": "Harelbeke", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8638200714,3.30736253608], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52012", "name": "Châtelet", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3971533102,4.52241118297], "name_fr": "Châtelet", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72042", "name": "Oudsbergen", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.0749327212,5.55632319585], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73066", "name": "Riemst", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.8053676648,5.59230689259], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24086", "name": "Oud-Heverlee", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8200058058,4.6869717416], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56016", "name": "Chimay", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.0409155755,4.32426477332], "name_fr": "Chimay", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52015", "name": "Courcelles", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4724442914,4.34105619277], "name_fr": "Courcelles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "41002", "name": "Aalst", "arrondissement_nis_code": "41000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9447094639,4.06872631774], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "81001", "name": "Arlon", "arrondissement_nis_code": "81000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.6751904812,5.79388742388], "name_fr": "Arlon", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12029", "name": "Putte", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0506851737,4.63635443351], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "38016", "name": "Nieuwpoort", "arrondissement_nis_code": "38000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.1232753882,2.7673279461], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25118", "name": "Hélécine", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.7483495898,4.98273959698], "name_fr": "Hélécine", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91015", "name": "Bièvre", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [49.921995095,5.01964958396], "name_fr": "Bièvre", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23002", "name": "Asse", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9072808195,4.21534487771], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "83013", "name": "Érezée", "arrondissement_nis_code": "83000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.2965885113,5.55781535595], "name_fr": "Érezée", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25121", "name": "Ottignies-Louvain-la-Neuve", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6703547675,4.56412181357], "name_fr": "Ottignies-Louvain-la-Neuve", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64021", "name": "Crisnée", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.710604534,5.39683796698], "name_fr": "Crisnée", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57072", "name": "Rumes", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5444982625,3.31981590288], "name_fr": "Rumes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92138", "name": "Fernelmont", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.5539748009,4.98722400293], "name_fr": "Fernelmont", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24133", "name": "Linter", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8207863005,5.04199287443], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "83028", "name": "Hotton", "arrondissement_nis_code": "83000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.2673649172,5.43278799063], "name_fr": "Hotton", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72020", "name": "Lommel", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.225586123,5.30205821462], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63061", "name": "Raeren", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6647975702,6.12474510594], "name_fr": "Raeren", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23105", "name": "Affligem", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9041654719,4.11572268765], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92094", "name": "Namur", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.4604502381,4.87259367323], "name_fr": "Namur", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53065", "name": "Quaregnon", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4377995284,3.85870490339], "name_fr": "Quaregnon", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "33011", "name": "Ieper", "arrondissement_nis_code": "33000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8543398156,2.86646721791], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24041", "name": "Hoegaarden", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7791342597,4.87653334052], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63058", "name": "Pepinster", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5722034354,5.79704490761], "name_fr": "Pepinster", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64023", "name": "Donceel", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6493702432,5.32601771167], "name_fr": "Donceel", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61024", "name": "Hamoir", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4373306987,5.55500874663], "name_fr": "Hamoir", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62038", "name": "Fléron", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6151777797,5.68402183448], "name_fr": "Fléron", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "42011", "name": "Lebbeke", "arrondissement_nis_code": "42000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9925224512,4.11887315982], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24001", "name": "Aarschot", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9889803355,4.86460808179], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12041", "name": "Puurs-Sint-Amands", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0611534662,4.28183930792], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "37017", "name": "Wielsbeke", "arrondissement_nis_code": "37000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9120403253,3.36731305483], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11044", "name": "Stabroek", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3285785483,4.37672870961], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23038", "name": "Kampenhout", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9412904924,4.56066985966], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51019", "name": "Flobecq", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.7535752007,3.73166593352], "name_fr": "Flobecq", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61080", "name": "Engis", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5575996596,5.38881982052], "name_fr": "Engis", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "93010", "name": "Cerfontaine", "arrondissement_nis_code": "93000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.1795299656,4.43673867489], "name_fr": "Cerfontaine", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "81013", "name": "Martelange", "arrondissement_nis_code": "81000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.8111368926,5.72479004974], "name_fr": "Martelange", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64074", "name": "Waremme", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6938542757,5.26692344182], "name_fr": "Waremme", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "33040", "name": "Langemark-Poelkapelle", "arrondissement_nis_code": "33000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9159196013,2.92938502041], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44043", "name": "Merelbeke", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.974360356,3.74074293874], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "93022", "name": "Florennes", "arrondissement_nis_code": "93000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.2538109337,4.63117182946], "name_fr": "Florennes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11013", "name": "Edegem", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.156255093,4.43283026955], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21005", "name": "Etterbeek", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8329848493,4.39459805239], "name_fr": "Etterbeek", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84050", "name": "Paliseul", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.9077330231,5.1369218845], "name_fr": "Paliseul", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "83055", "name": "Manhay", "arrondissement_nis_code": "83000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.2890150684,5.66269846013], "name_fr": "Manhay", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "46014", "name": "Lokeren", "arrondissement_nis_code": "46000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1133213635,3.96140421834], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25023", "name": "Court-Saint-Étienne", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6264491177,4.56260071554], "name_fr": "Court-Saint-Étienne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "31033", "name": "Torhout", "arrondissement_nis_code": "31000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0623344759,3.09548535851], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21002", "name": "Auderghem", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8101948532,4.43800267228], "name_fr": "Auderghem", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13023", "name": "Merksplas", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3704312952,4.8702131688], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "42010", "name": "Laarne", "arrondissement_nis_code": "42000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0424204905,3.88421256864], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24107", "name": "Tienen", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8119574824,4.94188382081], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21008", "name": "Ganshoren", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8744058626,4.30944538223], "name_fr": "Ganshoren", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "46020", "name": "Sint-Gillis-Waas", "arrondissement_nis_code": "46000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.2295525577,4.11888577764], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "82038", "name": "Sainte-Ode", "arrondissement_nis_code": "82000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.0249454837,5.52799664728], "name_fr": "Sainte-Ode", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11050", "name": "Wijnegem", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2295826843,4.51687846073], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23086", "name": "Ternat", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8678334656,4.16021068065], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "81015", "name": "Messancy", "arrondissement_nis_code": "81000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.6147645602,5.81126797406], "name_fr": "Messancy", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71069", "name": "Ham", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.1002269059,5.16083314459], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "41018", "name": "Geraardsbergen", "arrondissement_nis_code": "41000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.7796430995,3.90406385569], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63023", "name": "Eupen", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6099975368,6.12552248824], "name_fr": "Eupen", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25048", "name": "Jodoigne", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.728388131,4.86033841848], "name_fr": "Jodoigne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "93088", "name": "Walcourt", "arrondissement_nis_code": "93000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.2666110088,4.43495008043], "name_fr": "Walcourt", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "42028", "name": "Zele", "arrondissement_nis_code": "42000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0673358748,4.03591339796], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92141", "name": "La Bruyère", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.5297519095,4.80978281416], "name_fr": "La Bruyère", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72043", "name": "Pelt", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.2149294202,5.42721650755], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25015", "name": "Braine-le-Château", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6796675667,4.28408503759], "name_fr": "Braine-le-Château", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "81004", "name": "Aubange", "arrondissement_nis_code": "81000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.5749022181,5.77698090644], "name_fr": "Aubange", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23101", "name": "Rhode-Saint-Genèse", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.744494949,4.37373296201], "name_fr": "Rhode-Saint-Genèse", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "45068", "name": "Kruisem", "arrondissement_nis_code": "45000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9080632335,3.56094044352], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21009", "name": "Ixelles", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8223200872,4.37707484596], "name_fr": "Ixelles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23025", "name": "Grimbergen", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9446773059,4.37655576158], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23081", "name": "Steenokkerzeel", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9210250911,4.49924584665], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "33041", "name": "Vleteren", "arrondissement_nis_code": "33000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9137099726,2.74157286653], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91120", "name": "Somme-Leuze", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.2996525387,5.31877690041], "name_fr": "Somme-Leuze", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "31005", "name": "Brugge", "arrondissement_nis_code": "31000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.247685556,3.21502031941], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62027", "name": "Dalhem", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.7207318591,5.75902999063], "name_fr": "Dalhem", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "35011", "name": "Middelkerke", "arrondissement_nis_code": "35000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.1522732686,2.84044112161], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11039", "name": "Schilde", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2561706371,4.58147908054], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25014", "name": "Braine-l'Alleud", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.673330782,4.35469400841], "name_fr": "Braine-l'Alleud", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73109", "name": "Fourons", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.7453248297,5.82267071798], "name_fr": "Fourons", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71034", "name": "Leopoldsburg", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.1174094569,5.25047337552], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "43018", "name": "Zelzate", "arrondissement_nis_code": "43000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1991574144,3.80859731833], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "81003", "name": "Attert", "arrondissement_nis_code": "81000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.7455319355,5.75749521023], "name_fr": "Attert", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "32003", "name": "Diksmuide", "arrondissement_nis_code": "32000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0473847405,2.85423452466], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53068", "name": "Quiévrain", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3958363017,3.70243863147], "name_fr": "Quiévrain", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23088", "name": "Vilvoorde", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9302566035,4.43391027097], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11009", "name": "Brecht", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3359402365,4.62819770408], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "42025", "name": "Wetteren", "arrondissement_nis_code": "42000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9944916964,3.87023289759], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62009", "name": "Aywaille", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.457506318,5.70155439854], "name_fr": "Aywaille", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63084", "name": "Welkenraedt", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6634446746,5.9392802782], "name_fr": "Welkenraedt", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61063", "name": "Verlaine", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6088028533,5.30225167655], "name_fr": "Verlaine", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21010", "name": "Jette", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8817195895,4.32138610038], "name_fr": "Jette", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11030", "name": "Niel", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1072923013,4.33555032201], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52011", "name": "Charleroi", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4210525981,4.43459890641], "name_fr": "Charleroi", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61079", "name": "Anthisnes", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4894160434,5.49857868964], "name_fr": "Anthisnes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51004", "name": "Ath", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.639363038,3.77289533995], "name_fr": "Ath", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "82003", "name": "Bastogne", "arrondissement_nis_code": "82000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.015742482,5.76154684722], "name_fr": "Bastogne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56029", "name": "Froidchapelle", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.165217559,4.347489648], "name_fr": "Froidchapelle", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12005", "name": "Bonheiden", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0208072402,4.57075509], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "55035", "name": "Le Roeulx", "arrondissement_nis_code": "55000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5073050686,4.10616502176], "name_fr": "Le Rœulx", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51069", "name": "Lessines", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.7125318051,3.83307827533], "name_fr": "Lessines", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24043", "name": "Holsbeek", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9296943174,4.80183828679], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "32011", "name": "Kortemark", "arrondissement_nis_code": "32000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0247814734,3.00178942625], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71057", "name": "Tessenderlo", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.0547877046,5.06954363279], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72037", "name": "Hamont-Achel", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.2601479353,5.50730652159], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52055", "name": "Pont-à-Celles", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.513891384,4.38132529709], "name_fr": "Pont-à-Celles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13016", "name": "Hulshout", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0628205934,4.8124749719], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84059", "name": "Saint-Hubert", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.0379100799,5.34559912771], "name_fr": "Saint-Hubert", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "45065", "name": "Zwalm", "arrondissement_nis_code": "45000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8772345569,3.72399238145], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34025", "name": "Lendelede", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8859915811,3.23202559131], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72018", "name": "Kinrooi", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.1530045578,5.76015437684], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91142", "name": "Hastière", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.1949937677,4.82573385882], "name_fr": "Hastière", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92097", "name": "Ohey", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.435753383,5.16636525745], "name_fr": "Ohey", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44064", "name": "Sint-Martens-Latem", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0095683406,3.622211519], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13025", "name": "Mol", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2337413441,5.15281575407], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21015", "name": "Schaerbeek", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8618085524,4.38605735304], "name_fr": "Schaerbeek", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "93090", "name": "Viroinval", "arrondissement_nis_code": "93000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.0538806599,4.62525614215], "name_fr": "Viroinval", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25043", "name": "Incourt", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6991059664,4.78147584791], "name_fr": "Incourt", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "58003", "name": "Estinnes", "arrondissement_nis_code": "58000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.37875029,4.10061800979], "name_fr": "Estinnes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44013", "name": "Destelbergen", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0442136803,3.80936474157], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34003", "name": "Avelgem", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.7776482612,3.453719179], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12040", "name": "Willebroek", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0539542585,4.36854133096], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24045", "name": "Huldenberg", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.789788337,4.61190687843], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62119", "name": "Blegny", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6744860868,5.7089108431], "name_fr": "Blegny", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56086", "name": "Ham-sur-Heure-Nalinnes", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3274099013,4.41386783957], "name_fr": "Ham-sur-Heure-Nalinnes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21012", "name": "Molenbeek-Saint-Jean", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8552305953,4.31841884165], "name_fr": "Molenbeek-Saint-Jean", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72041", "name": "Dilsen-Stokkem", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.0359808116,5.71209701848], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24137", "name": "Glabbeek", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8670096255,4.94943536286], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23003", "name": "Beersel", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7504045325,4.30184220354], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "83031", "name": "La Roche-en-Ardenne", "arrondissement_nis_code": "83000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.1670924504,5.60498414177], "name_fr": "La Roche-en-Ardenne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24094", "name": "Rotselaar", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9595550799,4.73228347805], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71011", "name": "Diepenbeek", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.9114855011,5.41742554196], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62121", "name": "Neupré", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5440729661,5.48805795556], "name_fr": "Neupré", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "46021", "name": "Sint-Niklaas", "arrondissement_nis_code": "46000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1647654285,4.10839820144], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "82037", "name": "Gouvy", "arrondissement_nis_code": "82000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.1925752644,5.91145924934], "name_fr": "Gouvy", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25119", "name": "Lasne", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6823369302,4.46158401545], "name_fr": "Lasne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13003", "name": "Balen", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.158613406,5.18296008734], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71066", "name": "Zonhoven", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.9919398732,5.3766581986], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71037", "name": "Lummen", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.9953098733,5.18328014615], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12009", "name": "Duffel", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0957175507,4.51414753158], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23039", "name": "Kapelle-op-den-Bos", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [51.0036602083,4.35465282448], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "85024", "name": "Meix-devant-Virton", "arrondissement_nis_code": "85000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.6036714106,5.46997319279], "name_fr": "Meix-devant-Virton", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25084", "name": "Perwez", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6341673519,4.78987865908], "name_fr": "Perwez", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13037", "name": "Rijkevorsel", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3551529454,4.76749734978], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24014", "name": "Boortmeerbeek", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9800997782,4.56084659428], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23102", "name": "Wemmel", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9095844404,4.3093249167], "name_fr": "Wemmel", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13012", "name": "Herenthout", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1391596436,4.75993131116], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11055", "name": "Zoersel", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2575655293,4.6698127355], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "37018", "name": "Wingene", "arrondissement_nis_code": "37000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0595098919,3.25994409487], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53083", "name": "Honnelles", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3514835648,3.72470878887], "name_fr": "Honnelles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "93014", "name": "Couvin", "arrondissement_nis_code": "93000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.0378830571,4.4809248275], "name_fr": "Couvin", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23062", "name": "Overijse", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7713129243,4.53287720849], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "82005", "name": "Bertogne", "arrondissement_nis_code": "82000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.0631947581,5.6461394678], "name_fr": "Bertogne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57027", "name": "Estaimpuis", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.6876577267,3.28846027965], "name_fr": "Estaimpuis", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "83044", "name": "Rendeux", "arrondissement_nis_code": "83000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.2193349172,5.51606295279], "name_fr": "Rendeux", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62108", "name": "Visé", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.7385714064,5.68752347976], "name_fr": "Visé", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "37010", "name": "Oostrozebeke", "arrondissement_nis_code": "37000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9305946442,3.33721898718], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "38008", "name": "De Panne", "arrondissement_nis_code": "38000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0785001545,2.58995324488], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91143", "name": "Vresse-sur-Semois", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [49.8503138411,4.91573970041], "name_fr": "Vresse-sur-Semois", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62022", "name": "Chaudfontaine", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5814623946,5.63373102487], "name_fr": "Chaudfontaine", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11008", "name": "Brasschaat", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3095091503,4.50042452992], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "41034", "name": "Lede", "arrondissement_nis_code": "41000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9661329013,3.94563715613], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63020", "name": "Dison", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6139791735,5.87450755063], "name_fr": "Dison", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "55050", "name": "Écaussinnes", "arrondissement_nis_code": "55000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5627131323,4.17944183669], "name_fr": "Écaussinnes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57096", "name": "Mouscron", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.7364097455,3.25108019127], "name_fr": "Mouscron", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51012", "name": "Brugelette", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.6020296334,3.86719399424], "name_fr": "Brugelette", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13014", "name": "Hoogstraten", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.4404538634,4.76369806223], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44081", "name": "Zulte", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9373510353,3.47263375493], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "42008", "name": "Hamme", "arrondissement_nis_code": "42000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0780696198,4.13990587412], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24109", "name": "Tremelo", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9991903038,4.72673652403], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23103", "name": "Wezembeek-Oppem", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8430902187,4.49242710913], "name_fr": "Wezembeek-Oppem", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63072", "name": "Spa", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4720230196,5.86707127425], "name_fr": "Spa", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11025", "name": "Lint", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1276962012,4.49781722239], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13011", "name": "Herentals", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1638224559,4.82761621869], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84035", "name": "Libin", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.9781184103,5.23815895124], "name_fr": "Libin", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23050", "name": "Meise", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9611441601,4.31565209073], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21013", "name": "Saint-Gilles", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8294787556,4.34469902888], "name_fr": "Saint-Gilles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "83012", "name": "Durbuy", "arrondissement_nis_code": "83000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.3607258978,5.48827608984], "name_fr": "Durbuy", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63045", "name": "Lierneux", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.3078242446,5.78553374173], "name_fr": "Lierneux", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "85007", "name": "Chiny", "arrondissement_nis_code": "85000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.72012427,5.39146106165], "name_fr": "Chiny", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21019", "name": "Woluwe-Saint-Pierre", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8310993328,4.44309634049], "name_fr": "Woluwe-Saint-Pierre", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25117", "name": "Chastre", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.5894409476,4.62326555141], "name_fr": "Chastre", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92003", "name": "Andenne", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.4867412663,5.05783205997], "name_fr": "Andenne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25068", "name": "Mont-Saint-Guibert", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6373481102,4.62326738605], "name_fr": "Mont-Saint-Guibert", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "38002", "name": "Alveringem", "arrondissement_nis_code": "38000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9757663594,2.67777309848], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13002", "name": "Baarle-Hertog", "arrondissement_nis_code": "undefined", "nis_code_province": "undefined", "region_nis_code": "undefined", "geo_point_2d": [51.4162255951,4.90359715421], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25018", "name": "Chaumont-Gistoux", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6871496197,4.69060675366], "name_fr": "Chaumont-Gistoux", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44034", "name": "Lochristi", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1030894963,3.85711438883], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73083", "name": "Tongeren", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.7717881947,5.46443143915], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "43007", "name": "Kaprijke", "arrondissement_nis_code": "43000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.2080451933,3.62723651589], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72038", "name": "Hechtel-Eksel", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.1384474488,5.34648085629], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21011", "name": "Koekelberg", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8630145492,4.32408190468], "name_fr": "Koekelberg", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13035", "name": "Ravels", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.4149185847,5.02596285844], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11018", "name": "Hemiksem", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1437298161,4.34018794509], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92045", "name": "Floreffe", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.4350034829,4.75571774775], "name_fr": "Floreffe", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "32030", "name": "Lo-Reninge", "arrondissement_nis_code": "32000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9606389733,2.77536150409], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "31003", "name": "Beernem", "arrondissement_nis_code": "31000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.1506914195,3.33666641468], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13040", "name": "Turnhout", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3289661154,4.94016831579], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84068", "name": "Tellin", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.0754385717,5.22566292792], "name_fr": "Tellin", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53084", "name": "Quévy", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3665815848,3.95435874651], "name_fr": "Quévy", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51065", "name": "Frasnes-lez-Anvaing", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.6823874413,3.60070535748], "name_fr": "Frasnes-lez-Anvaing", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62118", "name": "Grâce-Hollogne", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.637748483,5.43947534352], "name_fr": "Grâce-Hollogne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "43010", "name": "Maldegem", "arrondissement_nis_code": "43000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1981573016,3.45032460644], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57064", "name": "Péruwelz", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5294369667,3.56387702766], "name_fr": "Péruwelz", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "41024", "name": "Haaltert", "arrondissement_nis_code": "41000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8846745203,3.99335544473], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "82036", "name": "Vaux-sur-Sûre", "arrondissement_nis_code": "82000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.9342965283,5.60659222549], "name_fr": "Vaux-sur-Sûre", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "41063", "name": "Sint-Lievens-Houtem", "arrondissement_nis_code": "41000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9310136276,3.87585147922], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "31042", "name": "Zuienkerke", "arrondissement_nis_code": "31000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.2539497213,3.13138020577], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13049", "name": "Westerlo", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1064311567,4.88190054583], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "46025", "name": "Temse", "arrondissement_nis_code": "46000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.133125089,4.20566191042], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "43002", "name": "Assenede", "arrondissement_nis_code": "43000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.2271335945,3.71485157274], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92101", "name": "Profondeville", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.3752106127,4.8286802243], "name_fr": "Profondeville", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24048", "name": "Keerbergen", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [51.0052028767,4.65430222104], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63013", "name": "Butgenbach", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4645216963,6.209282186], "name_fr": "Butgenbach", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13021", "name": "Meerhout", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1245374646,5.07357649226], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "35005", "name": "Gistel", "arrondissement_nis_code": "35000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.1511494143,2.94315824504], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12026", "name": "Nijlen", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1509031236,4.66358552204], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63003", "name": "Aubel", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.7004844863,5.85135740665], "name_fr": "Aubel", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52010", "name": "Chapelle-lez-Herlaimont", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4624624708,4.28754971453], "name_fr": "Chapelle-lez-Herlaimont", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24028", "name": "Geetbets", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8914807326,5.13114027732], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84010", "name": "Bouillon", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.8059858535,5.08319311206], "name_fr": "Bouillon", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61081", "name": "Tinlot", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4751069949,5.38017759975], "name_fr": "Tinlot", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "37011", "name": "Pittem", "arrondissement_nis_code": "37000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0010040042,3.26556590647], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13017", "name": "Kasterlee", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2432537454,4.94203300284], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62099", "name": "Soumagne", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6325549363,5.73126913323], "name_fr": "Soumagne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25050", "name": "La Hulpe", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.7362969402,4.46483304023], "name_fr": "La Hulpe", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62026", "name": "Comblain-au-Pont", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.481701734,5.5799388086], "name_fr": "Comblain-au-Pont", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "36012", "name": "Moorslede", "arrondissement_nis_code": "36000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8808121463,3.07895929963], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34009", "name": "Deerlijk", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8425579336,3.3661679393], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "37007", "name": "Meulebeke", "arrondissement_nis_code": "37000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9510524265,3.29504155339], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63001", "name": "Amblève", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.3455696272,6.18143333466], "name_fr": "Amblève", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21017", "name": "Watermael-Boitsfort", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.7929199005,4.42347907979], "name_fr": "Watermael-Boitsfort", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24055", "name": "Kortenberg", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.889936312,4.56880569581], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12002", "name": "Berlaar", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1071524746,4.65615560451], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73001", "name": "Alken", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.8784046781,5.29056731645], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61019", "name": "Ferrières", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4072124652,5.62080957895], "name_fr": "Ferrières", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62051", "name": "Herstal", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6814088413,5.60651237601], "name_fr": "Herstal", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24016", "name": "Boutersem", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.83484097,4.84250173718], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71017", "name": "Gingelom", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.7374161236,5.16950030092], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "85026", "name": "Musson", "arrondissement_nis_code": "85000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.5694276995,5.6809737462], "name_fr": "Musson", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11024", "name": "Kontich", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1237001897,4.44454057918], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34043", "name": "Espierres-Helchin", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.7305584752,3.36278262432], "name_fr": "Espierres-Helchin", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57003", "name": "Antoing", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5561483279,3.46572079355], "name_fr": "Antoing", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13046", "name": "Vosselaar", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3052915595,4.88509399295], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11054", "name": "Zandhoven", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2113079596,4.67924364593], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "42003", "name": "Berlare", "arrondissement_nis_code": "42000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0362719931,3.97926970218], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73042", "name": "Lanaken", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.8994518187,5.64435676444], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "31040", "name": "Zedelgem", "arrondissement_nis_code": "31000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.1266417278,3.13432885201], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24135", "name": "Tielt-Winge", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9233873544,4.8965554662], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "55085", "name": "Seneffe", "arrondissement_nis_code": "55000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5478025557,4.26262601457], "name_fr": "Seneffe", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52074", "name": "Aiseau-Presles", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4049352564,4.5769112727], "name_fr": "Aiseau-Presles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44021", "name": "Gent", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0744574706,3.72553163628], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11056", "name": "Zwijndrecht", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2245954122,4.32330210947], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56001", "name": "Anderlues", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4036133187,4.26566533854], "name_fr": "Anderlues", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62063", "name": "Liège", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6317806096,5.59047864578], "name_fr": "Liège", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24008", "name": "Bekkevoort", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9343591503,4.97787674063], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23096", "name": "Zemst", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9789097669,4.45809146235], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84075", "name": "Wellin", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.0601368653,5.09849412946], "name_fr": "Wellin", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "36011", "name": "Lichtervelde", "arrondissement_nis_code": "36000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0277654193,3.14612212633], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11052", "name": "Wommelgem", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2067790127,4.51894617187], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84029", "name": "Herbeumont", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.7953353629,5.30941032174], "name_fr": "Herbeumont", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72039", "name": "Houthalen-Helchteren", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.0453487037,5.4084313109], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23045", "name": "Londerzeel", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [51.010580338,4.27640786184], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23077", "name": "Sint-Pieters-Leeuw", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7874726646,4.24486572976], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "38014", "name": "Koksijde", "arrondissement_nis_code": "38000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.109334583,2.68253473657], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23100", "name": "Linkebeek", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.767945822,4.34541269697], "name_fr": "Linkebeek", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13013", "name": "Herselt", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0473768868,4.88302998999], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "55004", "name": "Braine-le-Comte", "arrondissement_nis_code": "55000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.6161702095,4.1424512671], "name_fr": "Braine-le-Comte", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91034", "name": "Dinant", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.2494874895,4.9432206355], "name_fr": "Dinant", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63048", "name": "Lontzen", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6777186946,6.01249895932], "name_fr": "Lontzen", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11022", "name": "Kalmthout", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.398861016,4.46868779751], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71067", "name": "Zutendaal", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.9328783296,5.57540412286], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51014", "name": "Chièvres", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5711770537,3.78391668695], "name_fr": "Chièvres", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "35014", "name": "Oudenburg", "arrondissement_nis_code": "35000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.1860336104,3.01233746603], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61028", "name": "Héron", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5404488675,5.10537352797], "name_fr": "Héron", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "42026", "name": "Wichelen", "arrondissement_nis_code": "42000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9987549301,3.95347453875], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "84033", "name": "Léglise", "arrondissement_nis_code": "84000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.8098959933,5.56669540705], "name_fr": "Léglise", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11038", "name": "Schelle", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.122670099,4.34268025491], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "57081", "name": "Tournai", "arrondissement_nis_code": "57000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.6162145436,3.4079806796], "name_fr": "Tournai", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72021", "name": "Maaseik", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.0878455124,5.71519810446], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63035", "name": "Herve", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6487195295,5.79626821338], "name_fr": "Herve", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23052", "name": "Merchtem", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.9512075895,4.24715105873], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63057", "name": "Olne", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5873632877,5.7319648089], "name_fr": "Olne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "41081", "name": "Zottegem", "arrondissement_nis_code": "41000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8676644088,3.80553466541], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25072", "name": "Nivelles", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.5969822622,4.33219761406], "name_fr": "Nivelles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "64008", "name": "Berloz", "arrondissement_nis_code": "64000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.7037737067,5.20308535223], "name_fr": "Berloz", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73107", "name": "Maasmechelen", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.9689209775,5.67791616504], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "46024", "name": "Stekene", "arrondissement_nis_code": "46000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.2123143876,4.02873397891], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91013", "name": "Beauraing", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.0931303237,4.9525750256], "name_fr": "Beauraing", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "41011", "name": "Denderleeuw", "arrondissement_nis_code": "41000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8863289761,4.05812785533], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61072", "name": "Wanze", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5502954068,5.19791597282], "name_fr": "Wanze", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71070", "name": "Heusden-Zolder", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.0199165881,5.28915409424], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "72030", "name": "Peer", "arrondissement_nis_code": "72000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [51.1284355384,5.46159229759], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11016", "name": "Essen", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.4495030673,4.46589211798], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25091", "name": "Rixensart", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.7208266611,4.52562265595], "name_fr": "Rixensart", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "11007", "name": "Borsbeek", "arrondissement_nis_code": "11000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.1914579243,4.48768725465], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "82014", "name": "Houffalize", "arrondissement_nis_code": "82000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.1443940432,5.77093718255], "name_fr": "Houffalize", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "63076", "name": "Theux", "arrondissement_nis_code": "63000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.5099855237,5.81761450263], "name_fr": "Theux", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21007", "name": "Forest", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8140836232,4.32417899627], "name_fr": "Forest", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "92054", "name": "Gesves", "arrondissement_nis_code": "92000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.4224394518,5.05340464558], "name_fr": "Gesves", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21018", "name": "Woluwe-Saint-Lambert", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8478940331,4.4315468545], "name_fr": "Woluwe-Saint-Lambert", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "21006", "name": "Evere", "arrondissement_nis_code": "21000", "nis_code_province": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8700197032,4.40824051886], "name_fr": "Evere", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23097", "name": "Roosdaal", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8375108015,4.09736809546], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "37002", "name": "Dentergem", "arrondissement_nis_code": "37000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9532257487,3.40655892621], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "73009", "name": "Borgloon", "arrondissement_nis_code": "73000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.8047817158,5.34093095159], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "61012", "name": "Clavier", "arrondissement_nis_code": "61000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4129300248,5.33586271025], "name_fr": "Clavier", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "53014", "name": "Boussu", "arrondissement_nis_code": "53000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4271443036,3.79961369825], "name_fr": "Boussu", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44073", "name": "Wachtebeke", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1804061955,3.87326879836], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71053", "name": "Sint-Truiden", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.8103374316,5.20298388005], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24011", "name": "Bierbeek", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8301475092,4.76746818724], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44048", "name": "Nazareth", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.9570058293,3.60992160609], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "45060", "name": "Kluisbergen", "arrondissement_nis_code": "45000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [50.7796892025,3.53010129014], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "62006", "name": "Awans", "arrondissement_nis_code": "62000", "nis_code_province": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6928265582,5.45516738163], "name_fr": "Awans", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "83040", "name": "Nassogne", "arrondissement_nis_code": "83000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.1366313246,5.35879247189], "name_fr": "Nassogne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "83049", "name": "Tenneville", "arrondissement_nis_code": "83000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [50.0997111147,5.49012534642], "name_fr": "Tenneville", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24054", "name": "Kortenaken", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8931301925,5.0231127163], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "36008", "name": "Izegem", "arrondissement_nis_code": "36000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9210851134,3.20549662288], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56088", "name": "Sivry-Rance", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.1600173565,4.21052751175], "name_fr": "Sivry-Rance", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "24059", "name": "Landen", "arrondissement_nis_code": "24000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7546904338,5.06164105439], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "58004", "name": "Morlanwelz", "arrondissement_nis_code": "58000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4475068261,4.24361526733], "name_fr": "Morlanwelz", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "56044", "name": "Lobbes", "arrondissement_nis_code": "56000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3558144875,4.24179772492], "name_fr": "Lobbes", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "35013", "name": "Oostende", "arrondissement_nis_code": "35000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [51.2094584049,2.92645770302], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "71022", "name": "Hasselt", "arrondissement_nis_code": "71000", "nis_code_province": "70000", "region_nis_code": "02000", "geo_point_2d": [50.9354410581,5.30823006271], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12025", "name": "Mechelen", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0286636964,4.45023546552], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "12035", "name": "Sint-Katelijne-Waver", "arrondissement_nis_code": "12000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0623979268,4.53629042013], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "91114", "name": "Rochefort", "arrondissement_nis_code": "91000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.1569937455,5.16919354471], "name_fr": "Rochefort", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "44083", "name": "Deinze", "arrondissement_nis_code": "44000", "nis_code_province": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0177582005,3.53161328016], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "25037", "name": "Grez-Doiceau", "arrondissement_nis_code": "25000", "nis_code_province": "20002", "region_nis_code": "03000", "geo_point_2d": [50.7484596835,4.68804537912], "name_fr": "Grez-Doiceau", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "23032", "name": "Herne", "arrondissement_nis_code": "23000", "nis_code_province": "20001", "region_nis_code": "02000", "geo_point_2d": [50.7228829347,4.03672872298], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "82009", "name": "Fauvillers", "arrondissement_nis_code": "82000", "nis_code_province": "80000", "region_nis_code": "03000", "geo_point_2d": [49.8769799232,5.69657228519], "name_fr": "Fauvillers", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34023", "name": "Kuurne", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.860164841,3.27159968158], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "33029", "name": "Wervik", "arrondissement_nis_code": "33000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8101749969,3.05653383971], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "52048", "name": "Montigny-le-Tilleul", "arrondissement_nis_code": "52000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.3762370551,4.37131851943], "name_fr": "Montigny-le-Tilleul", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "93018", "name": "Doische", "arrondissement_nis_code": "93000", "nis_code_province": "90000", "region_nis_code": "03000", "geo_point_2d": [50.1442482297,4.69903866395], "name_fr": "Doische", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "51009", "name": "Bernissart", "arrondissement_nis_code": "51000", "nis_code_province": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4795154683,3.68655114441], "name_fr": "Bernissart", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34027", "name": "Menen", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.7924948365,3.15438934224], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "34041", "name": "Wevelgem", "arrondissement_nis_code": "34000", "nis_code_province": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8313151738,3.17401582402], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "C","niscode": "13004", "name": "Beerse", "arrondissement_nis_code": "13000", "nis_code_province": "10000", "region_nis_code": "02000", "geo_point_2d": [51.3144009416,4.82332852075], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
];

export default Communes;