import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, CardActions } from '@material-ui/core';
import { TranslationContext } from 'contexts/TranslationContext';

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'flex-start'
  },
  ...theme.common,
}));

const Footer = ({ onClickHandler }) => {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);
  
  return (
    <CardActions className={classes.actions}>
      <Button
        color="primary"
        size="small"
        variant="text"
        onClick={onClickHandler}
      >
        {t("signed-mandat-load-more")}
        {/* <ArrowRightIcon /> */}
      </Button>
    </CardActions>
  );
};

export default Footer;