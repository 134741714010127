import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ConnectPopUpContext } from '../../../../../../contexts/PopUpContext';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1)
  },
}));

const  RendezVous = ({ popUpContext }) => {
  const classes = useStyles();
  
  const { propertyId, checkValues, closePopup, saveUserAction } =  popUpContext;
  const [dateError, setDateError] = useState(false);
  const [formState, setFormState] = useState({
    state: 0, // meaning it is a RDV
    propertyId,
    ownerPhoneNumber: null,
    ownerAddress: null,
    rendezVousDate: null,
    note: null,
  });

  const updateProp = (propName, propValue) => {
    setFormState({
      ...formState,
      [propName]: propValue
    });
  }

  const submit = (event) => {
    event.preventDefault();
    // If we didn't set the date (the others prop are required by the form to submit)
    if (!formState.rendezVousDate) {
      setDateError(true);
      return;
    }

    if (checkValues(formState)) {
      saveUserAction(formState);
      closePopup();
    } else {
      console.log("Error", formState);
      alert("Something happened... try later");
    }
  }

  return (
    <form className={classes.root} autoComplete="off" onSubmit={submit}>
      <Typography variant="h5">Formulaire de rendez-vous</Typography>
      <br/>
      <Grid item container xs={12}>
        <Grid item xs={12} sm={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <React.Fragment>
              {
                formState.rendezVousDate && (
                  <Typography style={{ fontSize: 'x-small', marginTop: '-5px' }}>
                      Date du rdv*
                  </Typography>
                )
              }
              <DateTimePicker
                style={{ marginTop: formState.rendezVousDate ? 0 : '16px', width: '100%' }}
                emptyLabel='Date du rdv*'
                value={formState.rendezVousDate}
                ampm={false}
                autoOk={true}
                disablePast={true}
                required
                onChange={date => { updateProp('rendezVousDate', date); setDateError(false); }}
              />
              {
                dateError && (
                  <Typography style={{ fontSize: 'x-small', color: 'red' }}>
                      Date du rdv (champ obligatoire)*
                  </Typography>
                )
              }
            </React.Fragment>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name-required"
            label="Nom du Client"
            fullWidth
            onChange={e => updateProp('ownerName', e.target.value.trim())}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="phone-required"
            label="Numéro de téléphone"
            fullWidth
            onChange={e => updateProp('ownerPhoneNumber', e.target.value.trim())}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="adress-required"
            label="Adresse"
            fullWidth
            onChange={e => updateProp('ownerAddress', e.target.value.trim())}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="standard-multiline-static"
            label="Notes Supplémentaires"
            multiline
            fullWidth
            rows={2}
            onChange={e => updateProp('note', e.target.value.trim())}
          />
        </Grid>
      </Grid>
      <Button
        disabled={false}
        className={classes.button}
        variant="contained"
        type='submit'
        color="primary">
        Enregistrer
      </Button>
    </form>
  );
}
export default ConnectPopUpContext(RendezVous)