import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

const NoResponseSubForm = ({ updateProp }) => {
  const [date, setDate] = React.useState(null);

  const onChange = (dt) => {
    setDate(dt);
    updateProp('reminder', dt);
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={
            <Switch
              onChange={value => updateProp('hasMessage', value.target.checked)}
              name="checkedB"
              color="primary"
            />
          }
          label="Message vocal laissé ?"
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <React.Fragment>
            {
              date && (
                <Typography style={{ fontSize: 'x-small', marginTop: '-5px' }}>
                    Date du rappel*
                </Typography>
              )
            }
            <DateTimePicker
              style={{ marginTop: date ? 0 : '16px', width: '100%' }}
              emptyLabel='Programmer un rappel ?'
              value={date}
              ampm={false}
              autoOk={true}
              disablePast={true}
              required
              onChange={onChange}
            />
          </React.Fragment>
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

export default NoResponseSubForm;