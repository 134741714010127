const columns = [
  {
    label: "company-table-name",
    key: "name"
  },
  {
    label: "company-table-call-nbr",
    key: "phoneCallCount"
  },
  {
    label: "company-table-rdv-nbr",
    key: "rdvCount"
  },
  {
    label: "company-table-signed-nbr",
    key: "signedCount"
  },
  {
    label: "company-table-call-rdv-ratio",
    key: "ratioPR",
    format: (_, row) => {
      if (row.phoneCallCount === 0) {
        return 'company-table-no-call';
      }
      const t = Math.round((100/row.phoneCallCount)*row.rdvCount)
      return t + " %";
    }
  },
  {
    label: "company-table-rdv-contract-ratio",
    key: "ratioRS",
    format: (_, row) => {
      if (row.rdvCount === 0) {
        return 'company-table-no-rdv';
      }
      const t = Math.round((100/row.rdvCount)*row.signedCount)
      return t + " %";
    }
  },
];

export default columns;