import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  CircleGraph,
  DialogTitle,
  HomeTrackerForm,
  Stats,
  TableWithTitle
} from 'components';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid
} from '@material-ui/core';
import columns from './column-info';
import { Skeleton } from '@material-ui/lab';
import { addNewUserToCompany, loadMyCompanyStats } from 'myApi';
import { AuthContext } from 'contexts/AuthContext';
import { CustomizedSnackBarContext } from 'contexts/CustomizedSnackBarContext';
import { TranslationContext } from 'contexts/TranslationContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  hTrackerForm: {
    marginBottom: '12px'
  }
}));

const Company = () => {
  const classes = useStyles();

  const { myUserInfo } = React.useContext(AuthContext);
  const { t } = React.useContext(TranslationContext);
  const { successMessage, errorMessage } = React.useContext(
    CustomizedSnackBarContext
  );

  const [loading, setLoading] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setLoading('data');
    loadMyCompanyStats(myUserInfo.companyAdminId)
      .then(resp => {
        setRows(resp.data.myCompanyUserStat);
      })
      .catch(_ => {
        errorMessage('An error occured. Try again later.', 'error');
      })
      .finally(_ => {
        setLoading(null);
      });
  }, [errorMessage, myUserInfo.companyAdminId]);

  let statData = {};
  if (rows.length > 0) {
    statData = rows.reduce(function(a, b) {
      return {
        phoneCallCount: a.phoneCallCount + b.phoneCallCount,
        rdvCount: a.rdvCount + b.rdvCount,
        signedCount: a.signedCount + b.signedCount
      };
    });
  }

  return (
    <div className={classes.root}>
      <Stats data={statData} />
      <Box m={2} />
      <Grid spacing={3} container>
        <Grid item md={9} xs={12}>
          {loading === 'data' ? (
            <Skeleton height={150} variant="rect" />
          ) : (
            <TableWithTitle
              btnText={t('company-add-user')}
              columns={columns}
              rows={rows}
              title={t('company-users-list')}
              onActionButton={() => setIsOpen(true)}
            />
          )}
        </Grid>
        <Grid item md={3} xs={12}>
          {loading === 'data' ? (
            <Skeleton height={150} variant="rect" />
          ) : (
            <CircleGraph
              data={rows.map(row => ({
                id: row.id,
                label: row.name,
                value: row.signedCount
              }))}
              title={t('company-users-mandat')}
            />
          )}
        </Grid>
      </Grid>
      <Dialog
        maxWidth={'md'}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        onBackdropClick={() => setIsOpen(false)}
      >
        <DialogTitle id="customized-dialog-title">
          {t('company-add-new-user-form')}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {t('company-add-new-user-please-fill-form')}
          </DialogContentText>
          <HomeTrackerForm
            className={classes.hTrackerForm}
            fields={[
              {
                required: true,
                label: t('company-new-user-form-name'),
                name: 'name',
                type: 'text'
              },
              {
                required: true,
                label: t('company-new-user-form-email'),
                name: 'email',
                type: 'text'
              }
            ]}
            schema={{
              name: {
                presence: {
                  allowEmpty: false,
                  message: t('company-new-user-form-mandatory')
                }
              },
              email: {
                presence: {
                  allowEmpty: false,
                  message: t('company-new-user-form-mandatory')
                },
                email: true
              }
            }}
            submitForm={values => {
              setLoading('addNewUserToCompany');
              addNewUserToCompany(
                values.name,
                values.email,
                myUserInfo.companyAdminId
              )
                .then(() => {
                  successMessage(t('company-new-user-added'));
                  setTimeout(() => setIsOpen(false), 2500);
                })
                .catch(error => {
                  errorMessage(error.response.data[0].Message);
                })
                .finally(() => {
                  setLoading(null);
                });
            }}
            hideSubmit
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading === 'addNewUserToCompany'}
            onClick={() => setIsOpen(false)}
          >
            {t('common-cancel')}
          </Button>
          <Button
            onClick={() =>
              document.getElementById('hometracker-submit-button').click()
            }
            disabled={loading === 'addNewUserToCompany'}
            color="primary"
            autoFocus
          >
            {t('common-save')}
            &nbsp;
            {loading === 'addNewUserToCompany' && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Company;
