import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { ProspectionPopUp, PropertyListAsTable/*, PropertyListAsCard*/ } from './components';
import { ConnectLoaderContext } from 'contexts/LoaderContext';
import CustomizedSnackbars from 'components/CustomizedSnackbars';
import { ConnectAuthContext } from '../../../../contexts/AuthContext';
import { getProperties } from 'myApi';

import GroupFilter from '../Filters/GroupFilter';

import columns from './columns-info';

const initFilterState = {
  source: null,
  service: null,
  date: null,
  category: [],
  minPrice: null,
  maxPrice: null,
  peb: null,
  zipCodes: [],
  stateIds: []
};

const useStyles = makeStyles(_ => ({
  noFoundImageContainer: {
    textAlign: "center",
  },
  noFoundImage: {
    maxWidth: "100%",
    maxHeight: "450px",
  },
}));

export default ConnectLoaderContext(ConnectAuthContext(function StickyHeadTable(props) {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [propertyCount, setPropertyCount] = useState(0);
  const [filter, setFilter] = useState(initFilterState);
  const [toast, setToast] = useState({
    open: false,
    message: null,
    type: null
  });

  const setInfosToast = (message,type) => {
    setToast({
      open: true,
      message: message,
      type: type
    });

    setTimeout(() => {
      setToast({
        open: false,
        message: "",
        type: ""
      });
    }, 3000);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const prop = await getProperties(
          props.alreadyHadAction,
          page * rowsPerPage,
          rowsPerPage,
          filter.source,
          filter.service,
          filter.category,
          filter.zipCodes,
          filter.minPrice,
          filter.maxPrice,
          filter.stateIds,
          filter.peb,
          filter.date
        );
  
        const properties = prop.data.propertyGrid;
        setRows(properties.results);
        setPropertyCount(properties.totalCount);
      } catch (e) {
        if (e.response) {
          const error = e.response.status;
          let messageToToast = `Error  ${error}: `;
          let shouldLogOut = false;
  
          switch (error) {
            case 403:
              shouldLogOut = true;
              messageToToast += ` Action non autorisée, reconnectez-vous.`;
              break;
            case 500:
              messageToToast += ` Problème serveur, contactez un administrateur.`;
              break;
            default:
              messageToToast += ` Erreur inconnue`;
              break;
          }
  
          setInfosToast(messageToToast, 'error');

          if (shouldLogOut) {
            setTimeout(props.authContext.logOut, 3000);
          }
        } else {
          setInfosToast(e.toString(), 'error');
        }
      }
    };

    getData();
  }, [page, rowsPerPage, filter, props.authContext.logOut, props.alreadyHadAction]);

  return (
    <div>
        <GroupFilter filter={filter} setFilter={setFilter} displayIcons={props.alreadyHadAction} />
        {rows.length === 0 && !props.loaderContext.loading && (
          <div className={classes.noFoundImageContainer}>
            <img className={classes.noFoundImage} alt="no-data" src="/images/no-data.svg"/>
          </div>
        )}
        {
          rows.length > 0 && (
            <PropertyListAsTable rows={rows} rowsPerPage={rowsPerPage} columns={columns} page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} propertyCount={propertyCount} />
          )
        }
      <ProspectionPopUp />
      <CustomizedSnackbars
        isOpen={toast.open}
        message={toast.message}
        type={toast.type}
      />
    </div>
  );
}));
