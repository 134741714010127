import React, { useContext } from 'react';

import { Route, Redirect } from 'react-router-dom';
import {AuthContext} from 'contexts/AuthContext';

const PrivateRoute = ({
    component: RouteComponent,
    layout: Layout,
    path,
    ...rest
  }) => {
    const { initialized, tokenUser, myUserInfo } = useContext(AuthContext);

    // console.log(">>>", path, initialized === false, tokenUser && myUserInfo);

    // Used to wait for the context to find if we have token or not
    if (initialized === false ) {
      return null;
    }

    if (tokenUser && myUserInfo) {
      if (myUserInfo.userStatus === 1) {
        return <Redirect to={'/suspended-account'} />;
      }

      return (
        <Route
          {...rest}
          path={path}
          render={matchProps => (
            <Layout>
              <RouteComponent {...matchProps} />
            </Layout>
          )}
        />
      );
    }

    return <Redirect to={'/sign-in'} />;
};

export default PrivateRoute;
