import React from 'react';
import moment from 'moment';

// -----------------------------------------------------------------------

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import FeedbackIcon from '@material-ui/icons/Feedback';

// -----------------------------------------------------------------------

export const getIcon = (data) => {
  switch (data.state) {
    case 0:
      return <EventAvailableIcon htmlColor='green'/>;
    case 1:
      return data.reminder ? <AlarmAddIcon htmlColor='orange' /> : <PhoneMissedIcon htmlColor='orange' />;
    case 2:
      return <NotInterestedIcon htmlColor='red' />;
    case 3:
      return <VpnKeyIcon htmlColor='red' />;
    case 4:
      return <CloudOffIcon htmlColor='red' />;
    case 5:
      return data.reminder ? <AlarmAddIcon htmlColor='orange' /> : <FeedbackIcon htmlColor='orange' />;
    case 6:
      return <ThumbDownIcon htmlColor='red' />;
    default:
      return 'N/A';
  }
};

export const getSubTitle = (data) => {
  switch (data.state) {
    case 0:
      return moment(data.dateModified + "Z").format('LLL');
    case 1:
      return (data.reminder ? moment(data.reminder + "Z").format('LLL') : '');
    default:
      return "";
  }
};

export const getServiceText = (data) => {
  switch (data) {
    case 1:
      return 'service-type-sell';
    case 2:
      return 'service-type-rent';

    default:
      return 'N/A';
  }
};

export const getCategoryText = (data) => {
  switch (data) {
    case 1:
      return 'category-house';
    case 2:
      return 'category-appartment';
    case 3:
      return 'category-office';
    case 4:
      return 'category-business';
    case 5:
      return 'category-garage';
    case 6:
      return 'category-investment-property';
    case 7:
      return 'category-industry';
    case 8:
    case 9:
      return 'category-new-project';
    case 10:
      return 'category-land';
    case 11:
    case 12:
    case 13:
      return 'category-other';

    default:
      return 'N/A';
  }
};