import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  MenuItem,
  TextField
} from '@material-ui/core';
import validate from 'validate.js';
import { searchCompany, addNewUser } from 'myApi';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.error.dark
  },
  buttonSuccess: {
    marginLeft: 'auto',
    color: theme.palette.success.main
  },
  saveButton: {
    marginLeft: 'auto'
  },
  textField: {
    marginTop: '12px'
  },
  ...theme.common
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'est obligatoire' }
  },
  accountType: {
    presence: { allowEmpty: false, message: 'est obligatoire' }
  },
  email: {
    presence: { allowEmpty: false, message: 'est obligatoire' },
    email: true,
    length: {
      maximum: 64
    }
  }
};

const AddUserForm = () => {
  const classes = useStyles();

  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formState, setFormState] = React.useState({
    isValid: false,
    loading: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [companies, setCompanies] = React.useState([]);

  React.useEffect(() => {
    searchCompany('').then(resp => {
      setCompanies(resp.data.searchCompany);
    });
  }, []);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const onSubmit = e => {
    e.preventDefault();

    console.log(formState.values);

    const { name, email, accountType, companyId } = formState.values;

    setLoading(true);
    setSuccess(false);
    // -1 because to match the result of the combobox
    // The reason the select has not the value 0 or 1 (but 1 n 2 instead)
    // is because the value 0 is used in the select to deselect values
    addNewUser(name, accountType - 1, email, companyId)
      .then(() => {
        setSuccess(true);
      })
      .catch(_ => {
        alert(
          'An error occured. The user might already exists in the database'
        );
      })
      .finally(_ => {
        setLoading(false);
      });
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field => {
    return (
      formState.touched[field] &&
      formState.errors[field] &&
      formState.errors[field].length > 0
    );
  };

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <Card>
        <CardHeader
          avatar={<Avatar className={classes.avatar}>U</Avatar>}
          title="Ajout d'un utilisateur"
          subheader="Ajoutez un utilisateur lié à aucune agence"
        />
        <CardContent>
          <TextField
            required
            className={classes.textField}
            error={hasError('accountType')}
            fullWidth
            helperText={
              hasError('accountType') ? formState.errors.accountType[0] : ''
            }
            label="Type de compte"
            name="accountType"
            onChange={handleChange}
            select
            value={formState.values.accountType || ''}
            variant="outlined"
          >
            <MenuItem value={1}>Compte de base</MenuItem>
            <MenuItem value={2}>Compte PRO</MenuItem>
          </TextField>
          <TextField
            required
            className={classes.textField}
            error={hasError('name')}
            fullWidth
            helperText={hasError('name') ? formState.errors.name[0] : ''}
            label="Nom de l'utilisateur"
            name="name"
            onChange={handleChange}
            type="text"
            value={formState.values.name || ''}
            variant="outlined"
          />
          <TextField
            required
            className={classes.textField}
            error={hasError('email')}
            fullWidth
            helperText={hasError('email') ? formState.errors.email[0] : ''}
            label="Adresse email"
            name="email"
            onChange={handleChange}
            type="text"
            value={formState.values.email || ''}
            variant="outlined"
          />

          <TextField
            className={classes.textField}
            error={hasError('companyId')}
            fullWidth
            helperText={
              hasError('companyId') ? formState.errors.companyId[0] : ''
            }
            label="Company of the user"
            name="companyId"
            onChange={handleChange}
            select
            value={formState.values.companyId || ''}
            variant="outlined"
          >
            <MenuItem value={undefined}>Aucun</MenuItem>
            {companies.map(company => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </TextField>
        </CardContent>
        <CardActions disableSpacing>
          <Button
            color="primary"
            className={
              success === true ? classes.buttonSuccess : classes.saveButton
            }
            aria-label="save"
            type="submit"
          >
            {success ? 'Successfully Saved' : 'Save'}
            &nbsp;
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default AddUserForm;
