import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {  Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { AppBarTab, DialogTitle } from 'components';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Close } from '@material-ui/icons';
import { saveUserDate } from 'myApi';
import { CustomizedSnackBarContext } from 'contexts/CustomizedSnackBarContext';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  gridForm: {
    marginTop: "25px",
  }
}));

const PopUpForm = ({ isopen, close, popupInfo, isCreate, updateCalendar }) => {
  const classes = useStyles();

  const { errorMessage } = React.useContext(CustomizedSnackBarContext);
  
  const [dateError, setDateError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dateTypeError, setDateTypeError] = React.useState(false);
  const [formState, setFormState] = React.useState(popupInfo ?? {
    dateType: null,
    title: null,
    ownerPhoneNumber: null,
    ownerName: null,
    startDateTime: null,
    ownerAddress: null,
    note: null,
  });

  const updateProp = (propName, propValue) => {
    setFormState({
      ...formState,
      [propName]: propValue
    });
  }

  const submit = e => {
    e.preventDefault();
    
    if (!formState.startDateTime) {
      setDateError(true);
      return;
    }

    if (formState.dateType === null || formState.dateType === undefined) {
      setDateTypeError(true);
      return;
    }

    setLoading(true);
    saveUserDate(formState)
      .then(_ => {
        updateCalendar(formState.startDateTime);
      })
      .catch(_ => {
        errorMessage("Une erreur s'est produite. Reessayer plus tard.");
      })
      .finally(_ => {
        setLoading(false);
      })
    ;
  };

  return (
    <Dialog className={classes.dialog} fullWidth maxWidth={'md'} aria-labelledby="customized-dialog-title" open={isopen} onClose={close}>
      <form onSubmit={submit}>
        <DialogTitle id="customized-dialog-title">
          Info de l'evenement
          <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {isCreate && 
            (
              <>
                {
                  dateTypeError && (
                    <Typography style={{ fontSize: 'x-small', color: 'red' }}>
                        Champ obligatoire*
                    </Typography>
                  )
                }
                <Typography variant="caption"> Type d'évenement* </Typography>
                <AppBarTab value={formState.dateType} updateValue={value => { updateProp("dateType", value); setDateTypeError(false); }} />
              </>
            )
          }
          <Grid className={classes.gridForm} container spacing={1}>
            <Grid item xs={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <React.Fragment>
                  {
                    formState.startDateTime && (
                      <Typography style={{ fontSize: 'x-small', marginTop: '-5px' }}>
                        {formState.dateType === 0 ? "Date du rdv*" : "Date du rappel*"}
                      </Typography>
                    )
                  }
                  <DateTimePicker
                    style={{ marginTop: formState.startDateTime ? 0 : '16px', width: '100%' }}
                    emptyLabel={formState.dateType === 0 ? "Date du rdv" : "Date du rappel"}
                    value={formState.startDateTime}
                    ampm={false}
                    autoOk={true}
                    required
                    onChange={date => { updateProp('startDateTime', date); setDateError(false); }}
                    disabled={!isCreate}
                  />
                  {
                    dateError && (
                      <Typography style={{ fontSize: 'x-small', color: 'red' }}>
                          Date du rdv (champ obligatoire)*
                      </Typography>
                    )
                  }
                </React.Fragment>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required={formState.dateType === 0}
                label="Nom du Client"
                fullWidth
                defaultValue={formState['ownerName']}
                onChange={e => updateProp('ownerName', e.target.value.trim())}
                disabled={!isCreate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Numéro de téléphone"
                fullWidth
                defaultValue={formState['ownerPhoneNumber']}
                onChange={e => updateProp('ownerPhoneNumber', e.target.value.trim())}
                disabled={!isCreate}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                required={formState.dateType === 0}
                label="Adresse"
                fullWidth
                defaultValue={formState['ownerAddress']}
                onChange={e => updateProp('ownerAddress', e.target.value.trim())}
                disabled={!isCreate}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="standard-multiline-static"
                label="Notes Supplémentaires"
                multiline
                fullWidth
                rows={8}
                defaultValue={formState['note']}
                onChange={e => updateProp('note', e.target.value.trim())}
                disabled={!isCreate}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {
          isCreate && (
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
              >
                Sauvegarder
                &nbsp;&nbsp;
                {loading && <CircularProgress size={24} />}
              </Button>
            </DialogActions>
          )
        }
      </form>
    </Dialog>
  );
};

export default PopUpForm;