import React, { useState, useEffect, useCallback } from 'react';
import { ConnectAuthContext } from 'contexts/AuthContext';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Grid, Button, TextField, Typography, Link } from '@material-ui/core';

import useStyles from './SingIn.style';
import CustomizedSnackbars from 'components/CustomizedSnackbars';
import CircularProgress from '@material-ui/core/CircularProgress';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const SignIn = props => {
  const { history, authContext } = props;
  const { tokenUser, login } = authContext;

  const [showPassword, setShowPassword] = useState(false);

  const [toast, setToast] = useState({
    open: false,
    message: null,
    type: null
  });
  const [formState, setFormState] = useState({
    isValid: false,
    loading: false,
    values: {},
    touched: {},
    errors: {}
  });

  const classes = useStyles();
  const setInfosToast = (message, type) => {
    setToast({
      open: true,
      message: message,
      type: type
    });

    setTimeout(() => {
      setToast({
        open: false,
        message: '',
        type: type
      });
    }, 3000);
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const onSubmit = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = formState.values;
      if (!email || !password) {
        return;
      }
      try {
        setFormState(formState => ({
          ...formState,
          loading: true
        }));
        await login(email, password);
        history.push('/properties');
      } catch (e) {
        if (e.response) {
          const error = e.response.status;
          let messageToToast = `Error  ${error}: `;

          switch (error) {
            case 400:
            case 404:
              messageToToast += ` Mauvais identifiants`;
              break;
            case 403:
              messageToToast += ` Action non autorisée`;
              break;
            case 500:
              messageToToast += ` Problème serveur, contactez un administrateur`;
              break;
            default:
              messageToToast += ` Erreur inconnue`;
              break;
          }

          setInfosToast(messageToToast, 'error');
        } else {
          setInfosToast(e.toString(), 'error');
        }
        setFormState(formState => ({
          ...formState,
          loading: false
        }));
      }
    },
    [formState.values, history, login]
  );

  const hasError = field => {
    return (
      formState.touched[field] &&
      formState.errors[field] &&
      formState.errors[field].length > 0
    );
  };

  if (tokenUser) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                I have decided to stick with love. Hate is too great a burden to
                bear.
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Martin Luther King, Jr.
                </Typography>
                <Typography className={classes.bio} variant="body2">
                  Home Tracker
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={onSubmit}>
                <Typography className={classes.title} variant="h2">
                  Connexion
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : ''
                  }
                  label="Adresse email"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <FormControl
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Mot de passe
                  </InputLabel>
                  <OutlinedInput
                    placeholder={'Entrez votre mot de passe...'}
                    type={showPassword ? 'text' : 'password'}
                    value={formState.values.password || ''}
                    name="password"
                    onChange={handleChange}
                    fullWidth
                    error={hasError('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    required
                    notched={true}
                    labelWidth={formState.values.password ? 85 : 0}
                  />
                </FormControl>
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={formState.isValid === false || formState.loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Se connecter
                </Button>
                <CustomizedSnackbars
                  isOpen={toast.open}
                  message={toast.message}
                  type={toast.type}
                />
                <Link
                  component="button"
                  variant="body2"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/request-reset-password');
                  }}
                >
                  Demander votre mot de passe
                </Link>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default ConnectAuthContext(withRouter(SignIn));
