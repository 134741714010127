import React from 'react';
import { CardHeader } from '@material-ui/core';
import { TranslationContext } from 'contexts/TranslationContext';

const Header = () => {
  const { t } = React.useContext(TranslationContext);
  
  return (
    <CardHeader
      // action={
      //   <Button
      //     color="primary"
      //     size="small"
      //     variant="outlined"
      //   >
      //     New entry
      //   </Button>
      // }
      title={t("signed-mandat-header-title")}
    />
  );
};

export default Header;