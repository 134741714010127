import React from 'react';
import fr from "./i18n/fr.json";

export const TranslationContext = React.createContext({});

const Consumer = TranslationContext.Consumer;

const Provider = TranslationContext.Provider;

export function ConnectTranslationContext(Component) {
  return function WrapperComponent(props) {
    return (
      <Consumer>
        {state => <Component {...props} translation={state} />}
      </Consumer>
    );
  };
}

// Dont forget to add this at the root of your project around your <App />
export default class ProvideTranslationContext extends React.Component {
  translations = {
    // en,
    fr,
    // nl
  };
  defaultLg = "fr";
  currentLg = "fr";

  constructor(props) {
    super(props);
    
    if (props.defaultLg) {
      this.defaultLg = props.defaultLg;
    }

    if (props.currentLg) {
      this.currentLg = props.currentLg;
    }

    this.state = {
      update: true,
      t: this.t.bind(this),
      format: this.format,
      changeCurrentLanguage: this.changeCurrentLanguage.bind(this),
    };
  }

  format(translation, formatObj) {
    if (formatObj) {
      for (var property in formatObj) {
        translation = translation.replace(
          "{" + property + "}",
          formatObj[property]
        );
      }
    }
    return translation;
  }

  t(translationCode, dataToReplace) {
    var languageTranslations = this.translations[this.currentLg];
    let trans = "";
    if (languageTranslations) {
      trans = languageTranslations[translationCode];
    }

    if (trans) {
      return this.format(trans, dataToReplace);
    }

    trans = this.translations[this.defaultLg][translationCode];

    if (trans) {
      return this.format(trans, dataToReplace);
    }

    return translationCode;
  }

  changeCurrentLanguage(lgCode) {
    this.currentLg = lgCode;
    // Force the update of all the transaltions
    this.setState({ update: !this.update });
  }

  render() {
    return (
      <React.Fragment>
        <Provider value={this.state}>{this.props.children}</Provider>
      </React.Fragment>
    );
  }
}
