import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { ConnectPopUpContext } from '../../../../../contexts/PopUpContext';
import FormPopUp from '../../PopUp/FormPopUp';

const MainContent = () => {
  return (
    <>
      <DialogContent dividers>
        <FormPopUp key={'ProspectionPopUp-FormPopUp'} />
      </DialogContent>
    </>
  );
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const ProspectionPopUp = ({ popUpContext }) => {
  const { isOpen, closable, closePopup } = popUpContext;

  const closeHandler = () => {
    if (!closable) {
      return;
    }

    closePopup();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      onClose={closeHandler}
    >
      <MainContent closeHandler={closeHandler} closable={closable} />
    </Dialog>
  );
};

export default ConnectPopUpContext(ProspectionPopUp);
