import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import moment from 'moment';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, Grid } from '@material-ui/core';
import { getMyHistory } from 'myApi';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import { TranslationContext } from 'contexts/TranslationContext';
import { LoaderContext } from 'contexts/LoaderContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  red: {
    backgroundColor: "#f4f6f8",
    color: "#f44336",
  },
  green: {
    backgroundColor: "#f4f6f8",
    color: "#4caf50",
  },
  blue: {
    backgroundColor: "#f4f6f8",
    color: "#2196f3",
  },
  yellow: {
    backgroundColor: "#f4f6f8",
    color: "#ff9800",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const getHistoryIcon = (historyType) => {
  switch (historyType) {
    case 1:
    case 2:
        return <VpnKeyIcon />
    case 3:
    case 4:
        return <ExitToAppIcon />
    case 5:
        return <AlarmAddIcon />
    case 6:
        return <EventAvailableIcon />
    case 7:
        return <SettingsApplicationsIcon />
    case 8:
        return <GroupAddIcon />
    default:
      break;
  }
}

const getExtraInfo = (historyType, extra) => {
  if (historyType === 5 || historyType === 6) { // Reminder or Rdv
    const extraParsed = JSON.parse(extra);
    if (extraParsed) {
      return moment(extraParsed?.StartDateTime).format('LLL');
    }
  }
  if (historyType === 8) { // Add user to company
    const extraParsed = JSON.parse(extra);
    return extraParsed.email;
  }
  return "";
}

const UserHistory = () => {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);
  const { hideLoader, showLoader } = React.useContext(LoaderContext);

  const [elements, setElements] = React.useState([]);
  const [hasMoreToLoad, setHasMoreToLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(20);

  const loadMore = () => {
    let newArray = [...elements];

    if (hasMoreToLoad) {
      setLoading(true);
      getMyHistory(skip, take)
      .then(response => {
        const histories = response.data.getMyHistory;
  
        for (let i = 0; i < histories.length; i++) {
          const historyElement = histories[i];
          newArray.push({ id: historyElement.id, dateCreated: historyElement.dateCreated,  historyType: historyElement.historyType, extra: historyElement.extra });
        }
        
        setSkip(skip + take);
        setElements(newArray);
        if (histories.length < take) {
          setHasMoreToLoad(false);
          document.removeEventListener('scroll', trackScrolling);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      })
      .finally(_ => {
        setLoading(false);
        console.log("DONE");
      });
    }
  }
  
  // Check that we are 250px before the end
  const isBottom = (el) => el.getBoundingClientRect().bottom <= window.innerHeight + 250;

  const trackScrolling = () => {
    const wrappedElement = document.getElementById('history-wrapper');
    if (wrappedElement) {
      if (isBottom(wrappedElement)) {
        document.removeEventListener('scroll', trackScrolling);
        if (hasMoreToLoad)
          loadMore();
      }
    } else {
      document.removeEventListener('scroll', trackScrolling);
    }
  };

  // Load the first elements on mount
  React.useEffect(() => {
    hideLoader();
    loadMore();
    return () => {
      showLoader();
      document.removeEventListener('scroll', trackScrolling);
    };
  }, []);

  // Adds the listener after the the add to avoid multiple firing 
  React.useEffect(() => document.addEventListener('scroll', trackScrolling), [elements]);

  const getColor = (historyType) => {
    switch (historyType) {
      case 1: // Request new pwd
      case 2: // Reset pwd
          return classes.red;
      case 3: // login
      case 4: // logoff
          return classes.green;
      case 5: // Reminder
      case 6: // Rdv
          return classes.yellow;
      case 7: // UpdateUserInfo
      case 8: // Add user to company
          return classes.blue;
      default:
        break;
    }
  };

  return (
    <div id="history-wrapper">
      <Typography>Historic</Typography>
      <Timeline align="left">
        {
          elements.map(element => (
            <TimelineItem key={element.id}>
              <TimelineSeparator>
                <TimelineDot className={getColor(element.historyType)} color="inherit">
                  {getHistoryIcon(element.historyType)}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={1} className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                  >
                    <Grid>
                      <Typography variant="h6" component="h1">
                        {t("history-title-type-" + element.historyType)}
                      </Typography>
                      <Typography>
                        {
                          getExtraInfo(element.historyType, element.extra)
                        }
                      </Typography>
                    </Grid>
                    <Grid>
                      {
                        moment(element.dateCreated + "Z").fromNow()
                      }
                    </Grid>
                  </Grid>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))
        }
        {
          (loading || hasMoreToLoad) && (
            <CircularProgress />
          )
        }
        {
          (!loading && !hasMoreToLoad) && (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                  <FiberNewIcon />
                </TimelineDot>
              </TimelineSeparator>
            </TimelineItem>
          )
        }
      </Timeline>
    </div>
  );
}

export default UserHistory;