const zipCodes = [
  { "type": "L", "province": "Luxembourg", "commune_principale": "CHINY", "name": "Jamoigne", "code_postal": "6810", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "RIEMST", "name": "Kanne", "code_postal": "3770", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Klein-Gelmen", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Koninksem", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "LAAKDAL", "name": "LAAKDAL", "code_postal": "2430", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LEBBEKE", "name": "LEBBEKE", "code_postal": "9280", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "PUURS-SINT-AMANDS", "name": "Lippelo", "code_postal": "2890", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Lodelinsart", "code_postal": "6042", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ANZEGEM", "name": "ANZEGEM", "code_postal": "8570", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BEKKEVOORT", "name": "Assent", "code_postal": "3460", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "Ave-Et-Auffe", "code_postal": "5580", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KLUISBERGEN", "name": "Berchem", "code_postal": "9690", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "THUIN", "name": "Biesme-Sous-Thuin", "code_postal": "6531", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DALHEM", "name": "Bombaye", "code_postal": "4607", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Boninne", "code_postal": "5021", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Bothey", "code_postal": "5032", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TELLIN", "name": "Bure", "code_postal": "6927", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ETALLE", "name": "Buzenol", "code_postal": "6743", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Chairière", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHAPELLE-LEZ-HERLAIMONT", "name": "CHAPELLE-LEZ-HERLAIMONT", "code_postal": "7160", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "VERLAINE", "name": "Chapon-Seraing", "code_postal": "4537", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "CHINY", "name": "CHINY", "code_postal": "6810", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-GILLIS-WAAS", "name": "De Klinge", "code_postal": "9170", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "EGHEZÉE", "code_postal": "5310", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Eigenbilzen", "code_postal": "3740", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WORTEGEM-PETEGEM", "name": "Elsegem", "code_postal": "9790", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "CHAUDFONTAINE", "name": "Embourg", "code_postal": "4053", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VERVIERS", "name": "Ensival", "code_postal": "4800", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FROIDCHAPELLE", "name": "Erpion", "code_postal": "6441", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRAMERIES", "name": "Eugies", "code_postal": "7080", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DALHEM", "name": "Feneur", "code_postal": "4607", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FEXHE-LE-HAUT-CLOCHER", "name": "FEXHE-LE-HAUT-CLOCHER", "code_postal": "4347", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "HAMOIR", "name": "Filot", "code_postal": "4181", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Fraire", "code_postal": "5650", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FEXHE-LE-HAUT-CLOCHER", "name": "Freloux", "code_postal": "4347", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Gourdinne", "code_postal": "5651", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Grandhan", "code_postal": "6940", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "KORTESSEM", "name": "Guigoven", "code_postal": "3723", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "BEAUVECHAIN", "name": "Hamme-Mille", "code_postal": "1320", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ROUVROY", "name": "Harnoncourt", "code_postal": "6767", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DAMME", "name": "Hoeke", "code_postal": "8340", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KORTENAKEN", "name": "Hoeleden", "code_postal": "3471", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LA LOUVIÈRE", "name": "Houdeng-Goegnies", "code_postal": "7110", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "IEPER", "code_postal": "8900", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "OPWIJK", "name": "Mazenzele", "code_postal": "1745", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MERBES-LE-CHÂTEAU", "name": "MERBES-LE-CHÂTEAU", "code_postal": "6567", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Merdorp", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MESSANCY", "name": "MESSANCY", "code_postal": "6780", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUGELETTE", "name": "Mévergnies-Lez-Lens", "code_postal": "7942", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LE ROEULX", "name": "Mignault", "code_postal": "7070", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAMOIS", "name": "Mohiville", "code_postal": "5361", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DIEST", "name": "Molenstede", "code_postal": "3294", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RAMILLIES", "name": "Mont-Saint-André", "code_postal": "1367", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Moulbaix", "code_postal": "7812", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MECHELEN", "name": "Muizen", "code_postal": "2812", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Neerwinden", "code_postal": "3400", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Nerem", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "NIEUWERKERKEN", "name": "NIEUWERKERKEN", "code_postal": "3850", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ROESELARE", "name": "Oekene", "code_postal": "8800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "OHEY", "name": "OHEY", "code_postal": "5350", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "BREE", "name": "Opitter", "code_postal": "3960", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "AVELGEM", "name": "Outrijve", "code_postal": "8582", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BINCHE", "name": "Péronnes-Lez-Binche", "code_postal": "7134", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WORTEGEM-PETEGEM", "name": "Petegem-Aan-De-Schelde", "code_postal": "9790", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LO-RENINGE", "name": "Pollinkhove", "code_postal": "8647", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "COMBLAIN-AU-PONT", "name": "Poulseur", "code_postal": "4171", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Rièzes", "code_postal": "6464", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "RUISELEDE", "name": "RUISELEDE", "code_postal": "8755", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Runkelen", "code_postal": "3803", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Saint-Servais", "code_postal": "5002", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LA ROCHE-EN-ARDENNE", "name": "Samrée", "code_postal": "6982", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LAUREINS", "name": "Sint-Jan-In-Eremo", "code_postal": "9982", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BRECHT", "name": "Sint-Lenaarts", "code_postal": "2960", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SIVRY-RANCE", "name": "SIVRY-RANCE", "code_postal": "6470", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MIDDELKERKE", "name": "Slijpe", "code_postal": "8433", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DOISCHE", "name": "Soulme", "code_postal": "5680", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZAVENTEM", "name": "Sterrebeek", "code_postal": "1933", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TENNEVILLE", "name": "TENNEVILLE", "code_postal": "6970", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "WILLEBROEK", "name": "Tisselt", "code_postal": "2830", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "TONGEREN", "code_postal": "3700", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "VILLERS-LE-BOUILLET", "name": "Vaux-Et-Borset", "code_postal": "4530", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BEVEREN-WAAS", "name": "Verrebroek", "code_postal": "9130", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PONT-À-CELLES", "name": "Viesville", "code_postal": "6230", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Villers-Notre-Dame", "code_postal": "7812", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Villers-Sainte-Gertrude", "code_postal": "6941", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Vinkt", "code_postal": "9800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DOISCHE", "name": "Vodelée", "code_postal": "5680", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BINCHE", "name": "Waudrez", "code_postal": "7131", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "WELLEN", "name": "WELLEN", "code_postal": "3830", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Wezeren", "code_postal": "3401", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "DOUR", "name": "Wihéries", "code_postal": "7370", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZONNEBEKE", "name": "Zandvoorde", "code_postal": "8980", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LOCHRISTI", "name": "Zeveneken", "code_postal": "9080", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTRIJK", "name": "Aalbeke", "code_postal": "8511", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "AARSCHOT", "name": "AARSCHOT", "code_postal": "3200", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "CINEY", "name": "Achêne", "code_postal": "5590", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "AISEAU-PRESLES", "name": "Aiseau", "code_postal": "6250", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ANTOING", "name": "ANTOING", "code_postal": "7640", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BELOEIL", "name": "Aubechies", "code_postal": "7972", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "Autreppe", "code_postal": "7387", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MARCHE-EN-FAMENNE", "name": "Aye", "code_postal": "6900", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-NIKLAAS", "name": "Belsele", "code_postal": "9111", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HUY", "name": "Ben-Ahin", "code_postal": "4500", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BERINGEN", "name": "Beverlo", "code_postal": "3581", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BASSENGE", "name": "Boirs", "code_postal": "4690", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "NIVELLES", "name": "Bornival", "code_postal": "1404", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "PALISEUL", "name": "Carlsbourg", "code_postal": "6850", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "STOUMONT", "name": "Chevron", "code_postal": "4987", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Cognelée", "code_postal": "5022", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTINNES", "name": "Croix-Lez-Rouveroy", "code_postal": "7120", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Cul-Des-Sarts", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LOKEREN", "name": "Daknam", "code_postal": "9160", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZOUTLEEUW", "name": "Dormaal", "code_postal": "3440", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DROGENBOS", "name": "DROGENBOS", "code_postal": "1620", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BEERSEL", "name": "Dworp", "code_postal": "1653", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "PEPINGEN", "name": "Elingen", "code_postal": "1671", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTINNES", "name": "ESTINNES", "code_postal": "7120", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "YVOIR", "name": "Evrehailles", "code_postal": "5530", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FONTAINE-L'EVÊQUE", "name": "Forchies-La-Marche", "code_postal": "6141", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "TINLOT", "name": "Fraiture", "code_postal": "4557", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Gelinden", "code_postal": "3800", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "GINGELOM", "code_postal": "3890", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LE ROEULX", "name": "Gottignies", "code_postal": "7070", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "THUIN", "name": "Gozée", "code_postal": "6534", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIÈVRES", "name": "Grosage", "code_postal": "7950", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HAM", "name": "HAM", "code_postal": "3945", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BERNISSART", "name": "Harchies", "code_postal": "7321", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HASTIÈRE", "name": "Hastière-Lavaux", "code_postal": "5540", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ITTRE", "name": "Haut-Ittre", "code_postal": "1461", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KNOKKE-HEIST", "name": "Heist-Aan-Zee", "code_postal": "8301", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIERDE", "name": "Hemelveerdegem", "code_postal": "9571", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRAINE-LE-COMTE", "name": "Hennuyères", "code_postal": "7090", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BOORTMEERBEEK", "name": "Hever", "code_postal": "3191", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HOEILAART", "name": "HOEILAART", "code_postal": "1560", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZUIENKERKE", "name": "Houtave", "code_postal": "8377", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LEDE", "name": "Impe", "code_postal": "9340", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "JODOIGNE", "name": "Jodoigne-Souveraine", "code_postal": "1370", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LEUVEN", "name": "Kessel Lo", "code_postal": "3010", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "Kortijs", "code_postal": "3890", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIÈVRES", "name": "Ladeuze", "code_postal": "7950", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Ledeberg", "code_postal": "9050", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "WAVRE", "name": "Limal", "code_postal": "1300", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LO-RENINGE", "name": "LO-RENINGE", "code_postal": "8647", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "LONTZEN", "name": "LONTZEN", "code_postal": "4710", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GENAPPE", "name": "Loupoigne", "code_postal": "1471", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOMIGNIES", "name": "Macquenoise", "code_postal": "6593", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ASSESSE", "name": "Maillen", "code_postal": "5330", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MALLE", "name": "MALLE", "code_postal": "2390", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "RENDEUX", "name": "Marcourt", "code_postal": "6987", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASMECHELEN", "name": "Mechelen-Aan-De-Maas", "code_postal": "3630", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HOOGSTRATEN", "name": "Meer", "code_postal": "2321", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Meilegem", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MENEN", "name": "MENEN", "code_postal": "8930", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "Merksem", "code_postal": "2170", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "MONS", "code_postal": "7000", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BRAKEL", "name": "Nederbrakel", "code_postal": "9660", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LANAKEN", "name": "Neerharen", "code_postal": "3620", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BOUTERSEM", "name": "Neervelp", "code_postal": "3370", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LO-RENINGE", "name": "Noordschote", "code_postal": "8647", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "Noville-Les-Bois", "code_postal": "5380", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PECQ", "name": "Obigies", "code_postal": "7743", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIENEN", "name": "Oorbeek", "code_postal": "3300", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "PUURS-SINT-AMANDS", "name": "Oppuurs", "code_postal": "2890", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Pesche", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Petegem-Aan-De-Leie", "code_postal": "9800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Petite-Chapelle", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "PITTEM", "name": "PITTEM", "code_postal": "8740", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LANGEMARK-POELKAPELLE", "name": "Poelkapelle", "code_postal": "8920", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "Quévy-Le-Grand", "code_postal": "7040", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Ransart", "code_postal": "6043", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ASSE", "name": "Relegem", "code_postal": "1731", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "RIEMST", "name": "RIEMST", "code_postal": "3770", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "Rochehaut", "code_postal": "6830", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ROESELARE", "name": "Rumbeke", "code_postal": "8800", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "RUMES", "name": "RUMES", "code_postal": "7610", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Saint-Germain", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTAIMPUIS", "name": "Saint-Léger", "code_postal": "7730", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "JALHAY", "name": "Sart-Lez-Spa", "code_postal": "4845", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FOSSES-LA-VILLE", "name": "Sart-Saint-Laurent", "code_postal": "5070", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SIVRY-RANCE", "name": "Sautin", "code_postal": "6470", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HOESELT", "name": "Sint-Huibrechts-Hern", "code_postal": "3730", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SINT-PIETERS-LEEUW", "name": "Sint-Laureins-Berchem", "code_postal": "1600", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOUFFALIZE", "name": "Tavigny", "code_postal": "6662", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NEUFCHÂTEAU", "name": "Tournay", "code_postal": "6840", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBIN", "name": "Transinne", "code_postal": "6890", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LA LOUVIÈRE", "name": "Trivières", "code_postal": "7100", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BERLARE", "name": "Uitbergen", "code_postal": "9290", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "CHAUDFONTAINE", "name": "Vaux-Sous-Chèvremont", "code_postal": "4051", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "LAAKDAL", "name": "Veerle", "code_postal": "2431", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LANAKEN", "name": "Veldwezelt", "code_postal": "3620", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Vosselare", "code_postal": "9850", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUAREGNON", "name": "Wasmuel", "code_postal": "7390", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "LILLE", "name": "Wechelderzande", "code_postal": "2275", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "WEZEMBEEK-OPPEM", "name": "WEZEMBEEK-OPPEM", "code_postal": "1970", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Winenne", "code_postal": "5570", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "KORTESSEM", "name": "Wintershoven", "code_postal": "3722", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HEUVELLAND", "name": "Wulvergem", "code_postal": "8952", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIEVEGEM", "name": "Zomergem", "code_postal": "9930", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RAVELS", "name": "RAVELS", "code_postal": "2380", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "REBECQ", "name": "Rebecq-Rognon", "code_postal": "1430", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BINCHE", "name": "Ressaix", "code_postal": "7134", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LES BONS VILLERS", "name": "Rèves", "code_postal": "6210", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Rienne", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Roly", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Romedenne", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HOESELT", "name": "Schalkhoven", "code_postal": "3732", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANDENNE", "name": "Sclayn", "code_postal": "5300", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Sint-Amandsberg", "code_postal": "9040", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LEDE", "name": "Smetlede", "code_postal": "9340", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "GISTEL", "name": "Snaaskerke", "code_postal": "8470", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BEAUMONT", "name": "Solre-Saint-Géry", "code_postal": "6500", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LE ROEULX", "name": "Thieu", "code_postal": "7070", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BEAUMONT", "name": "Thirimont", "code_postal": "6500", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "TIELT", "name": "TIELT", "code_postal": "8700", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIELT-WINGE", "name": "TIELT-WINGE", "code_postal": "3390", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "BREE", "name": "Tongerlo", "code_postal": "3960", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "TOURNAI", "code_postal": "7500", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "TURNHOUT", "name": "TURNHOUT", "code_postal": "2300", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTINNES", "name": "Vellereille-Le-Sec", "code_postal": "7120", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBIN", "name": "Villance", "code_postal": "6890", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "Villers-Sur-Lesse", "code_postal": "5580", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "KORTESSEM", "name": "Vliermaalroot", "code_postal": "3721", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Voorde", "code_postal": "9400", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASMECHELEN", "name": "Vucht", "code_postal": "3630", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Walshoutem", "code_postal": "3401", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WAREMME", "name": "WAREMME", "code_postal": "4300", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WERVIK", "name": "WERVIK", "code_postal": "8940", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "WESTERLO", "name": "WESTERLO", "code_postal": "2260", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LEUVEN", "name": "Wijgmaal", "code_postal": "3018", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MIDDELKERKE", "name": "Wilskerke", "code_postal": "8431", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "WOMMELGEM", "name": "WOMMELGEM", "code_postal": "2160", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "NANDRIN", "name": "Yernée-Fraineux", "code_postal": "4550", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OOSTENDE", "name": "Zandvoorde", "code_postal": "8400", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Zepperen", "code_postal": "3800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HASTIÈRE", "name": "Agimont", "code_postal": "5544", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "ANTWERPEN", "code_postal": "2030", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Appelterre-Eichem", "code_postal": "9400", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUIÉVRAIN", "name": "Audregnies", "code_postal": "7382", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RAMILLIES", "name": "Autre-Eglise", "code_postal": "1367", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BEGIJNENDIJK", "name": "BEGIJNENDIJK", "code_postal": "3130", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALTER", "name": "Bellem", "code_postal": "9881", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LEUZE-EN-HAINAUT", "name": "Blicquy", "code_postal": "7903", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BURDINNE", "name": "BURDINNE", "code_postal": "4210", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Champion", "code_postal": "5020", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SOIGNIES", "name": "Chaussée-Notre-Dame-Louvignies", "code_postal": "7063", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "Corbion", "code_postal": "6838", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "DIKSMUIDE", "code_postal": "8600", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "HAMOIS", "name": "Emptinne", "code_postal": "5363", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ENGIS", "name": "ENGIS", "code_postal": "4480", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ORP-JAUCHE", "name": "Enines", "code_postal": "1350", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "EREZÉE", "name": "EREZÉE", "code_postal": "6997", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Ernage", "code_postal": "5030", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ERPE-MERE", "name": "Erondegem", "code_postal": "9420", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ONHAYE", "name": "Falaën", "code_postal": "5522", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SENEFFE", "name": "Feluy", "code_postal": "7181", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Forest", "code_postal": "7910", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WERVIK", "name": "Geluwe", "code_postal": "8940", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BERLAAR", "name": "Gestel", "code_postal": "2590", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GESVES", "name": "GESVES", "code_postal": "5340", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GENAPPE", "name": "Glabais", "code_postal": "1473", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Gorsem", "code_postal": "3803", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "GOUVY", "name": "GOUVY", "code_postal": "6670", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Grammene", "code_postal": "9800", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ZOERSEL", "name": "Halle", "code_postal": "2980", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HERSELT", "name": "HERSELT", "code_postal": "2230", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "RENDEUX", "name": "Hodister", "code_postal": "6987", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HOVE", "name": "HOVE", "code_postal": "2540", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERLEEUW", "name": "Iddergem", "code_postal": "9472", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "CHINY", "name": "Izel", "code_postal": "6810", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Kain", "code_postal": "7540", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Keiem", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "EUPEN", "name": "Kettenis", "code_postal": "4701", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KLUISBERGEN", "name": "KLUISBERGEN", "code_postal": "9690", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "L'Escaillère", "code_postal": "6464", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PONT-À-CELLES", "name": "Luttre", "code_postal": "6238", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Maffle", "code_postal": "7810", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Hoelbeek", "code_postal": "3746", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SERAING", "name": "Jemeppe-Sur-Meuse", "code_postal": "4101", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "JEMEPPE-SUR-SAMBRE", "name": "JEMEPPE-SUR-SAMBRE", "code_postal": "5190", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUNEHAUT", "name": "Jollain-Merlin", "code_postal": "7620", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Kerniel", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "Lessive", "code_postal": "5580", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "PUURS-SINT-AMANDS", "name": "Liezele", "code_postal": "2870", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Ligne", "code_postal": "7812", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Martouzin-Neuville", "code_postal": "5573", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ANTOING", "name": "Maubray", "code_postal": "7640", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KORTENBERG", "name": "Meerbeek", "code_postal": "3078", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AMBLÈVE", "name": "Meyerode", "code_postal": "4770", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MOL", "name": "MOL", "code_postal": "2400", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Mourcourt", "code_postal": "7543", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "JEMEPPE-SUR-SAMBRE", "name": "Moustier-Sur-Sambre", "code_postal": "5190", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Mullem", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MUSSON", "name": "MUSSON", "code_postal": "6750", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "HÉLÉCINE", "name": "Neerheylissem", "code_postal": "1357", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HULDENBERG", "name": "Neerijse", "code_postal": "3040", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "AS", "name": "Niel-Bij-As", "code_postal": "3668", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-NIKLAAS", "name": "Nieuwkerken-Waas", "code_postal": "9100", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Paulatem", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "PEER", "name": "PEER", "code_postal": "3990", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "PEPINGEN", "name": "PEPINGEN", "code_postal": "1670", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALTER", "name": "AALTER", "code_postal": "9880", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "AARTSELAAR", "name": "AARTSELAAR", "code_postal": "2630", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BEERSEL", "name": "Alsemberg", "code_postal": "1652", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "ANTWERPEN", "code_postal": "2000", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GAVERE", "name": "Baaigem", "code_postal": "9890", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BAARLE-HERTOG", "name": "BAARLE-HERTOG", "code_postal": "2387", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "BIÈVRE", "name": "Baillamont", "code_postal": "5555", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TINTIGNY", "name": "Bellefontaine", "code_postal": "6730", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BINCHE", "name": "BINCHE", "code_postal": "7130", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BERNISSART", "name": "Blaton", "code_postal": "7321", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VIRTON", "name": "Bleid", "code_postal": "6760", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PROFONDEVILLE", "name": "Bois-De-Villers", "code_postal": "5170", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Bouge", "code_postal": "5004", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SOUMAGNE", "name": "Cérexhe-Heuseux", "code_postal": "4632", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "CHARLEROI", "code_postal": "6000", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Chastrès", "code_postal": "5650", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Corroy-Le-Château", "code_postal": "5032", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Dion", "code_postal": "5570", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "DURBUY", "code_postal": "6940", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "YVOIR", "name": "Durnal", "code_postal": "5530", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "Ekeren", "code_postal": "2180", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BUTGENBACH", "name": "Elsenborn", "code_postal": "4750", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Ename", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KORTENBERG", "name": "Everberg", "code_postal": "3078", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VILLERS-LE-BOUILLET", "name": "Fize-Fontaine", "code_postal": "4530", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "Forville", "code_postal": "5380", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "GEDINNE", "code_postal": "5575", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HOOGLEDE", "name": "Gits", "code_postal": "8830", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "OHEY", "name": "Goesnes", "code_postal": "5353", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Gors-Opleeuw", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Grand-Hallet", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VIELSALM", "name": "Grand-Halleux", "code_postal": "6698", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Gutshoven", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BEVEREN-WAAS", "name": "Haasdonk", "code_postal": "9120", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Heks", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GENAPPE", "name": "Houtain-Le-Val", "code_postal": "1476", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HOUTHALEN-HELCHTEREN", "name": "HOUTHALEN-HELCHTEREN", "code_postal": "3530", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HARELBEKE", "name": "Hulste", "code_postal": "8531", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERVE", "name": "Julémont", "code_postal": "4650", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SAMBREVILLE", "name": "Keumiée", "code_postal": "5060", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KRUISEM", "name": "Kruishoutem", "code_postal": "9770", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VERVIERS", "name": "Lambermont", "code_postal": "4800", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "GEER", "name": "Ligney", "code_postal": "4254", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LUMMEN", "name": "Linkhout", "code_postal": "3560", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LINTER", "name": "LINTER", "code_postal": "3350", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NEUFCHÂTEAU", "name": "Longlier", "code_postal": "6840", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASEIK", "name": "MAASEIK", "code_postal": "3680", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "VILLERS-LA-VILLE", "name": "Marbais", "code_postal": "1495", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Henis", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LEOPOLDSBURG", "name": "Heppen", "code_postal": "3971", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LEDEGEM", "name": "LEDEGEM", "code_postal": "8880", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LENDELEDE", "name": "LENDELEDE", "code_postal": "8860", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Marche-Les-Dames", "code_postal": "5024", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WASSEIGES", "name": "Meeffe", "code_postal": "4219", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALST", "name": "Meldert", "code_postal": "9310", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "VILLERS-LA-VILLE", "name": "Mellery", "code_postal": "1495", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Membre", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "RIEMST", "name": "Membruggen", "code_postal": "3770", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Mendonk", "code_postal": "9042", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "name": "Messelbroek", "code_postal": "3272", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BEKKEVOORT", "name": "Molenbeek-Wersbeek", "code_postal": "3461", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "REMICOURT", "name": "Momalle", "code_postal": "4350", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "GOUVY", "name": "Montleban", "code_postal": "6674", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Onkerzele", "code_postal": "9500", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OOSTENDE", "name": "OOSTENDE", "code_postal": "8400", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OOSTERZELE", "name": "OOSTERZELE", "code_postal": "9860", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERMONDE", "name": "Oudegem", "code_postal": "9200", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Overrepen", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LINCENT", "name": "Pellaines", "code_postal": "4287", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "AISEAU-PRESLES", "name": "Presles", "code_postal": "6250", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TINTIGNY", "name": "Rossignol", "code_postal": "6730", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HABAY", "name": "Rulles", "code_postal": "6724", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "SAINT-GILLES", "name": "SAINT-GILLES", "code_postal": "1060", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "FLORENVILLE", "name": "Sainte-Cécile", "code_postal": "6820", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ETALLE", "name": "Sainte-Marie-Sur-Semois", "code_postal": "6740", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HEIST-OP-DEN-BERG", "name": "Schriek", "code_postal": "2223", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CINEY", "name": "Serinchamps", "code_postal": "5590", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HOREBEKE", "name": "Sint-Kornelis-Horebeke", "code_postal": "9667", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LENNIK", "name": "Sint-Kwintens-Lennik", "code_postal": "1750", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HERZELE", "name": "Sint-Lievens-Esse", "code_postal": "9550", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MEIX-DEVANT-VIRTON", "name": "Sommethonne", "code_postal": "6769", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANHÉE", "name": "Sosoye", "code_postal": "5537", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SOUMAGNE", "name": "SOUMAGNE", "code_postal": "4630", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "AFFLIGEM", "name": "Teralfene", "code_postal": "1790", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LEUZE-EN-HAINAUT", "name": "Thieulain", "code_postal": "7901", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Thimougies", "code_postal": "7533", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "PERWEZ", "name": "Thorembais-Saint-Trond", "code_postal": "1360", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SILLY", "name": "Thoricourt", "code_postal": "7830", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HUY", "name": "Tihange", "code_postal": "4500", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VAUX-SUR-SÛRE", "name": "VAUX-SUR-SÛRE", "code_postal": "6640", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "VEURNE", "code_postal": "8630", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "Vinkem", "code_postal": "8630", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "WEMMEL", "name": "WEMMEL", "code_postal": "1780", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VLETEREN", "name": "Westvleteren", "code_postal": "8640", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WORTEGEM-PETEGEM", "name": "Wortegem", "code_postal": "9790", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "JODOIGNE", "name": "Zétrud-Lumay", "code_postal": "1370", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAMOIS", "name": "Achet", "code_postal": "5362", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VISÉ", "name": "Argenteau", "code_postal": "4601", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GLABBEEK", "name": "Attenrode", "code_postal": "3384", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "Aulnois", "code_postal": "7040", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Avernas-Le-Bauduin", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "BEAURAING", "code_postal": "5570", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "BEAUVECHAIN", "name": "BEAUVECHAIN", "code_postal": "1320", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "BIÈVRE", "name": "Bellefontaine", "code_postal": "5555", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "WELLEN", "name": "Berlingen", "code_postal": "3830", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Berzée", "code_postal": "5651", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "CLAVIER", "name": "Bois-Et-Borsu", "code_postal": "4560", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "AVELGEM", "name": "Bossuit", "code_postal": "8583", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LA LOUVIÈRE", "name": "Boussoit", "code_postal": "7110", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIÈGE", "name": "Bressoux", "code_postal": "4020", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Brustem", "code_postal": "3800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BIÈVRE", "name": "Cornimont", "code_postal": "5555", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "THUIN", "name": "Donstiennes", "code_postal": "6536", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "YVOIR", "name": "Dorinne", "code_postal": "5530", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALST", "name": "Erembodegem", "code_postal": "9320", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "Erquennes", "code_postal": "7387", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ESPIERRES-HELCHIN", "name": "ESPIERRES-HELCHIN", "code_postal": "8587", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "PALISEUL", "name": "Fays-Les-Veneurs", "code_postal": "6856", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉRON", "name": "FLÉRON", "code_postal": "4620", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GLABBEEK", "name": "GLABBEEK", "code_postal": "3380", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "DOISCHE", "name": "Gochenée", "code_postal": "5680", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHAPELLE-LEZ-HERLAIMONT", "name": "Godarville", "code_postal": "7160", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GEETBETS", "name": "Grazen", "code_postal": "3450", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TELLIN", "name": "Grupont", "code_postal": "6927", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LA ROCHE-EN-ARDENNE", "name": "Halleux", "code_postal": "6986", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NEUFCHÂTEAU", "name": "Hamipré", "code_postal": "6840", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HAMONT-ACHEL", "name": "HAMONT-ACHEL", "code_postal": "3930", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOTTON", "name": "Hampteau", "code_postal": "6990", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAVELANGE", "name": "HAVELANGE", "code_postal": "5370", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ESPIERRES-HELCHIN", "name": "Helchin", "code_postal": "8587", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "Hemptinne", "code_postal": "5380", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "JURBISE", "name": "Herchies", "code_postal": "7050", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "RIEMST", "name": "Herderen", "code_postal": "3770", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DESTELBERGEN", "name": "Heusden", "code_postal": "9070", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "MONT-SAINT-GUIBERT", "name": "Hévillers", "code_postal": "1435", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HERZELE", "name": "Hillegem", "code_postal": "9550", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "PLOMBIÈRES", "name": "Hombourg", "code_postal": "4852", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HOOGSTRATEN", "name": "HOOGSTRATEN", "code_postal": "2320", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "HULSHOUT", "name": "Houtvenne", "code_postal": "2235", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HULSHOUT", "name": "HULSHOUT", "code_postal": "2235", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RAMILLIES", "name": "Huppaye", "code_postal": "1367", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ORP-JAUCHE", "name": "Jandrain-Jandrenouille", "code_postal": "1350", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KAPRIJKE", "name": "KAPRIJKE", "code_postal": "9970", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KORTENAKEN", "name": "Kersbeek-Miskom", "code_postal": "3472", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BEVEREN-WAAS", "name": "Kieldrecht", "code_postal": "9130", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Kleine-Spouwen", "code_postal": "3740", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LA LOUVIÈRE", "name": "LA LOUVIÈRE", "code_postal": "7100", "sous_commune": false },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "BRUXELLES", "name": "Laeken", "code_postal": "1020", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LANAKEN", "name": "LANAKEN", "code_postal": "3620", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "THUIN", "name": "Leers-Et-Fosteau", "code_postal": "6530", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CINEY", "name": "Leignon", "code_postal": "5590", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERSTAL", "name": "Liers", "code_postal": "4042", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LOKEREN", "name": "LOKEREN", "code_postal": "9160", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LENS", "name": "Lombise", "code_postal": "7870", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LONDERZEEL", "name": "LONDERZEEL", "code_postal": "1840", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIEVEGEM", "name": "Lovendegem", "code_postal": "9920", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PROFONDEVILLE", "name": "Lustin", "code_postal": "5170", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOMIGNIES", "name": "Macon", "code_postal": "6591", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SAMBREVILLE", "name": "Moignelée", "code_postal": "5060", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SAINT-NICOLAS", "name": "Montegnée", "code_postal": "4420", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LENS", "name": "Montignies-Lez-Lens", "code_postal": "7870", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "FOURONS", "name": "Mouland", "code_postal": "3790", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Nederzwalm-Hermelgem", "code_postal": "9636", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GREZ-DOICEAU", "name": "Nethen", "code_postal": "1390", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SOIGNIES", "name": "Neufvilles", "code_postal": "7063", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZAVENTEM", "name": "Nossegem", "code_postal": "1930", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "WESTERLO", "name": "Oevel", "code_postal": "2260", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VIROINVAL", "name": "Olloy-Sur-Viroin", "code_postal": "5670", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Oudekapelle", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "QUÉVY", "code_postal": "7040", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LEDEGEM", "name": "Rollegem-Kapelle", "code_postal": "8880", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DOISCHE", "name": "Romerée", "code_postal": "5680", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PÉRUWELZ", "name": "Roucourt", "code_postal": "7601", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SINT-PIETERS-LEEUW", "name": "Ruisbroek", "code_postal": "1601", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BLÉGNY", "name": "Saint-Remy", "code_postal": "4672", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "SCHAERBEEK", "name": "SCHAERBEEK", "code_postal": "1030", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MERELBEKE", "name": "Schelderode", "code_postal": "9820", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BRECHT", "name": "Sint-Job-In-'T-Goor", "code_postal": "2960", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "Sint-Maria-Oudenhove", "code_postal": "9620", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MIDDELKERKE", "name": "Sint-Pieters-Kapelle", "code_postal": "8433", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBIN", "name": "Smuid", "code_postal": "6890", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ONHAYE", "name": "Sommière", "code_postal": "5523", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OOSTENDE", "name": "Stene", "code_postal": "8400", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Suarlée", "code_postal": "5020", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "PERWEZ", "name": "Thorembais-Les-Béguines", "code_postal": "1360", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SOUMAGNE", "name": "Tignée", "code_postal": "4630", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TREMELO", "name": "TREMELO", "code_postal": "3120", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "RIEMST", "name": "Val-Meer", "code_postal": "3770", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "Velzeke-Ruddershove", "code_postal": "9620", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VIELSALM", "name": "VIELSALM", "code_postal": "6690", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "BRAIVES", "name": "Ville-En-Hesbaye", "code_postal": "4260", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VILLERS-LE-BOUILLET", "name": "VILLERS-LE-BOUILLET", "code_postal": "4530", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DE HAAN", "name": "Vlissegem", "code_postal": "8421", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Voort", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Warchin", "code_postal": "7548", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WAREGEM", "name": "WAREGEM", "code_postal": "8790", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DIEST", "name": "Webbekom", "code_postal": "3290", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ELLEZELLES", "name": "Wodecq", "code_postal": "7890", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZEMST", "name": "ZEMST", "code_postal": "1980", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUAREGNON", "name": "QUAREGNON", "code_postal": "7390", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BELOEIL", "name": "Quevaucamps", "code_postal": "7972", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LINCENT", "name": "Racour", "code_postal": "4287", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "STOUMONT", "name": "Rahier", "code_postal": "4987", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Robechies", "code_postal": "6460", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Roborst", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ROESELARE", "name": "ROESELARE", "code_postal": "8800", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTINNES", "name": "Rouveroy", "code_postal": "7120", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KLUISBERGEN", "name": "Ruien", "code_postal": "9690", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FOSSES-LA-VILLE", "name": "Sart-Eustache", "code_postal": "5070", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HERZELE", "name": "Sint-Antelinks", "code_postal": "9550", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "Sint-Jan", "code_postal": "8900", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-NIKLAAS", "name": "SINT-NIKLAAS", "code_postal": "9100", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HERZELE", "name": "Steenhuize-Wijnhuize", "code_postal": "9550", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Tarcienne", "code_postal": "5651", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ESNEUX", "name": "Tilff", "code_postal": "4130", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ARLON", "name": "Toernich", "code_postal": "6700", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "TROOZ", "name": "TROOZ", "code_postal": "4870", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VAUX-SUR-SÛRE", "name": "Vaux-Lez-Rosières", "code_postal": "6640", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "VILVOORDE", "name": "VILVOORDE", "code_postal": "1800", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Virelles", "code_postal": "6461", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIENEN", "name": "Vissenaken", "code_postal": "3300", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BEVEREN-WAAS", "name": "Vrasene", "code_postal": "9120", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Waarbeke", "code_postal": "9506", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OUFFET", "name": "Warzée", "code_postal": "4590", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERLEEUW", "name": "Welle", "code_postal": "9473", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FERRIÈRES", "name": "Werbomont", "code_postal": "4190", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HEUVELLAND", "name": "Westouter", "code_postal": "8954", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "Wilrijk", "code_postal": "2610", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZELZATE", "name": "ZELZATE", "code_postal": "9060", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "WESTERLO", "name": "Zoerle-Parwijs", "code_postal": "2260", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZULTE", "name": "ZULTE", "code_postal": "9870", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZWEVEGEM", "name": "ZWEVEGEM", "code_postal": "8550", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "WASSEIGES", "name": "Ambresin", "code_postal": "4219", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIÈGE", "name": "Angleur", "code_postal": "4031", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BAELEN", "name": "BAELEN", "code_postal": "4837", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "JEMEPPE-SUR-SAMBRE", "name": "Balâtre", "code_postal": "5190", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAVELANGE", "name": "Barvaux-Condroz", "code_postal": "5370", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HARELBEKE", "name": "Bavikhove", "code_postal": "8531", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ROESELARE", "name": "Beveren", "code_postal": "8800", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "REBECQ", "name": "Bierghes", "code_postal": "1430", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SOMME-LEUZE", "name": "Bonsin", "code_postal": "5377", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GREZ-DOICEAU", "name": "Bossut-Gottechain", "code_postal": "1390", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "GOUVY", "name": "Bovigny", "code_postal": "6671", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LEUZE-EN-HAINAUT", "name": "Chapelle-À-Wattines", "code_postal": "7903", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "CHASTRE", "name": "Chastre-Villeroux-Blanmont", "code_postal": "1450", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "GOUVY", "name": "Cherain", "code_postal": "6673", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIÈVRES", "name": "CHIÈVRES", "code_postal": "7950", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "SAINT-VITH", "name": "Crombach", "code_postal": "4784", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DE HAAN", "name": "DE HAAN", "code_postal": "8420", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DEERLIJK", "name": "DEERLIJK", "code_postal": "8540", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LEBBEKE", "name": "Denderbelle", "code_postal": "9280", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-MARTENS-LATEM", "name": "Deurle", "code_postal": "9831", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HECHTEL-EKSEL", "name": "Eksel", "code_postal": "3941", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ELLEZELLES", "name": "ELLEZELLES", "code_postal": "7890", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BRAKEL", "name": "Elst", "code_postal": "9660", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ENGHIEN", "name": "ENGHIEN", "code_postal": "7850", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Ere", "code_postal": "7500", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MERBES-LE-CHÂTEAU", "name": "Fontaine-Valmont", "code_postal": "6567", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Froidmont", "code_postal": "7504", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DAVERDISSE", "name": "Gembes", "code_postal": "6929", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RIXENSART", "name": "Genval", "code_postal": "1332", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RAMILLIES", "name": "Gérompont", "code_postal": "1367", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "GERPINNES", "name": "Gougnies", "code_postal": "6280", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Grand-Leez", "code_postal": "5031", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERVE", "name": "Grand-Rechain", "code_postal": "4650", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BELOEIL", "name": "Grandglise", "code_postal": "7973", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NEUFCHÂTEAU", "name": "Grapfontaine", "code_postal": "6840", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERMONDE", "name": "Grembergen", "code_postal": "9200", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUNEHAUT", "name": "Guignies", "code_postal": "7620", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HABAY", "name": "Habay-La-Neuve", "code_postal": "6720", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HALEN", "name": "HALEN", "code_postal": "3545", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HERENT", "name": "HERENT", "code_postal": "3020", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "REMICOURT", "name": "Hodeige", "code_postal": "4351", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HOEGAARDEN", "name": "HOEGAARDEN", "code_postal": "3320", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "GEER", "name": "Hollogne-Sur-Geer", "code_postal": "4250", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WANZE", "name": "Huccorgne", "code_postal": "4520", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "INCOURT", "name": "INCOURT", "code_postal": "1315", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "JABBEKE", "name": "JABBEKE", "code_postal": "8490", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "JODOIGNE", "name": "Jauchelette", "code_postal": "1370", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Jemappes", "code_postal": "7012", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BEVEREN-WAAS", "name": "Kallo", "code_postal": "9120", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HOUTHULST", "name": "Klerken", "code_postal": "8650", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LA CALAMINE", "name": "LA CALAMINE", "code_postal": "4720", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "BURDINNE", "name": "Lamontzée", "code_postal": "4210", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONTIGNY-LE-TILLEUL", "name": "Landelies", "code_postal": "6111", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MENEN", "name": "Lauwe", "code_postal": "8930", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VIROINVAL", "name": "Le Mesnil", "code_postal": "5670", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "CHINY", "name": "Les Bulles", "code_postal": "6811", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIERNEUX", "name": "LIERNEUX", "code_postal": "4990", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "OTTIGNIES-LOUVAIN-LA-NEUVE", "name": "Limelette", "code_postal": "1342", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BERTOGNE", "name": "Longchamps", "code_postal": "6688", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BASTOGNE", "name": "Longvilly", "code_postal": "6600", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Mesvin", "code_postal": "7022", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZWEVEGEM", "name": "Moen", "code_postal": "8552", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONTIGNY-LE-TILLEUL", "name": "MONTIGNY-LE-TILLEUL", "code_postal": "6110", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MOORSLEDE", "name": "MOORSLEDE", "code_postal": "8890", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "EREZÉE", "name": "Mormont", "code_postal": "6997", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "Muizen", "code_postal": "3891", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Nederboelare", "code_postal": "9500", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Nevele", "code_postal": "9850", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Noville-Sur-Mehaigne", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Obourg", "code_postal": "7034", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VIROINVAL", "name": "Oignies-En-Thiérache", "code_postal": "5670", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HAM", "name": "Oostham", "code_postal": "3945", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BIERBEEK", "name": "Opvelp", "code_postal": "3360", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OUPEYE", "name": "OUPEYE", "code_postal": "4680", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ENGHIEN", "name": "Petit-Enghien", "code_postal": "7850", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Pollare", "code_postal": "9401", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CELLES", "name": "Pottes", "code_postal": "7760", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AMAY", "name": "Ampsin", "code_postal": "4540", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "ANTWERPEN", "code_postal": "2018", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "PROFONDEVILLE", "name": "Arbre", "code_postal": "5170", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Arc-Wattripont", "code_postal": "7910", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Avin", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OOSTERZELE", "name": "Balegem", "code_postal": "9860", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Batsheers", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KRUIBEKE", "name": "Bazel", "code_postal": "9150", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BEVEREN-WAAS", "name": "BEVEREN-WAAS", "code_postal": "9120", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "Boezinge", "code_postal": "8904", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HERZELE", "name": "Borsbeke", "code_postal": "9552", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Bourseigne-Vieille", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "BRAINE-L'ALLEUD", "name": "BRAINE-L'ALLEUD", "code_postal": "1420", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "BRECHT", "name": "BRECHT", "code_postal": "2960", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "BREE", "name": "BREE", "code_postal": "3960", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HALLE", "name": "Buizingen", "code_postal": "1501", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LENS", "name": "Cambron-Saint-Vincent", "code_postal": "7870", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HOUYET", "name": "Ciergnon", "code_postal": "5560", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Cordes", "code_postal": "7910", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "COURT-SAINT-ETIENNE", "name": "COURT-SAINT-ETIENNE", "code_postal": "1490", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "DOISCHE", "name": "DOISCHE", "code_postal": "5680", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TENNEVILLE", "name": "Erneuville", "code_postal": "6972", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "EVERGEM", "name": "Ertvelde", "code_postal": "9940", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MAARKEDAL", "name": "Etikhove", "code_postal": "9680", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Fagnolle", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FAIMES", "name": "FAIMES", "code_postal": "4317", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GALMAARDEN", "name": "GALMAARDEN", "code_postal": "1570", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Gentbrugge", "code_postal": "9050", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIMBOURG", "name": "Goé", "code_postal": "4834", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Grand-Manil", "code_postal": "5030", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ERQUELINNES", "name": "Grand-Reng", "code_postal": "6560", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DILBEEK", "name": "Groot-Bijgaarden", "code_postal": "1702", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BIÈVRE", "name": "Gros-Fays", "code_postal": "5555", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NASSOGNE", "name": "Harsin", "code_postal": "6950", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "HEERS", "code_postal": "3870", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "HERSTAPPE", "name": "HERSTAPPE", "code_postal": "3717", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HERZELE", "name": "HERZELE", "code_postal": "9550", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DILBEEK", "name": "Itterbeek", "code_postal": "1701", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DE HAAN", "name": "Klemskerke", "code_postal": "8420", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Kolmont", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "POPERINGE", "name": "Krombeke", "code_postal": "8972", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRAMERIES", "name": "La Bouverie", "code_postal": "7080", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "LA HULPE", "name": "LA HULPE", "code_postal": "1310", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ELLEZELLES", "name": "Lahamaide", "code_postal": "7890", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FLEURUS", "name": "Lambusart", "code_postal": "6220", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIÈGE", "name": "LIÈGE", "code_postal": "4000", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "ZANDHOVEN", "name": "Massenhoven", "code_postal": "2240", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAVELANGE", "name": "Méan", "code_postal": "5372", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIELT-WINGE", "name": "Meensel-Kiezegem", "code_postal": "3391", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MEIX-DEVANT-VIRTON", "name": "MEIX-DEVANT-VIRTON", "code_postal": "6769", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MELLE", "name": "MELLE", "code_postal": "9090", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BEVEREN-WAAS", "name": "Melsele", "code_postal": "9120", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BAELEN", "name": "Membach", "code_postal": "4837", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HOUYET", "name": "Mesnil-Saint-Blaise", "code_postal": "5560", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Monceau-Sur-Sambre", "code_postal": "6031", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HENSIES", "name": "Montroeul-Sur-Haine", "code_postal": "7350", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "PLOMBIÈRES", "name": "Moresnet", "code_postal": "4850", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NAZARETH", "name": "NAZARETH", "code_postal": "9810", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LINTER", "name": "Neerhespen", "code_postal": "3350", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LINTER", "name": "Neerlinter", "code_postal": "3350", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ONHAYE", "name": "ONHAYE", "code_postal": "5520", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LEDE", "name": "Oordegem", "code_postal": "9340", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIENEN", "name": "Oplinter", "code_postal": "3300", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "OUD-HEVERLEE", "name": "OUD-HEVERLEE", "code_postal": "3050", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GLABBEEK", "name": "Kapellen", "code_postal": "3381", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MERBES-LE-CHÂTEAU", "name": "Labuissière", "code_postal": "6567", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "FLORENVILLE", "name": "Lacuisine", "code_postal": "6821", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "Lillo", "code_postal": "2040", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "OTTIGNIES-LOUVAIN-LA-NEUVE", "name": "Louvain-La-Neuve", "code_postal": "1348", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Quartes", "code_postal": "7540", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LO-RENINGE", "name": "Reninge", "code_postal": "8647", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "AARSCHOT", "name": "Rillaar", "code_postal": "3202", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FEXHE-LE-HAUT-CLOCHER", "name": "Roloux", "code_postal": "4347", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIEVEGEM", "name": "Ronsele", "code_postal": "9932", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "DILSEN-STOKKEM", "name": "Rotem", "code_postal": "3650", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OOSTKAMP", "name": "Ruddervoorde", "code_postal": "8020", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Rumsdorp", "code_postal": "3400", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "METTET", "name": "Saint-Gérard", "code_postal": "5640", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Sart-En-Fagne", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAMOIS", "name": "Scy", "code_postal": "5361", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOMIGNIES", "name": "Seloignes", "code_postal": "6596", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Sint-Blasius-Boekel", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Spiennes", "code_postal": "7032", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "STAVELOT", "name": "STAVELOT", "code_postal": "4970", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LA LOUVIÈRE", "name": "Strépy-Bracquegnies", "code_postal": "7110", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "NIVELLES", "name": "Thines", "code_postal": "1402", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BRAIVES", "name": "Tourinne", "code_postal": "4263", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ETALLE", "name": "Vance", "code_postal": "6741", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VERLAINE", "name": "VERLAINE", "code_postal": "4537", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BOUTERSEM", "name": "Vertrijk", "code_postal": "3370", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BORNEM", "name": "Weert", "code_postal": "2880", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Wéris", "code_postal": "6940", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HOESELT", "name": "Werm", "code_postal": "3730", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WICHELEN", "name": "WICHELEN", "code_postal": "9260", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Widooie", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "OUDSBERGEN", "name": "Wijshagen", "code_postal": "3670", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "YVOIR", "name": "YVOIR", "code_postal": "5530", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "ZONHOVEN", "name": "ZONHOVEN", "code_postal": "3520", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ALVERINGEM", "name": "ALVERINGEM", "code_postal": "8690", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "AUBANGE", "name": "Athus", "code_postal": "6791", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "Avekapelle", "code_postal": "8630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Beerlegem", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Bende", "code_postal": "6941", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "METTET", "name": "Biesmerée", "code_postal": "5640", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "BRAINE-LE-CHÂTEAU", "name": "BRAINE-LE-CHÂTEAU", "code_postal": "1440", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "ANDENNE", "name": "Coutisse", "code_postal": "5300", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HAALTERT", "name": "Denderhoutem", "code_postal": "9450", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANHÉE", "name": "Denée", "code_postal": "5537", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WAREGEM", "name": "Desselgem", "code_postal": "8792", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Desteldonk", "code_postal": "9042", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DIEST", "name": "DIEST", "code_postal": "3290", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Duras", "code_postal": "3803", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BASSENGE", "name": "Eben-Emael", "code_postal": "4690", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ESSEN", "name": "ESSEN", "code_postal": "2910", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "SOUMAGNE", "name": "Evegnée", "code_postal": "4631", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MANAGE", "name": "Fayt-Lez-Manage", "code_postal": "7170", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "FOURONS", "name": "Fouron-Saint-Martin", "code_postal": "3790", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FLOREFFE", "name": "Franière", "code_postal": "5150", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "GEER", "name": "GEER", "code_postal": "4250", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "LILLE", "name": "Gierle", "code_postal": "2275", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GOOIK", "name": "GOOIK", "code_postal": "1755", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Gotem", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HAALTERT", "name": "HAALTERT", "code_postal": "9450", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HABAY", "name": "HABAY", "code_postal": "6720", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "Hanzinelle", "code_postal": "5621", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "BRUXELLES", "name": "Haren", "code_postal": "1130", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MANHAY", "name": "Harre", "code_postal": "6960", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HASTIÈRE", "name": "Hastière-Par-Delà", "code_postal": "5541", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HASTIÈRE", "name": "Heer", "code_postal": "5543", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "WILLEBROEK", "name": "Heindonk", "code_postal": "2830", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "AFFLIGEM", "name": "Hekelgem", "code_postal": "1790", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HOUTHALEN-HELCHTEREN", "name": "Helchteren", "code_postal": "3530", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SILLY", "name": "Hellebecq", "code_postal": "7830", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERVE", "name": "HERVE", "code_postal": "4650", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "BLÉGNY", "name": "Housse", "code_postal": "4671", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "Houtem", "code_postal": "8630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ICHTEGEM", "name": "ICHTEGEM", "code_postal": "8480", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "INGELMUNSTER", "name": "INGELMUNSTER", "code_postal": "8770", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Izier", "code_postal": "6941", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "Jeuk", "code_postal": "3890", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "NIJLEN", "name": "Kessel", "code_postal": "2560", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTEMARK", "name": "KORTEMARK", "code_postal": "8610", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KUURNE", "name": "KUURNE", "code_postal": "8520", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LANGEMARK-POELKAPELLE", "name": "Langemark", "code_postal": "8920", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "LASNE", "name": "LASNE", "code_postal": "1380", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Mal", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MARCHE-EN-FAMENNE", "name": "MARCHE-EN-FAMENNE", "code_postal": "6900", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Mariakerke", "code_postal": "9030", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ORP-JAUCHE", "name": "Marilles", "code_postal": "1350", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MEERHOUT", "name": "MEERHOUT", "code_postal": "2450", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Meslin-L'Evêque", "code_postal": "7822", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MEULEBEKE", "name": "MEULEBEKE", "code_postal": "8760", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "MORLANWELZ", "name": "Mont-Sainte-Aldegonde", "code_postal": "7141", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "Montenaken", "code_postal": "3890", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ERQUELINNES", "name": "Montignies-Saint-Christophe", "code_postal": "6560", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DALHEM", "name": "Neufchâteau", "code_postal": "4608", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "NIEUWPOORT", "name": "NIEUWPOORT", "code_postal": "8620", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "PALISEUL", "name": "Nollevaux", "code_postal": "6851", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LIEVENS-HOUTEM", "name": "Oombergen", "code_postal": "9520", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ERPE-MERE", "name": "Ottergem", "code_postal": "9420", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "OTTIGNIES-LOUVAIN-LA-NEUVE", "name": "Ottignies", "code_postal": "1340", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "OUDENAARDE", "code_postal": "9700", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LUBBEEK", "name": "Pellenberg", "code_postal": "3212", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PÉRUWELZ", "name": "PÉRUWELZ", "code_postal": "7600", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "POPERINGE", "name": "POPERINGE", "code_postal": "8970", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "REMICOURT", "name": "Pousset", "code_postal": "4350", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Presgaux", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "THUIN", "name": "Ragnies", "code_postal": "6532", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SIVRY-RANCE", "name": "Rance", "code_postal": "6470", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BURG-REULAND", "name": "Reuland", "code_postal": "4790", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Riksingen", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUNEHAUT", "name": "Rongy", "code_postal": "7623", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ROOSDAAL", "name": "ROOSDAAL", "code_postal": "1760", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINT-LÉGER", "name": "SAINT-LÉGER", "code_postal": "6747", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TINTIGNY", "name": "Saint-Vincent", "code_postal": "6730", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Sautour", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "TIELT", "name": "Schuiferskapelle", "code_postal": "8700", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "Sensenruth", "code_postal": "6832", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CERFONTAINE", "name": "Senzeilles", "code_postal": "5630", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VAUX-SUR-SÛRE", "name": "Sibret", "code_postal": "6640", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-GILLIS-WAAS", "name": "SINT-GILLIS-WAAS", "code_postal": "9170", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LAUREINS", "name": "SINT-LAUREINS", "code_postal": "9980", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HOREBEKE", "name": "Sint-Maria-Horebeke", "code_postal": "9667", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SINT-PIETERS-LEEUW", "name": "SINT-PIETERS-LEEUW", "code_postal": "1600", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "SINT-TRUIDEN", "code_postal": "3800", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "STABROEK", "name": "STABROEK", "code_postal": "2940", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "RUMES", "name": "Taintignies", "code_postal": "7618", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIELT-WINGE", "name": "Tielt", "code_postal": "3390", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "VILLERS-LA-VILLE", "name": "Tilly", "code_postal": "1495", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "LAAKDAL", "name": "Varendonk", "code_postal": "2431", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Vechmaal", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "Voormezele", "code_postal": "8902", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Waltwilder", "code_postal": "3740", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FLEURUS", "name": "Wanfercée-Baulet", "code_postal": "6224", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "WATERLOO", "name": "WATERLOO", "code_postal": "1410", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LAUREINS", "name": "Watervliet", "code_postal": "9988", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "PEPINSTER", "name": "Wegnez", "code_postal": "4860", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "Zandvliet", "code_postal": "2040", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HALEN", "name": "Zelem", "code_postal": "3545", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ASSE", "name": "Zellik", "code_postal": "1731", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "ZOTTEGEM", "code_postal": "9620", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "Zuidschote", "code_postal": "8904", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HAMONT-ACHEL", "name": "Achel", "code_postal": "3930", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "AFFLIGEM", "name": "AFFLIGEM", "code_postal": "1790", "sous_commune": false },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "ANDERLECHT", "name": "ANDERLECHT", "code_postal": "1070", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBIN", "name": "Anloy", "code_postal": "6890", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "name": "Averbode", "code_postal": "3271", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERMONDE", "name": "Baasrode", "code_postal": "9200", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BALEN", "name": "BALEN", "code_postal": "2490", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Baronville", "code_postal": "5570", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Barry", "code_postal": "7534", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WANZE", "name": "Bas-Oha", "code_postal": "4520", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BEAUMONT", "name": "BEAUMONT", "code_postal": "6500", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ASSE", "name": "Bekkerzeel", "code_postal": "1730", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Beuzet", "code_postal": "5030", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "NIJLEN", "name": "Bevel", "code_postal": "2560", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LOBBES", "name": "Bienne-Lez-Happart", "code_postal": "6543", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "NIEUWERKERKEN", "name": "Binderveld", "code_postal": "3850", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTRIJK", "name": "Bissegem", "code_postal": "8501", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "CHAUMONT-GISTOUX", "name": "Bonlez", "code_postal": "1325", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BOORTMEERBEEK", "name": "BOORTMEERBEEK", "code_postal": "3190", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "WAREMME", "name": "Bovenistier", "code_postal": "4300", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PÉRUWELZ", "name": "Brasménil", "code_postal": "7604", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BUTGENBACH", "name": "BUTGENBACH", "code_postal": "4750", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "FAIMES", "name": "Celles", "code_postal": "4317", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Clermont", "code_postal": "5650", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HAMOIR", "name": "Comblain-Fairon", "code_postal": "4180", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Daussoulx", "code_postal": "5020", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OUFFET", "name": "Ellemelle", "code_postal": "4590", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "Elverdinge", "code_postal": "8906", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CELLES", "name": "Escanaffles", "code_postal": "7760", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FLEURUS", "name": "FLEURUS", "code_postal": "6220", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Fontenelle", "code_postal": "5650", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SILLY", "name": "Fouleng", "code_postal": "7830", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "Foy-Notre-Dame", "code_postal": "5504", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "Franc-Waret", "code_postal": "5380", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "Genly", "code_postal": "7040", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "GERAARDSBERGEN", "code_postal": "9500", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "LIÈGE", "name": "Glain", "code_postal": "4000", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LA LOUVIÈRE", "name": "Haine-Saint-Paul", "code_postal": "7100", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "WELLIN", "name": "Halma", "code_postal": "6922", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ARLON", "name": "Heinsch", "code_postal": "6700", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Lampernisse", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OOSTERZELE", "name": "Landskouter", "code_postal": "9860", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LANGEMARK-POELKAPELLE", "name": "LANGEMARK-POELKAPELLE", "code_postal": "8920", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Lauw", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Leke", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBIN", "name": "LIBIN", "code_postal": "6890", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Lieferinge", "code_postal": "9400", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "PERWEZ", "name": "Malèves-Sainte-Marie-Wastinnes", "code_postal": "1360", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Maulde", "code_postal": "7534", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MERELBEKE", "name": "MERELBEKE", "code_postal": "9820", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MALDEGEM", "name": "Middelburg", "code_postal": "9992", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "Mielen-Boven-Aalst", "code_postal": "3891", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ASSE", "name": "Mollem", "code_postal": "1730", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DALHEM", "name": "Mortroux", "code_postal": "4607", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOUFFALIZE", "name": "Nadrin", "code_postal": "6660", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Naninne", "code_postal": "5100", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KAMPENHOUT", "name": "Nederokkerzeel", "code_postal": "1910", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LESSINES", "name": "Ogy", "code_postal": "7862", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AMAY", "name": "Ombret", "code_postal": "4540", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ASSENEDE", "name": "Oosteeklo", "code_postal": "9968", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LINTER", "name": "Orsmaal-Gussenhoven", "code_postal": "3350", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "OTTIGNIES-LOUVAIN-LA-NEUVE", "name": "OTTIGNIES-LOUVAIN-LA-NEUVE", "code_postal": "1340", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ANTOING", "name": "Péronnes-Lez-Antoing", "code_postal": "7640", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PONT-À-CELLES", "name": "PONT-À-CELLES", "code_postal": "6230", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALST", "name": "Herdersem", "code_postal": "9310", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OOSTKAMP", "name": "Hertsberge", "code_postal": "8020", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "YVOIR", "name": "Houx", "code_postal": "5530", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "KINROOI", "name": "Kessenich", "code_postal": "3640", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KNOKKE-HEIST", "name": "KNOKKE-HEIST", "code_postal": "8300", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "HASSELT", "name": "Kuringen", "code_postal": "3511", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "CLAVIER", "name": "Les Avins", "code_postal": "4560", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NASSOGNE", "name": "Lesterny", "code_postal": "6953", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "STOUMONT", "name": "Lorcé", "code_postal": "4987", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "REMICOURT", "name": "REMICOURT", "code_postal": "4350", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TELLIN", "name": "Resteigne", "code_postal": "6927", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "LA BRUYÈRE", "name": "Rhisnes", "code_postal": "5080", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "RHODE-SAINT-GENÈSE", "name": "RHODE-SAINT-GENÈSE", "code_postal": "1640", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Saint-Maur", "code_postal": "7500", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MAARKEDAL", "name": "Schorisse", "code_postal": "9688", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GAVERE", "name": "Semmerzake", "code_postal": "9890", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WIELSBEKE", "name": "Sint-Baafs-Vijve", "code_postal": "8710", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LIEVENS-HOUTEM", "name": "SINT-LIEVENS-HOUTEM", "code_postal": "9520", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "EVERGEM", "name": "Sleidinge", "code_postal": "9940", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "DILSEN-STOKKEM", "name": "Stokkem", "code_postal": "3650", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HASSELT", "name": "Stokrooie", "code_postal": "3511", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "CHINY", "name": "Termes", "code_postal": "6813", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINTE-ODE", "name": "Tillet", "code_postal": "6680", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ROUVROY", "name": "Torgny", "code_postal": "6767", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "TROIS-PONTS", "name": "TROIS-PONTS", "code_postal": "4980", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BLANKENBERGE", "name": "Uitkerke", "code_postal": "8370", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZEDELGEM", "name": "Veldegem", "code_postal": "8210", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTINNES", "name": "Vellereille-Les-Brayeux", "code_postal": "7120", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Veulen", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VILLERS-LE-BOUILLET", "name": "Vieux-Waleffe", "code_postal": "4530", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ITTRE", "name": "Virginal-Samme", "code_postal": "1460", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "RIEMST", "name": "Vlijtingen", "code_postal": "3770", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BELOEIL", "name": "Wadelincourt", "code_postal": "7971", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Walsbets", "code_postal": "3401", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TERNAT", "name": "Wambeek", "code_postal": "1741", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LAUREINS", "name": "Waterland-Oudeman", "code_postal": "9988", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "WAVRE", "name": "WAVRE", "code_postal": "1300", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "HULSHOUT", "name": "Westmeerbeek", "code_postal": "2235", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "name": "Zichem", "code_postal": "3271", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ZOERSEL", "name": "ZOERSEL", "code_postal": "2980", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Abolens", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANDENNE", "name": "ANDENNE", "code_postal": "5300", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "Angre", "code_postal": "7387", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "Anseremme", "code_postal": "5500", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ARENDONK", "name": "ARENDONK", "code_postal": "2370", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ASSE", "name": "ASSE", "code_postal": "1730", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "AWANS", "name": "AWANS", "code_postal": "4340", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Bachte-Maria-Leerne", "code_postal": "9800", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GENAPPE", "name": "Baisy-Thy", "code_postal": "1470", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Barvaux-Sur-Ourthe", "code_postal": "6940", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BEERSEL", "name": "BEERSEL", "code_postal": "1650", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "PEPINGEN", "name": "Beert", "code_postal": "1673", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "BERCHEM-SAINTE-AGATHE", "name": "BERCHEM-SAINTE-AGATHE", "code_postal": "1082", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "BERLAAR", "name": "BERLAAR", "code_postal": "2590", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ERQUELINNES", "name": "Bersillies-L'Abbaye", "code_postal": "6560", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "MALMEDY", "name": "Bevercé", "code_postal": "4960", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "Blaregnies", "code_postal": "7040", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "Boekhout", "code_postal": "3890", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Bohan", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PÉRUWELZ", "name": "Braffe", "code_postal": "7604", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Broekom", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HOUYET", "name": "Celles", "code_postal": "5561", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Cuesmes", "code_postal": "7033", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "CHAUMONT-GISTOUX", "name": "Dion-Valmont", "code_postal": "1325", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOUSCRON", "name": "Dottignies", "code_postal": "7711", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "DILSEN-STOKKEM", "name": "Elen", "code_postal": "3650", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ERQUELINNES", "name": "ERQUELINNES", "code_postal": "6560", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "AFFLIGEM", "name": "Essene", "code_postal": "1790", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "ETTERBEEK", "name": "ETTERBEEK", "code_postal": "1040", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "HOUYET", "name": "Finnevaux", "code_postal": "5560", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LESSINES", "name": "Ghoy", "code_postal": "7863", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ALVERINGEM", "name": "Gijverinkhove", "code_postal": "8691", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "HANNUT", "code_postal": "4280", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "Hanzinne", "code_postal": "5621", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Haren", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "Hemptinne-Lez-Florennes", "code_postal": "5620", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "STABROEK", "name": "Hoevenen", "code_postal": "2940", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HABAY", "name": "Houdemont", "code_postal": "6724", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AMAY", "name": "Jehay", "code_postal": "4540", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "GERPINNES", "name": "Joncret", "code_postal": "6280", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Jumet", "code_postal": "6040", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "KINROOI", "name": "KINROOI", "code_postal": "3640", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "LIER", "name": "Koningshooikt", "code_postal": "2500", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "STOUMONT", "name": "La Gleize", "code_postal": "4987", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PROFONDEVILLE", "name": "Lesve", "code_postal": "5170", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASMECHELEN", "name": "Leut", "code_postal": "3630", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LIEDEKERKE", "name": "LIEDEKERKE", "code_postal": "1770", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LOCHRISTI", "name": "LOCHRISTI", "code_postal": "9080", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HEUVELLAND", "name": "Loker", "code_postal": "8958", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Lonzée", "code_postal": "5030", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALTER", "name": "Lotenhulle", "code_postal": "9880", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AYWAILLE", "name": "Louveigné", "code_postal": "4920", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOUFFALIZE", "name": "Mabompré", "code_postal": "6663", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "Marchipont", "code_postal": "7387", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DENTERGEM", "name": "Markegem", "code_postal": "8720", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "JURBISE", "name": "Masnuy-Saint-Pierre", "code_postal": "7050", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-GILLIS-WAAS", "name": "Meerdonk", "code_postal": "9170", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Mehaigne", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LUMMEN", "name": "Meldert", "code_postal": "3560", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Melles", "code_postal": "7540", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BRAKEL", "name": "Michelbeke", "code_postal": "9660", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERSTAL", "name": "Milmort", "code_postal": "4041", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "GISTEL", "name": "Moere", "code_postal": "8470", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉMALLE", "name": "Mons-Lez-Liège", "code_postal": "4400", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "MONT-SAINT-GUIBERT", "name": "MONT-SAINT-GUIBERT", "code_postal": "1435", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALST", "name": "Moorsel", "code_postal": "9310", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BASTOGNE", "name": "Noville", "code_postal": "6600", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "LASNE", "name": "Ohain", "code_postal": "1380", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BRAKEL", "name": "Opbrakel", "code_postal": "9660", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASEIK", "name": "Opoeteren", "code_postal": "3680", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ORP-JAUCHE", "name": "Orp-Le-Grand", "code_postal": "1350", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Ostiches", "code_postal": "7804", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Pétigny", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BASTOGNE", "name": "Villers-La-Bonne-Eau", "code_postal": "6600", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Villers-Le-Gambon", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "Vivy", "code_postal": "6833", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "LAAKDAL", "name": "Vorst", "code_postal": "2430", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HOUYET", "name": "Wanlin", "code_postal": "5564", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COLFONTAINE", "name": "Wasmes", "code_postal": "7340", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "POPERINGE", "name": "Watou", "code_postal": "8978", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Wépion", "code_postal": "5100", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "JUPRELLE", "name": "Wihogne", "code_postal": "4452", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "PEER", "name": "Wijchmaal", "code_postal": "3990", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LEUVEN", "name": "Wilsele", "code_postal": "3012", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WINGENE", "name": "WINGENE", "code_postal": "8750", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "ZANDHOVEN", "name": "ZANDHOVEN", "code_postal": "2240", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZONNEBEKE", "name": "ZONNEBEKE", "code_postal": "8980", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "BULLANGE", "name": "Rocherath", "code_postal": "4761", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "SAINT-JOSSE-TEN-NOODE", "name": "SAINT-JOSSE-TEN-NOODE", "code_postal": "1210", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "BLÉGNY", "name": "Saive", "code_postal": "4671", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SENEFFE", "name": "SENEFFE", "code_postal": "7180", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Sint-Denijs-Boekel", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERMONDE", "name": "Sint-Gillis-Dendermonde", "code_postal": "9200", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIERDE", "name": "Sint-Martens-Lierde", "code_postal": "9572", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SIVRY-RANCE", "name": "Sivry", "code_postal": "6470", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "JABBEKE", "name": "Snellegem", "code_postal": "8490", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "METTET", "name": "Stave", "code_postal": "5646", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOUFFALIZE", "name": "Tailles", "code_postal": "6661", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "THUIN", "name": "THUIN", "code_postal": "6530", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIÈVRES", "name": "Tongre-Saint-Martin", "code_postal": "7950", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WASSEIGES", "name": "Acosse", "code_postal": "4219", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONT-DE-L'ENCLUS", "name": "Amougies", "code_postal": "7750", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Astene", "code_postal": "9800", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ASSENEDE", "name": "Bassevelde", "code_postal": "9968", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Béclers", "code_postal": "7532", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "PUTTE", "name": "Beerzel", "code_postal": "2580", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ALVERINGEM", "name": "Beveren-Aan-De-Ijzer", "code_postal": "8691", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VIELSALM", "name": "Bihain", "code_postal": "6690", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ASSENEDE", "name": "Boekhoute", "code_postal": "9961", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "LA BRUYÈRE", "name": "Bovesse", "code_postal": "5081", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CELLES", "name": "CELLES", "code_postal": "7760", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "HERVE", "name": "Charneux", "code_postal": "4654", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "THIMISTER-CLERMONT", "name": "Clermont", "code_postal": "4890", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MOORSLEDE", "name": "Dadizele", "code_postal": "8890", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "De Moeren", "code_postal": "8630", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "DIEPENBEEK", "name": "DIEPENBEEK", "code_postal": "3590", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MANHAY", "name": "Dochamps", "code_postal": "6960", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BEVEREN-WAAS", "name": "Doel", "code_postal": "9130", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VIROINVAL", "name": "Dourbes", "code_postal": "5670", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ECAUSSINNES", "name": "Ecaussinnes-Lalaing", "code_postal": "7191", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASMECHELEN", "name": "Eisden", "code_postal": "3630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NAZARETH", "name": "Eke", "code_postal": "9810", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Engelmanshoven", "code_postal": "3800", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PECQ", "name": "Esquelmes", "code_postal": "7743", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTINNES", "name": "Estinnes-Au-Val", "code_postal": "7120", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "Falmagne", "code_postal": "5500", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "FLORENNES", "code_postal": "5620", "sous_commune": false },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "FOREST", "name": "FOREST", "code_postal": "1190", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "FROIDCHAPELLE", "name": "Fourbechies", "code_postal": "6440", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZONNEBEKE", "name": "Geluveld", "code_postal": "8980", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "RIEMST", "name": "Genoelselderen", "code_postal": "3770", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OOSTERZELE", "name": "Gijzenzele", "code_postal": "9860", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Goeferdinge", "code_postal": "9500", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Goutroux", "code_postal": "6030", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIÈGE", "name": "Grivegnée", "code_postal": "4030", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WEVELGEM", "name": "Gullegem", "code_postal": "8560", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "AUBANGE", "name": "Halanzy", "code_postal": "6792", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HALLE", "name": "HALLE", "code_postal": "1500", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "HAMONT-ACHEL", "name": "Hamont", "code_postal": "3930", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HOESELT", "name": "HOESELT", "code_postal": "3730", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Isières", "code_postal": "7822", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "JURBISE", "name": "JURBISE", "code_postal": "7050", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GOOIK", "name": "Kester", "code_postal": "1755", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "KONTICH", "name": "KONTICH", "code_postal": "2550", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUNEHAUT", "name": "Laplaigne", "code_postal": "7622", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HALLE", "name": "Lembeek", "code_postal": "1502", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MERELBEKE", "name": "Lemberge", "code_postal": "9820", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LES BONS VILLERS", "name": "LES BONS VILLERS", "code_postal": "6210", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "Les Hayons", "code_postal": "6830", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LO-RENINGE", "name": "Lo", "code_postal": "8647", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZEDELGEM", "name": "Loppem", "code_postal": "8210", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Martenslinde", "code_postal": "3742", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Mater", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "OUDSBERGEN", "name": "Meeuwen", "code_postal": "3670", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "STEENOKKERZEEL", "name": "Melsbroek", "code_postal": "1820", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MERBES-LE-CHÂTEAU", "name": "Merbes-Sainte-Marie", "code_postal": "6567", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Montroeul-Au-Bois", "code_postal": "7911", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAMOIS", "name": "Natoye", "code_postal": "5360", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Nieuwenhove", "code_postal": "9506", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LESSINES", "name": "Ollignies", "code_postal": "7866", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "GEER", "name": "Omal", "code_postal": "4252", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MARCHE-EN-FAMENNE", "name": "On", "code_postal": "6900", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DAMME", "name": "Oostkerke", "code_postal": "8340", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "PALISEUL", "name": "Opont", "code_postal": "6852", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SERAING", "name": "Ougrée", "code_postal": "4102", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "PELT", "name": "Overpelt", "code_postal": "3900", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VISÉ", "name": "Richelle", "code_postal": "4600", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RIXENSART", "name": "RIXENSART", "code_postal": "1330", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ROUVROY", "name": "ROUVROY", "code_postal": "6767", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Rumillies", "code_postal": "7540", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SAINT-NICOLAS", "name": "SAINT-NICOLAS", "code_postal": "4420", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Saint-Remy", "code_postal": "6460", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINTE-ODE", "name": "SAINTE-ODE", "code_postal": "6680", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "TUBIZE", "name": "Saintes", "code_postal": "1480", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SAMBREVILLE", "name": "SAMBREVILLE", "code_postal": "5060", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "HERK-DE-STAD", "name": "Schulen", "code_postal": "3540", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Septon", "code_postal": "6940", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BRAKEL", "name": "Sint-Maria-Oudenhove", "code_postal": "9660", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HOLSBEEK", "name": "Sint-Pieters-Rode", "code_postal": "3220", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "JUPRELLE", "name": "Slins", "code_postal": "4450", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COURCELLES", "name": "Souvret", "code_postal": "6182", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "STEENOKKERZEEL", "name": "STEENOKKERZEEL", "code_postal": "1820", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "STEKENE", "name": "STEKENE", "code_postal": "9190", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "HASSELT", "name": "Stevoort", "code_postal": "3512", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "MODAVE", "name": "Strée-Lez-Huy", "code_postal": "4577", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ATTERT", "name": "Thiaumont", "code_postal": "6717", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "WESTERLO", "name": "Tongerlo", "code_postal": "2260", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VIROINVAL", "name": "Treignes", "code_postal": "5670", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Upigny", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Vaulx-Lez-Chimay", "code_postal": "6462", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Vezon", "code_postal": "7538", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MEIX-DEVANT-VIRTON", "name": "Villers-La-Loue", "code_postal": "6769", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "JUPRELLE", "name": "Villers-Saint-Siméon", "code_postal": "4453", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VIRTON", "name": "VIRTON", "code_postal": "6760", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "KORTESSEM", "name": "Vliermaal", "code_postal": "3724", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Volkegem", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LONTZEN", "name": "Walhorn", "code_postal": "4711", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MESSANCY", "name": "Wolkrange", "code_postal": "6780", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LOCHRISTI", "name": "Zaffelare", "code_postal": "9080", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZAVENTEM", "name": "ZAVENTEM", "code_postal": "1930", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DE PINTE", "name": "Zevergem", "code_postal": "9840", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Afsnee", "code_postal": "9051", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FOSSES-LA-VILLE", "name": "Aisemont", "code_postal": "5070", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HABAY", "name": "Anlier", "code_postal": "6721", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ONHAYE", "name": "Anthée", "code_postal": "5520", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SENEFFE", "name": "Arquennes", "code_postal": "7181", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "AS", "name": "AS", "code_postal": "3665", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUGELETTE", "name": "Attre", "code_postal": "7941", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BERLARE", "name": "BERLARE", "code_postal": "9290", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "WAREMME", "name": "Bettincourt", "code_postal": "4300", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUNEHAUT", "name": "Bléharies", "code_postal": "7620", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KOEKELARE", "name": "Bovekerke", "code_postal": "8680", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERVE", "name": "Chaineux", "code_postal": "4650", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "PEPINSTER", "name": "Cornesse", "code_postal": "4860", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ASSESSE", "name": "Courrière", "code_postal": "5336", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HÉRON", "name": "Couthuin", "code_postal": "4218", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "MACHELEN", "name": "Diegem", "code_postal": "1831", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "Dréhance", "code_postal": "5500", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LINTER", "name": "Drieslinter", "code_postal": "3350", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ENGIS", "name": "Ehein", "code_postal": "4480", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LOKEREN", "name": "Eksaarde", "code_postal": "9160", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "METTET", "name": "Ermeton-Sur-Biert", "code_postal": "5644", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ESNEUX", "name": "ESNEUX", "code_postal": "4130", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉMALLE", "name": "Flémalle-Haute", "code_postal": "4400", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FLOREFFE", "name": "FLOREFFE", "code_postal": "5150", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Forges", "code_postal": "6464", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "FRASNES-LEZ-ANVAING", "code_postal": "7910", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "GEEL", "name": "GEEL", "code_postal": "2440", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RAMILLIES", "name": "Geest-Gérompont-Petit-Rosière", "code_postal": "1367", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "CHASTRE", "name": "Gentinnes", "code_postal": "1450", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "Givry", "code_postal": "7041", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BASSENGE", "name": "Glons", "code_postal": "4690", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LEUZE-EN-HAINAUT", "name": "Grandmetz", "code_postal": "7900", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HAACHT", "name": "HAACHT", "code_postal": "3150", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LA LOUVIÈRE", "name": "Haine-Saint-Pierre", "code_postal": "7100", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Hansbeke", "code_postal": "9850", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "RAEREN", "name": "Hauset", "code_postal": "4730", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SAINT-GHISLAIN", "name": "Hautrage", "code_postal": "7334", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "Havay", "code_postal": "7041", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "HÉLÉCINE", "name": "HÉLÉCINE", "code_postal": "1357", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "HERENTALS", "name": "HERENTALS", "code_postal": "2200", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "HERK-DE-STAD", "name": "HERK-DE-STAD", "code_postal": "3540", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "OUPEYE", "name": "Hermalle-Sous-Argenteau", "code_postal": "4681", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MECHELEN", "name": "Hombeek", "code_postal": "2811", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Houdremont", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ANZEGEM", "name": "Ingooigem", "code_postal": "8570", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "KALMTHOUT", "name": "KALMTHOUT", "code_postal": "2920", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BOUTERSEM", "name": "Kerkom", "code_postal": "3370", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LAARNE", "name": "LAARNE", "code_postal": "9270", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Lamain", "code_postal": "7522", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Laneffe", "code_postal": "5651", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "JODOIGNE", "name": "Lathuy", "code_postal": "1370", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUNEHAUT", "name": "Lesdain", "code_postal": "7621", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LIEVENS-HOUTEM", "name": "Letterhoutem", "code_postal": "9521", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DOISCHE", "name": "Matagne-La-Petite", "code_postal": "5680", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MERELBEKE", "name": "Melsen", "code_postal": "9820", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "METTET", "name": "METTET", "code_postal": "5640", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASEIK", "name": "Neeroeteren", "code_postal": "3680", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "TROOZ", "name": "Nessonvaux", "code_postal": "4870", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "NIEL", "name": "NIEL", "code_postal": "2845", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZUIENKERKE", "name": "Nieuwmunster", "code_postal": "8377", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "NIVELLES", "name": "NIVELLES", "code_postal": "1400", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BEERNEM", "name": "Oedelem", "code_postal": "8730", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Oeudeghien", "code_postal": "7911", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AWANS", "name": "Othée", "code_postal": "4340", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LESSINES", "name": "Papignies", "code_postal": "7861", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "THEUX", "name": "Polleur", "code_postal": "4910", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "YVOIR", "name": "Purnode", "code_postal": "5530", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "NIEUWPOORT", "name": "Ramskapelle", "code_postal": "8620", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBRAMONT-CHEVIGNY", "name": "Remagne", "code_postal": "6800", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRAINE-LE-COMTE", "name": "Ronquières", "code_postal": "7090", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ASSESSE", "name": "Sart-Bernard", "code_postal": "5330", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DIEST", "name": "Schaffen", "code_postal": "3290", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OOSTERZELE", "name": "Scheldewindeke", "code_postal": "9860", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LEDEGEM", "name": "Sint-Eloois-Winkel", "code_postal": "8880", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TERNAT", "name": "Sint-Katherina-Lombeek", "code_postal": "1742", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Taviers", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Temploux", "code_postal": "5020", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SAINT-GHISLAIN", "name": "Villerot", "code_postal": "7334", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VIROINVAL", "name": "VIROINVAL", "code_postal": "5670", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "LIÈGE", "name": "Wandre", "code_postal": "4020", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WANZE", "name": "WANZE", "code_postal": "4520", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LEDE", "name": "Wanzele", "code_postal": "9340", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RAVELS", "name": "Weelde", "code_postal": "2381", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Yves-Gomezée", "code_postal": "5650", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "ZWALM", "code_postal": "9630", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "AMAY", "name": "AMAY", "code_postal": "4540", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "ANHÉE", "name": "Annevoie-Rouillon", "code_postal": "5537", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ANTHISNES", "name": "ANTHISNES", "code_postal": "4160", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERMONDE", "name": "Appels", "code_postal": "9200", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BLÉGNY", "name": "Barchon", "code_postal": "4671", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BEERSE", "name": "BEERSE", "code_postal": "2340", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "WILLEBROEK", "name": "Blaasveld", "code_postal": "2830", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BONHEIDEN", "name": "BONHEIDEN", "code_postal": "2820", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ROOSDAAL", "name": "Borchtlombeek", "code_postal": "1761", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RANST", "name": "Broechem", "code_postal": "2520", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUNEHAUT", "name": "BRUNEHAUT", "code_postal": "7620", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "MERCHTEM", "name": "Brussegem", "code_postal": "1785", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PÉRUWELZ", "name": "Bury", "code_postal": "7602", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIÈGE", "name": "Chênée", "code_postal": "4032", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "COMBLAIN-AU-PONT", "name": "COMBLAIN-AU-PONT", "code_postal": "4170", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "HAM-SUR-HEURE", "name": "Cour-Sur-Heure", "code_postal": "6120", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "DILSEN-STOKKEM", "name": "Dilsen", "code_postal": "3650", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Drongen", "code_postal": "9031", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Ellignies-Lez-Frasnes", "code_postal": "7910", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "Eprave", "code_postal": "5580", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "Falmignoul", "code_postal": "5500", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "FAUVILLERS", "name": "FAUVILLERS", "code_postal": "6637", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "CRISNÉE", "name": "Fize-Le-Marsal", "code_postal": "4367", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ONHAYE", "name": "Gérin", "code_postal": "5524", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIENEN", "name": "Goetsenhoven", "code_postal": "3300", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OREYE", "name": "Grandville", "code_postal": "4360", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SILLY", "name": "Graty", "code_postal": "7830", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "METTET", "name": "Graux", "code_postal": "5640", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "PEER", "name": "Grote-Brogel", "code_postal": "3990", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HABAY", "name": "Hachy", "code_postal": "6720", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HAM-SUR-HEURE", "name": "HAM-SUR-HEURE", "code_postal": "6120", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "HEIST-OP-DEN-BERG", "name": "HEIST-OP-DEN-BERG", "code_postal": "2220", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "VERVIERS", "name": "Heusy", "code_postal": "4802", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ANTHISNES", "name": "Hody", "code_postal": "4162", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MESSANCY", "name": "Hondelange", "code_postal": "6780", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Honnay", "code_postal": "5570", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Hyon", "code_postal": "7022", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HEIST-OP-DEN-BERG", "name": "Itegem", "code_postal": "2222", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Jamagne", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "KAPELLEN", "name": "KAPELLEN", "code_postal": "2950", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "HASSELT", "name": "Kermt", "code_postal": "3510", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HAM", "name": "Kwaadmechelen", "code_postal": "3945", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KLUISBERGEN", "name": "Kwaremont", "code_postal": "9690", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "LANDEN", "code_postal": "3400", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "LASNE", "name": "Lasne-Chapelle-Saint-Lambert", "code_postal": "1380", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LÉGLISE", "name": "LÉGLISE", "code_postal": "6860", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LENS", "name": "LENS", "code_postal": "7870", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LINKEBEEK", "name": "LINKEBEEK", "code_postal": "1630", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MAARKEDAL", "name": "MAARKEDAL", "code_postal": "9680", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Malvoisin", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MANAGE", "name": "MANAGE", "code_postal": "7170", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "MARCHIN", "name": "MARCHIN", "code_postal": "4570", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Mazy", "code_postal": "5032", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LES BONS VILLERS", "name": "Mellet", "code_postal": "6211", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Merendree", "code_postal": "9850", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DAMME", "name": "Moerkerke", "code_postal": "8340", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "KINROOI", "name": "Molenbeersel", "code_postal": "3640", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "Morialmé", "code_postal": "5621", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "Morville", "code_postal": "5620", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FERRIÈRES", "name": "My", "code_postal": "4190", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "NANDRIN", "name": "NANDRIN", "code_postal": "4550", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "VIROINVAL", "name": "Nismes", "code_postal": "5670", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Omezée", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WORTEGEM-PETEGEM", "name": "Ooike", "code_postal": "9790", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BUGGENHOUT", "name": "Opdorp", "code_postal": "9255", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASMECHELEN", "name": "Opgrimbie", "code_postal": "3630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BERLARE", "name": "Overmere", "code_postal": "9290", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Overwinden", "code_postal": "3400", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "STEENOKKERZEEL", "name": "Perk", "code_postal": "1820", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRAINE-LE-COMTE", "name": "Petit-Roeulx-Lez-Braine", "code_postal": "7090", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SENEFFE", "name": "Petit-Roeulx-Lez-Nivelles", "code_postal": "7181", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FARCIENNES", "name": "Pironchamps", "code_postal": "6240", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HERSELT", "name": "Ramsel", "code_postal": "2230", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIÈGE", "name": "Rocourt", "code_postal": "4000", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "PUURS-SINT-AMANDS", "name": "Ruisbroek", "code_postal": "2870", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIELT-WINGE", "name": "Sint-Joris-Winge", "code_postal": "3390", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CERFONTAINE", "name": "Soumoy", "code_postal": "5630", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WAIMES", "name": "Sourbrodt", "code_postal": "4950", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Thisnes", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Thy-Le-Château", "code_postal": "5651", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HAACHT", "name": "Tildonk", "code_postal": "3150", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GALMAARDEN", "name": "Tollembeek", "code_postal": "1570", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "TORHOUT", "name": "TORHOUT", "code_postal": "8820", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "WELLEN", "name": "Ulbeek", "code_postal": "3832", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Vaulx", "code_postal": "7536", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SAMBREVILLE", "name": "Velaine-Sur-Sambre", "code_postal": "5060", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Vencimont", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ZANDHOVEN", "name": "Viersel", "code_postal": "2240", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AWANS", "name": "Villers-L'Evêque", "code_postal": "4340", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "VRESSE-SUR-SEMOIS", "code_postal": "5550", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DENTERGEM", "name": "Wakken", "code_postal": "8720", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Wange", "code_postal": "3400", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Waret-La-Chaussée", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KNOKKE-HEIST", "name": "Westkapelle", "code_postal": "8300", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HASSELT", "name": "Wimmertingen", "code_postal": "3501", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "Wulveringem", "code_postal": "8630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KOEKELARE", "name": "Zande", "code_postal": "8680", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZELE", "name": "ZELE", "code_postal": "9240", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KLUISBERGEN", "name": "Zulzeke", "code_postal": "9690", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ERPE-MERE", "name": "Aaigem", "code_postal": "9420", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Aische-En-Refail", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINT-HUBERT", "name": "Arville", "code_postal": "6870", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NASSOGNE", "name": "Bande", "code_postal": "6951", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERVE", "name": "Battice", "code_postal": "4651", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BINCHE", "name": "Battignies", "code_postal": "7130", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "GOUVY", "name": "Beho", "code_postal": "6672", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "MALMEDY", "name": "Bellevaux-Ligneuville", "code_postal": "4960", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BELOEIL", "name": "BELOEIL", "code_postal": "7970", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "DALHEM", "name": "Berneau", "code_postal": "4607", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BERTEM", "name": "BERTEM", "code_postal": "3060", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BERTOGNE", "name": "BERTOGNE", "code_postal": "6687", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Beverst", "code_postal": "3740", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "BILZEN", "code_postal": "3740", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "BLÉGNY", "name": "BLÉGNY", "code_postal": "4670", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Blehen", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SOMBREFFE", "name": "Boignée", "code_postal": "5140", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "Booitshoeke", "code_postal": "8630", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Boussu-En-Fagne", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Bouvignies", "code_postal": "7803", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CINEY", "name": "Braibant", "code_postal": "5590", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Branchon", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BINCHE", "name": "Bray", "code_postal": "7130", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Brûly", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "Bulskamp", "code_postal": "8630", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ETALLE", "name": "Chantemelle", "code_postal": "6742", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COURCELLES", "name": "COURCELLES", "code_postal": "6180", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Dailly", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Dampremy", "code_postal": "6020", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CERFONTAINE", "name": "Daussois", "code_postal": "5630", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Dergneau", "code_postal": "7912", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DILBEEK", "name": "DILBEEK", "code_postal": "1700", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "DINANT", "code_postal": "5500", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "DOUR", "name": "DOUR", "code_postal": "7370", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "DUFFEL", "name": "DUFFEL", "code_postal": "2570", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ECAUSSINNES", "name": "Ecaussinnes-D'Enghien", "code_postal": "7190", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "DOUR", "name": "Elouges", "code_postal": "7370", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "LA BRUYÈRE", "name": "Emines", "code_postal": "5080", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTAIMPUIS", "name": "Evregnies", "code_postal": "7730", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "RAEREN", "name": "Eynatten", "code_postal": "4731", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FARCIENNES", "name": "FARCIENNES", "code_postal": "6240", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "FLORENVILLE", "name": "Fontenoille", "code_postal": "6820", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AWANS", "name": "Fooz", "code_postal": "4340", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRAMERIES", "name": "FRAMERIES", "code_postal": "7080", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LENNIK", "name": "Gaasbeek", "code_postal": "1750", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUGELETTE", "name": "Gages", "code_postal": "7943", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LEUZE-EN-HAINAUT", "name": "Gallaix", "code_postal": "7906", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LANAKEN", "name": "Gellik", "code_postal": "3620", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COURCELLES", "name": "Gouy-Lez-Piéton", "code_postal": "6181", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "GRÂCE-HOLLOGNE", "name": "Grâce-Berleur", "code_postal": "4460", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "OUD-HEVERLEE", "name": "Haasrode", "code_postal": "3053", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BURDINNE", "name": "Hannêche", "code_postal": "4210", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HARELBEKE", "name": "HARELBEKE", "code_postal": "8530", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Harveng", "code_postal": "7022", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AMBLÈVE", "name": "Heppenbach", "code_postal": "4771", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAVELANGE", "name": "Jeneffe", "code_postal": "5370", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIÈGE", "name": "Jupille-Sur-Meuse", "code_postal": "4020", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HEUVELLAND", "name": "Kemmel", "code_postal": "8956", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Lives-Sur-Meuse", "code_postal": "5101", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LOBBES", "name": "LOBBES", "code_postal": "6540", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "WELLIN", "name": "Lomprez", "code_postal": "6924", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ENGHIEN", "name": "Marcq", "code_postal": "7850", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BURDINNE", "name": "Marneffe", "code_postal": "4210", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Merlemont", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "LA BRUYÈRE", "name": "Meux", "code_postal": "5081", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAVELANGE", "name": "Miécret", "code_postal": "5376", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HOOGSTRATEN", "name": "Minderhout", "code_postal": "2322", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WEVELGEM", "name": "Moorsele", "code_postal": "8560", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Moxhe", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Nafraiture", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "BRUXELLES", "name": "Neder-Over-Heembeek", "code_postal": "1120", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ATTERT", "name": "Nothomb", "code_postal": "6717", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PONT-À-CELLES", "name": "Obaix", "code_postal": "6230", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BIÈVRE", "name": "Oizy", "code_postal": "5555", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ROOSDAAL", "name": "Onze-Lieve-Vrouw-Lombeek", "code_postal": "1760", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Ooike", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OOSTKAMP", "name": "OOSTKAMP", "code_postal": "8020", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "JUPRELLE", "name": "Paifve", "code_postal": "4452", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ROOSDAAL", "name": "Pamel", "code_postal": "1760", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Pry", "code_postal": "5650", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HULDENBERG", "name": "HULDENBERG", "code_postal": "3040", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BERTRIX", "name": "Jehonville", "code_postal": "6880", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Roux", "code_postal": "6044", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DALHEM", "name": "Saint-André", "code_postal": "4606", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HERBEUMONT", "name": "Saint-Médard", "code_postal": "6887", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KORTENAKEN", "name": "Sint-Margriete-Houtem", "code_postal": "3470", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Sint-Martens-Leerne", "code_postal": "9800", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ALVERINGEM", "name": "Sint-Rijkers", "code_postal": "8690", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HERBEUMONT", "name": "Straimont", "code_postal": "6887", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Sugny", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TESSENDERLO", "name": "TESSENDERLO", "code_postal": "3980", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "FOURONS", "name": "Teuven", "code_postal": "3793", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BURG-REULAND", "name": "Thommen", "code_postal": "4791", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIENEN", "name": "TIENEN", "code_postal": "3300", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CELLES", "name": "Velaines", "code_postal": "7760", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANDENNE", "name": "Vezin", "code_postal": "5300", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "GERPINNES", "name": "Villers-Poterie", "code_postal": "6280", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Villers-Saint-Ghislain", "code_postal": "7031", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WANZE", "name": "Vinalmont", "code_postal": "4520", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VLETEREN", "name": "VLETEREN", "code_postal": "8640", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "BEERSE", "name": "Vlimmeren", "code_postal": "2340", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERSTAL", "name": "Vottem", "code_postal": "4041", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIEVEGEM", "name": "Waarschoot", "code_postal": "9950", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FLEURUS", "name": "Wagnelée", "code_postal": "6223", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LESSINES", "name": "Wannebecq", "code_postal": "7861", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HÉRON", "name": "Waret-L'Evêque", "code_postal": "4217", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ONHAYE", "name": "Weillen", "code_postal": "5523", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MIDDELKERKE", "name": "Westende", "code_postal": "8434", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Wilderen", "code_postal": "3803", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Willerzie", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Zandbergen", "code_postal": "9506", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Zarlardinge", "code_postal": "9500", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "JABBEKE", "name": "Zerkegem", "code_postal": "8490", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WINGENE", "name": "Zwevezele", "code_postal": "8750", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉRON", "name": "Retinne", "code_postal": "4621", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FAIMES", "name": "Aineffe", "code_postal": "4317", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AMBLÈVE", "name": "AMBLÈVE", "code_postal": "4770", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ANDERLUES", "name": "ANDERLUES", "code_postal": "6150", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "DISON", "name": "Andrimont", "code_postal": "4821", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANHÉE", "name": "ANHÉE", "code_postal": "5537", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Aspelare", "code_postal": "9404", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "Athis", "code_postal": "7387", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AUBEL", "name": "AUBEL", "code_postal": "4880", "sous_commune": false },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "AUDERGHEM", "name": "AUDERGHEM", "code_postal": "1160", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "SAINT-GHISLAIN", "name": "Baudour", "code_postal": "7331", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "NIVELLES", "name": "Baulers", "code_postal": "1401", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BERINGEN", "name": "BERINGEN", "code_postal": "3580", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "LIMBOURG", "name": "Bilstain", "code_postal": "4831", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RAMILLIES", "name": "Bomal", "code_postal": "1367", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BREDENE", "name": "BREDENE", "code_postal": "8450", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GLABBEEK", "name": "Bunsbeek", "code_postal": "3380", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PONT-À-CELLES", "name": "Buzet", "code_postal": "6230", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "CRISNÉE", "name": "CRISNÉE", "code_postal": "4367", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Diets-Heur", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Dikkele", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "DILSEN-STOKKEM", "name": "DILSEN-STOKKEM", "code_postal": "3650", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Edelare", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ICHTEGEM", "name": "Eernegem", "code_postal": "8480", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "TEMSE", "name": "Elversele", "code_postal": "9140", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ERPE-MERE", "name": "Erpe", "code_postal": "9420", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WAIMES", "name": "Faymonville", "code_postal": "4950", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FERRIÈRES", "name": "FERRIÈRES", "code_postal": "4190", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "Flavion", "code_postal": "5620", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Frasnes-Lez-Buissenal", "code_postal": "7911", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "GEMBLOUX", "code_postal": "5030", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "DOISCHE", "name": "Gimnée", "code_postal": "5680", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MELLE", "name": "Gontrode", "code_postal": "9090", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Groot-Gelmen", "code_postal": "3800", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MESSANCY", "name": "Habergy", "code_postal": "6782", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Hacquegnies", "code_postal": "7911", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "OHEY", "name": "Haillot", "code_postal": "5351", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HAMOIR", "name": "HAMOIR", "code_postal": "4180", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZWEVEGEM", "name": "Heestert", "code_postal": "8551", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MECHELEN", "name": "Heffen", "code_postal": "2801", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ENGIS", "name": "Hermalle-Sous-Huy", "code_postal": "4480", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Herquegies", "code_postal": "7911", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTRIJK", "name": "Heule", "code_postal": "8501", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BORNEM", "name": "Hingene", "code_postal": "2880", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIELT-WINGE", "name": "Houwaart", "code_postal": "3390", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BEERSEL", "name": "Huizingen", "code_postal": "1654", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Kerkom-Bij-Sint-Truiden", "code_postal": "3800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "LA BRUYÈRE", "name": "LA BRUYÈRE", "code_postal": "5080", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ROUVROY", "name": "Lamorteau", "code_postal": "6767", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DAMME", "name": "Lapscheure", "code_postal": "8340", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LEDE", "name": "LEDE", "code_postal": "9340", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BERTEM", "name": "Leefdaal", "code_postal": "3061", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LENNIK", "name": "LENNIK", "code_postal": "1750", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Lens-Saint-Remy", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OREYE", "name": "Lens-Sur-Geer", "code_postal": "4360", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIEVEGEM", "name": "LIEVEGEM", "code_postal": "9930", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "HALEN", "name": "Loksbergen", "code_postal": "3545", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Longchamps", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOUSCRON", "name": "Luingne", "code_postal": "7700", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Mainvault", "code_postal": "7812", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VIROINVAL", "name": "Mazée", "code_postal": "5670", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "MEISE", "name": "MEISE", "code_postal": "1860", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Melden", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "JODOIGNE", "name": "Mélin", "code_postal": "1370", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WANZE", "name": "Moha", "code_postal": "4520", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOMIGNIES", "name": "Monceau-Imbrechies", "code_postal": "6592", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Mont-Saint-Aubert", "code_postal": "7542", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "JEMEPPE-SUR-SAMBRE", "name": "Mornimont", "code_postal": "5190", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANDENNE", "name": "Namêche", "code_postal": "5300", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HOLSBEEK", "name": "Nieuwrode", "code_postal": "3221", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "Onnezies", "code_postal": "7387", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "INCOURT", "name": "Opprebais", "code_postal": "1315", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OREYE", "name": "Otrange", "code_postal": "4360", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "MODAVE", "name": "Outrelouxhe", "code_postal": "4577", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Patignies", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CELLES", "name": "Popuelles", "code_postal": "7760", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "POPERINGE", "name": "Proven", "code_postal": "8972", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ZANDHOVEN", "name": "Pulderbos", "code_postal": "2242", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "REBECQ", "name": "REBECQ", "code_postal": "1430", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "LA BRUYÈRE", "name": "Saint-Denis-Bovesse", "code_postal": "5081", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Sart-Custinne", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "SCHILDE", "name": "SCHILDE", "code_postal": "2970", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "SCHOTEN", "name": "SCHOTEN", "code_postal": "2900", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZWEVEGEM", "name": "Sint-Denijs", "code_postal": "8554", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "WELLIN", "name": "Sohier", "code_postal": "6920", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HASSELT", "name": "Spalbeek", "code_postal": "3510", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "TEMSE", "name": "TEMSE", "code_postal": "9140", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TERVUREN", "name": "TERVUREN", "code_postal": "3080", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "HENSIES", "name": "Thulin", "code_postal": "7350", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BELOEIL", "name": "Thumaide", "code_postal": "7971", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "Thy-Le-Baudouin", "code_postal": "5621", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SAINT-NICOLAS", "name": "Tilleur", "code_postal": "4420", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "FAUVILLERS", "name": "Tintange", "code_postal": "6637", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Tohogne", "code_postal": "6941", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "UCCLE", "name": "UCCLE", "code_postal": "1180", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Villers-Saint-Amand", "code_postal": "7812", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Vonêche", "code_postal": "5570", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TERVUREN", "name": "Vossem", "code_postal": "3080", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WACHTEBEKE", "name": "WACHTEBEKE", "code_postal": "9185", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "WELLIN", "name": "WELLIN", "code_postal": "6920", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUNEHAUT", "name": "Wez-Velvain", "code_postal": "7620", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOUFFALIZE", "name": "Wibrin", "code_postal": "6666", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PÉRUWELZ", "name": "Wiers", "code_postal": "7608", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "MEISE", "name": "Wolvertem", "code_postal": "1861", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "GISTEL", "name": "Zevekote", "code_postal": "8470", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Aalst", "code_postal": "3800", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "TIELT", "name": "Aarsele", "code_postal": "8700", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "AISEAU-PRESLES", "name": "AISEAU-PRESLES", "code_postal": "6250", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Alle", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINTE-ODE", "name": "Amberloup", "code_postal": "6680", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ASSENEDE", "name": "ASSENEDE", "code_postal": "9960", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Attenhoven", "code_postal": "3404", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Bagimont", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTAIMPUIS", "name": "Bailleul", "code_postal": "7730", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BEKKEVOORT", "name": "BEKKEVOORT", "code_postal": "3460", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Bertrée", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Boneffe", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Bossière", "code_postal": "5032", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ERPE-MERE", "name": "Burst", "code_postal": "9420", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "FLORENVILLE", "name": "Chassepierre", "code_postal": "6824", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "TUBIZE", "name": "Clabecq", "code_postal": "1480", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ENGIS", "name": "Clermont-Sous-Huy", "code_postal": "4480", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DALHEM", "name": "DALHEM", "code_postal": "4607", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "Dikkebus", "code_postal": "8900", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DISON", "name": "DISON", "code_postal": "4820", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "AYWAILLE", "name": "Ernonheid", "code_postal": "4920", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTINNES", "name": "Estinnes-Au-Mont", "code_postal": "7120", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ETALLE", "name": "ETALLE", "code_postal": "6740", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Feschaux", "code_postal": "5570", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "JUPRELLE", "name": "Fexhe-Slins", "code_postal": "4458", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FONTAINE-L'EVÊQUE", "name": "FONTAINE-L'EVÊQUE", "code_postal": "6140", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ANTOING", "name": "Fontenoy", "code_postal": "7643", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NASSOGNE", "name": "Forrières", "code_postal": "6953", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "GANSHOREN", "name": "GANSHOREN", "code_postal": "1083", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GAVERE", "name": "GAVERE", "code_postal": "9890", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MEIX-DEVANT-VIRTON", "name": "Gérouville", "code_postal": "6769", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RAMILLIES", "name": "Grand-Rosière-Hottomont", "code_postal": "1367", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PECQ", "name": "Hérinnes-Lez-Pecq", "code_postal": "7742", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEUSDEN-ZOLDER", "name": "HEUSDEN-ZOLDER", "code_postal": "3550", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LEUVEN", "name": "Heverlee", "code_postal": "3001", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "Hoboken", "code_postal": "2660", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUNEHAUT", "name": "Hollain", "code_postal": "7620", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COMINES-WARNETON", "name": "Houthem", "code_postal": "7781", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HUY", "name": "HUY", "code_postal": "4500", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Idegem", "code_postal": "9506", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉMALLE", "name": "Ivoz-Ramet", "code_postal": "4400", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KEERBERGEN", "name": "KEERBERGEN", "code_postal": "3140", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "STEKENE", "name": "Kemzeke", "code_postal": "9190", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALTER", "name": "Knesselare", "code_postal": "9910", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KOEKELARE", "name": "KOEKELARE", "code_postal": "8680", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BRUGGE", "name": "Koolkerke", "code_postal": "8000", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "NIEUWERKERKEN", "name": "Kozen", "code_postal": "3850", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KRUISEM", "name": "KRUISEM", "code_postal": "9770", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LA ROCHE-EN-ARDENNE", "name": "LA ROCHE-EN-ARDENNE", "code_postal": "6980", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LESSINES", "name": "LESSINES", "code_postal": "7860", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LEUZE-EN-HAINAUT", "name": "LEUZE-EN-HAINAUT", "code_postal": "7900", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "GOUVY", "name": "Limerlé", "code_postal": "6670", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SAINT-VITH", "name": "Lommersweiler", "code_postal": "4783", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ANS", "name": "Loncin", "code_postal": "4431", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Louette-Saint-Denis", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LUMMEN", "name": "LUMMEN", "code_postal": "3560", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZULTE", "name": "Machelen", "code_postal": "9870", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉRON", "name": "Magnée", "code_postal": "4623", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "LASNE", "name": "Maransart", "code_postal": "1380", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOTTON", "name": "Marenne", "code_postal": "6990", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Meerbeke", "code_postal": "9402", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MIDDELKERKE", "name": "MIDDELKERKE", "code_postal": "8430", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HAMME", "name": "Moerzeke", "code_postal": "9220", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBRAMONT-CHEVIGNY", "name": "Moircy", "code_postal": "6800", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "MOLENBEEK-SAINT-JEAN", "name": "MOLENBEEK-SAINT-JEAN", "code_postal": "1080", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NASSOGNE", "name": "NASSOGNE", "code_postal": "6950", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTAIMPUIS", "name": "Néchin", "code_postal": "7730", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Neuville", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Nieuwkapelle", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ORP-JAUCHE", "name": "Noduwez", "code_postal": "1350", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SOMME-LEUZE", "name": "Noiseux", "code_postal": "5377", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "OLEN", "name": "OLEN", "code_postal": "2250", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WIELSBEKE", "name": "Ooigem", "code_postal": "8710", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "STADEN", "name": "Oostnieuwkerke", "code_postal": "8840", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VLETEREN", "name": "Oostvleteren", "code_postal": "8640", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OUFFET", "name": "OUFFET", "code_postal": "4590", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "VERVIERS", "name": "Petit-Rechain", "code_postal": "4800", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "JODOIGNE", "name": "Piétrain", "code_postal": "1370", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "NEUPRÉ", "name": "Plainevaux", "code_postal": "4122", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Pondrôme", "code_postal": "5574", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "TINLOT", "name": "Ramelot", "code_postal": "4557", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RANST", "name": "RANST", "code_postal": "2520", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HERZELE", "name": "Ressegem", "code_postal": "9551", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "AISEAU-PRESLES", "name": "Roselies", "code_postal": "6250", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RUMST", "name": "RUMST", "code_postal": "2840", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBRAMONT-CHEVIGNY", "name": "Saint-Pierre", "code_postal": "6800", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Saint-Sauveur", "code_postal": "7912", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LA LOUVIÈRE", "name": "Saint-Vaast", "code_postal": "7100", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBRAMONT-CHEVIGNY", "name": "Sainte-Marie-Chevigny", "code_postal": "6800", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERMONDE", "name": "Schoonaarde", "code_postal": "9200", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Sint-Maria-Latem", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BRUGGE", "name": "Sint-Michiels", "code_postal": "8200", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SOMME-LEUZE", "name": "SOMME-LEUZE", "code_postal": "5377", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "FLOREFFE", "name": "Soye", "code_postal": "5150", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "STOUMONT", "name": "STOUMONT", "code_postal": "4987", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "CHINY", "name": "Suxy", "code_postal": "6812", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RUMST", "name": "Terhagen", "code_postal": "2840", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "TEMSE", "name": "Tielrode", "code_postal": "9140", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "Tillier", "code_postal": "5380", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TINTIGNY", "name": "TINTIGNY", "code_postal": "6730", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "BEAUVECHAIN", "name": "Tourinnes-La-Grosse", "code_postal": "1320", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CERFONTAINE", "name": "Villers-Deux-Eglises", "code_postal": "5630", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "NANDRIN", "name": "Villers-Le-Temple", "code_postal": "4550", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ETALLE", "name": "Villers-Sur-Semois", "code_postal": "6740", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VISÉ", "name": "VISÉ", "code_postal": "4600", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LIEVENS-HOUTEM", "name": "Vlierzele", "code_postal": "9520", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KORTENAKEN", "name": "Waanrode", "code_postal": "3473", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MECHELEN", "name": "Walem", "code_postal": "2800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "LA BRUYÈRE", "name": "Warisoulx", "code_postal": "5080", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VILLERS-LE-BOUILLET", "name": "Warnant-Dreye", "code_postal": "4530", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "BRAINE-LE-CHÂTEAU", "name": "Wauthier-Braine", "code_postal": "1440", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HEUVELLAND", "name": "Wijtschate", "code_postal": "8953", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEUSDEN-ZOLDER", "name": "Zolder", "code_postal": "3550", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "ALKEN", "name": "ALKEN", "code_postal": "3570", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "AUBANGE", "name": "AUBANGE", "code_postal": "6790", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Aublain", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ARLON", "name": "Autelbas", "code_postal": "6706", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "PEPINGEN", "name": "Bellingen", "code_postal": "1674", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Berg", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "BOUILLON", "code_postal": "6830", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Bourseigne-Neuve", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBRAMONT-CHEVIGNY", "name": "Bras", "code_postal": "6800", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BULLANGE", "name": "BULLANGE", "code_postal": "4760", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "Corenne", "code_postal": "5620", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "Cortil-Wodon", "code_postal": "5380", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DE PANNE", "name": "DE PANNE", "code_postal": "8660", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERLEEUW", "name": "DENDERLEEUW", "code_postal": "9470", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "Eggewaartskapelle", "code_postal": "8630", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZEMST", "name": "Elewijt", "code_postal": "1982", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IZEGEM", "name": "Emelgem", "code_postal": "8870", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SAMBREVILLE", "name": "Falisolle", "code_postal": "5060", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BRAIVES", "name": "Fallais", "code_postal": "4260", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SENEFFE", "name": "Familleureux", "code_postal": "7181", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GESVES", "name": "Faulx-Les-Tombes", "code_postal": "5340", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FROIDCHAPELLE", "name": "FROIDCHAPELLE", "code_postal": "6440", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Froidfontaine", "code_postal": "5576", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BREE", "name": "Gerdingen", "code_postal": "3960", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALST", "name": "Gijzegem", "code_postal": "9308", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SPRIMONT", "name": "Gomzé-Andoumont", "code_postal": "4140", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Gosselies", "code_postal": "6041", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "Grotenberge", "code_postal": "9620", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HAMME", "name": "HAMME", "code_postal": "9220", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Heurne", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALST", "name": "Hofstade", "code_postal": "9308", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SILLY", "name": "Hoves", "code_postal": "7830", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIÈVRES", "name": "Huissignies", "code_postal": "7950", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Irchonwelz", "code_postal": "7801", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ALVERINGEM", "name": "Izenberge", "code_postal": "8691", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "PEER", "name": "Kleine-Brogel", "code_postal": "3990", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KNOKKE-HEIST", "name": "Knokke", "code_postal": "8300", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KRAAINEM", "name": "KRAAINEM", "code_postal": "1950", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "BEAUVECHAIN", "name": "L'Ecluse", "code_postal": "1320", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WAREMME", "name": "Lantremange", "code_postal": "4300", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LE ROEULX", "name": "LE ROEULX", "code_postal": "7070", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "FONTAINE-L'EVÊQUE", "name": "Leernes", "code_postal": "6142", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MIDDELKERKE", "name": "Lombardsijde", "code_postal": "8434", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BEERSEL", "name": "Lot", "code_postal": "1651", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASMECHELEN", "name": "MAASMECHELEN", "code_postal": "3630", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "HAVELANGE", "name": "Maffe", "code_postal": "5374", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "MALMEDY", "name": "MALMEDY", "code_postal": "4960", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ECAUSSINNES", "name": "Marche-Lez-Ecaussinnes", "code_postal": "7190", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASMECHELEN", "name": "Meeswijk", "code_postal": "3630", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "MODAVE", "name": "MODAVE", "code_postal": "4577", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONT-DE-L'ENCLUS", "name": "MONT-DE-L'ENCLUS", "code_postal": "7750", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "SIVRY-RANCE", "name": "Montbliart", "code_postal": "6470", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WORTEGEM-PETEGEM", "name": "Moregem", "code_postal": "9790", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Munkzwalm", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "FLORENVILLE", "name": "Muno", "code_postal": "6820", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Munsterbilzen", "code_postal": "3740", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SOIGNIES", "name": "Naast", "code_postal": "7062", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "NEUPRÉ", "name": "Neuville-En-Condroz", "code_postal": "4121", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALST", "name": "Nieuwerkerken", "code_postal": "9320", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GOOIK", "name": "Oetingen", "code_postal": "1755", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OLNE", "name": "OLNE", "code_postal": "4877", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OOSTROZEBEKE", "name": "OOSTROZEBEKE", "code_postal": "8780", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "KINROOI", "name": "Ophoven", "code_postal": "3640", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Orchimont", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BURDINNE", "name": "Oteppe", "code_postal": "4210", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OUDENBURG", "name": "OUDENBURG", "code_postal": "8460", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "OUDSBERGEN", "name": "OUDSBERGEN", "code_postal": "3670", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Outer", "code_postal": "9406", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VERVIERS", "name": "Polleur", "code_postal": "4800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAVELANGE", "name": "Porcheresse", "code_postal": "5370", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Poucet", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "PUURS-SINT-AMANDS", "name": "PUURS-SINT-AMANDS", "code_postal": "2870", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KNOKKE-HEIST", "name": "Ramskapelle", "code_postal": "8301", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "RENAIX", "name": "RENAIX", "code_postal": "9600", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BOUTERSEM", "name": "Roosbeek", "code_postal": "3370", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ROTSELAAR", "name": "ROTSELAAR", "code_postal": "3110", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "SAINT-VITH", "name": "SAINT-VITH", "code_postal": "4780", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WICHELEN", "name": "Schellebelle", "code_postal": "9260", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Schendelbeke", "code_postal": "9506", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BRUGGE", "name": "Sint-Andries", "code_postal": "8200", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIENEN", "name": "Sint-Margriete-Houtem", "code_postal": "3300", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "TINLOT", "name": "Soheit-Tinlot", "code_postal": "4557", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SOIGNIES", "name": "SOIGNIES", "code_postal": "7060", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "JEMEPPE-SUR-SAMBRE", "name": "Spy", "code_postal": "5190", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LONDERZEEL", "name": "Steenhuffel", "code_postal": "1840", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRAINE-LE-COMTE", "name": "Steenkerque", "code_postal": "7090", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "Strijpen", "code_postal": "9620", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIÈVRES", "name": "Tongre-Notre-Dame", "code_postal": "7951", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAVELANGE", "name": "Verlée", "code_postal": "5370", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Viane", "code_postal": "9500", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Villers-La-Tour", "code_postal": "6460", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SINT-PIETERS-LEEUW", "name": "Vlezenbeek", "code_postal": "1602", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Vogenée", "code_postal": "5650", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "AVELGEM", "name": "Waarmaarde", "code_postal": "8581", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WAIMES", "name": "WAIMES", "code_postal": "4950", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "WASSEIGES", "name": "WASSEIGES", "code_postal": "4219", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DE HAAN", "name": "Wenduine", "code_postal": "8420", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WETTEREN", "name": "WETTEREN", "code_postal": "9230", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LEBBEKE", "name": "Wieze", "code_postal": "9280", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LINTER", "name": "Wommersom", "code_postal": "3350", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KOKSIJDE", "name": "Wulpen", "code_postal": "8670", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "WUUSTWEZEL", "name": "WUUSTWEZEL", "code_postal": "2990", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "Zillebeke", "code_postal": "8902", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "SCHILDE", "name": "'S Gravenwezel", "code_postal": "2970", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "EREZÉE", "name": "Amonines", "code_postal": "6997", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Arc-Ainières", "code_postal": "7910", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LÉGLISE", "name": "Assenois", "code_postal": "6860", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "ATH", "code_postal": "7800", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BEAUMONT", "name": "Barbençon", "code_postal": "6500", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BELOEIL", "name": "Basècles", "code_postal": "7971", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "TROIS-PONTS", "name": "Basse-Bodeux", "code_postal": "4983", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BEERNEM", "name": "BEERNEM", "code_postal": "8730", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTRIJK", "name": "Bellegem", "code_postal": "8510", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "Bellevaux", "code_postal": "6834", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "Berendrecht", "code_postal": "2040", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BERLOZ", "name": "BERLOZ", "code_postal": "4257", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "Bierwart", "code_postal": "5380", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GREZ-DOICEAU", "name": "Biez", "code_postal": "1390", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WAREMME", "name": "Bleret", "code_postal": "4300", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BOECHOUT", "name": "BOECHOUT", "code_postal": "2530", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LESSINES", "name": "Bois-De-Lessines", "code_postal": "7866", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Bomal-Sur-Ourthe", "code_postal": "6941", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HEIST-OP-DEN-BERG", "name": "Booischot", "code_postal": "2221", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "Bougnies", "code_postal": "7040", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BRASSCHAAT", "name": "BRASSCHAAT", "code_postal": "2930", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KAMPENHOUT", "name": "Buken", "code_postal": "1910", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TENNEVILLE", "name": "Champlon", "code_postal": "6971", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LEUZE-EN-HAINAUT", "name": "Chapelle-À-Oie", "code_postal": "7903", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHÂTELET", "name": "CHÂTELET", "code_postal": "6200", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHÂTELET", "name": "Châtelineau", "code_postal": "6200", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HAMOIR", "name": "Comblain-La-Tour", "code_postal": "4180", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "GEER", "name": "Darion", "code_postal": "4253", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GAVERE", "name": "Dikkelvenne", "code_postal": "9890", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Eliksem", "code_postal": "3400", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KORTENBERG", "name": "Erps-Kwerps", "code_postal": "3071", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "TROIS-PONTS", "name": "Fosse", "code_postal": "4980", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "FOURONS", "name": "FOURONS", "code_postal": "3790", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "STAVELOT", "name": "Francorchamps", "code_postal": "4970", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Frasnes", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GENK", "name": "GENK", "code_postal": "3600", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NEUFCHÂTEAU", "name": "Grandvoir", "code_postal": "6840", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Groot-Loon", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIENEN", "name": "Hakendover", "code_postal": "3300", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CINEY", "name": "Haversin", "code_postal": "5590", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Jamiolle", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Jesseren", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BERINGEN", "name": "Koersel", "code_postal": "3582", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KRUIBEKE", "name": "KRUIBEKE", "code_postal": "9150", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "Leeuwergem", "code_postal": "9620", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LEUVEN", "name": "LEUVEN", "code_postal": "3000", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BINCHE", "name": "Leval-Trahegnies", "code_postal": "7134", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PONT-À-CELLES", "name": "Liberchies", "code_postal": "6238", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VISÉ", "name": "Lixhe", "code_postal": "4600", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "CHAUMONT-GISTOUX", "name": "Longueville", "code_postal": "1325", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "GERPINNES", "name": "Loverval", "code_postal": "6280", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WETTEREN", "name": "Massemen", "code_postal": "9230", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MOERBEKE-WAAS", "name": "MOERBEKE-WAAS", "code_postal": "9180", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Neerlanden", "code_postal": "3404", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HEUVELLAND", "name": "Nieuwkerke", "code_postal": "8950", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VAUX-SUR-SÛRE", "name": "Nives", "code_postal": "6640", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "Noirfontaine", "code_postal": "6831", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HERENTALS", "name": "Noorderwijk", "code_postal": "2200", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "METTET", "name": "Oret", "code_postal": "5640", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "PALISEUL", "name": "PALISEUL", "code_postal": "6850", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUIÉVRAIN", "name": "QUIÉVRAIN", "code_postal": "7380", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "AUBANGE", "name": "Rachecourt", "code_postal": "6792", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Ramegnies-Chin", "code_postal": "7520", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LANAKEN", "name": "Rekem", "code_postal": "3621", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉRON", "name": "Romsée", "code_postal": "4624", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VIRTON", "name": "Ruette", "code_postal": "6760", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Samart", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "VILLERS-LA-VILLE", "name": "Sart-Dames-Avelines", "code_postal": "1495", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SILLY", "name": "SILLY", "code_postal": "7830", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-NIKLAAS", "name": "Sinaai-Waas", "code_postal": "9112", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HASSELT", "name": "Sint-Lambrechts-Herk", "code_postal": "3500", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SPRIMONT", "name": "SPRIMONT", "code_postal": "4140", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ANZEGEM", "name": "Tiegem", "code_postal": "8573", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALTER", "name": "Ursel", "code_postal": "9910", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VIROINVAL", "name": "Vierves-Sur-Viroin", "code_postal": "5670", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ANTHISNES", "name": "Villers-Aux-Tours", "code_postal": "4161", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Villers-Le-Peuplier", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GALMAARDEN", "name": "Vollezele", "code_postal": "1570", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BOECHOUT", "name": "Vremde", "code_postal": "2531", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Vreren", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MARCHE-EN-FAMENNE", "name": "Waha", "code_postal": "6900", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KRUISEM", "name": "Wannegem-Lede", "code_postal": "9772", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HASTIÈRE", "name": "Waulsort", "code_postal": "5540", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LES BONS VILLERS", "name": "Wayaux", "code_postal": "6210", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "STADEN", "name": "Westrozebeke", "code_postal": "8840", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WIELSBEKE", "name": "WIELSBEKE", "code_postal": "8710", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Zeveren", "code_postal": "9800", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "Zoutenaaie", "code_postal": "8630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZUIENKERKE", "name": "ZUIENKERKE", "code_postal": "8377", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "WELLEN", "name": "Herten", "code_postal": "3831", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "Hingeon", "code_postal": "5380", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "GRÂCE-HOLLOGNE", "name": "Hollogne-Aux-Pierres", "code_postal": "4460", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOTTON", "name": "HOTTON", "code_postal": "6990", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LA LOUVIÈRE", "name": "Houdeng-Aimeries", "code_postal": "7110", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "IXELLES", "name": "IXELLES", "code_postal": "1050", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "LIERNEUX", "name": "Arbrefontaine", "code_postal": "4990", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AYWAILLE", "name": "AYWAILLE", "code_postal": "4920", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "SOMME-LEUZE", "name": "Baillonville", "code_postal": "5377", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "PEPINGEN", "name": "Bogaarden", "code_postal": "1670", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "Borgerhout", "code_postal": "2140", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Borlon", "code_postal": "6941", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FROIDCHAPELLE", "name": "Boussu-Lez-Walcourt", "code_postal": "6440", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Bovelingen", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BRUGGE", "name": "BRUGGE", "code_postal": "8000", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Brûly-De-Pesche", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FLEURUS", "name": "Brye", "code_postal": "6222", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Castillon", "code_postal": "5650", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "CHAUDFONTAINE", "name": "CHAUDFONTAINE", "code_postal": "4050", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "VISÉ", "name": "Cheratte", "code_postal": "4602", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COMINES-WARNETON", "name": "Comines", "code_postal": "7780", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "MONT-SAINT-GUIBERT", "name": "Corbais", "code_postal": "1435", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Couillet", "code_postal": "6010", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HOUYET", "name": "Custinne", "code_postal": "5562", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ROUVROY", "name": "Dampicourt", "code_postal": "6767", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DE PINTE", "name": "DE PINTE", "code_postal": "9840", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LESSINES", "name": "Deux-Acren", "code_postal": "7864", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HERK-DE-STAD", "name": "Donk", "code_postal": "3540", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "OHEY", "name": "Evelette", "code_postal": "5350", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Felenne", "code_postal": "5570", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉMALLE", "name": "FLÉMALLE", "code_postal": "4400", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Gilly", "code_postal": "6060", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SILLY", "name": "Gondregnies", "code_postal": "7830", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Hanret", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Haren", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANHÉE", "name": "Haut-Le-Wastia", "code_postal": "5537", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WELKENRAEDT", "name": "Henri-Chapelle", "code_postal": "4841", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HASTIÈRE", "name": "Hermeton-Sur-Meuse", "code_postal": "5540", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZEMST", "name": "Hofstade", "code_postal": "1981", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "Hollebeke", "code_postal": "8902", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VAUX-SUR-SÛRE", "name": "Hompré", "code_postal": "6640", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BOUSSU", "name": "Hornu", "code_postal": "7301", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUNEHAUT", "name": "Howardries", "code_postal": "7624", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MARCHE-EN-FAMENNE", "name": "Humain", "code_postal": "6900", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Isnes", "code_postal": "5032", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BERTEM", "name": "Korbeek-Dijle", "code_postal": "3060", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "KORTESSEM", "name": "KORTESSEM", "code_postal": "3720", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Laforêt", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DONCEEL", "name": "Limont", "code_postal": "4357", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MANHAY", "name": "Malempré", "code_postal": "6960", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BULLANGE", "name": "Manderfeld", "code_postal": "4760", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Marcinelle", "code_postal": "6001", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HOEGAARDEN", "name": "Meldert", "code_postal": "3320", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "Mont-Gauthier", "code_postal": "5580", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MERELBEKE", "name": "Munte", "code_postal": "9820", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HAM-SUR-HEURE", "name": "Nalinnes", "code_postal": "6120", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Neerrepen", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KAPELLE-OP-DEN-BOS", "name": "Nieuwenrode", "code_postal": "1880", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "NINOVE", "code_postal": "9400", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBIN", "name": "Ochamps", "code_postal": "6890", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZULTE", "name": "Olsene", "code_postal": "9870", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Oostakker", "code_postal": "9041", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIEVEGEM", "name": "Oostwinkel", "code_postal": "9931", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "BRAINE-L'ALLEUD", "name": "Ophain-Bois-Seigneur-Isaac", "code_postal": "1421", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "HÉLÉCINE", "name": "Opheylissem", "code_postal": "1357", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "PERWEZ", "name": "Orbais", "code_postal": "1360", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Ormeignies", "code_postal": "7802", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZWEVEGEM", "name": "Otegem", "code_postal": "8553", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Overboelare", "code_postal": "9500", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COLFONTAINE", "name": "Pâturages", "code_postal": "7340", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALTER", "name": "Poeke", "code_postal": "9880", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DAVERDISSE", "name": "Porcheresse", "code_postal": "6929", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KORTENAKEN", "name": "Ransberg", "code_postal": "3470", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBRAMONT-CHEVIGNY", "name": "Recogne", "code_postal": "6800", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RUMST", "name": "Reet", "code_postal": "2840", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "INCOURT", "name": "Roux-Miroir", "code_postal": "1315", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FLEURUS", "name": "Saint-Amand", "code_postal": "6221", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MESSANCY", "name": "Sélange", "code_postal": "6781", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VERLAINE", "name": "Seraing-Le-Château", "code_postal": "4537", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "PUURS-SINT-AMANDS", "name": "Sint-Amands", "code_postal": "2890", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "SINT-KATELIJNE-WAVER", "name": "SINT-KATELIJNE-WAVER", "code_postal": "2860", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LAUREINS", "name": "Sint-Margriete", "code_postal": "9981", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DILBEEK", "name": "Sint-Martens-Bodegem", "code_postal": "1700", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HERNE", "name": "Sint-Pieters-Kapelle", "code_postal": "1541", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Sluizen", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SPA", "name": "SPA", "code_postal": "4900", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "YVOIR", "name": "Spontin", "code_postal": "5530", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "THIMISTER-CLERMONT", "name": "Thimister", "code_postal": "4890", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASMECHELEN", "name": "Uikhoven", "code_postal": "3631", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LES BONS VILLERS", "name": "Villers-Perwin", "code_postal": "6210", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OUDENBURG", "name": "Westkerke", "code_postal": "8460", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "NIEUWERKERKEN", "name": "Wijer", "code_postal": "3850", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERVE", "name": "Xhendelesse", "code_postal": "4652", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WANZE", "name": "Antheit", "code_postal": "4520", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "ANTWERPEN", "code_postal": "2050", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ARLON", "name": "ARLON", "code_postal": "6700", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "Asquillies", "code_postal": "7040", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "AVELGEM", "name": "AVELGEM", "code_postal": "8580", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Beerst", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "RENDEUX", "name": "Beffe", "code_postal": "6987", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BERNISSART", "name": "BERNISSART", "code_postal": "7320", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "BOCHOLT", "name": "BOCHOLT", "code_postal": "3950", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "GEER", "name": "Boëlhe", "code_postal": "4250", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERVE", "name": "Bolland", "code_postal": "4653", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MERELBEKE", "name": "Bottelare", "code_postal": "9820", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GENAPPE", "name": "Bousval", "code_postal": "1470", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MORLANWELZ", "name": "Carnières", "code_postal": "7141", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "CHASTRE", "name": "Cortil-Noirmont", "code_postal": "1450", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BERTRIX", "name": "Cugnon", "code_postal": "6880", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERMONDE", "name": "DENDERMONDE", "code_postal": "9200", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "PITTEM", "name": "Egem", "code_postal": "8740", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "Elene", "code_postal": "9620", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RANST", "name": "Emblem", "code_postal": "2520", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ERPE-MERE", "name": "ERPE-MERE", "code_postal": "9420", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Flawinne", "code_postal": "5020", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AMAY", "name": "Flône", "code_postal": "4540", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Focant", "code_postal": "5572", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBRAMONT-CHEVIGNY", "name": "Freux", "code_postal": "6800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "Furfooz", "code_postal": "5500", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Gaurain-Ramecroix", "code_postal": "7530", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Gelbressée", "code_postal": "5024", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ANZEGEM", "name": "Gijzelbrechtegem", "code_postal": "8570", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "GROBBENDONK", "name": "GROBBENDONK", "code_postal": "2280", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HABAY", "name": "Habay-La-Vieille", "code_postal": "6723", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DONCEEL", "name": "Haneffe", "code_postal": "4357", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HASTIÈRE", "name": "HASTIÈRE", "code_postal": "5540", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Havré", "code_postal": "7021", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Hees", "code_postal": "3740", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HEMIKSEM", "name": "HEMIKSEM", "code_postal": "2620", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HERNE", "name": "HERNE", "code_postal": "1540", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "HÉRON", "name": "HÉRON", "code_postal": "4217", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "OUPEYE", "name": "Heure-Le-Romain", "code_postal": "4682", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HOREBEKE", "name": "HOREBEKE", "code_postal": "9667", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "HOUTHALEN-HELCHTEREN", "name": "Houthalen", "code_postal": "3530", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KRUISEM", "name": "Huise", "code_postal": "9750", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HOUYET", "name": "Hulsonniaux", "code_postal": "5560", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ORP-JAUCHE", "name": "Jauche", "code_postal": "1350", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KAMPENHOUT", "name": "KAMPENHOUT", "code_postal": "1910", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTRIJK", "name": "Kooigem", "code_postal": "8510", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KORTENAKEN", "name": "KORTENAKEN", "code_postal": "3470", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "THEUX", "name": "La Reid", "code_postal": "4910", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "Lavaux-Sainte-Anne", "code_postal": "5580", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GOOIK", "name": "Leerbeek", "code_postal": "1755", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MECHELEN", "name": "Leest", "code_postal": "2811", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LICHTERVELDE", "name": "LICHTERVELDE", "code_postal": "8810", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "SOMBREFFE", "name": "Ligny", "code_postal": "5140", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MAARKEDAL", "name": "Maarke-Kerkem", "code_postal": "9680", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HAM-SUR-HEURE", "name": "Marbaix", "code_postal": "6120", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "Marchovelette", "code_postal": "5380", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BORNEM", "name": "Mariekerke", "code_postal": "2880", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "JURBISE", "name": "Masnuy-Saint-Jean", "code_postal": "7050", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HOOGSTRATEN", "name": "Meerle", "code_postal": "2328", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZUIENKERKE", "name": "Meetkerke", "code_postal": "8377", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MERKSPLAS", "name": "MERKSPLAS", "code_postal": "2330", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Nederhasselt", "code_postal": "9400", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KRUISEM", "name": "Nokere", "code_postal": "9771", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FEXHE-LE-HAUT-CLOCHER", "name": "Noville", "code_postal": "4347", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WAREMME", "name": "Oleye", "code_postal": "4300", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Oostkerke", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Opheers", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BERTRIX", "name": "Orgeo", "code_postal": "6880", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ORP-JAUCHE", "name": "ORP-JAUCHE", "code_postal": "1350", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HULDENBERG", "name": "Ottenburg", "code_postal": "3040", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HOEGAARDEN", "name": "Outgaarden", "code_postal": "3321", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BERNISSART", "name": "Pommeroeul", "code_postal": "7322", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BEYNE-HEUSAY", "name": "Queue-Du-Bois", "code_postal": "4610", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RAMILLIES", "name": "RAMILLIES", "code_postal": "1367", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Rebaix", "code_postal": "7804", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "ROCHEFORT", "code_postal": "5580", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "NEUPRÉ", "name": "Rotheux-Rimière", "code_postal": "4120", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Rozebeke", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "JEMEPPE-SUR-SAMBRE", "name": "Saint-Martin", "code_postal": "5190", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "JODOIGNE", "name": "Saint-Remy-Geest", "code_postal": "1370", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANDENNE", "name": "Seilles", "code_postal": "5300", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BEERNEM", "name": "Sint-Joris", "code_postal": "8730", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Sint-Kruis-Winkel", "code_postal": "9042", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LENNIK", "name": "Sint-Martens-Lennik", "code_postal": "1750", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GESVES", "name": "Sorée", "code_postal": "5340", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CINEY", "name": "Sovet", "code_postal": "5590", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "EREZÉE", "name": "Soy", "code_postal": "6997", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "KASTERLEE", "name": "Tielen", "code_postal": "2460", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Villers-En-Fagne", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GAVERE", "name": "Vurste", "code_postal": "9890", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WAASMUNSTER", "name": "WAASMUNSTER", "code_postal": "9250", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "TROIS-PONTS", "name": "Wanne", "code_postal": "4980", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "WATERMAEL-BOITSFORT", "name": "WATERMAEL-BOITSFORT", "code_postal": "1170", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "MALLE", "name": "Westmalle", "code_postal": "2390", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HERZELE", "name": "Woubrechtegem", "code_postal": "9550", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Woumen", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KRUISEM", "name": "Zingem", "code_postal": "9750", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DE PANNE", "name": "Adinkerke", "code_postal": "8660", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GAVERE", "name": "Asper", "code_postal": "9890", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ATTERT", "name": "ATTERT", "code_postal": "6717", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINT-HUBERT", "name": "Awenne", "code_postal": "6870", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COMINES-WARNETON", "name": "Bas-Warneton", "code_postal": "7784", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GRIMBERGEN", "name": "Beigem", "code_postal": "1852", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "THUIN", "name": "Biercée", "code_postal": "6533", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BIÈVRE", "name": "BIÈVRE", "code_postal": "5555", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "OUD-HEVERLEE", "name": "Blanden", "code_postal": "3052", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "DOUR", "name": "Blaugies", "code_postal": "7370", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VERLAINE", "name": "Bodegnée", "code_postal": "4537", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MANAGE", "name": "Bois-D'Haine", "code_postal": "7170", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Bommershoven", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "Borlo", "code_postal": "3891", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIERNEUX", "name": "Bra", "code_postal": "4990", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "BRUXELLES", "name": "BRUXELLES", "code_postal": "1000", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZOUTLEEUW", "name": "Budingen", "code_postal": "3440", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SOIGNIES", "name": "Casteau", "code_postal": "7061", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "CHASTRE", "name": "CHASTRE", "code_postal": "1450", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "COLFONTAINE", "name": "COLFONTAINE", "code_postal": "7340", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "Deurne", "code_postal": "2100", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HEUVELLAND", "name": "Dranouter", "code_postal": "8951", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BRUGGE", "name": "Dudzele", "code_postal": "8380", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TERVUREN", "name": "Duisburg", "code_postal": "3080", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ECAUSSINNES", "name": "ECAUSSINNES", "code_postal": "7190", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "NEUPRÉ", "name": "Ehein", "code_postal": "4120", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "LAAKDAL", "name": "Eindhout", "code_postal": "2430", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Eine", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZEMST", "name": "Eppegem", "code_postal": "1980", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Erpent", "code_postal": "5101", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ESPIERRES-HELCHIN", "name": "Espierres", "code_postal": "8587", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FOSSES-LA-VILLE", "name": "FOSSES-LA-VILLE", "code_postal": "5070", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "FOURONS", "name": "Fouron-Le-Comte", "code_postal": "3798", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Franchimont", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "GERPINNES", "name": "GERPINNES", "code_postal": "6280", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "GISTEL", "name": "GISTEL", "code_postal": "8470", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉMALLE", "name": "Gleixhe", "code_postal": "4400", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "YVOIR", "name": "Godinne", "code_postal": "5530", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "Godveerdegem", "code_postal": "9620", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BIÈVRE", "name": "Graide", "code_postal": "5555", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HEIST-OP-DEN-BERG", "name": "Hallaar", "code_postal": "2220", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAMOIS", "name": "HAMOIS", "code_postal": "5360", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Hendrieken", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HENSIES", "name": "HENSIES", "code_postal": "7350", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOUSCRON", "name": "Herseaux", "code_postal": "7712", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HERSTAL", "name": "HERSTAL", "code_postal": "4040", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "SOMME-LEUZE", "name": "Heure", "code_postal": "5377", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "FAUVILLERS", "name": "Hollange", "code_postal": "6637", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Horpmaal", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOUFFALIZE", "name": "HOUFFALIZE", "code_postal": "6660", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "HOUYET", "name": "Hour", "code_postal": "5563", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HOUYET", "name": "HOUYET", "code_postal": "5560", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZWALM", "name": "Hundelgem", "code_postal": "9630", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "OHEY", "name": "Jallet", "code_postal": "5354", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Javingue", "code_postal": "5570", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Kaaskerke", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DIEST", "name": "Kaggevinne", "code_postal": "3293", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BEVEREN-WAAS", "name": "Kallo", "code_postal": "9130", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "AVELGEM", "name": "Kerkhove", "code_postal": "8581", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ARDOOIE", "name": "Koolskamp", "code_postal": "8851", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Lanquesaint", "code_postal": "7800", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINTE-ODE", "name": "Lavacherie", "code_postal": "6681", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "GEER", "name": "Lens-Saint-Servais", "code_postal": "4250", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "KASTERLEE", "name": "Lichtaart", "code_postal": "2460", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SPRIMONT", "name": "Louveigné", "code_postal": "4141", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Loyers", "code_postal": "5101", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "PALISEUL", "name": "Maissin", "code_postal": "6852", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Marchienne-Au-Pont", "code_postal": "6030", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINT-LÉGER", "name": "Meix-Le-Tige", "code_postal": "6747", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MESSINES", "name": "MESSINES", "code_postal": "8957", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "PLOMBIÈRES", "name": "Montzen", "code_postal": "4850", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Mouzaive", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SAINT-GHISLAIN", "name": "Neufmaison", "code_postal": "7332", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ATTERT", "name": "Nobressart", "code_postal": "6717", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MANHAY", "name": "Odeigne", "code_postal": "6960", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Okegem", "code_postal": "9400", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "OPWIJK", "name": "OPWIJK", "code_postal": "1745", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LA ROCHE-EN-ARDENNE", "name": "Ortho", "code_postal": "6983", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTINNES", "name": "Peissant", "code_postal": "7120", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Poesele", "code_postal": "9850", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RAVELS", "name": "Poppel", "code_postal": "2382", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "PUTTE", "name": "PUTTE", "code_postal": "2580", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "PUURS-SINT-AMANDS", "name": "Puurs", "code_postal": "2870", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BEAUMONT", "name": "Renlies", "code_postal": "6500", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OUDENBURG", "name": "Roksem", "code_postal": "8460", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SPRIMONT", "name": "Rouvreux", "code_postal": "4140", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONT-DE-L'ENCLUS", "name": "Russeignies", "code_postal": "7750", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINT-HUBERT", "name": "SAINT-HUBERT", "code_postal": "6870", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VIRTON", "name": "Saint-Mard", "code_postal": "6762", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Saint-Symphorien", "code_postal": "7030", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRAMERIES", "name": "Sars-La-Bruyère", "code_postal": "7080", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "name": "SCHERPENHEUVEL-ZICHEM", "code_postal": "3270", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "ONHAYE", "name": "Serville", "code_postal": "5521", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ERQUELINNES", "name": "Solre-Sur-Sambre", "code_postal": "6560", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ANTHISNES", "name": "Tavier", "code_postal": "4163", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "THUIN", "name": "Thuillies", "code_postal": "6536", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ATTERT", "name": "Tontelange", "code_postal": "6717", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "Ucimont", "code_postal": "6833", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Vedrin", "code_postal": "5020", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "GRÂCE-HOLLOGNE", "name": "Velroux", "code_postal": "4460", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ANZEGEM", "name": "Vichte", "code_postal": "8570", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LE ROEULX", "name": "Ville-Sur-Haine", "code_postal": "7070", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Vodecée", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "JUPRELLE", "name": "Voroux-Lez-Liers", "code_postal": "4451", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "MARCHIN", "name": "Vyle-Et-Tharoul", "code_postal": "4570", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OOSTKAMP", "name": "Waardamme", "code_postal": "8020", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "KONTICH", "name": "Waarloos", "code_postal": "2550", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BASTOGNE", "name": "Wardin", "code_postal": "6600", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTEMARK", "name": "Zarren", "code_postal": "8610", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LIEVENS-HOUTEM", "name": "Zonnegem", "code_postal": "9520", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZOUTLEEUW", "name": "ZOUTLEEUW", "code_postal": "3440", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTRIJK", "name": "Marke", "code_postal": "8510", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Meigem", "code_postal": "9800", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SOUMAGNE", "name": "Melen", "code_postal": "4633", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LÉGLISE", "name": "Mellier", "code_postal": "6860", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HOUYET", "name": "Mesnil-Eglise", "code_postal": "5560", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Mettekoven", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LOBBES", "name": "Mont-Sainte-Geneviève", "code_postal": "6540", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Montignies-Sur-Sambre", "code_postal": "6061", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MUSSON", "name": "Mussy-La-Ville", "code_postal": "6750", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Neigem", "code_postal": "9403", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "NEUPRÉ", "name": "NEUPRÉ", "code_postal": "4120", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "WALHAIN", "name": "Nil-Saint-Vincent-Saint-Martin", "code_postal": "1457", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Nimy", "code_postal": "7020", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MALLE", "name": "Oostmalle", "code_postal": "2390", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KRUISEM", "name": "Ouwegem", "code_postal": "9750", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "OHEY", "name": "Perwez-Haillot", "code_postal": "5352", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VIELSALM", "name": "Petit-Thier", "code_postal": "6692", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "VRESSE-SUR-SEMOIS", "name": "Pussemange", "code_postal": "5550", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "REBECQ", "name": "Quenast", "code_postal": "1430", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "RAEREN", "name": "RAEREN", "code_postal": "4730", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KAPELLE-OP-DEN-BOS", "name": "Ramsdonk", "code_postal": "1880", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BASSENGE", "name": "Roclenge-Sur-Geer", "code_postal": "4690", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BERLOZ", "name": "Rosoux-Crenwick", "code_postal": "4257", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Rukkelingen-Loon", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "Saint-Aubin", "code_postal": "5620", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "PLOMBIÈRES", "name": "Sippenaeken", "code_postal": "4851", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BELOEIL", "name": "Stambruges", "code_postal": "7973", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ALVERINGEM", "name": "Stavele", "code_postal": "8691", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "Surice", "code_postal": "5600", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "TINLOT", "name": "TINLOT", "code_postal": "4557", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "TUBIZE", "name": "TUBIZE", "code_postal": "1480", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "OUD-HEVERLEE", "name": "Vaalbeek", "code_postal": "3054", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Velm", "code_postal": "3806", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FERRIÈRES", "name": "Vieuxville", "code_postal": "4190", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "VILLERS-LA-VILLE", "name": "VILLERS-LA-VILLE", "code_postal": "1495", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "WALHAIN", "name": "WALHAIN", "code_postal": "1457", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Welden", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ROTSELAAR", "name": "Wezemaal", "code_postal": "3111", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZEDELGEM", "name": "ZEDELGEM", "code_postal": "8210", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALST", "name": "AALST", "code_postal": "9300", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "SAMBREVILLE", "name": "Arsimont", "code_postal": "5060", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ASSESSE", "name": "ASSESSE", "code_postal": "5330", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUIÉVRAIN", "name": "Baisieux", "code_postal": "7380", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LENS", "name": "Bauffe", "code_postal": "7870", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-LIEVENS-HOUTEM", "name": "Bavegem", "code_postal": "9520", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BREE", "name": "Beek", "code_postal": "3960", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HERK-DE-STAD", "name": "Berbroek", "code_postal": "3540", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "Berchem", "code_postal": "2600", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WAREGEM", "name": "Beveren", "code_postal": "8791", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "GRÂCE-HOLLOGNE", "name": "Bierset", "code_postal": "4460", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HASTIÈRE", "name": "Blaimont", "code_postal": "5542", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PÉRUWELZ", "name": "Bon-Secours", "code_postal": "7603", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "MAASMECHELEN", "name": "Boorsem", "code_postal": "3631", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "BORGLOON", "code_postal": "3840", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "BORNEM", "name": "BORNEM", "code_postal": "2880", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "BORSBEEK", "name": "BORSBEEK", "code_postal": "2150", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "GROBBENDONK", "name": "Bouwel", "code_postal": "2288", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BINCHE", "name": "Buvrinnes", "code_postal": "7133", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BERLOZ", "name": "Corswarem", "code_postal": "4257", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "DEINZE", "code_postal": "9800", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Dhuy", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "JURBISE", "name": "Erbaut", "code_postal": "7050", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "EUPEN", "name": "EUPEN", "code_postal": "4700", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "Fayt-Le-Franc", "code_postal": "7387", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BERTOGNE", "name": "Flamierge", "code_postal": "6686", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "FOURONS", "name": "Fouron-Saint-Pierre", "code_postal": "3792", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "Goegnies-Chaussée", "code_postal": "7040", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "GRÂCE-HOLLOGNE", "name": "GRÂCE-HOLLOGNE", "code_postal": "4460", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Grimminge", "code_postal": "9506", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GESVES", "name": "Haltinne", "code_postal": "5340", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Jambes", "code_postal": "5100", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "TIELT", "name": "Kanegem", "code_postal": "8700", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BOCHOLT", "name": "Kaulille", "code_postal": "3950", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BEAUMONT", "name": "Leval-Chaudeville", "code_postal": "6500", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LIÈGE", "name": "LIÈGE", "code_postal": "4020", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "LINT", "name": "LINT", "code_postal": "2547", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HAALTERT", "name": "Heldergem", "code_postal": "9450", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Hertain", "code_postal": "7522", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ALVERINGEM", "name": "Hoogstade", "code_postal": "8690", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Houtaing", "code_postal": "7812", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GRIMBERGEN", "name": "Humbeek", "code_postal": "1851", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ITTRE", "name": "ITTRE", "code_postal": "1460", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IZEGEM", "name": "IZEGEM", "code_postal": "8870", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BIERBEEK", "name": "Korbeek-Lo", "code_postal": "3360", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Laar", "code_postal": "3400", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LEOPOLDSBURG", "name": "LEOPOLDSBURG", "code_postal": "3970", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LUBBEEK", "name": "Linden", "code_postal": "3210", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "Lisogne", "code_postal": "5501", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "WUUSTWEZEL", "name": "Loenhout", "code_postal": "2990", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "LOMMEL", "name": "LOMMEL", "code_postal": "3920", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BIERBEEK", "name": "Lovenjoel", "code_postal": "3360", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Arbre", "code_postal": "7811", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BRAIVES", "name": "Avennes", "code_postal": "4260", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TREMELO", "name": "Baal", "code_postal": "3128", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Baileux", "code_postal": "6464", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Bailièvre", "code_postal": "6460", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ICHTEGEM", "name": "Bekegem", "code_postal": "8480", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Bevere", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Bolinne", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FAIMES", "name": "Borlez", "code_postal": "4317", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BOUSSU", "name": "BOUSSU", "code_postal": "7300", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BOUTERSEM", "name": "BOUTERSEM", "code_postal": "3370", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "BRAIVES", "name": "BRAIVES", "code_postal": "4260", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "PUURS-SINT-AMANDS", "name": "Breendonk", "code_postal": "2870", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BURG-REULAND", "name": "BURG-REULAND", "code_postal": "4790", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ANTOING", "name": "Calonne", "code_postal": "7642", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "OTTIGNIES-LOUVAIN-LA-NEUVE", "name": "Céroux-Mousty", "code_postal": "1341", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "COUVIN", "code_postal": "5660", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DAMME", "name": "DAMME", "code_postal": "8340", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "SPRIMONT", "name": "Dolembreux", "code_postal": "4140", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "JODOIGNE", "name": "Dongelberg", "code_postal": "1370", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BELOEIL", "name": "Ellignies-Sainte-Anne", "code_postal": "7972", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Esen", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTAIMPUIS", "name": "ESTAIMPUIS", "code_postal": "7730", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "FLORENVILLE", "name": "FLORENVILLE", "code_postal": "6820", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "TROOZ", "name": "Forêt", "code_postal": "4870", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "METTET", "name": "Furnaux", "code_postal": "5641", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "PLOMBIÈRES", "name": "Gemmenich", "code_postal": "4851", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GREZ-DOICEAU", "name": "GREZ-DOICEAU", "code_postal": "1390", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTINNES", "name": "Haulchin", "code_postal": "7120", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FLEURUS", "name": "Heppignies", "code_postal": "6220", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HEUVELLAND", "name": "HEUVELLAND", "code_postal": "8950", "sous_commune": false },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "JETTE", "name": "JETTE", "code_postal": "1090", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VAUX-SUR-SÛRE", "name": "Juseret", "code_postal": "6642", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ANZEGEM", "name": "Kaster", "code_postal": "8572", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "KASTERLEE", "name": "KASTERLEE", "code_postal": "2460", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Kolmont", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KORTENBERG", "name": "KORTENBERG", "code_postal": "3070", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "REMICOURT", "name": "Lamine", "code_postal": "4350", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "AARSCHOT", "name": "Langdorp", "code_postal": "3201", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KAPRIJKE", "name": "Lembeke", "code_postal": "9971", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FAIMES", "name": "Les Waleffes", "code_postal": "4317", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BEAUMONT", "name": "Leugnies", "code_postal": "6500", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "HÉLÉCINE", "name": "Linsmeau", "code_postal": "1357", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BRUGGE", "name": "Lissewege", "code_postal": "8380", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Maisières", "code_postal": "7020", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MALDEGEM", "name": "MALDEGEM", "code_postal": "9990", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Mariembourg", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CELLES", "name": "Molenbaix", "code_postal": "7760", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOMIGNIES", "name": "MOMIGNIES", "code_postal": "6590", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHARLEROI", "name": "Mont-Sur-Marchienne", "code_postal": "6032", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HERENTALS", "name": "Morkhoven", "code_postal": "2200", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MORLANWELZ", "name": "MORLANWELZ", "code_postal": "7140", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOUSCRON", "name": "MOUSCRON", "code_postal": "7700", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "BEAUVECHAIN", "name": "Nodebais", "code_postal": "1320", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RANST", "name": "Oelegem", "code_postal": "2520", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DENTERGEM", "name": "Oeselgem", "code_postal": "8720", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONT-DE-L'ENCLUS", "name": "Orroir", "code_postal": "7750", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "CLAVIER", "name": "Pailhe", "code_postal": "4560", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "INCOURT", "name": "Piétrebais", "code_postal": "1315", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "Poupehan", "code_postal": "6830", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "POPERINGE", "name": "Roesbrugge-Haringe", "code_postal": "8972", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "JODOIGNE", "name": "Saint-Jean-Geest", "code_postal": "1370", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "NANDRIN", "name": "Saint-Séverin", "code_postal": "4550", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "TINLOT", "name": "Seny", "code_postal": "4557", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CERFONTAINE", "name": "Silenrieux", "code_postal": "5630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Sint-Denijs-Westrem", "code_postal": "9051", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Sint-Jacobs-Kapelle", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BRUGGE", "name": "Sint-Kruis", "code_postal": "8310", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-GILLIS-WAAS", "name": "Sint-Pauwels", "code_postal": "9170", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DILBEEK", "name": "Sint-Ulriks-Kapelle", "code_postal": "1700", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "JABBEKE", "name": "Stalhille", "code_postal": "8490", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VEURNE", "name": "Steenkerke", "code_postal": "8630", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BEAUMONT", "name": "Strée", "code_postal": "6511", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GRIMBERGEN", "name": "Strombeek-Bever", "code_postal": "1853", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "WALHAIN", "name": "Tourinnes-Saint-Lambert", "code_postal": "1457", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HERENT", "name": "Veltem-Beisem", "code_postal": "3020", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VERVIERS", "name": "VERVIERS", "code_postal": "4800", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GENAPPE", "name": "Vieux-Genappe", "code_postal": "1472", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ERPE-MERE", "name": "Vlekkem", "code_postal": "9420", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "WALHAIN", "name": "Walhain-Saint-Paul", "code_postal": "1457", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DALHEM", "name": "Warsage", "code_postal": "4608", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PÉRUWELZ", "name": "Wasmes-Audemez-Briffoeil", "code_postal": "7604", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GENAPPE", "name": "Ways", "code_postal": "1474", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZEMST", "name": "Weerde", "code_postal": "1982", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "WIJNEGEM", "name": "WIJNEGEM", "code_postal": "2110", "sous_commune": false },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "WOLUWE-SAINT-LAMBERT", "name": "WOLUWE-SAINT-LAMBERT", "code_postal": "1200", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "ANTWERPEN", "code_postal": "2040", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LA ROCHE-EN-ARDENNE", "name": "Beausaint", "code_postal": "6980", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BEGIJNENDIJK", "name": "Betekom", "code_postal": "3130", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LUBBEEK", "name": "Binkom", "code_postal": "3211", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANHÉE", "name": "Bioul", "code_postal": "5537", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Blandain", "code_postal": "7522", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRAINE-LE-COMTE", "name": "BRAINE-LE-COMTE", "code_postal": "7090", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "Brielen", "code_postal": "8900", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BUGGENHOUT", "name": "BUGGENHOUT", "code_postal": "9255", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Buissenal", "code_postal": "7911", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "Buissonville", "code_postal": "5580", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PÉRUWELZ", "name": "Callenelle", "code_postal": "7604", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CERFONTAINE", "name": "CERFONTAINE", "code_postal": "5630", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINT-LÉGER", "name": "Châtillon", "code_postal": "6747", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BRAIVES", "name": "Ciplet", "code_postal": "4260", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CINEY", "name": "Conneux", "code_postal": "5590", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DIEST", "name": "Deurne", "code_postal": "3290", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "EEKLO", "name": "EEKLO", "code_postal": "9900", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "OUDSBERGEN", "name": "Ellikom", "code_postal": "3670", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BINCHE", "name": "Epinois", "code_postal": "7134", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "Erwetegem", "code_postal": "9620", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Esplechin", "code_postal": "7502", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOTTON", "name": "Fronville", "code_postal": "6990", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GEETBETS", "name": "GEETBETS", "code_postal": "3450", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Ghislenghien", "code_postal": "7822", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Ghlin", "code_postal": "7011", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "COUVIN", "name": "Gonrieux", "code_postal": "5660", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MANHAY", "name": "Grandménil", "code_postal": "6960", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NASSOGNE", "name": "Grune", "code_postal": "6952", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HENSIES", "name": "Hainin", "code_postal": "7350", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "MERCHTEM", "name": "Hamme", "code_postal": "1785", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ERQUELINNES", "name": "Hantes-Wihéries", "code_postal": "6560", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DAVERDISSE", "name": "Haut-Fays", "code_postal": "6929", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Havinnes", "code_postal": "7531", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HECHTEL-EKSEL", "name": "HECHTEL-EKSEL", "code_postal": "3940", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "PEPINGEN", "name": "Heikruis", "code_postal": "1670", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DURBUY", "name": "Heyd", "code_postal": "6941", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HOLSBEEK", "name": "HOLSBEEK", "code_postal": "3220", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "Jemelle", "code_postal": "5580", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "JUPRELLE", "name": "JUPRELLE", "code_postal": "4450", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HOLSBEEK", "name": "Kortrijk-Dutsel", "code_postal": "3220", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "RUMES", "name": "La Glanerie", "code_postal": "7611", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MANAGE", "name": "La Hestre", "code_postal": "7170", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ALVERINGEM", "name": "Leisele", "code_postal": "8691", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBRAMONT-CHEVIGNY", "name": "LIBRAMONT-CHEVIGNY", "code_postal": "6800", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "LIMBOURG", "name": "LIMBOURG", "code_postal": "4830", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NASSOGNE", "name": "Masbourg", "code_postal": "6953", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LA LOUVIÈRE", "name": "Maurage", "code_postal": "7110", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LINTER", "name": "Melkwezer", "code_postal": "3350", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VAUX-SUR-SÛRE", "name": "Morhet", "code_postal": "6640", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "NAMUR", "code_postal": "5000", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "CLAVIER", "name": "Ocquier", "code_postal": "4560", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "CRISNÉE", "name": "Odeur", "code_postal": "4367", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Orcq", "code_postal": "7501", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LINTER", "name": "Overhespen", "code_postal": "3350", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BERINGEN", "name": "Paal", "code_postal": "3583", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PECQ", "name": "PECQ", "code_postal": "7740", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "PELT", "name": "PELT", "code_postal": "3900", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "CINEY", "name": "Pessoux", "code_postal": "5590", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Piringen", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PROFONDEVILLE", "name": "PROFONDEVILLE", "code_postal": "5170", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "QUÉVY", "name": "Quévy-Le-Petit", "code_postal": "7040", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BELOEIL", "name": "Ramegnies", "code_postal": "7971", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MENEN", "name": "Rekkem", "code_postal": "8930", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BOCHOLT", "name": "Reppel", "code_postal": "3950", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Rijkhoven", "code_postal": "3740", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BONHEIDEN", "name": "Rijmenam", "code_postal": "2820", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PROFONDEVILLE", "name": "Rivière", "code_postal": "5170", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MEIX-DEVANT-VIRTON", "name": "Robelmont", "code_postal": "6769", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HOESELT", "name": "Romershoven", "code_postal": "3730", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Rosmeer", "code_postal": "3740", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MARCHE-EN-FAMENNE", "name": "Roy", "code_postal": "6900", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "Rutten", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEMBLOUX", "name": "Sauvenière", "code_postal": "5030", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "SCHELLE", "name": "SCHELLE", "code_postal": "2627", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WICHELEN", "name": "Serskamp", "code_postal": "9260", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HULDENBERG", "name": "Sint-Agatha-Rode", "code_postal": "3040", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WAREGEM", "name": "Sint-Eloois-Vijve", "code_postal": "8793", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "OUD-HEVERLEE", "name": "Sint-Joris-Weert", "code_postal": "3051", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ASSESSE", "name": "Sorinne-La-Longue", "code_postal": "5333", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AYWAILLE", "name": "Sougné-Remouchamps", "code_postal": "4920", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "TELLIN", "name": "TELLIN", "code_postal": "6927", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "CLAVIER", "name": "Terwagne", "code_postal": "4560", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "CRISNÉE", "name": "Thys", "code_postal": "4367", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "JABBEKE", "name": "Varsenare", "code_postal": "8490", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FROIDCHAPELLE", "name": "Vergnies", "code_postal": "6440", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BERNISSART", "name": "Ville-Pommeroeul", "code_postal": "7322", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FOSSES-LA-VILLE", "name": "Vitrival", "code_postal": "5070", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OUPEYE", "name": "Vivegnis", "code_postal": "4683", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FLEURUS", "name": "Wangenies", "code_postal": "6220", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COLFONTAINE", "name": "Warquignies", "code_postal": "7340", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Wattripont", "code_postal": "7910", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HAACHT", "name": "Wespelaar", "code_postal": "3150", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "HOOGSTRATEN", "name": "Wortel", "code_postal": "2323", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Zwijnaarde", "code_postal": "9052", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NASSOGNE", "name": "Ambly", "code_postal": "6953", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "ANTWERPEN", "code_postal": "2060", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GREZ-DOICEAU", "name": "Archennes", "code_postal": "1390", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BERTRIX", "name": "Auby-Sur-Semois", "code_postal": "6880", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SOUMAGNE", "name": "Ayeneux", "code_postal": "4630", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "AALST", "name": "Baardegem", "code_postal": "9310", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ERPE-MERE", "name": "Bambrugge", "code_postal": "9420", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOMIGNIES", "name": "Beauwelz", "code_postal": "6594", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Beez", "code_postal": "5000", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OREYE", "name": "Bergilers", "code_postal": "4360", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BERTRIX", "name": "BERTRIX", "code_postal": "6880", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "BEYNE-HEUSAY", "name": "BEYNE-HEUSAY", "code_postal": "4610", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "WAVRE", "name": "Bierges", "code_postal": "1301", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BOOM", "name": "BOOM", "code_postal": "2850", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHÂTELET", "name": "Bouffioulx", "code_postal": "6200", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "Bouvignes-Sur-Meuse", "code_postal": "5500", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUGELETTE", "name": "Cambron-Casteau", "code_postal": "7940", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "CINEY", "name": "Chevetogne", "code_postal": "5590", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COMINES-WARNETON", "name": "COMINES-WARNETON", "code_postal": "7780", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Cras-Avernas", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Dave", "code_postal": "5100", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DESTELBERGEN", "name": "DESTELBERGEN", "code_postal": "9070", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTAIMPUIS", "name": "Estaimbourg", "code_postal": "7730", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "OUDENBURG", "name": "Ettelgem", "code_postal": "8460", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "FERNELMONT", "code_postal": "5380", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉMALLE", "name": "Flémalle-Grande", "code_postal": "4400", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FLOBECQ", "name": "FLOBECQ", "code_postal": "7880", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "ASSESSE", "name": "Florée", "code_postal": "5334", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Froyennes", "code_postal": "7503", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BRAIVES", "name": "Fumal", "code_postal": "4260", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ATH", "name": "Gibecq", "code_postal": "7823", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WAREMME", "name": "Grand-Axhe", "code_postal": "4300", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "OUDSBERGEN", "name": "Gruitrode", "code_postal": "3670", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ARLON", "name": "Guirsch", "code_postal": "6704", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "Han-Sur-Lesse", "code_postal": "5580", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTEMARK", "name": "Handzame", "code_postal": "8610", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AYWAILLE", "name": "Harzé", "code_postal": "4920", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HERNE", "name": "Herfelingen", "code_postal": "1540", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LA CALAMINE", "name": "Hergenrath", "code_postal": "4728", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HOOGLEDE", "name": "HOOGLEDE", "code_postal": "8830", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "OUPEYE", "name": "Houtain-Saint-Siméon", "code_postal": "4682", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LAARNE", "name": "Kalken", "code_postal": "9270", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "KOEKELBERG", "name": "KOEKELBERG", "code_postal": "1081", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "JUPRELLE", "name": "Lantin", "code_postal": "4450", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BRAIVES", "name": "Latinne", "code_postal": "4261", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HÉRON", "name": "Lavoir", "code_postal": "4217", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTAIMPUIS", "name": "Leers-Nord", "code_postal": "7730", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MIDDELKERKE", "name": "Leffinge", "code_postal": "8432", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "LIER", "name": "LIER", "code_postal": "2500", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LONDERZEEL", "name": "Malderen", "code_postal": "1840", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MECHELEN", "name": "MECHELEN", "code_postal": "2800", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEERS", "name": "Mechelen-Bovelingen", "code_postal": "3870", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ERPE-MERE", "name": "Mere", "code_postal": "9420", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SOUMAGNE", "name": "Micheroux", "code_postal": "4630", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINT-HUBERT", "name": "Mirwart", "code_postal": "6870", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Mopertingen", "code_postal": "3740", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MORLANWELZ", "name": "Morlanwelz-Mariemont", "code_postal": "7140", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BLÉGNY", "name": "Mortier", "code_postal": "4670", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "OUDSBERGEN", "name": "Neerglabbeek", "code_postal": "3670", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "NEUFCHÂTEAU", "name": "NEUFCHÂTEAU", "code_postal": "6840", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "NIJLEN", "name": "NIJLEN", "code_postal": "2560", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRAMERIES", "name": "Noirchain", "code_postal": "7080", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Nouvelles", "code_postal": "7022", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "PALISEUL", "name": "Offagne", "code_postal": "6850", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "JEMEPPE-SUR-SAMBRE", "name": "Onoz", "code_postal": "5190", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "SINT-KATELIJNE-WAVER", "name": "Onze-Lieve-Vrouw-Waver", "code_postal": "2861", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "OUDSBERGEN", "name": "Opglabbeek", "code_postal": "3660", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "OVERIJSE", "name": "OVERIJSE", "code_postal": "3090", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BRAKEL", "name": "Parike", "code_postal": "9661", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "PERWEZ", "name": "PERWEZ", "code_postal": "1360", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "VILVOORDE", "name": "Peutie", "code_postal": "1800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FERNELMONT", "name": "Pontillas", "code_postal": "5380", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LIBIN", "name": "Redu", "code_postal": "6890", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "RENDEUX", "name": "RENDEUX", "code_postal": "6987", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "WAIMES", "name": "Robertville", "code_postal": "4950", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "Roisin", "code_postal": "7387", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "RIXENSART", "name": "Rosières", "code_postal": "1331", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "CHASTRE", "name": "Saint-Géry", "code_postal": "1450", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SAINT-GHISLAIN", "name": "SAINT-GHISLAIN", "code_postal": "7330", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DAMME", "name": "Sijsele", "code_postal": "8340", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "NIEUWPOORT", "name": "Sint-Joris", "code_postal": "8620", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SAINT-GHISLAIN", "name": "Sirault", "code_postal": "7332", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "STADEN", "name": "STADEN", "code_postal": "8840", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "SAMBREVILLE", "name": "Tamines", "code_postal": "5060", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SAINT-GHISLAIN", "name": "Tertre", "code_postal": "7333", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "name": "Testelt", "code_postal": "3272", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANDENNE", "name": "Thon", "code_postal": "5300", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "Thynes", "code_postal": "5502", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DOISCHE", "name": "Vaucelles", "code_postal": "5680", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINT-HUBERT", "name": "Vesqueville", "code_postal": "6870", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FAIMES", "name": "Viemme", "code_postal": "4317", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Vladslo", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANHÉE", "name": "Warnant", "code_postal": "5537", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WETTEREN", "name": "Westrem", "code_postal": "9230", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "WILLEBROEK", "name": "WILLEBROEK", "code_postal": "2830", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "VLETEREN", "name": "Woesten", "code_postal": "8640", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BRAKEL", "name": "Zegelsem", "code_postal": "9660", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SAINT-VITH", "name": "Recht", "code_postal": "4780", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "FOURONS", "name": "Remersdaal", "code_postal": "3791", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RIJKEVORSEL", "name": "RIJKEVORSEL", "code_postal": "2310", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GEETBETS", "name": "Rummen", "code_postal": "3454", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "KRUIBEKE", "name": "Rupelmonde", "code_postal": "9150", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "DILBEEK", "name": "Schepdaal", "code_postal": "1703", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "name": "Scherpenheuvel", "code_postal": "3270", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MUSSON", "name": "Signeulx", "code_postal": "6750", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "Sint-Goriks-Oudenhove", "code_postal": "9620", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DINANT", "name": "Sorinnes", "code_postal": "5503", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VERVIERS", "name": "Stembert", "code_postal": "4801", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Stuivekenskerke", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TERNAT", "name": "TERNAT", "code_postal": "1740", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "THEUX", "name": "THEUX", "code_postal": "4910", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "PONT-À-CELLES", "name": "Thiméon", "code_postal": "6230", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LEUZE-EN-HAINAUT", "name": "Tourpes", "code_postal": "7904", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COURCELLES", "name": "Trazegnies", "code_postal": "6183", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MANHAY", "name": "Vaux-Chavanne", "code_postal": "6960", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "FLORENVILLE", "name": "Villers-Devant-Orval", "code_postal": "6823", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "LA BRUYÈRE", "name": "Villers-Lez-Heest", "code_postal": "5080", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIEVEGEM", "name": "Vinderhoute", "code_postal": "9921", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "VORSELAAR", "name": "VORSELAAR", "code_postal": "2290", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "Vorsen", "code_postal": "3890", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SOMME-LEUZE", "name": "Waillet", "code_postal": "5377", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Wancennes", "code_postal": "5570", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Wansin", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PECQ", "name": "Warcoing", "code_postal": "7740", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTEMARK", "name": "Werken", "code_postal": "8610", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "WEVELGEM", "name": "WEVELGEM", "code_postal": "8560", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "HEIST-OP-DEN-BERG", "name": "Wiekevorst", "code_postal": "2222", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BEAURAING", "name": "Wiesme", "code_postal": "5571", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BOUTERSEM", "name": "Willebringen", "code_postal": "3370", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Willemeau", "code_postal": "7506", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "WOLUWE-SAINT-PIERRE", "name": "WOLUWE-SAINT-PIERRE", "code_postal": "1150", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "Wondelgem", "code_postal": "9032", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ANS", "name": "Xhendremael", "code_postal": "4432", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "ZUTENDAAL", "name": "ZUTENDAAL", "code_postal": "3690", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "ZWIJNDRECHT", "name": "ZWIJNDRECHT", "code_postal": "2070", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "TINLOT", "name": "Abée", "code_postal": "4557", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "GERPINNES", "name": "Acoz", "code_postal": "6280", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MALDEGEM", "name": "Adegem", "code_postal": "9991", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ANTWERPEN", "name": "ANTWERPEN", "code_postal": "2020", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "SAMBREVILLE", "name": "Auvelais", "code_postal": "5060", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SILLY", "name": "Bassilly", "code_postal": "7830", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "PÉRUWELZ", "name": "Baugnies", "code_postal": "7604", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LOCHRISTI", "name": "Beervelde", "code_postal": "9080", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BLANKENBERGE", "name": "BLANKENBERGE", "code_postal": "8370", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "ARLON", "name": "Bonnert", "code_postal": "6700", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIENEN", "name": "Bost", "code_postal": "3300", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BRAKEL", "name": "BRAKEL", "code_postal": "9660", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRUGELETTE", "name": "BRUGELETTE", "code_postal": "7940", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "ZWIJNDRECHT", "name": "Burcht", "code_postal": "2070", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "CHIMAY", "code_postal": "6460", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "CINEY", "name": "CINEY", "code_postal": "5590", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "LASNE", "name": "Couture-Saint-Germain", "code_postal": "1380", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Crehen", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ASSESSE", "name": "Crupet", "code_postal": "5332", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LÉGLISE", "name": "Ebly", "code_postal": "6860", "sous_commune": true },
  { "type": "L", "province": "Bruxelles (19 communes)", "commune_principale": "EVERE", "name": "EVERE", "code_postal": "1140", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "EVERGEM", "name": "EVERGEM", "code_postal": "9940", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "TROOZ", "name": "Fraipont", "code_postal": "4870", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "INCOURT", "name": "Glimes", "code_postal": "1315", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BILZEN", "name": "Grote-Spouwen", "code_postal": "3740", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Halmaal", "code_postal": "3800", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "JEMEPPE-SUR-SAMBRE", "name": "Ham-Sur-Sambre", "code_postal": "5190", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HASSELT", "name": "HASSELT", "code_postal": "3500", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZOUTLEEUW", "name": "Helen-Bos", "code_postal": "3440", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "BRAINE-LE-COMTE", "name": "Henripont", "code_postal": "7090", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HERBEUMONT", "name": "HERBEUMONT", "code_postal": "6887", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "HERENTHOUT", "name": "HERENTHOUT", "code_postal": "2270", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LA ROCHE-EN-ARDENNE", "name": "Hives", "code_postal": "6984", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Hoepertingen", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "HONNELLES", "code_postal": "7387", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "GRÂCE-HOLLOGNE", "name": "Horion-Hozémont", "code_postal": "4460", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HOUTHULST", "name": "HOUTHULST", "code_postal": "8650", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "HAM-SUR-HEURE", "name": "Jamioulx", "code_postal": "6120", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "JODOIGNE", "name": "JODOIGNE", "code_postal": "1370", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ASSE", "name": "Kobbegem", "code_postal": "1730", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KOKSIJDE", "name": "KOKSIJDE", "code_postal": "8670", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTRIJK", "name": "KORTRIJK", "code_postal": "8500", "sous_commune": false },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Kuttekoven", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Landegem", "code_postal": "9850", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FOSSES-LA-VILLE", "name": "Le Roux", "code_postal": "5070", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "LILLE", "name": "LILLE", "code_postal": "2275", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "LINCENT", "name": "LINCENT", "code_postal": "4287", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HULDENBERG", "name": "Loonbeek", "code_postal": "3040", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "MACHELEN", "name": "MACHELEN", "code_postal": "1830", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "ANDENNE", "name": "Maizeret", "code_postal": "5300", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MANHAY", "name": "MANHAY", "code_postal": "6960", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "HOUTHULST", "name": "Merkem", "code_postal": "8650", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DENDERMONDE", "name": "Mespelare", "code_postal": "9200", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "HOUFFALIZE", "name": "Mont", "code_postal": "6661", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Moustier", "code_postal": "7911", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GESVES", "name": "Mozet", "code_postal": "5340", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DOISCHE", "name": "Niverlée", "code_postal": "5680", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OREYE", "name": "OREYE", "code_postal": "4360", "sous_commune": false },
  { "type": "L", "province": "Anvers", "commune_principale": "OUD-TURNHOUT", "name": "OUD-TURNHOUT", "code_postal": "2360", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "SINT-PIETERS-LEEUW", "name": "Oudenaken", "code_postal": "1600", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BIÈVRE", "name": "Petit-Fays", "code_postal": "5555", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Petit-Hallet", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LEUZE-EN-HAINAUT", "name": "Pipaix", "code_postal": "7904", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "PLOMBIÈRES", "name": "PLOMBIÈRES", "code_postal": "4850", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZEDELGEM", "name": "Aartrijke", "code_postal": "8211", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "Angreau", "code_postal": "7387", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ANS", "name": "ANS", "code_postal": "4430", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONT-DE-L'ENCLUS", "name": "Anseroeul", "code_postal": "7750", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "FRASNES-LEZ-ANVAING", "name": "Anvaing", "code_postal": "7910", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BRUGGE", "name": "Assebroek", "code_postal": "8310", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉMALLE", "name": "Awirs", "code_postal": "4400", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BASSENGE", "name": "BASSENGE", "code_postal": "4690", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "CHAUDFONTAINE", "name": "Beaufays", "code_postal": "4052", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BEYNE-HEUSAY", "name": "Bellaire", "code_postal": "4610", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MANAGE", "name": "Bellecourt", "code_postal": "7170", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KAMPENHOUT", "name": "Berg", "code_postal": "1910", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZONNEBEKE", "name": "Beselare", "code_postal": "8980", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "METTET", "name": "Biesme", "code_postal": "5640", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SERAING", "name": "Boncelles", "code_postal": "4100", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANDENNE", "name": "Bonneville", "code_postal": "5300", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Bourlers", "code_postal": "6464", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ANTOING", "name": "Bruyelle", "code_postal": "7641", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "Buvingen", "code_postal": "3891", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "CHAUMONT-GISTOUX", "name": "CHAUMONT-GISTOUX", "code_postal": "1325", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Ciply", "code_postal": "7024", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "CLAVIER", "name": "CLAVIER", "code_postal": "4560", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "CHAUMONT-GISTOUX", "name": "Corroy-Le-Grand", "code_postal": "1325", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "DAVERDISSE", "name": "DAVERDISSE", "code_postal": "6929", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "NINOVE", "name": "Denderwindeke", "code_postal": "9400", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "DESSEL", "name": "DESSEL", "code_postal": "2480", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BOUILLON", "name": "Dohan", "code_postal": "6836", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "DONCEEL", "name": "DONCEEL", "code_postal": "4357", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Driekapellen", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "EDEGEM", "name": "EDEGEM", "code_postal": "2650", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VIRTON", "name": "Ethe", "code_postal": "6760", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "BRAKEL", "name": "Everbeek", "code_postal": "9660", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Flénu", "code_postal": "7012", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FLOREFFE", "name": "Floriffoux", "code_postal": "5150", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "ORP-JAUCHE", "name": "Folx-Les-Caves", "code_postal": "1350", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MOMIGNIES", "name": "Forge-Philippe", "code_postal": "6596", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LES BONS VILLERS", "name": "Frasnes-Lez-Gosselies", "code_postal": "6210", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GENT", "name": "GENT", "code_postal": "9000", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "SIVRY-RANCE", "name": "Grandrieu", "code_postal": "6470", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OUPEYE", "name": "Haccourt", "code_postal": "4684", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SOMME-LEUZE", "name": "Hogne", "code_postal": "5377", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "EVERGEM", "name": "Kluizen", "code_postal": "9940", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "TIENEN", "name": "Kumtich", "code_postal": "3300", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "DILSEN-STOKKEM", "name": "Lanklaar", "code_postal": "3650", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIERDE", "name": "LIERDE", "code_postal": "9570", "sous_commune": false },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "BRAINE-L'ALLEUD", "name": "Lillois-Witterzée", "code_postal": "1428", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "GEDINNE", "name": "Louette-Saint-Pierre", "code_postal": "5575", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LUBBEEK", "name": "LUBBEEK", "code_postal": "3210", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Marquain", "code_postal": "7522", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "DOISCHE", "name": "Matagne-La-Grande", "code_postal": "5680", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "RIEMST", "name": "Millen", "code_postal": "3770", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BIÈVRE", "name": "Monceau-En-Ardenne", "code_postal": "5555", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "BIÈVRE", "name": "Naomé", "code_postal": "5555", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "PELT", "name": "Neerpelt", "code_postal": "3910", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "LA CALAMINE", "name": "Neu-Moresnet", "code_postal": "4721", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "MAARKEDAL", "name": "Nukerke", "code_postal": "9681", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ALVERINGEM", "name": "Oeren", "code_postal": "8690", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "TUBIZE", "name": "Oisquercq", "code_postal": "1480", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "ZOTTEGEM", "name": "Oombergen", "code_postal": "9620", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Ophasselt", "code_postal": "9500", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "SINT-TRUIDEN", "name": "Ordingen", "code_postal": "3800", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "PEPINSTER", "name": "PEPINSTER", "code_postal": "4860", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DIKSMUIDE", "name": "Pervijze", "code_postal": "8600", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "LASNE", "name": "Plancenoit", "code_postal": "1380", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "LILLE", "name": "Poederlee", "code_postal": "2275", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "AISEAU-PRESLES", "name": "Pont-De-Loup", "code_postal": "6250", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "ZANDHOVEN", "name": "Pulle", "code_postal": "2243", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "POPERINGE", "name": "Reningelst", "code_postal": "8970", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "RETIE", "name": "RETIE", "code_postal": "2470", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Rognée", "code_postal": "5651", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KORTRIJK", "name": "Rollegem", "code_postal": "8510", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SAINT-GEORGES-SUR-MEUSE", "name": "SAINT-GEORGES-SUR-MEUSE", "code_postal": "4470", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "LOBBES", "name": "Sars-La-Buissière", "code_postal": "6542", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SAINT-VITH", "name": "Schoenberg", "code_postal": "4782", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MIDDELKERKE", "name": "Schore", "code_postal": "8433", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SOMME-LEUZE", "name": "Sinsin", "code_postal": "5377", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "PELT", "name": "Sint-Huibrechts-Lille", "code_postal": "3910", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIERDE", "name": "Sint-Maria-Lierde", "code_postal": "9570", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Smeerebbe-Vloerzegem", "code_postal": "9506", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "PEPINSTER", "name": "Soiron", "code_postal": "4861", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SOMBREFFE", "name": "SOMBREFFE", "code_postal": "5140", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "TEMSE", "name": "Steendorp", "code_postal": "9140", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ROOSDAAL", "name": "Strijtem", "code_postal": "1760", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Templeuve", "code_postal": "7520", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BLÉGNY", "name": "Trembleur", "code_postal": "4670", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "MODAVE", "name": "Vierset-Barse", "code_postal": "4577", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IEPER", "name": "Vlamertinge", "code_postal": "8908", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "VOSSELAAR", "name": "VOSSELAAR", "code_postal": "2350", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "COMINES-WARNETON", "name": "Warneton", "code_postal": "7784", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ROTSELAAR", "name": "Werchter", "code_postal": "3118", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Wierde", "code_postal": "5100", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "LEUZE-EN-HAINAUT", "name": "Willaupuis", "code_postal": "7904", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "LÉGLISE", "name": "Witry", "code_postal": "6860", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "BASSENGE", "name": "Wonck", "code_postal": "4690", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MARTELANGE", "name": "MARTELANGE", "code_postal": "6630", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "MERCHTEM", "name": "MERCHTEM", "code_postal": "1785", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "GERAARDSBERGEN", "name": "Moerbeke", "code_postal": "9500", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "NIVELLES", "name": "Monstreux", "code_postal": "1400", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "YVOIR", "name": "Mont", "code_postal": "5530", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "HONNELLES", "name": "Montignies-Sur-Roc", "code_postal": "7387", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OOSTERZELE", "name": "Moortsele", "code_postal": "9860", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "MORTSEL", "name": "MORTSEL", "code_postal": "2640", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Nederename", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "SOMME-LEUZE", "name": "Nettinne", "code_postal": "5377", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "GINGELOM", "name": "Niel-Bij-Sint-Truiden", "code_postal": "3890", "sous_commune": true },
  { "type": "L", "province": "Anvers", "commune_principale": "BALEN", "name": "Olmen", "code_postal": "2491", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "KOKSIJDE", "name": "Oostduinkerke", "code_postal": "8670", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ZONNEBEKE", "name": "Passendale", "code_postal": "8980", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "PHILIPPEVILLE", "name": "PHILIPPEVILLE", "code_postal": "5600", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHAPELLE-LEZ-HERLAIMONT", "name": "Piéton", "code_postal": "7160", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COMINES-WARNETON", "name": "Ploegsteert", "code_postal": "7782", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "BORGLOON", "name": "Rijkel", "code_postal": "3840", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "FLORENNES", "name": "Rosée", "code_postal": "5620", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Saint-Denis", "code_postal": "7034", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Saint-Marc", "code_postal": "5003", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Salles", "code_postal": "6460", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAMOIS", "name": "Schaltin", "code_postal": "5364", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "SERAING", "name": "SERAING", "code_postal": "4100", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "SINT-MARTENS-LATEM", "name": "SINT-MARTENS-LATEM", "code_postal": "9830", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZAVENTEM", "name": "Sint-Stevens-Woluwe", "code_postal": "1932", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "Somzée", "code_postal": "5651", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SOIGNIES", "name": "Thieusies", "code_postal": "7061", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "THIMISTER-CLERMONT", "name": "THIMISTER-CLERMONT", "code_postal": "4890", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "SOMBREFFE", "name": "Tongrinne", "code_postal": "5140", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "HANNUT", "name": "Trognée", "code_postal": "4280", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FEXHE-LE-HAUT-CLOCHER", "name": "Voroux-Goreux", "code_postal": "4347", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "RIEMST", "name": "Vroenhoven", "code_postal": "3770", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Waasmont", "code_postal": "3401", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "WALCOURT", "name": "WALCOURT", "code_postal": "5650", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "ROCHEFORT", "name": "Wavreille", "code_postal": "5580", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "WELKENRAEDT", "name": "WELKENRAEDT", "code_postal": "4840", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "HERENT", "name": "Winksele", "code_postal": "3020", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Wontergem", "code_postal": "9800", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "WORTEGEM-PETEGEM", "name": "WORTEGEM-PETEGEM", "code_postal": "9790", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "FERRIÈRES", "name": "Xhoris", "code_postal": "4190", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "BRUGGE", "name": "Zeebrugge", "code_postal": "8380", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "RIEMST", "name": "Zichen-Zussen-Bolder", "code_postal": "3770", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "TONGEREN", "name": "'S Herenelderen", "code_postal": "3700", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "ANS", "name": "Alleur", "code_postal": "4432", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "ARDOOIE", "name": "ARDOOIE", "code_postal": "8850", "sous_commune": false },
  { "type": "L", "province": "Luxembourg", "commune_principale": "BASTOGNE", "name": "BASTOGNE", "code_postal": "6600", "sous_commune": false },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Belgrade", "code_postal": "5001", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BIERBEEK", "name": "BIERBEEK", "code_postal": "3360", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "BIÉVÈNE", "name": "BIÉVÈNE", "code_postal": "1547", "sous_commune": false },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "LANGEMARK-POELKAPELLE", "name": "Bikschote", "code_postal": "8920", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "COMINES-WARNETON", "name": "Bizet", "code_postal": "7783", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "WELLIN", "name": "Chanly", "code_postal": "6921", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "TOURNAI", "name": "Chercq", "code_postal": "7521", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "FLÉMALLE", "name": "Chokier", "code_postal": "4400", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "LIERDE", "name": "Deftinge", "code_postal": "9570", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "DENTERGEM", "name": "DENTERGEM", "code_postal": "8720", "sous_commune": false },
  { "type": "L", "province": "Hainaut", "commune_principale": "JURBISE", "name": "Erbisoeul", "code_postal": "7050", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "LANDEN", "name": "Ezemaal", "code_postal": "3400", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "ESTINNES", "name": "Fauroeulx", "code_postal": "7120", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "HAVELANGE", "name": "Flostoy", "code_postal": "5370", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "PALISEUL", "name": "Framont", "code_postal": "6853", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "AARSCHOT", "name": "Gelrode", "code_postal": "3200", "sous_commune": true },
  { "type": "L", "province": "Brabant Wallon", "commune_principale": "GENAPPE", "name": "GENAPPE", "code_postal": "1470", "sous_commune": false },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "DEINZE", "name": "Gottem", "code_postal": "9800", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "GRIMBERGEN", "name": "GRIMBERGEN", "code_postal": "1850", "sous_commune": false },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "ZOUTLEEUW", "name": "Halle-Booienhoven", "code_postal": "3440", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "MARCHE-EN-FAMENNE", "name": "Hargimont", "code_postal": "6900", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "MONS", "name": "Harmignies", "code_postal": "7022", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "SAINT-HUBERT", "name": "Hatrival", "code_postal": "6870", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HECHTEL-EKSEL", "name": "Hechtel", "code_postal": "3940", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "OUPEYE", "name": "Hermée", "code_postal": "4680", "sous_commune": true },
  { "type": "L", "province": "Limbourg", "commune_principale": "HEUSDEN-ZOLDER", "name": "Heusden", "code_postal": "3550", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "AWANS", "name": "Hognoul", "code_postal": "4342", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "SOIGNIES", "name": "Horrues", "code_postal": "7060", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "JALHAY", "name": "JALHAY", "code_postal": "4845", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "DONCEEL", "name": "Jeneffe", "code_postal": "4357", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "IZEGEM", "name": "Kachtem", "code_postal": "8870", "sous_commune": true },
  { "type": "L", "province": "Brabant Flamand", "commune_principale": "KAPELLE-OP-DEN-BOS", "name": "KAPELLE-OP-DEN-BOS", "code_postal": "1880", "sous_commune": false },
  { "type": "L", "province": "Liège", "commune_principale": "CRISNÉE", "name": "Kemexhe", "code_postal": "4367", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "HAALTERT", "name": "Kerksken", "code_postal": "9451", "sous_commune": true },
  { "type": "L", "province": "Liège", "commune_principale": "VISÉ", "name": "Lanaye", "code_postal": "4600", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "ANDENNE", "name": "Landenne", "code_postal": "5300", "sous_commune": true },
  { "type": "L", "province": "Luxembourg", "commune_principale": "VIRTON", "name": "Latour", "code_postal": "6761", "sous_commune": true },
  { "type": "L", "province": "Flandre-Orientale", "commune_principale": "OUDENAARDE", "name": "Leupegem", "code_postal": "9700", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Leuze", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "EGHEZÉE", "name": "Liernu", "code_postal": "5310", "sous_commune": true },
  { "type": "L", "province": "Hainaut", "commune_principale": "CHIMAY", "name": "Lompret", "code_postal": "6463", "sous_commune": true },
  { "type": "L", "province": "Namur", "commune_principale": "NAMUR", "name": "Malonne", "code_postal": "5020", "sous_commune": true },
  { "type": "L", "province": "Flandre-Occidentale", "commune_principale": "MIDDELKERKE", "name": "Mannekensvere", "code_postal": "8433", "sous_commune": true },
];

export default zipCodes;