import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Paper,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { TranslationContext } from 'contexts/TranslationContext';
import { getUsersActions } from 'myApi';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import FeedbackIcon from '@material-ui/icons/Feedback';

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  red: {
    backgroundColor: '#f4f6f8',
    color: '#f44336'
  },
  green: {
    backgroundColor: '#f4f6f8',
    color: '#4caf50'
  },
  blue: {
    backgroundColor: '#f4f6f8',
    color: '#2196f3'
  },
  yellow: {
    backgroundColor: '#f4f6f8',
    color: '#ff9800'
  },
  textField: {
    marginBottom: '15px'
  },
  ...theme.common
}));

const getActionIcon = state => {
  switch (state) {
    case 0:
      return <EventAvailableIcon htmlColor="green" />;
    case 1:
      return <PhoneMissedIcon htmlColor="orange" />;
    case 2:
      return <NotInterestedIcon htmlColor="red" />;
    case 3:
      return <VpnKeyIcon htmlColor="red" />;
    case 4:
      return <CloudOffIcon htmlColor="red" />;
    case 5:
      return <FeedbackIcon htmlColor="orange" />;
    case 6:
      return <ThumbDownIcon htmlColor="red" />;
    default:
      break;
  }
};

// eslint-disable-next-line
const UsersActions = ({ users }) => {
  const classes = useStyles();
  const { t } = React.useContext(TranslationContext);

  const [skip, setSkip] = React.useState(0);
  // This will contain the id of the user we want to see the actions of
  const [userId, setUserId] = React.useState(null);
  const [hasMoreToLoad, setHasMoreToLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [histories, setHistories] = React.useState([]);

  const getMoreData = (sk, hist) => {
    setLoading(true);
    getUsersActions(userId, sk).then(response => {
      const actions = response.data.userActions;

      setSkip(sk + 10);
      setHistories(hist.concat(actions));

      setLoading(false);

      setHasMoreToLoad(!actions.length < 10);
    });
  };

  useEffect(() => {
    getMoreData(0, []);
  }, [userId]);

  const onClick = () => {
    getMoreData(skip, [...histories]);
  };

  const getColor = state => {
    switch (state) {
      case 0: // RDV
        return classes.green;
      case 1: // No response
      case 5: // Client not intereseted
      case 6: // Property not interesting
        return classes.yellow;
      case 2: // Already in agency
      case 3: // Already sold
      case 4: // Not available
        return classes.red;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader
          avatar={<Avatar className={classes.avatar}>C</Avatar>}
          title="Les dernieres actions"
          subheader="Ci-dessous la liste des dernieres actions sur l'application"
        />
        <CardContent>
          <TextField
            className={classes.textField}
            fullWidth
            label="Filtrer sur utilisateur"
            onChange={e => setUserId(e.target.value)}
            select
            value={userId}
            variant="outlined"
          >
            {users.map(usr => (
              <MenuItem key={usr.id} value={usr.id}>
                {usr.name} ({usr.email})
              </MenuItem>
            ))}
          </TextField>
          <Timeline align="left">
            {histories.map(element => (
              <TimelineItem key={element.id}>
                <TimelineSeparator>
                  <TimelineDot
                    className={getColor(element.state)}
                    color="inherit"
                  >
                    {getActionIcon(element.state)}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper}>
                    <Grid container direction="row" justify="space-between">
                      <Grid>
                        <Typography variant="h6" component="h1">
                          {element.userName}
                        </Typography>
                        <Typography>
                          {t('user-action-property-state-' + element.state)}
                        </Typography>
                      </Grid>
                      <Grid>{moment(element.dateCreated + 'Z').fromNow()}</Grid>
                    </Grid>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
            {loading && <CircularProgress />}
            {!loading && (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot className={classes.red} color="inherit" />
                </TimelineSeparator>
              </TimelineItem>
            )}
          </Timeline>
        </CardContent>
        <CardActions disableSpacing>
          {hasMoreToLoad && (
            <Button color="primary" aria-label="save" onClick={onClick}>
              Voir plus
            </Button>
          )}
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default UsersActions;
