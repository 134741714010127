import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AuthContext } from 'contexts/AuthContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    overflow: 'hidden',
    borderRadius: '50%',
    animation: 'fa-spin 2s linear infinite'
  },
  avatar: {
    width: 260,
    height: 260
  },
  name: {
    marginTop: theme.spacing(1)
  },
  center: {
    position: 'fixed',
    zIndex: '9999',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    borderRadius: '50%',
  },
  overlay: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 3,
    cursor: "pointer",
  }
}));

export default function TableLoader({ hidden }) {
  const classes = useStyles();

  const { myUserImage } = React.useContext(AuthContext);

  return (
    <div className={classes.overlay} hidden={hidden}>
      <img alt="loader" className={clsx("fa-spin", classes.center)} src={myUserImage} />
    </div>
      
  );
}