import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ClientNotInterestedRadioGroup, ClientNotInterestedClientForm } from './components';

const ClientNotInterestedSubForm = ({ updateProp }) => {
  const [value, setValue] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const onChange = (_, newValue) => {
    setValue(parseInt(newValue));
    updateProp("reasonClientNotInterested", parseInt(newValue));
  };

  const otherOnChange = (otherReason) => updateProp("clientNotInterestedOtherReason", otherReason);

  const goNextStep = () => {
    if (value) {
      setIndex(index + 1);
    }
  };

  return (
    <Grid item container xs={12}>
      {
        index === 0 
        ? <ClientNotInterestedRadioGroup value={value} onChange={onChange} otherOnChange={otherOnChange} goNextStep={goNextStep} />
        : index === 1
        ? <ClientNotInterestedClientForm updateProp={updateProp} />
        : "Something went wrong"
      }
    </Grid>
  );
};

export default ClientNotInterestedSubForm;