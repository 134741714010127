import React from 'react';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import { addNewCompany } from 'myApi';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.error.dark,
  },
  buttonSuccess: {
    marginLeft: 'auto',
    color: theme.palette.success.main
  },
  saveButton: {
    marginLeft: 'auto',
  },
  textField: {
    marginTop: "12px"
  }
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'est obligatoire' },
  },
  email: {
    presence: { allowEmpty: false, message: 'est obligatoire' },
    email: true,
    length: {
      maximum: 64
    }
  }
};

const AddCompanyForm = () => {
  const classes = useStyles();

  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formState, setFormState] = React.useState({
    isValid: false,
    loading: false,
    values: {},
    touched: {},
    errors: {}
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const onSubmit = e => {
    e.preventDefault();

    const { name, email } = formState.values;

    setLoading(true);
    setSuccess(false);
    addNewCompany(name, email)
      .then(() => {
        setSuccess(true);
      })
      .catch(_ => {
        alert("Error. try again later.");
      })
      .finally(_ => {
        setLoading(false);
      });
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] && formState.errors[field].length > 0;
  };

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader
          avatar={
          <Avatar className={classes.avatar}>
            C
          </Avatar>
          }
          title="Ajouter une nouvelle agence"
          subheader="Créer une nouvelle agence pour leur donner accès"
        />
        <CardContent>
          <TextField
            required
            className={classes.textField}
            error={hasError('name')}
            fullWidth
            helperText={
              hasError('name') ? formState.errors.name[0] : ''
            }
            label="Nom de l'agence"
            name="name"
            onChange={handleChange}
            type="text"
            value={formState.values.name || ''}
            variant="outlined"
          />
          <TextField
            required
            className={classes.textField}
            error={hasError('email')}
            fullWidth
            helperText={
              hasError('email') ? formState.errors.email[0] : ''
            }
            label="Adresse email"
            name="email"
            onChange={handleChange}
            type="text"
            value={formState.values.email || ''}
            variant="outlined"
          />
        </CardContent>
        <CardActions disableSpacing>
          <Button
            color="primary"
            className={success === true ? classes.buttonSuccess : classes.saveButton}
            aria-label="save"
            type="submit"
          >
            {success ? "Successfully Saved" : "Save"}
            &nbsp;{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default AddCompanyForm;