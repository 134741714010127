import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { ConnectAuthContext } from 'contexts/AuthContext';
import { updateMyUserInfo } from 'myApi';
import { TranslationContext } from 'contexts/TranslationContext';

const useStyles = makeStyles(theme => ({
  buttonSuccess: {
    backgroundColor: theme.palette.success.main
  }
}));

const AccountDetails = props => {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);

  const { authContext, className, ...rest } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [values, setValues] = useState({
    address: authContext.myUserInfo?.address ?? '',
    city: authContext.myUserInfo?.city ?? '',
    language: authContext.myUserInfo?.language ?? 'fr',
    phoneNumber: authContext.myUserInfo?.phoneNumber ?? '',
    vAT: authContext.myUserInfo?.vAT ?? '',
    zipCode: authContext.myUserInfo?.zipCode ?? false,
    userIdentifier: authContext.myUserInfo?.userIdentifier ?? '',
    clientType: authContext.myUserInfo?.clientType ?? 0
  });

  useEffect(() => {
    setValues({
      address: authContext.myUserInfo?.address ?? '',
      city: authContext.myUserInfo?.city ?? '',
      language: authContext.myUserInfo?.language ?? 'fr',
      phoneNumber: authContext.myUserInfo?.phoneNumber ?? '',
      vAT: authContext.myUserInfo?.vAT ?? '',
      zipCode: authContext.myUserInfo?.zipCode ?? false,
      userIdentifier: authContext.myUserInfo?.userIdentifier ?? '',
      clientType: authContext.myUserInfo?.clientType ?? 0
    });
  }, [authContext]);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSubmit = e => {
    e.preventDefault();

    setLoading(true);
    updateMyUserInfo({
      ...values,
      id: authContext.myUserInfo.id
    }).then(_ => {
      setSuccess(true);
      setLoading(false);
    });
  };

  const updateClientType = newValue => {
    if (newValue !== values.clientType) {
      setValues({ ...values, clientType: newValue, userIdentifier: '' });
    }
  };

  const languages = [
    {
      value: 'fr',
      label: t('profile-lang-fr')
    },
    {
      value: 'nl',
      label: t('profile-lang-nl')
    }
  ];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" onSubmit={onSubmit}>
        <CardHeader
          subheader={t('profile-informations-subtitle')}
          title={t('profile-informations-title')}
          action={
            <React.Fragment>
              <Button
                color="primary"
                variant={values.clientType === 0 ? 'contained' : 'outlined'}
                onClick={() => updateClientType(0)}
              >
                {t('profile-button-i-am-an-agent')}
              </Button>
              <Button
                color="primary"
                variant={values.clientType === 1 ? 'contained' : 'outlined'}
                onClick={() => updateClientType(1)}
              >
                {t('profile-button-i-am-an-certificator')}
              </Button>
            </React.Fragment>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <TextField
                disabled={!authContext.myUserInfo}
                fullWidth
                label={t('profile-choose-lang')}
                margin="dense"
                name="state"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.language}
                variant="outlined"
              >
                {languages.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={!authContext.myUserInfo}
                fullWidth
                label={
                  values.clientType === 0
                    ? t('profile-input-ipi-nbr')
                    : t('profile-input-peb-cert-nbr')
                }
                margin="dense"
                name="userIdentifier"
                onChange={handleChange}
                value={values.userIdentifier}
                variant="outlined"
              />
            </Grid>
            <Box width="100%" />
            <Grid item md={6} xs={12}>
              <TextField
                disabled={true}
                fullWidth
                label={t('profile-input-vat')}
                margin="dense"
                name="vAT"
                onChange={handleChange}
                value={values.vAT}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={!authContext.myUserInfo}
                fullWidth
                label={t('profile-input-phone-number')}
                margin="dense"
                name="phoneNumber"
                onChange={handleChange}
                required
                value={values.phoneNumber}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={!authContext.myUserInfo}
                fullWidth
                label={t('profile-input-address')}
                margin="dense"
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={!authContext.myUserInfo}
                fullWidth
                label={t('profile-input-zipcode')}
                margin="dense"
                name="zipCode"
                onChange={handleChange}
                type="number"
                required
                value={values.zipCode}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={!authContext.myUserInfo}
                fullWidth
                label={t('profile-input-city')}
                margin="dense"
                name="city"
                onChange={handleChange}
                required
                value={values.city}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={loading || !authContext.myUserInfo}
            endIcon={success === true ? <CheckIcon /> : null}
            className={success === true ? classes.buttonSuccess : ''}
          >
            {success ? t('common-saved') : t('common-save')}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default ConnectAuthContext(AccountDetails);
