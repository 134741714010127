import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import { 
  Dialog,
} from '@material-ui/core';
import { loadMyUserRdvSummaries } from 'myApi';
import { AuthContext } from './AuthContext';
import { DialogContent, DialogTitle } from 'components';
import RdvSummaryList from './components/RdvSummaryList';
import { TranslationContext } from './TranslationContext';

export const RdvSummaryContext = React.createContext({});

/// Used to translate the text
const MyDialogTitle = () => {
  const { t } = React.useContext(TranslationContext);

  return (
    <DialogTitle id="customized-dialog-title">  
      {t("rdv-summary-title")}
    </DialogTitle>
  );
};

/// Used to translate the text
const MyDialogContentText = () => {
  const { t } = React.useContext(TranslationContext);

  return (
    <DialogContentText>
      {t("rdv-summary-subtitle")}
    </DialogContentText>
  );
};

// Dont forget to add this at the root of your project around your <App />
export default class ProvideRdvSummaryContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      isOpen: false,
      rdvs: []
    };
  }

  componentDidUpdate() {
    let { initialized, tokenUser } = this.context;

    // If we are connected (we have a connection token)
    // AND we did not have the rdv summaries yes
    if (initialized && this.state.loaded === false) {
      if (tokenUser) {

        this.setState({
          loaded: true,
        });

        loadMyUserRdvSummaries()
          .then(resp => {
            const rdvs = resp.data.myUserRdvSummaries
            if (rdvs && rdvs.length > 0) {
              this.setState({ rdvs, isOpen: true });
            }
          });
      }
    }
  }

  componentDidMount() { }

  closeDialog = () => this.setState({ isOpen: false });

  render() {
    return (
      <React.Fragment>
        <RdvSummaryContext.Provider value={this.state}>{this.props.children}</RdvSummaryContext.Provider>
        
        <Dialog fullScreen maxWidth={'md'} aria-labelledby="customized-dialog-title" open={this.state.isOpen}>
          <MyDialogTitle />
          <DialogContent dividers>
            <MyDialogContentText />
            <RdvSummaryList rdvs={this.state.rdvs} closeDialog={this.closeDialog}/>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

ProvideRdvSummaryContext.contextType = AuthContext;