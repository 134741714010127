import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';

import { InfoCard } from 'components';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PhoneIcon from '@material-ui/icons/Phone';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { ConnectStatContext } from 'contexts/StatContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { AuthContext } from 'contexts/AuthContext';

const Stats = ({ statContext, data }) => {
  const theme = useTheme();

  const { myUserInfo } = React.useContext(AuthContext);
  const { t } = React.useContext(TranslationContext);

  const [hasData, setHasData] = React.useState(false);
  const [stats, setStats] = React.useState(
    data ?? {
      phoneCallCount: 0,
      phoneCallCountOld: 0,
      rdvCount: 0,
      rdvCountOld: 0,
      signedCount: 0,
      signedCountOld: 0
    }
  );

  React.useEffect(() => {
    // If no data provided, make a call to get data for the user connected
    if (!data) {
      // accountType 0 is a simple account with no stats
      if (myUserInfo.isProAccount === false) {
        setHasData(true);
        return;
      }
      statContext.getMyUserStat().then(resp => {
        setStats(resp);
        setHasData(true);
      });
    } else {
      setStats(data);
      setHasData(true);
    }
  }, [statContext, data, myUserInfo.isProAccount]);

  if (hasData === false) {
    return null;
  }

  let phoneCallCountPercentage = undefined;
  if (stats.phoneCallCountOld) {
    phoneCallCountPercentage =
      (100 / stats.phoneCallCountOld) * stats.phoneCallCount;
  }

  let rdvCountPercentage = undefined;
  if (stats.rdvCountOld) {
    rdvCountPercentage = (100 / stats.rdvCountOld) * stats.rdvCount;
  }

  let signedCountPercentage = undefined;
  if (stats.signedCountOld) {
    rdvCountPercentage = (100 / stats.signedCountOld) * stats.signedCount;
  }

  let ratio = 0;
  if (stats.rdvCount) {
    ratio = (100 / stats.rdvCount) * stats.signedCount;
  }

  return (
    <div className="container">
      {myUserInfo.isProAccount === false && (
        <Button variant="contained" color="primary" className="text-overlay">
          {t('stat-get-pro-account')}
        </Button>
      )}
      <Grid
        // We add the blurred class if the account is has no access to stats
        className={myUserInfo.isProAccount === false ? 'blurred' : ''}
        container
        spacing={4}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3} xl={3}>
              <InfoCard
                title={t('stat-call-number')}
                value={stats.phoneCallCount}
                Icon={PhoneIcon}
                iconBGColor={theme.palette.info.main}
                percentage={phoneCallCountPercentage}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={3}>
              <InfoCard
                title={t('stat-rdv-number')}
                value={stats.rdvCount}
                Icon={EventAvailableIcon}
                iconBGColor={theme.palette.warning.main}
                percentage={rdvCountPercentage}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={3}>
              <InfoCard
                title={t('stat-signed-contract-number')}
                value={stats.signedCount}
                Icon={DoneAllIcon}
                iconBGColor={theme.palette.success.main}
                percentage={signedCountPercentage}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={3}>
              <InfoCard
                title={t('stat-ratio')}
                value={ratio + ' %'}
                Icon={AssessmentIcon}
                iconBGColor={theme.palette.secondary.main}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConnectStatContext(Stats);
