import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ListSubheader from '@material-ui/core/ListSubheader';
import Provinces from '../../../../common/provinces';
import Arrondissements from '../../../../common/arrondissements';
import Communes from '../../../../common/communes';
import Localities from '../../../../common/localite';
import ZipCodesList from '../../../../common/zipCodes';
import { TranslationContext } from 'contexts/TranslationContext';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function ZipCodeFilter({ updateFilter }) {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);
  const [zipCodes, setZipCodes] = React.useState([]);

  const getOptionTypeText = (option) => {
    switch (option.type) {
      case "P":
        return t("filter-province");
      case "A":
        return t("filter-arrondissement");
      case "C":
        return t("filter-commune");
      case "L":
        return option.code_postal;
      default:
        return "N\\A";
    }
  };

  const normalizeToUpperCase = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-standard"
        style={{ marginTop: zipCodes.length > 0 ? "-9px" : ""}}
        options={[]}
        getOptionLabel={(option) => { return option.name + " (" + getOptionTypeText(option) + ")"; }}
        defaultValue={[]}
        onChange={(event, selections) => {
          let array = [];
          selections.forEach(sel => {
            if (sel.type === "L") {
              array.push(parseInt(sel.code_postal));
            } else {
              let localityProperty = '';

              if (sel.type === "C") {
                localityProperty = "commune_niscode";
              }
              if (sel.type === "A") {
                localityProperty = "arrondissement_niscode";
              }
              if (sel.type === "P") {
                localityProperty = "province_niscode";
              }

              Localities
                .filter(locality => locality[localityProperty] === sel.niscode)
                .forEach(locality => {
                  const zipCode = parseInt(locality.code_postal);
                  if(array.indexOf(zipCode) === -1) {
                    array.push(zipCode);
                  }
                });
            }
          });

          setZipCodes(array);
          updateFilter("zipCodes", array);
        }}
        renderGroup={(option) => { return (
          <React.Fragment key={option.key}>
            <ListSubheader component="div" style={{ backgroundColor: "#f4f6f8" }} disableSticky>
              {
                option.group === "P"
                ? t("filter-province")
                : option.group === "A"
                ? t("filter-arrondissement")
                : option.group === "L"
                ? t("filter-localite")
                : option.group === "C"
                ? t("filter-commune")
                : "N/A"
              }
            </ListSubheader>
            {option.children}
          </React.Fragment>
        ); }}
        groupBy={(option) => { return option.type; }}
        noOptionsText={t("filter-zip-no-result")}
        filterSelectedOptions
        filterOptions={(options, state) => {
          if (state.inputValue.length < 2) {
            return [];
          }

          const array = [];
          
          if (isNaN(state.inputValue)) {
            array.push.apply(array, Provinces.filter(elem => normalizeToUpperCase(elem.name).includes(normalizeToUpperCase(state.inputValue))));
            array.push.apply(array, Arrondissements.filter(elem => normalizeToUpperCase(elem.name).includes(normalizeToUpperCase(state.inputValue))));
            array.push.apply(array, Communes.filter(elem => normalizeToUpperCase(elem.name).includes(normalizeToUpperCase(state.inputValue))));
            array.push.apply(array, ZipCodesList.filter(elem => normalizeToUpperCase(elem.name).includes(normalizeToUpperCase(state.inputValue))));
          } else {
            array.push.apply(array, ZipCodesList.filter(elem => elem.code_postal.startsWith((state.inputValue))));
          }

          return array;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={t("filter-zip-search")}
            placeholder={t("filter-zip-enter-search")}
          />
        )}
      />
    </div>
  );
}
