import React, { useState, useEffect } from 'react';
import { ConnectAuthContext } from 'contexts/AuthContext';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Grid, Button, TextField, Typography, Link } from '@material-ui/core';
import useStyles from './ResetPassword.style.';
import CustomizedSnackbars from 'components/CustomizedSnackbars';

const schema = {
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  confirmPassword: {
    equality: 'password',
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  }
};

const ResetPassword = props => {
  const classes = useStyles();

  const { history, authContext } = props;
  const { tokenUser, resetPassword, setTokenUser } = authContext;

  const [redirectTo, setRedirectTo] = useState(null);
  const [toast, setToast] = useState({
    open: false,
    message: null,
    type: null
  });
  const [formState, setFormState] = useState({
    isValid: false,
    loading: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const setInfosToast = (message, type) => {
    setToast({
      open: true,
      message: message,
      type: type
    });
    setTimeout(() => {
      setToast({
        open: false,
        message: null,
        type: ''
      });
    }, 3000);
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const onSubmit = async event => {
    event.preventDefault();
    const { password } = formState.values;
    const token = props.match.params.token;

    setFormState(formState => ({
      ...formState,
      loading: true
    }));

    try {
      const response = await resetPassword(token, password);
      setInfosToast('Changement effectué avec succès ! ', 'success');
      setTokenUser(response.data, false);
    } catch (e) {
      if (e.response) {
        const error = e.response.status;
        // je fais la meme dans sign in
        let messageToToast = `Error  ${error}: `;
        switch (error) {
          case 400:
            if (e.response.data === 'The request is not valid anymore') {
              messageToToast += ` Le token n'est plus valide`;
              setTimeout(() => {
                setRedirectTo('/request-reset-password');
              }, 1750);
            } else {
              messageToToast += ` Erreur lors du reset demandé`;
            }
            break;
          case 404:
            messageToToast += ` Erreur lors du reset demandé`;
            break;
          case 403:
            messageToToast += ` Action non autorisée`;
            break;
          case 500:
            messageToToast += ` Problème serveur, contactez un administrateur`;
            break;
          default:
            messageToToast += ` Erreur inconnue`;
            break;
        }
        setInfosToast(messageToToast, 'error');
      } else {
        setInfosToast(e.toString(), 'error');
      }

      setFormState(formState => ({
        ...formState,
        loading: false
      }));
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  if (tokenUser) {
    return <Redirect to="/dashboard" />;
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                I have decided to stick with love. Hate is too great a burden to
                bear.
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Martin Luther King, Jr.
                </Typography>
                <Typography className={classes.bio} variant="body2">
                  Home Tracker
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={onSubmit}>
                <Typography className={classes.title} variant="h2">
                  Entrez votre nouveau mot de passe
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Mot de passe"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('confirmPassword')}
                  fullWidth
                  helperText={
                    hasError('confirmPassword')
                      ? formState.errors.confirmPassword[0]
                      : null
                  }
                  label="Confirmer mot de passe"
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.confirmPassword || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={formState.isValid === false || formState.loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Confirmer
                </Button>
                <CustomizedSnackbars
                  isOpen={toast.open}
                  message={toast.message}
                  type={toast.type}
                />
                <Link
                  component="button"
                  variant="body2"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/sign-in');
                  }}
                >
                  Se Connecter
                </Link>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object
};

export default ConnectAuthContext(withRouter(ResetPassword));
