import React, { useState, useEffect } from 'react';
import { ConnectAuthContext } from 'contexts/AuthContext';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Grid, Button, TextField, Typography, Link } from '@material-ui/core';
import useStyles from './RequestPassword.style.';
import CustomizedSnackbars from 'components/CustomizedSnackbars';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  }
};

const SignIn = props => {
  const { history, authContext } = props;
  const { tokenUser, requestPassword } = authContext;
  const [toast, setToast] = useState({
    open: false,
    message: null,
    type: null
  });
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    loading: false,
    alreadySent: false,
    values: {},
    touched: {},
    errors: {}
  });
  const setInfosToast = (message, type) => {
    setToast({
      open: true,
      message: message,
      type: type
    });
    setTimeout(() => {
      setToast({
        open: false,
        message: null,
        type: ''
      });
    }, 3000);
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
  const onSubmit = async event => {
    event.preventDefault();
    const { email } = formState.values;
    setFormState(formState => ({
      ...formState,
      loading: true
    }));

    try {
      await requestPassword(email);
      setInfosToast('Message envoyé ! ', 'success');

      setFormState(formState => ({
        ...formState,
        loading: false,
        alreadySent: true
      }));
    } catch (e) {
      if (e.response) {
        const error = e.response.status;
        // je fais la meme dans sign in
        let messageToToast = `Error  ${error}: `;
        switch (error) {
          case 400:
          case 404:
            messageToToast += ` Votre email n'existe pas`;
            break;
          case 403:
            messageToToast += ` Action non autorisée`;
            break;
          case 500:
            messageToToast += ` Problème serveur , contactez un administrateur`;
            break;
          default:
            messageToToast += ` Erreur inconnue`;
            break;
        }
        setInfosToast(messageToToast, 'error');
      } else {
        setInfosToast(e.toString(), 'error');
      }

      setFormState(formState => ({
        ...formState,
        loading: false
      }));
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  if (tokenUser) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                I have decided to stick with love. Hate is too great a burden to
                bear.
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Martin Luther King, Jr.
                </Typography>
                <Typography className={classes.bio} variant="body2">
                  Home Tracker
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={onSubmit}>
                <Typography className={classes.title} variant="h2">
                  Demande de mot de passe
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Adresse email"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={
                    formState.isValid === false ||
                    formState.loading ||
                    formState.alreadySent
                  }
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Demander son mot de passe
                </Button>
                <CustomizedSnackbars
                  isOpen={toast.open}
                  message={toast.message}
                  type={toast.type}
                />
                <Link
                  component="button"
                  variant="body2"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/sign-in');
                  }}
                >
                  Se Connecter
                </Link>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default ConnectAuthContext(withRouter(SignIn));
