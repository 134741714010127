import React from 'react';
import RightProtectedPrivateRoute from './RightProtectedPrivateRoute';

const AdminPrivateRoute = ({
  component, layout, path, ...rest
}) => (
  <RightProtectedPrivateRoute
    component={component}
    hasRight={userInfo => userInfo.email === 'admin@niaur.com'}
    exact
    layout={layout}
    path={path}
    {...rest}
  />
);

export default AdminPrivateRoute;