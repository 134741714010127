import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ConnectAuthContext } from '../contexts/AuthContext';
import { withRouter } from 'react-router-dom';
import { TranslationContext } from 'contexts/TranslationContext';

const LogOutPopUp =  ({ history, authContext }) => {

  const { logOut } = authContext

  const { t } = React.useContext(TranslationContext);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (logout) => {
    if(logout === true){
      logOut()
    }
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth variant="text" color ="primary" onClick={e => history.push("/account")}>
        {t("sidebar-my-profile")}
      </Button>
      <Button fullWidth variant="outlined" color ="primary" onClick={handleClickOpen}>
        {t("sidebar-logoff")}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Déconnexion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("sidebar-confirm-logoff")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t("common-no")}
          </Button>
          <Button onClick={()=>handleClose(true)} color="primary" autoFocus>
            {t("common-yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConnectAuthContext(withRouter(LogOutPopUp))