import React from 'react';
import { TranslationContext } from 'contexts/TranslationContext';
import { TableCell, TableHead, TableRow } from '@material-ui/core';

const TableHeader = ({ displayCollaborator }) => {

  const { t } = React.useContext(TranslationContext);
  
  return (
    <TableHead>
      <TableRow>
        {
          displayCollaborator && (
            <TableCell>{t("signed-mandat-table-head-collaborator")}</TableCell>
          )
        }
        <TableCell>{t("signed-mandat-table-head-name")}</TableCell>
        <TableCell>{t("signed-mandat-table-head-phoneNbr")}</TableCell>
        <TableCell>{t("signed-mandat-table-head-address")}</TableCell>
        <TableCell>{t("signed-mandat-table-head-date")}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;