const Provinces = [
  { "type": "P", "niscode": "20002", "name": "Brabant wallon", "region_nis_code": "03000", "geo_point_2d": [50.6693315044,4.58378331504], "name_fr": "Brabant wallon (le)", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "P", "niscode": "50000", "name": "Hainaut", "region_nis_code": "03000", "geo_point_2d": [50.4696833234,3.95942395396], "name_fr": "Hainaut (le)", "name_nl": "Henegouwen", "name_de": "undefined", },
  { "type": "P", "niscode": "90000", "name": "Namur", "region_nis_code": "03000", "geo_point_2d": [50.2557147156,4.85229157774], "name_fr": "Namur", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "P", "niscode": "60000", "name": "Liège", "region_nis_code": "03000", "geo_point_2d": [50.5205229193,5.73660627556], "name_fr": "Liège", "name_nl": "undefined", "name_de": "Lüttich", },
  { "type": "P", "niscode": "20001", "name": "Brabant flamand", "region_nis_code": "02000", "geo_point_2d": [50.8727278671,4.59060619389], "name_fr": "Brabant flamand (le)", "name_nl": "Vlaams-Brabant", "name_de": "undefined", },
  { "type": "P", "niscode": "01000", "name": "Région de Bruxelles-Capitale", "region_nis_code": "01000", "geo_point_2d": [50.8360549337,4.37064954642], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "P", "niscode": "10000", "name": "Anvers", "region_nis_code": "02000", "geo_point_2d": [51.2296725713,4.71976818903], "name_fr": "undefined", "name_nl": "Antwerpen", "name_de": "undefined", },
  { "type": "P", "niscode": "70000", "name": "Limbourg", "region_nis_code": "02000", "geo_point_2d": [50.9914942194,5.42918088508], "name_fr": "Limbourg (le)", "name_nl": "Limburg", "name_de": "undefined", },
  { "type": "P", "niscode": "40000", "name": "Flandre-Orientale", "region_nis_code": "02000", "geo_point_2d": [51.0364553586,3.81629340129], "name_fr": "Flandre orientale (la)", "name_nl": "Oost-Vlaanderen", "name_de": "undefined", },
  { "type": "P", "niscode": "80000", "name": "Luxembourg", "region_nis_code": "03000", "geo_point_2d": [49.9617231608,5.51336151165], "name_fr": "Luxembourg", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "P", "niscode": "30000", "name": "Flandre-Occidentale", "region_nis_code": "02000", "geo_point_2d": [51.0114226865,3.05764707575], "name_fr": "Flandre occidentale (la)", "name_nl": "West-Vlaanderen", "name_de": "undefined", },
];

export default Provinces;