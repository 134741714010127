import React, { Component } from 'react';
import App from './App';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';

import ProvidePopUpContext from 'contexts/PopUpContext';
import ProvideLoaderContext from 'contexts/LoaderContext';
import ProvideAuthContext from 'contexts/AuthContext';
import ProvideStatContext from 'contexts/StatContext';
import ProvideRdvSummaryContext from 'contexts/RdvSummaryContext';
import ErrorBoundary from 'ErrorBoundary';
import ProvideCustomizedSnackBarContext from 'contexts/CustomizedSnackBarContext';
import ProvideTranslationContext from 'contexts/TranslationContext';

class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newVersionAvailable: false,
    };
  }

  componentDidMount() {
    // Listen to custom event
    window.addEventListener('new-version-custom-event', (e) => {
      console.log('A new version is available', e.detail);
    });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <ProvideCustomizedSnackBarContext>
          <ProvideAuthContext>
            <ProvidePopUpContext>
              <ProvideLoaderContext>
                <ProvideStatContext>
                  <ProvideTranslationContext>
                    <ProvideRdvSummaryContext>
                      <ErrorBoundary><App /></ErrorBoundary>
                    </ProvideRdvSummaryContext>
                  </ProvideTranslationContext>
                </ProvideStatContext>
              </ProvideLoaderContext>
            </ProvidePopUpContext>
          </ProvideAuthContext>
        </ProvideCustomizedSnackBarContext>
      </ThemeProvider>
    );
  }
}

export default AppProvider;