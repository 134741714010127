import React from 'react';
import moment from 'moment';
import { TableCell, TableRow } from '@material-ui/core';

const TableContent = ({ displayCollaborator, rows }) => {
  return (
    <>
      {rows.map(row => (
        <TableRow
          hover
          key={row.id}
        >
          {
            displayCollaborator && (
              <TableCell>{row.user.name}</TableCell>
            )
          }
          <TableCell>{row.ownerName}</TableCell>
          <TableCell>{row.ownerPhone}</TableCell>
          <TableCell>{row.ownerAddress}</TableCell>
          <TableCell>{moment(row.rdvDate).format('LLL')}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TableContent;