import React from 'react';
import { 
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.success.main,
    '&:hover': {
      color: theme.palette.success.light,
      backgroundColor: "transparent",
    },
  },
  danger: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.light,
      backgroundColor: "transparent",
    },
  },
  warning: {
    color: theme.palette.warning.main,
    '&:hover': {
      color: theme.palette.warning.light,
      backgroundColor: "transparent",
    },
  }
}));

const ExtendedButton = ({ children, color, ...rest }) => {
  const classes = useStyles();
  var className = classes[color];

  return (
    <Button
      color="primary"
      className={className}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ExtendedButton;