import React from 'react';
import { addUserAction } from 'myApi';

export const PopUpContext = React.createContext({});

const Consumer = PopUpContext.Consumer;

const Provider = PopUpContext.Provider;

export function ConnectPopUpContext(Component) {
  return function WrapperComponent(props) {
    return (
      <Consumer>
        {state => <Component {...props} popUpContext={state} />}
      </Consumer>
    );
  };
}

// Dont forget to add this at the root of your project around your <App />
export default class ProvidePopUpContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyId: null,
      closable: null,
      isOpen: false,
      openPopup: (closable) => this.setState({ isOpen: true, closable: closable}),
      closePopup: () => this.setState({ isOpen: false}),
      checkValues: (obj) => {
        const {
          state,
          propertyId,
          ownerName,
          ownerAddress,
          ownerPhoneNumber,
          rendezVousDate
        } = obj;
        if (
          state !== null &&
          propertyId !== null &&
          ownerName !== null &&
          ownerAddress !== null &&
          ownerPhoneNumber !== null &&
          rendezVousDate !== null
        ) {
          return true;
        } else {
          return false;
        }
      },
      setPropertyId: (propertyId) => this.setState({ propertyId }),
      saveUserAction: (userAction) => addUserAction(userAction),
    };
  }
  render() {
    return (
      <React.Fragment>
        <Provider value={this.state}>{this.props.children}</Provider>
      </React.Fragment>
    );
  }
}
