import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { SubformSwitcher } from './Subforms';

const FormControlLabelRadioWithSubForm = ({ label, index, value }) => {
  let fWeight = '400';
  let fSize = '14px';
  if (value) {
    if (index === value) {
      fWeight = '600';
    } else {
      fSize = '13px';
    }
  }

  return (
    <React.Fragment>
      <FormControlLabel
        value={index}
        control={<Radio color="primary" />}
        label={
          <Typography
            style={{
              fontWeight: fWeight,
              fontSize: fSize,
              '& label': { fWeight: fWeight, fontSize: fSize }
            }}
          >
            {label}
          </Typography>
        }
      />
      <Hidden smUp>
        <CommonSubForm index={index} value={value} />
      </Hidden>
    </React.Fragment>
  );
};

const CommonSubForm = ({ index, value }) => {
  // to ensure that we display only under the selected radio button
  if (index && index !== value) {
    return null;
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      style={{ backgroundColor: '#00000007', padding: '16px' }}
      key={'Grid-for-subform-autoeval-' + index}
    >
      <SubformSwitcher value={value} />
    </Grid>
  );
};

const AutoEvalForm = () => {
  const [value, setValue] = React.useState(false);
  const handleChange = (_, newValue) => {
    setValue(parseInt(newValue));
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5">Formulaire d'auto-évaluation</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="question"
            name="question"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabelRadioWithSubForm
              label={'Client pas interessé'}
              index={5}
              value={value}
            />
            <FormControlLabelRadioWithSubForm
              label={'Répondeur'}
              index={1}
              value={value}
            />
            <FormControlLabelRadioWithSubForm
              label={'Agence'}
              index={2}
              value={value}
            />
            <FormControlLabelRadioWithSubForm
              label={'Vendu'}
              index={3}
              value={value}
            />
            <FormControlLabelRadioWithSubForm
              label={'Annonce indisponible'}
              index={4}
              value={value}
            />
            <FormControlLabelRadioWithSubForm
              label={"Le bien n'est pas interessant"}
              index={6}
              value={value}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {value && (
        <Hidden xsDown>
          <CommonSubForm value={value} />
        </Hidden>
      )}
    </Grid>
  );
};

export default AutoEvalForm;
