import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import columns from './components/PropertiesTable/columns-info';

import PropertiesTableBis from './components/PropertiesTable/PropertiesTableBis';
import PropertiesTable from './components/PropertiesTable/PropertiesTable';

import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  boxButtonGroup: {
    display: 'flex'
  },
  buttonGroup: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main
  },
  toggleButton: {
    color: 'white !important'
  }
}));

const Properties = () => {
  const classes = useStyles();

  const [displayType, setDisplayType] = React.useState(
    localStorage.getItem('HT-DisplayType') ?? 'card'
  );

  const handleChangeDisplayType = newValue => {
    localStorage.setItem('HT-DisplayType', newValue);
    setDisplayType(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Hidden smDown>
            <Box className={classes.boxButtonGroup} flexDirection="row-reverse">
              <ToggleButtonGroup
                className={classes.buttonGroup}
                value={displayType}
                exclusive
                onChange={(_, newValue) => handleChangeDisplayType(newValue)}
                aria-label="text alignment"
              >
                <ToggleButton
                  className={classes.toggleButton}
                  value={'card'}
                  aria-label="card display"
                >
                  <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton
                  className={classes.toggleButton}
                  value={'table'}
                  aria-label="table display"
                >
                  <ViewListIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Hidden>
          {displayType === 'card' ? (
            <PropertiesTable alreadyHadAction={false} columns={columns} />
          ) : (
            <PropertiesTableBis alreadyHadAction={false} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Properties;
