import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SelectFilter from './SelectFilter';
import ZipCodeFilter from './ZipCodeFilter';
import { Chip } from '@material-ui/core';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { TranslationContext } from 'contexts/TranslationContext';

const selectFilters = [
  {
    label: 'filter-per-site',
    name: 'source',
    options: [
      { value: 1, label: 'Immoweb' },
      { value: 2, label: 'Deuxième' },
      { value: 3, label: 'ImmoVlan' },
      { value: 4, label: 'VivaStreet' },
     // { value: 5, label: 'Athome' },
     // { value: 6, label: 'Logicimmo' }
    ]
  },
  {
    label: 'filter-per-type',
    name: 'service',
    options: [
      { value: 1, label: 'service-type-sell' },
      { value: 2, label: 'service-type-rent' }
    ]
  },
  {
    label: 'filter-per-category',
    name: 'category',
    multiple: true,
    options: [
      { value: 1, label: 'category-houses' },
      { value: 2, label: 'category-appartments' },
      { value: 3, label: 'category-offices' },
      { value: 4, label: 'category-businesses' },
      { value: 6, label: 'category-investment-properties' },
      { value: 7, label: 'category-industries' },
      { value: 10, label: 'category-lands' },
      { value: 11, label: 'category-others' }
    ]
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  icons: {
    marginTop: '25px',
    marginBottom: "20px",
    maxHeight: '55px',
    minHeight: '55px',
    overflowX: 'overlay',
    overflowY: 'hidden',
    display: 'flex',
    '&::-webkit-scrollbar': {
      backgroundColor: "#fff",
      width: "16px",
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: "#f4f6f8",
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: "#babac0",
      borderRadius: "16px",
      border: "4px solid #f4f6f8",
    },
    '&::-webkit-scrollbar-button': {
      display: "none"
    },
  },
  iconChip: {
    marginRight: "8px",
    marginBottom: "8px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const iconsFilter = [
  {
    id: 0,
    icon: <EventAvailableIcon />,
    label: 'filter-rdv'
  },
  {
    id: 1,
    icon: <PhoneMissedIcon />,
    label: 'filter-no-response'
  },
  {
    id: 2,
    icon: <NotInterestedIcon />,
    label: 'filter-in-agency'
  },
  {
    id: 3,
    icon: <VpnKeyIcon />,
    label: 'filter-already-sold'
  },
  {
    id: 4,
    icon: <CloudOffIcon />,
    label: 'filter-not-available'
  },
  {
    id: 5,
    icon: <FeedbackIcon />,
    label: 'filter-not-interested'
  },
  {
    id: 6,
    icon: <ThumbDownIcon />,
    label: 'filter-not-interesting'
  },
];

export default function GroupFilter({ filter, setFilter, displayIcons }) {
  const classes = useStyles();

  const updateFilter = (propName, value) =>
    setFilter({ ...filter, [propName]: value });
  
  const { t } = React.useContext(TranslationContext);

  const [minPrice, setMinPrice] = React.useState(null);
  const [maxPrice, setMaxPrice] = React.useState(null);
  const [stateFilters, setStateFilter] = React.useState([]);

  const onSubmit = (event) => {
    event.preventDefault();

    setFilter({
      ...filter,
      minPrice: minPrice,
      maxPrice: maxPrice
    });
  };

  const onClick = id => {
    var index = stateFilters.indexOf(id)
    let array = null;
    if (index !== -1) {
      stateFilters.splice(index, 1);
      array = [...stateFilters];
      
    } else {
      array = [ ...stateFilters, id ];
    }

    setStateFilter(array);
    updateFilter("stateIds", array);
  };

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography className={classes.heading}>{t("filter-filters-label")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form className={classes.root} onSubmit={onSubmit}>
            <Grid container spacing={3}>
              {selectFilters.map(element => (
                <Grid key={element.name} item xs={12} sm={4}>
                  <SelectFilter
                    label={t(element.label)}
                    name={element.name}
                    setFilter={updateFilter}
                    val={filter[element.name]}
                    options={element.options}
                    multiple={element.multiple}
                  />
                </Grid>
              ))}
              <Grid item xs={12} sm={4}>
                <TextField
                  id="filter-minimum"
                  label={t("filter-min-price")}
                  fullWidth
                  type="number"
                  inputProps={{ min: "0", step: "1" }}
                  onChange={e => {
                    if (e.target.value) {
                      setMinPrice(parseInt(e.target.value));
                    } else {
                      setMinPrice(null);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="filter-maximum"
                  label={t("filter-max-price")}
                  fullWidth
                  type="number"
                  inputProps={{ min: "0", step: "1" }}
                  onChange={e => {
                    if (e.target.value) {
                      setMaxPrice(parseInt(e.target.value));
                    } else {
                      setMaxPrice(null);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ZipCodeFilter updateFilter={updateFilter}/>
              </Grid>
              <Grid item xs={12} sm={3}>
                <SelectFilter
                  label={t("filter-date")}
                  name="date"
                  fullWidth
                  setFilter={updateFilter}
                  val={filter["date"]}
                  options={[
                    { value: 1, label: 'date-filter-choice-last-week' },
                    { value: 2, label: 'date-filter-choice-last-month' },
                    { value: 3, label: 'date-filter-choice-more-than-a-month-ago' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SelectFilter
                  label={t("filter-peb")}
                  name="peb"
                  fullWidth
                  setFilter={updateFilter}
                  val={filter["peb"]}
                  options={[
                    { value: true, label: 'peb-filter-choice-true' },
                    { value: false, label: 'peb-filter-choice-false' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    type="submit"
                >
                  {t("filter-filter-action")}
                </Button>
              </Grid>
  {/*
    NEED FILTER IN THE BACK END for date
  */}
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <div className={classes.icons} hidden={!displayIcons}>
        {
          iconsFilter.map((iconFilter) => (
            <Chip
              key={iconFilter.id}
              className={classes.iconChip}
              label={t(iconFilter.label)}
              color={stateFilters.includes(iconFilter.id) ? "primary" :  "default"}
              onClick={_ => onClick(iconFilter.id)}
              icon={iconFilter.icon} clickable
            />
          ))
        }
      </div>
    </div>
  );
}
