import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';


const AlreadySoldSubForm = ({ updateProp }) => {
  const [value, setValue] = React.useState(false);

  const onChange = (_, newValue) => {
    setValue(parseInt(newValue));
    updateProp("soldStatus", parseInt(newValue));
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6">État de la vente</Typography>
      </Grid>
      <FormControl component="fieldset">
          <RadioGroup
            aria-label="question"
            name="question"
            value={value}
            onChange={onChange}>
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="Offre"
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label="Compromis"
            />
            <FormControlLabel
              value={3}
              control={<Radio />}
              label="Condition suspensive levée"
            />
          </RadioGroup>
        </FormControl>
    </Grid>
  );
};

export default AlreadySoldSubForm;