import React from 'react';
import clsx from 'clsx';
import { Button, colors, Fab } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { getMyUserDates } from 'myApi';
import PopUpForm from './PopUpForm';
import { Add } from '@material-ui/icons';
import { TranslationContext } from 'contexts/TranslationContext';
import { AuthContext } from 'contexts/AuthContext';
import { CustomizedSnackBarContext } from 'contexts/CustomizedSnackBarContext';

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: "100%",
  },
  addButton: {
    position: "absolute",
    right: "20px",
    bottom: "20px",
    zIndex: 15,
  },
  PayMoreOverlay: {
    position: "absolute",
    zIndex: 100,
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backdropFilter: "blur(5px)",
  },
}));

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>&nbsp;- <i>{eventInfo.event.title}</i>
    </>
  )
}

const colorList = [
  "blue",
  "green",
  "red",
  "yellow",
  "purple",
  "pink",
  "amber",
];
const variantValues = [
  { light: "200", dark: "900"},
  { light: "300", dark: "800"},
  { light: "400", dark: "700"}
];

const Calendar = () => {
  const classes = useStyles();

  const { successMessage } = React.useContext(CustomizedSnackBarContext);
  const { myUserInfo } = React.useContext(AuthContext);
  const { t } = React.useContext(TranslationContext);

  const [dates, setDates] = React.useState([]);
  const [displayPopup, setDisplayPopup] = React.useState(null);
  const [popupInfo, setPopupInfo] = React.useState(null);

  const handleEventClick = (clickInfo) => {
    // eslint-disable-next-line
    var clickedDate = dates.filter(date => date.id == clickInfo.event.id)[0];
    setPopupInfo(clickedDate);
    setDisplayPopup("DISPLAY");
  };

  const updateCalendar = (date) => {
    setDisplayPopup(null);
    successMessage(t('calendar-added-with-success'))
    getData(date);
  };

  const handleDateSelect = (selectInfo) => {
    setPopupInfo({
      startDateTime: selectInfo.startStr,
      endDateTime: selectInfo.endStr,
    });
    setDisplayPopup("CREATE");
  };

  const getData = async (date) => {
    var dates = await getMyUserDates(date);
    setDates(dates.data.myUserDates);
  };

  const onClosePopup = () => {
    setPopupInfo(null);
    setDisplayPopup(null);
  };
  
  const ids = new Set();
  dates.forEach(element => ids.add(element.user.id));
  
  const getDateColor = (id) => {
    let index = 0;
    for (const value of ids) {
      if (id == value) {
        break; // this will stop the iteration
      }
      index ++;
    }

    var color = colorList[index % colorList.length];
    var variants = variantValues[Math.floor(index / colorList.length)];
    
    return { color, ...variants };
  };

  const events = dates.map(date => {
    let title = date.dateType === 0 ? t('calendar-rdv') : t('calendar-reminder');
    // We add the name of the user in the title
    // bc if the user connected is the admin, it has access to all the elment of the user
    if (myUserInfo.isAdminOfCompany) {
      title += " (" + date.user.name + ")";
    }

    var userColors = getDateColor(date.user.id);

    return {
      id: date.id,
      title: title,
      start: date.startDateTime + 'Z',
      backgroundColor: colors[userColors.color][date.dateType === 0 ? userColors.dark : userColors.light ],
    };
  });

  return (
    <div className={clsx(classes.fullHeight, classes.calendar)}>
      <Fab className={classes.addButton} onClick={() => { setPopupInfo(null); setDisplayPopup("CREATE"); }} color="primary" aria-label="add">
        <Add />
      </Fab>
      <Paper className={classes.fullHeight}>
        <FullCalendar
          firstDay={1}
          expandRows={true}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev',
            center: 'title',
            right: 'next'
          }}
          scrollTime={new Date().toLocaleTimeString()}
          initialView='timeGridWeek'
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          events={events} // alternatively, use the `events` setting to fetch from a feed
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          datesSet={(dateInfo) => getData(dateInfo.end)}
          /*
          // you can update a remote database when these fire:
          eventAdd={function(){}}
          eventChange={function(){ console.log("EVENT CHANGE")}}
          eventRemove={function(){}}
          */
        />
      </Paper>
      {displayPopup && ( <PopUpForm updateCalendar={updateCalendar} isopen={true} isCreate={displayPopup === "CREATE"} close={onClosePopup} popupInfo={popupInfo}/> )}
      {myUserInfo.isProAccount === false && (
        <div className={classes.PayMoreOverlay}>
          <Button variant="contained" color="primary" className="text-overlay">{t("stat-get-pro-account")}</Button>
        </div>
      )}
      
    </div>
  );
};

export default Calendar;