import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "15px",
  }
}));

const AppBarTab = ({ value, updateValue }) => {
  const classes = useStyles();

  function a11yProps(a11yIndex) {
    return {
      id: `full-width-tab-${a11yIndex}`,
      'aria-controls': `full-width-tabpanel-${a11yIndex}`,
    };
  }
  
  return (
    <AppBar className={classes.root} position="static" color="default">
      <Tabs
        value={value ?? false}
        onChange={(_, newValue) => updateValue(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="RDV" icon={<EventAvailableIcon />} {...a11yProps(0)} />
        <Tab label="Rappel" icon={<AlarmAddIcon />} {...a11yProps(1)} />
      </Tabs>
    </AppBar>
  );
};

export default AppBarTab;