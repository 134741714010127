import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ConnectPopUpContext } from '../../../../../../../contexts/PopUpContext';
import { NoResponseSubForm, AlreadyInAgencySubForm, AlreadySoldSubForm, ClientNotInterestedSubForm } from '.';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1)
  },
}));

const renderSwitch = (param, updateProp) => {
  if (param) {
    switch(param) {
      case 1:
        return <NoResponseSubForm updateProp={updateProp} />;
      case 2:
        return <AlreadyInAgencySubForm updateProp={updateProp} />;
      case 3:
        return <AlreadySoldSubForm updateProp={updateProp} />;
      case 5:
        return <ClientNotInterestedSubForm updateProp={updateProp} />;
      default:
        return null;
    }
  }
  
  return null;
}

const SubFormSwitcher = ({ value, popUpContext }) => {
  const classes = useStyles();
  const { propertyId, closePopup, saveUserAction } =  popUpContext;
  const [formState, setFormState] = React.useState({
    state: value,
    propertyId,
    hasMessage: false,
    reminder: null,
    alreadyInAgency: null,
    soldStatus: null,
    reasonClientNotInterested: null,
  });
  
  React.useEffect(() => {
      // Reset the state when the value (meaning state of the user action) changes
      setFormState({
        state: value,
        propertyId,
        hasMessage: false,
        reminder: null,
        alreadyInAgency: null,
        soldStatus: null,
        reasonClientNotInterested: null,
        clientNotInterestedOtherReason: null,
      });
  }, [value, propertyId]);

  const submit = (event) => {
    event.preventDefault();
    saveUserAction(formState);
    closePopup();
  }

  const updateProp = (propName, propValue) => {
    setFormState({
      ...formState,
      [propName]: propValue
    });
  }

  if (value) {
    return (
      <form className={classes.root} autoComplete="off" onSubmit={submit}>
        <React.Fragment>
          {renderSwitch(value, updateProp)}
          {
            // special case
            // If client not interested we have multiple level of form to specify
            value !== 5 &&
            <Button
              disabled={false}
              className={classes.button}
              variant="contained"
              type='submit'
              color="primary">
              Enregistrer
            </Button>
          }
        </React.Fragment>
      </form>
    );
  }

  return null;
};

export default ConnectPopUpContext(SubFormSwitcher);