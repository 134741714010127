import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardContent, CircularProgress, Divider, Table, TableBody, Typography } from '@material-ui/core';
import { TranslationContext } from 'contexts/TranslationContext';
import { Redirect } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Header from './components/Header';
import Footer from './components/Footer';
import TableHeader from './components/TableHeader';
import TableContent from './components/TableContent';
import { AuthContext } from 'contexts/AuthContext';
import { loadMySignedMandatInfo } from 'myApi';
import { CustomizedSnackBarContext } from 'contexts/CustomizedSnackBarContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  noFoundImageContainer: {
    textAlign: "center",
  },
  noFoundImage: {
    maxWidth: "100%",
    maxHeight: "450px",
  },
  btn: {
    marginTop: theme.spacing(2)
  },
  ...theme.common,
}));

const SignedMandat = () => {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);
  const { myUserInfo } = React.useContext(AuthContext);
  const { errorMessage } = React.useContext(CustomizedSnackBarContext);

  const [skip, setSkip] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [canLoadMore, setCanLoadMore] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const loadData = () => {
    setLoading(true);
    loadMySignedMandatInfo(skip)
    .then(response => {
      setRows([...rows, ...response.data.mySignedMandatInfo]);
      if (response.data.mySignedMandatInfo.length < 5) {
        setCanLoadMore(false);
      } else {
        setSkip(skip + 5);
      }
    })
    .catch(_ => errorMessage(t("common-an-error-occured")))
    .finally(_ => setLoading(false));
  };

  React.useEffect(() => loadData(), []);

  if (redirect) {
    return <Redirect to={'/properties'} />;
  }

  const redirectToProperties = () => setRedirect(true);

  return (
    <div className={classes.root}>
      {
        rows.length === 0 && !loading && (
          <CardContent className={classes.content}>
            <div className={classes.noFoundImageContainer}>
              <img className={classes.noFoundImage} alt="no-data" src="/images/no-signed-mandat.svg"/>
              <Typography variant="h2" align="center">{t("signed-mandat-no-signed-mandat")}</Typography>
              <Button className={classes.btn} onClick={redirectToProperties} variant="contained" color="primary">{t("rdv-summary-go-to-properties")}</Button>
            </div>
          </CardContent>
        )
      }
      <Card
        className={clsx(classes.root)}
        hidden={rows.length === 0 && !loading}
      >
        <Header />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHeader displayCollaborator={myUserInfo.isAdminOfCompany} />
                <TableBody>
                  <TableContent displayCollaborator={myUserInfo.isAdminOfCompany} rows={rows}/>
                  {loading && (<div className={classes.noFoundImageContainer}><CircularProgress /></div>)}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <Divider />
        {canLoadMore && (<Footer onClickHandler={loadData}/>)}
      </Card>
    </div>
  );
};

export default SignedMandat;