import React from 'react';
import { loadMyStat } from 'myApi';

export const StatContext = React.createContext({});

const Consumer = StatContext.Consumer;

const Provider = StatContext.Provider;

export function ConnectStatContext(Component) {
  return function WrapperComponent(props) {
    return (
      <Consumer>
        {state => <Component {...props} statContext={state} />}
      </Consumer>
    );
  };
}

// Dont forget to add this at the root of your project around your <App />
export default class ProvideStatContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myUserStat: {
        phoneCallCount: null,
        rdvCount: null,
        SignedCount: null
      },
      initialized: false,
      loading: false,
      getMyUserStat: async () => {
        if (this.state.initialized) {
          return this.state.myUserStat;
        }
        const response = await loadMyStat();
        this.setState({
          initialized: true,
          myUserStat: response.data.myUserStat
        });
        return response.data.myUserStat;
      }
    };
  }

  componentDidMount() { }

  render() {
    return (
      <React.Fragment>
        <Provider value={this.state}>{this.props.children}</Provider>
      </React.Fragment>
    );
  }
}
