import React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { SubRow } from './SubRow';
import { SimpleRow } from './SimpleRow';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DataTable = props => {
  const { columns, rows } = props;

  const classes = useStyles();


  return (
    <TableContainer>
      <div className={classes.inner}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              row.hasSubRow
              ? <SubRow key={row.id} classes={classes} columns={columns} row={row} />
              : <SimpleRow key={row.id} classes={classes} columns={columns} row={row} />
            ))}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
};

export default DataTable;
