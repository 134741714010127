import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  CardHeader,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { TranslationContext } from 'contexts/TranslationContext';
import { getIcon, getSubTitle } from 'common/common';

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  actionOfMember: {
    backgroundColor: "#f4f6f8",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: 250
    },
  },
  ...theme.common,
}));

const ActionsOfMembers = ({ actionOfOtherMembers, setActionOfOtherMembers }) => {
  const classes = useStyles();
  const { t } = React.useContext(TranslationContext);

  const getTexts = (data) => {
    switch (data.state) {
      case 0:
        return [t("article-card-rdv")];
      case 1:
        return [t("article-card-no-resp"), (data.reminder ? t("article-card-no-resp-reminder") : '')];
      case 2:
        return [t("article-card-already-in-agency")];
      case 3:
        return [t("article-card-already-sold")];
      case 4:
        return [t("article-card-not-available")];
      case 5:
        return [t("article-card-not-interested"), (data.reminder ? t("article-card-not-interested-reminder") : '')];
      case 6:
        return [t("article-card-not-interesting")];
      default:
        return [t("article-card-na")];
    }
  };
  
  if (!actionOfOtherMembers) {
    return null;
  }

  return (
    <Dialog
      open={true}
      onClose={() => setActionOfOtherMembers(null)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{"Actions"}</DialogTitle>
      <DialogContent>
        {
          actionOfOtherMembers.map(action =>
            <Box component="div" className={classes.actionOfMember}>
              <Typography variant="h5"> { action.userName.toUpperCase() } </Typography>
              <Typography variant="caption">{moment(action.dateModified + "Z").format('LLL')}</Typography>
              <CardHeader
                avatar={getIcon(action)}
                title={getTexts(action)}
                subheader={getSubTitle(action)}
              />
            </Box>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={() => setActionOfOtherMembers(null)}>
          {t("common-close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionsOfMembers;