const axios = require('axios').default;

let baseUrl = '';
if (process.env.NODE_ENV !== 'production') {
  baseUrl = 'http://localhost:60158/';
} else {
  baseUrl = 'https://api.hometracker.be/';
}
const graphqlUrl = `${baseUrl}graphql`;
const userApiUrl = `${baseUrl}api/user/`;

export async function getMyHistory(skip, take) {
  let data = {
    query:
      'query GetMyHistory($skip: Int!, $take: Int!) { getMyHistory(skip: $skip, take: $take) { id dateCreated historyType extra } }',
    variables: `{ "skip": ${skip}, "take": ${take} }`
  };

  return axios.post(graphqlUrl, data);
}

export async function searchUser(search) {
  let data = {
    query:
      'query SearchUser($search: String!) { searchUser(search: $search) { id name email companyMemberId } }',
    variables: `{ "search": "${search}" }`
  };

  return axios.post(graphqlUrl, data);
}

export async function searchCompany(search) {
  let data = {
    query:
      'query SearchCompany($search: String!) { searchCompany(search: $search) { id name } }',
    variables: `{ "search": "${search}" }`
  };

  return axios.post(graphqlUrl, data);
}

export async function saveMyUserImage(imageBase64) {
  let data = {
    query:
      'mutation SetMyImage($base64: String!) { setMyImage(base64: $base64) { image } }',
    variables: `{ "base64": ${JSON.stringify(imageBase64)}}`
  };

  return axios.post(graphqlUrl, data);
}

export async function getMyUserImage() {
  let data = {
    query: 'query { getMyImage { image } }'
  };

  return axios.post(graphqlUrl, data);
}

export async function saveUserDate(userDate) {
  let data = {
    query:
      'mutation AddUserDate($userDate: UserDateInputType!) { addUserDate(userDate: $userDate) { id } }',
    variables: `{ "userDate": ${JSON.stringify(userDate)}}`
  };

  return axios.post(graphqlUrl, data);
}

export async function getMyUserDates(date) {
  let data = {
    query:
      'query MyUserDates($date: DateTime!) { myUserDates(date: $date) { id dateType title startDateTime endDateTime ownerName ownerAddress ownerPhoneNumber note user { id name }  } }',
    variables: `{ "date": ${JSON.stringify(date)} }`
  };

  return axios.post(graphqlUrl, data);
}

export async function addNewUserToCompany(name, email, companyId) {
  let data = {
    query:
      'mutation AddNewUserToCompany($name: String!, $email: String!, $companyId: Int!) { addNewUserToCompany(name: $name, email: $email, companyId: $companyId) { id } }',
    variables: `{ "name": '${name}', "email": '${email}', "companyId": ${companyId} }`
  };

  return axios.post(graphqlUrl, data);
}

export async function loadMyCompanyStats(companyId) {
  let data = {
    query:
      'query MyCompanyUserStat($companyId: Int!) { myCompanyUserStat(companyId: $companyId) { id name phoneCallCount rdvCount signedCount  } }',
    variables: `{ "companyId": ${companyId} }`
  };

  return axios.post(graphqlUrl, data);
}

export async function addNewUser(name, accountType, email, companyId) {
  let data = {
    query:
      'mutation ($name: String!, $accountType: Int!, $email: String!, $companyId: Int) { addNewUser(name: $name, accountType: $accountType, email: $email, companyId: $companyId) { id } }',
    variables: `{ "name": '${name}', "accountType": '${accountType}' , "email": '${email}', "companyId": ${companyId} }`
  };

  return axios.post(graphqlUrl, data);
}

export async function addNewCompany(name, email) {
  let data = {
    query:
      'mutation ($name: String!, $email: String!) { addNewCompany(name: $name, email: $email) { id } }',
    variables: `{ "name": '${name}', "email": '${email}' }`
  };

  return axios.post(graphqlUrl, data);
}

export async function saveRdvSummaryResponse(id, response) {
  let data = {
    query:
      'mutation ($userRdvSummaryResponseId: Int!, $response: Int!) { saveRdvSummaryResponse(userRdvSummaryResponseId: $userRdvSummaryResponseId, response: $response) { id } }',
    variables: `{ "userRdvSummaryResponseId": ${id}, "response": ${response} }`
  };

  return axios.post(graphqlUrl, data);
}

export async function loadMyUserRdvSummaries() {
  let data = {
    query:
      'query { myUserRdvSummaries { id rdvDate dateResponded response property { id title url pictureUrl } ownerAddress } }'
  };

  return axios.post(graphqlUrl, data);
}

export async function loadMySignedMandatInfo(skip) {
  let data = {
    query:
      'query MySignedMandatInfo($skip: Int!) { mySignedMandatInfo(skip: $skip) { id ownerName ownerAddress ownerPhone rdvDate user { name } } }',
    variables: `{ "skip": ${skip} }`
  };

  return axios.post(graphqlUrl, data);
}

export async function loadMyWaitingUserRdvSummaries() {
  let data = {
    query:
      'query { myWaitingUserRdvSummaries { id rdvDate property { id title url pictureUrl } userActionProperty { id ownerAddress } user { name } } }'
  };

  return axios.post(graphqlUrl, data);
}

export async function loadMyStat() {
  let data = {
    query:
      'query { myUserStat { id phoneCallCount rdvCount signedCount phoneCallCountOld rdvCountOld signedCountOld } }'
  };

  return axios.post(graphqlUrl, data);
}

export async function updateMyUserInfo(obj) {
  let data = {
    query:
      'mutation ($userInfo: UserInputType!) { updateUserInfo(userInfo: $userInfo) { id } }',
    variables: `{ "userInfo": ${JSON.stringify(obj)}}`
  };

  return axios.post(graphqlUrl, data);
}

export async function getMyUserInfo() {
  let data = {
    query:
      'query { myUserInfo { id email name userStatus phoneNumber language gender zipCode address city vAT companyAdminId accountType clientType userIdentifier isAdminOfCompany isProAccount companyMember { name } } }'
  };

  return axios.post(graphqlUrl, data);
}

export async function login(email, password) {
  return axios.post(`${userApiUrl}auth/?email=${email}&password=${password}`);
}

export async function resetPassword(token, newPassword) {
  return axios.post(
    `${userApiUrl}ResetPassword/?token=${token}&newPassword=${newPassword}`
  );
}

export async function requestPassword(email) {
  return axios.get(`${userApiUrl}RequestResetPassword/?email=${email}`);
}

export async function logout(tokenUser) {
  return axios.get(`${userApiUrl}logout/?token=${tokenUser}`);
}

export async function getImports() {
  let data = {
    query:
      'query { imports { id startDateTime endDateTime importLogs { importLogSeverity message } } }'
  };

  return axios.post(graphqlUrl, data);
}

export async function getScrapingInfo(siteName) {
  let data = {
    query:
      'query ScrapingInfo($siteName: String!) { scrapingInfo(siteName: $siteName) { id latestUrls { id key url } } }',
    variables: `{ "siteName": '${siteName}' }`
  };

  return axios.post(graphqlUrl, data);
}

export async function getProperties(
  alreadyHadAction,
  skip,
  take,
  source,
  service,
  category,
  zipCodes,
  minPrice,
  maxPrice,
  stateIds,
  peb,
  date
) {
  let data = {
    query:
      'query Properties' +
      '($date: Int, $peb: Boolean, $alreadyHadAction: Boolean, $skip: Int, $take: Int , $source : Int, $service: Int, $category: [Int], $zipCodes: [Int!], $minPrice: Int, $maxPrice: Int, $stateIds: [Int])' +
      '{ ' +
      'propertyGrid' +
      '(' +
      'date: $date, peb: $peb, alreadyHadAction: $alreadyHadAction, take: $take, skip: $skip , source : $source, service: $service, category: $category,' +
      ' zipCodes: $zipCodes, minPrice: $minPrice, maxPrice: $maxPrice, stateIds: $stateIds' +
      ')' +
      '{' +
      'skip, totalCount, size, results ' +
      '{ ' +
      'id, title, price, priceAsText, zipCode, city, hasPEB, hasPhone, pictureUrl, url, source, propertyType, serviceType, date' +
      (alreadyHadAction // to specify if we want the use action of the user on each property
        ? ', userStatus { state, reminder, dateModified, rendezVousDate, note }'
        : ', actionsOfCompany { state, reminder, dateModified, rendezVousDate, note, userName }') +
      '}' +
      '}' +
      '}',
    variables: `{ "date": ${date}, "peb": ${peb}, "alreadyHadAction": ${alreadyHadAction}, "skip": ${skip}, "take": ${take} , "source" : ${source}, "service": ${service}, "category": ${JSON.stringify(
      category
    )}, "zipCodes": ${JSON.stringify(
      zipCodes
    )}, "minPrice": ${minPrice}, "maxPrice": ${maxPrice}, "stateIds": ${JSON.stringify(
      stateIds
    )} }`
  };

  return axios.post(graphqlUrl, data);
}

export async function addUserAction(obj) {
  let data = {
    query:
      'mutation AddUserAction($userAction: UserActionPropertyInputType!) { addUserAction(userAction: $userAction) { id } }',
    variables: `{ "userAction": ${JSON.stringify(obj)}}`
  };

  return axios.post(graphqlUrl, data);
}

export async function saveContactForm(data) {
  return axios.post(`${userApiUrl}ContactForm`, data);
}

export async function getUsersActions(userId, skip) {
  let data = {
    query:
      'query UserActions($userId: Int, $skip: Int!) { userActions(skip: $skip, userId: $userId) { id dateCreated state userName } }',
    variables: `{ "userId": ${userId}, "skip": ${skip} }`
  };

  return axios.post(graphqlUrl, data);
}

export async function getLogins(skip) {
  let data = {
    query:
      'query GetLogins($skip: Int!) { getLogins(skip: $skip) { id dateCreated userName } }',
    variables: `{ "skip": ${skip} }`
  };

  return axios.post(graphqlUrl, data);
}

export async function getAllUsers() {
  let data = {
    query: 'query { getAllUsers { id name email } }'
  };

  return axios.post(graphqlUrl, data);
}
