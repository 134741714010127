const Localities = [
  { "type": "L", "code_postal": "6810", "name": "Jamoigne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "CHINY", "commune_niscode": "85007", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3770", "name": "Kanne", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "RIEMST", "commune_niscode": "73066", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Klein-Gelmen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Koninksem", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2890", "name": "Lippelo", "province": "Anvers", "province_niscode": "10000", "commune_principale": "PUURS-SINT-AMANDS", "commune_niscode": "12041", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6042", "name": "Lodelinsart", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3460", "name": "Assent", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BEKKEVOORT", "commune_niscode": "24008", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5580", "name": "Ave-Et-Auffe", "province": "Namur", "province_niscode": "90000", "commune_principale": "ROCHEFORT", "commune_niscode": "91114", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9690", "name": "Berchem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KLUISBERGEN", "commune_niscode": "45060", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6531", "name": "Biesme-Sous-Thuin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "THUIN", "commune_niscode": "56078", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4607", "name": "Bombaye", "province": "Liège", "province_niscode": "60000", "commune_principale": "DALHEM", "commune_niscode": "62027", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5021", "name": "Boninne", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5032", "name": "Bothey", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6927", "name": "Bure", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "TELLIN", "commune_niscode": "84068", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6743", "name": "Buzenol", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ETALLE", "commune_niscode": "85009", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Chairière", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4537", "name": "Chapon-Seraing", "province": "Liège", "province_niscode": "60000", "commune_principale": "VERLAINE", "commune_niscode": "61063", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9170", "name": "De Klinge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-GILLIS-WAAS", "commune_niscode": "46020", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3740", "name": "Eigenbilzen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9790", "name": "Elsegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "WORTEGEM-PETEGEM", "commune_niscode": "45061", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4053", "name": "Embourg", "province": "Liège", "province_niscode": "60000", "commune_principale": "CHAUDFONTAINE", "commune_niscode": "62022", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4800", "name": "Ensival", "province": "Liège", "province_niscode": "60000", "commune_principale": "VERVIERS", "commune_niscode": "63079", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6441", "name": "Erpion", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FROIDCHAPELLE", "commune_niscode": "56029", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7080", "name": "Eugies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRAMERIES", "commune_niscode": "53028", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4607", "name": "Feneur", "province": "Liège", "province_niscode": "60000", "commune_principale": "DALHEM", "commune_niscode": "62027", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4181", "name": "Filot", "province": "Liège", "province_niscode": "60000", "commune_principale": "HAMOIR", "commune_niscode": "61024", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5650", "name": "Fraire", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4347", "name": "Freloux", "province": "Liège", "province_niscode": "60000", "commune_principale": "FEXHE-LE-HAUT-CLOCHER", "commune_niscode": "64025", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5651", "name": "Gourdinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6940", "name": "Grandhan", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3723", "name": "Guigoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "KORTESSEM", "commune_niscode": "73040", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1320", "name": "Hamme-Mille", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "BEAUVECHAIN", "commune_niscode": "25005", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6767", "name": "Harnoncourt", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ROUVROY", "commune_niscode": "85047", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8340", "name": "Hoeke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DAMME", "commune_niscode": "31006", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3471", "name": "Hoeleden", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KORTENAKEN", "commune_niscode": "24054", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7110", "name": "Houdeng-Goegnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LA LOUVIÈRE", "commune_niscode": "58001", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1745", "name": "Mazenzele", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "OPWIJK", "commune_niscode": "23060", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Merdorp", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7942", "name": "Mévergnies-Lez-Lens", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUGELETTE", "commune_niscode": "51012", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7070", "name": "Mignault", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LE ROEULX", "commune_niscode": "55035", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5361", "name": "Mohiville", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAMOIS", "commune_niscode": "91059", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3294", "name": "Molenstede", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "DIEST", "commune_niscode": "24020", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1367", "name": "Mont-Saint-André", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "RAMILLIES", "commune_niscode": "25122", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7812", "name": "Moulbaix", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2812", "name": "Muizen", "province": "Anvers", "province_niscode": "10000", "commune_principale": "MECHELEN", "commune_niscode": "12025", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3400", "name": "Neerwinden", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Nerem", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8800", "name": "Oekene", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ROESELARE", "commune_niscode": "36015", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3960", "name": "Opitter", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BREE", "commune_niscode": "72004", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8582", "name": "Outrijve", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "AVELGEM", "commune_niscode": "34003", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7134", "name": "Péronnes-Lez-Binche", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BINCHE", "commune_niscode": "58002", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9790", "name": "Petegem-Aan-De-Schelde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "WORTEGEM-PETEGEM", "commune_niscode": "45061", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8647", "name": "Pollinkhove", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "LO-RENINGE", "commune_niscode": "32030", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4171", "name": "Poulseur", "province": "Liège", "province_niscode": "60000", "commune_principale": "COMBLAIN-AU-PONT", "commune_niscode": "62026", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6464", "name": "Rièzes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3803", "name": "Runkelen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5002", "name": "Saint-Servais", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6982", "name": "Samrée", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LA ROCHE-EN-ARDENNE", "commune_niscode": "83031", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9982", "name": "Sint-Jan-In-Eremo", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-LAUREINS", "commune_niscode": "43014", "arrondissement_niscode": "43000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2960", "name": "Sint-Lenaarts", "province": "Anvers", "province_niscode": "10000", "commune_principale": "BRECHT", "commune_niscode": "11009", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8433", "name": "Slijpe", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MIDDELKERKE", "commune_niscode": "35011", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5680", "name": "Soulme", "province": "Namur", "province_niscode": "90000", "commune_principale": "DOISCHE", "commune_niscode": "93018", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1933", "name": "Sterrebeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZAVENTEM", "commune_niscode": "23094", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2830", "name": "Tisselt", "province": "Anvers", "province_niscode": "10000", "commune_principale": "WILLEBROEK", "commune_niscode": "12040", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4530", "name": "Vaux-Et-Borset", "province": "Liège", "province_niscode": "60000", "commune_principale": "VILLERS-LE-BOUILLET", "commune_niscode": "61068", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9130", "name": "Verrebroek", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BEVEREN-WAAS", "commune_niscode": "46003", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6230", "name": "Viesville", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PONT-À-CELLES", "commune_niscode": "52055", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7812", "name": "Villers-Notre-Dame", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6941", "name": "Villers-Sainte-Gertrude", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9800", "name": "Vinkt", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5680", "name": "Vodelée", "province": "Namur", "province_niscode": "90000", "commune_principale": "DOISCHE", "commune_niscode": "93018", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7131", "name": "Waudrez", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BINCHE", "commune_niscode": "58002", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3401", "name": "Wezeren", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7370", "name": "Wihéries", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "DOUR", "commune_niscode": "53020", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8980", "name": "Zandvoorde", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZONNEBEKE", "commune_niscode": "33037", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9080", "name": "Zeveneken", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LOCHRISTI", "commune_niscode": "44034", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8511", "name": "Aalbeke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KORTRIJK", "commune_niscode": "34022", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5590", "name": "Achêne", "province": "Namur", "province_niscode": "90000", "commune_principale": "CINEY", "commune_niscode": "91030", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6250", "name": "Aiseau", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "AISEAU-PRESLES", "commune_niscode": "52074", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7972", "name": "Aubechies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BELOEIL", "commune_niscode": "51008", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7387", "name": "Autreppe", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HONNELLES", "commune_niscode": "53083", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6900", "name": "Aye", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MARCHE-EN-FAMENNE", "commune_niscode": "83034", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9111", "name": "Belsele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-NIKLAAS", "commune_niscode": "46021", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4500", "name": "Ben-Ahin", "province": "Liège", "province_niscode": "60000", "commune_principale": "HUY", "commune_niscode": "61031", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3581", "name": "Beverlo", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BERINGEN", "commune_niscode": "71004", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4690", "name": "Boirs", "province": "Liège", "province_niscode": "60000", "commune_principale": "BASSENGE", "commune_niscode": "62011", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1404", "name": "Bornival", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "NIVELLES", "commune_niscode": "25072", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6850", "name": "Carlsbourg", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "PALISEUL", "commune_niscode": "84050", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4987", "name": "Chevron", "province": "Liège", "province_niscode": "60000", "commune_principale": "STOUMONT", "commune_niscode": "63075", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5022", "name": "Cognelée", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7120", "name": "Croix-Lez-Rouveroy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTINNES", "commune_niscode": "58003", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Cul-Des-Sarts", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9160", "name": "Daknam", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LOKEREN", "commune_niscode": "46014", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3440", "name": "Dormaal", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZOUTLEEUW", "commune_niscode": "24130", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1653", "name": "Dworp", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BEERSEL", "commune_niscode": "23003", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1671", "name": "Elingen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "PEPINGEN", "commune_niscode": "23064", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5530", "name": "Evrehailles", "province": "Namur", "province_niscode": "90000", "commune_principale": "YVOIR", "commune_niscode": "91141", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6141", "name": "Forchies-La-Marche", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FONTAINE-L'EVÊQUE", "commune_niscode": "52022", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4557", "name": "Fraiture", "province": "Liège", "province_niscode": "60000", "commune_principale": "TINLOT", "commune_niscode": "61081", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3800", "name": "Gelinden", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7070", "name": "Gottignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LE ROEULX", "commune_niscode": "55035", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6534", "name": "Gozée", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "THUIN", "commune_niscode": "56078", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7950", "name": "Grosage", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIÈVRES", "commune_niscode": "51014", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7321", "name": "Harchies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BERNISSART", "commune_niscode": "51009", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5540", "name": "Hastière-Lavaux", "province": "Namur", "province_niscode": "90000", "commune_principale": "HASTIÈRE", "commune_niscode": "91142", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1461", "name": "Haut-Ittre", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "ITTRE", "commune_niscode": "25044", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8301", "name": "Heist-Aan-Zee", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KNOKKE-HEIST", "commune_niscode": "31043", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9571", "name": "Hemelveerdegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LIERDE", "commune_niscode": "45063", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7090", "name": "Hennuyères", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRAINE-LE-COMTE", "commune_niscode": "55004", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3191", "name": "Hever", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BOORTMEERBEEK", "commune_niscode": "24014", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8377", "name": "Houtave", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZUIENKERKE", "commune_niscode": "31042", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9340", "name": "Impe", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LEDE", "commune_niscode": "41034", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1370", "name": "Jodoigne-Souveraine", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "JODOIGNE", "commune_niscode": "25048", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3010", "name": "Kessel Lo", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LEUVEN", "commune_niscode": "24062", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3890", "name": "Kortijs", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "GINGELOM", "commune_niscode": "71017", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7950", "name": "Ladeuze", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIÈVRES", "commune_niscode": "51014", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9050", "name": "Ledeberg", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1300", "name": "Limal", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "WAVRE", "commune_niscode": "25112", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1471", "name": "Loupoigne", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GENAPPE", "commune_niscode": "25031", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6593", "name": "Macquenoise", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MOMIGNIES", "commune_niscode": "56051", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5330", "name": "Maillen", "province": "Namur", "province_niscode": "90000", "commune_principale": "ASSESSE", "commune_niscode": "92006", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6987", "name": "Marcourt", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "RENDEUX", "commune_niscode": "83044", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3630", "name": "Mechelen-Aan-De-Maas", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "MAASMECHELEN", "commune_niscode": "73107", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2321", "name": "Meer", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HOOGSTRATEN", "commune_niscode": "13014", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Meilegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2170", "name": "Merksem", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ANTWERPEN", "commune_niscode": "11002", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9660", "name": "Nederbrakel", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BRAKEL", "commune_niscode": "45059", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3620", "name": "Neerharen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "LANAKEN", "commune_niscode": "73042", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3370", "name": "Neervelp", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BOUTERSEM", "commune_niscode": "24016", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8647", "name": "Noordschote", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "LO-RENINGE", "commune_niscode": "32030", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5380", "name": "Noville-Les-Bois", "province": "Namur", "province_niscode": "90000", "commune_principale": "FERNELMONT", "commune_niscode": "92138", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7743", "name": "Obigies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PECQ", "commune_niscode": "57062", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3300", "name": "Oorbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIENEN", "commune_niscode": "24107", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2890", "name": "Oppuurs", "province": "Anvers", "province_niscode": "10000", "commune_principale": "PUURS-SINT-AMANDS", "commune_niscode": "12041", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Pesche", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9800", "name": "Petegem-Aan-De-Leie", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Petite-Chapelle", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8920", "name": "Poelkapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "LANGEMARK-POELKAPELLE", "commune_niscode": "33040", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7040", "name": "Quévy-Le-Grand", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUÉVY", "commune_niscode": "53084", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6043", "name": "Ransart", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1731", "name": "Relegem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ASSE", "commune_niscode": "23002", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6830", "name": "Rochehaut", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BOUILLON", "commune_niscode": "84010", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8800", "name": "Rumbeke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ROESELARE", "commune_niscode": "36015", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Saint-Germain", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7730", "name": "Saint-Léger", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTAIMPUIS", "commune_niscode": "57027", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4845", "name": "Sart-Lez-Spa", "province": "Liège", "province_niscode": "60000", "commune_principale": "JALHAY", "commune_niscode": "63038", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5070", "name": "Sart-Saint-Laurent", "province": "Namur", "province_niscode": "90000", "commune_principale": "FOSSES-LA-VILLE", "commune_niscode": "92048", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6470", "name": "Sautin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SIVRY-RANCE", "commune_niscode": "56088", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3730", "name": "Sint-Huibrechts-Hern", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HOESELT", "commune_niscode": "73032", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1600", "name": "Sint-Laureins-Berchem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "SINT-PIETERS-LEEUW", "commune_niscode": "23077", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6662", "name": "Tavigny", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HOUFFALIZE", "commune_niscode": "82014", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6840", "name": "Tournay", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NEUFCHÂTEAU", "commune_niscode": "84043", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6890", "name": "Transinne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBIN", "commune_niscode": "84035", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7100", "name": "Trivières", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LA LOUVIÈRE", "commune_niscode": "58001", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9290", "name": "Uitbergen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BERLARE", "commune_niscode": "42003", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4051", "name": "Vaux-Sous-Chèvremont", "province": "Liège", "province_niscode": "60000", "commune_principale": "CHAUDFONTAINE", "commune_niscode": "62022", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2431", "name": "Veerle", "province": "Anvers", "province_niscode": "10000", "commune_principale": "LAAKDAL", "commune_niscode": "13053", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3620", "name": "Veldwezelt", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "LANAKEN", "commune_niscode": "73042", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9850", "name": "Vosselare", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7390", "name": "Wasmuel", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUAREGNON", "commune_niscode": "53065", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2275", "name": "Wechelderzande", "province": "Anvers", "province_niscode": "10000", "commune_principale": "LILLE", "commune_niscode": "13019", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5570", "name": "Winenne", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3722", "name": "Wintershoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "KORTESSEM", "commune_niscode": "73040", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8952", "name": "Wulvergem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HEUVELLAND", "commune_niscode": "33039", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9930", "name": "Zomergem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LIEVEGEM", "commune_niscode": "44085", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6791", "name": "Athus", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "AUBANGE", "commune_niscode": "81004", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8630", "name": "Avekapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VEURNE", "commune_niscode": "38025", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Beerlegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6941", "name": "Bende", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5640", "name": "Biesmerée", "province": "Namur", "province_niscode": "90000", "commune_principale": "METTET", "commune_niscode": "92087", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5300", "name": "Coutisse", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANDENNE", "commune_niscode": "92003", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9450", "name": "Denderhoutem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HAALTERT", "commune_niscode": "41024", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5537", "name": "Denée", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANHÉE", "commune_niscode": "91005", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8792", "name": "Desselgem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "WAREGEM", "commune_niscode": "34040", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9042", "name": "Desteldonk", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3803", "name": "Duras", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4690", "name": "Eben-Emael", "province": "Liège", "province_niscode": "60000", "commune_principale": "BASSENGE", "commune_niscode": "62011", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4631", "name": "Evegnée", "province": "Liège", "province_niscode": "60000", "commune_principale": "SOUMAGNE", "commune_niscode": "62099", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7170", "name": "Fayt-Lez-Manage", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MANAGE", "commune_niscode": "55086", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3790", "name": "Fouron-Saint-Martin", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "FOURONS", "commune_niscode": "73109", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5150", "name": "Franière", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLOREFFE", "commune_niscode": "92045", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2275", "name": "Gierle", "province": "Anvers", "province_niscode": "10000", "commune_principale": "LILLE", "commune_niscode": "13019", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Gotem", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5621", "name": "Hanzinelle", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLORENNES", "commune_niscode": "93022", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1130", "name": "Haren", "province": "Région de Bruxelles-Capitale", "province_niscode": "01000", "commune_principale": "BRUXELLES", "commune_niscode": "21004", "arrondissement_niscode": "21000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6960", "name": "Harre", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MANHAY", "commune_niscode": "83055", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5541", "name": "Hastière-Par-Delà", "province": "Namur", "province_niscode": "90000", "commune_principale": "HASTIÈRE", "commune_niscode": "91142", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5543", "name": "Heer", "province": "Namur", "province_niscode": "90000", "commune_principale": "HASTIÈRE", "commune_niscode": "91142", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2830", "name": "Heindonk", "province": "Anvers", "province_niscode": "10000", "commune_principale": "WILLEBROEK", "commune_niscode": "12040", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1790", "name": "Hekelgem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "AFFLIGEM", "commune_niscode": "23105", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3530", "name": "Helchteren", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HOUTHALEN-HELCHTEREN", "commune_niscode": "72039", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7830", "name": "Hellebecq", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SILLY", "commune_niscode": "51068", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4671", "name": "Housse", "province": "Liège", "province_niscode": "60000", "commune_principale": "BLÉGNY", "commune_niscode": "62119", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8630", "name": "Houtem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VEURNE", "commune_niscode": "38025", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6941", "name": "Izier", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3890", "name": "Jeuk", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "GINGELOM", "commune_niscode": "71017", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2560", "name": "Kessel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "NIJLEN", "commune_niscode": "12026", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8920", "name": "Langemark", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "LANGEMARK-POELKAPELLE", "commune_niscode": "33040", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Mal", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9030", "name": "Mariakerke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1350", "name": "Marilles", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "ORP-JAUCHE", "commune_niscode": "25120", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7822", "name": "Meslin-L'Evêque", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7141", "name": "Mont-Sainte-Aldegonde", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MORLANWELZ", "commune_niscode": "58004", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3890", "name": "Montenaken", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "GINGELOM", "commune_niscode": "71017", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6560", "name": "Montignies-Saint-Christophe", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ERQUELINNES", "commune_niscode": "56022", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4608", "name": "Neufchâteau", "province": "Liège", "province_niscode": "60000", "commune_principale": "DALHEM", "commune_niscode": "62027", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6851", "name": "Nollevaux", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "PALISEUL", "commune_niscode": "84050", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9520", "name": "Oombergen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-LIEVENS-HOUTEM", "commune_niscode": "41063", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9420", "name": "Ottergem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ERPE-MERE", "commune_niscode": "41082", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1340", "name": "Ottignies", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "OTTIGNIES-LOUVAIN-LA-NEUVE", "commune_niscode": "25121", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3212", "name": "Pellenberg", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LUBBEEK", "commune_niscode": "24066", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4350", "name": "Pousset", "province": "Liège", "province_niscode": "60000", "commune_principale": "REMICOURT", "commune_niscode": "64063", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Presgaux", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6532", "name": "Ragnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "THUIN", "commune_niscode": "56078", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6470", "name": "Rance", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SIVRY-RANCE", "commune_niscode": "56088", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4790", "name": "Reuland", "province": "Liège", "province_niscode": "60000", "commune_principale": "BURG-REULAND", "commune_niscode": "63087", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Riksingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7623", "name": "Rongy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUNEHAUT", "commune_niscode": "57093", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6730", "name": "Saint-Vincent", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "TINTIGNY", "commune_niscode": "85039", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Sautour", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8700", "name": "Schuiferskapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "TIELT", "commune_niscode": "37015", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6832", "name": "Sensenruth", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BOUILLON", "commune_niscode": "84010", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5630", "name": "Senzeilles", "province": "Namur", "province_niscode": "90000", "commune_principale": "CERFONTAINE", "commune_niscode": "93010", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6640", "name": "Sibret", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VAUX-SUR-SÛRE", "commune_niscode": "82036", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9667", "name": "Sint-Maria-Horebeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HOREBEKE", "commune_niscode": "45062", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7618", "name": "Taintignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "RUMES", "commune_niscode": "57072", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3390", "name": "Tielt", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIELT-WINGE", "commune_niscode": "24135", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1495", "name": "Tilly", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "VILLERS-LA-VILLE", "commune_niscode": "25107", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2431", "name": "Varendonk", "province": "Anvers", "province_niscode": "10000", "commune_principale": "LAAKDAL", "commune_niscode": "13053", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Vechmaal", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8902", "name": "Voormezele", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IEPER", "commune_niscode": "33011", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3740", "name": "Waltwilder", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6224", "name": "Wanfercée-Baulet", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FLEURUS", "commune_niscode": "52021", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9988", "name": "Watervliet", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-LAUREINS", "commune_niscode": "43014", "arrondissement_niscode": "43000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4860", "name": "Wegnez", "province": "Liège", "province_niscode": "60000", "commune_principale": "PEPINSTER", "commune_niscode": "63058", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2040", "name": "Zandvliet", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ANTWERPEN", "commune_niscode": "11002", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3545", "name": "Zelem", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HALEN", "commune_niscode": "71020", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1731", "name": "Zellik", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ASSE", "commune_niscode": "23002", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8904", "name": "Zuidschote", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IEPER", "commune_niscode": "33011", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1430", "name": "Rebecq-Rognon", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "REBECQ", "commune_niscode": "25123", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7134", "name": "Ressaix", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BINCHE", "commune_niscode": "58002", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6210", "name": "Rèves", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LES BONS VILLERS", "commune_niscode": "52075", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Rienne", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Roly", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Romedenne", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3732", "name": "Schalkhoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HOESELT", "commune_niscode": "73032", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5300", "name": "Sclayn", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANDENNE", "commune_niscode": "92003", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9040", "name": "Sint-Amandsberg", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9340", "name": "Smetlede", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LEDE", "commune_niscode": "41034", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8470", "name": "Snaaskerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "GISTEL", "commune_niscode": "35005", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6500", "name": "Solre-Saint-Géry", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BEAUMONT", "commune_niscode": "56005", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7070", "name": "Thieu", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LE ROEULX", "commune_niscode": "55035", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6500", "name": "Thirimont", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BEAUMONT", "commune_niscode": "56005", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3960", "name": "Tongerlo", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BREE", "commune_niscode": "72004", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7120", "name": "Vellereille-Le-Sec", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTINNES", "commune_niscode": "58003", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6890", "name": "Villance", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBIN", "commune_niscode": "84035", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5580", "name": "Villers-Sur-Lesse", "province": "Namur", "province_niscode": "90000", "commune_principale": "ROCHEFORT", "commune_niscode": "91114", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3721", "name": "Vliermaalroot", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "KORTESSEM", "commune_niscode": "73040", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9400", "name": "Voorde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3630", "name": "Vucht", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "MAASMECHELEN", "commune_niscode": "73107", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3401", "name": "Walshoutem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3018", "name": "Wijgmaal", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LEUVEN", "commune_niscode": "24062", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8431", "name": "Wilskerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MIDDELKERKE", "commune_niscode": "35011", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4550", "name": "Yernée-Fraineux", "province": "Liège", "province_niscode": "60000", "commune_principale": "NANDRIN", "commune_niscode": "61043", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8400", "name": "Zandvoorde", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "OOSTENDE", "commune_niscode": "35013", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3800", "name": "Zepperen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5544", "name": "Agimont", "province": "Namur", "province_niscode": "90000", "commune_principale": "HASTIÈRE", "commune_niscode": "91142", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9400", "name": "Appelterre-Eichem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7382", "name": "Audregnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUIÉVRAIN", "commune_niscode": "53068", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1367", "name": "Autre-Eglise", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "RAMILLIES", "commune_niscode": "25122", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9881", "name": "Bellem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALTER", "commune_niscode": "44084", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7903", "name": "Blicquy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LEUZE-EN-HAINAUT", "commune_niscode": "57094", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5020", "name": "Champion", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7063", "name": "Chaussée-Notre-Dame-Louvignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SOIGNIES", "commune_niscode": "55040", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6838", "name": "Corbion", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BOUILLON", "commune_niscode": "84010", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5363", "name": "Emptinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAMOIS", "commune_niscode": "91059", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1350", "name": "Enines", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "ORP-JAUCHE", "commune_niscode": "25120", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5030", "name": "Ernage", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9420", "name": "Erondegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ERPE-MERE", "commune_niscode": "41082", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5522", "name": "Falaën", "province": "Namur", "province_niscode": "90000", "commune_principale": "ONHAYE", "commune_niscode": "91103", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7181", "name": "Feluy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SENEFFE", "commune_niscode": "55085", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7910", "name": "Forest", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8940", "name": "Geluwe", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "WERVIK", "commune_niscode": "33029", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2590", "name": "Gestel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "BERLAAR", "commune_niscode": "12002", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1473", "name": "Glabais", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GENAPPE", "commune_niscode": "25031", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3803", "name": "Gorsem", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9800", "name": "Grammene", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2980", "name": "Halle", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ZOERSEL", "commune_niscode": "11055", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6987", "name": "Hodister", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "RENDEUX", "commune_niscode": "83044", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9472", "name": "Iddergem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DENDERLEEUW", "commune_niscode": "41011", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6810", "name": "Izel", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "CHINY", "commune_niscode": "85007", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7540", "name": "Kain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Keiem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4701", "name": "Kettenis", "province": "Liège", "province_niscode": "60000", "commune_principale": "EUPEN", "commune_niscode": "63023", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6464", "name": "L'Escaillère", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6238", "name": "Luttre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PONT-À-CELLES", "commune_niscode": "52055", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7810", "name": "Maffle", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3746", "name": "Hoelbeek", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4101", "name": "Jemeppe-Sur-Meuse", "province": "Liège", "province_niscode": "60000", "commune_principale": "SERAING", "commune_niscode": "62096", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7620", "name": "Jollain-Merlin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUNEHAUT", "commune_niscode": "57093", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Kerniel", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5580", "name": "Lessive", "province": "Namur", "province_niscode": "90000", "commune_principale": "ROCHEFORT", "commune_niscode": "91114", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2870", "name": "Liezele", "province": "Anvers", "province_niscode": "10000", "commune_principale": "PUURS-SINT-AMANDS", "commune_niscode": "12041", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7812", "name": "Ligne", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5573", "name": "Martouzin-Neuville", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7640", "name": "Maubray", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ANTOING", "commune_niscode": "57003", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3078", "name": "Meerbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KORTENBERG", "commune_niscode": "24055", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4770", "name": "Meyerode", "province": "Liège", "province_niscode": "60000", "commune_principale": "AMBLÈVE", "commune_niscode": "63001", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7543", "name": "Mourcourt", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5190", "name": "Moustier-Sur-Sambre", "province": "Namur", "province_niscode": "90000", "commune_principale": "JEMEPPE-SUR-SAMBRE", "commune_niscode": "92140", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Mullem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1357", "name": "Neerheylissem", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "HÉLÉCINE", "commune_niscode": "25118", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3040", "name": "Neerijse", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HULDENBERG", "commune_niscode": "24045", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3668", "name": "Niel-Bij-As", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "AS", "commune_niscode": "71002", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9100", "name": "Nieuwkerken-Waas", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-NIKLAAS", "commune_niscode": "46021", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Paulatem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4540", "name": "Ampsin", "province": "Liège", "province_niscode": "60000", "commune_principale": "AMAY", "commune_niscode": "61003", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5170", "name": "Arbre", "province": "Namur", "province_niscode": "90000", "commune_principale": "PROFONDEVILLE", "commune_niscode": "92101", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7910", "name": "Arc-Wattripont", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Avin", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9860", "name": "Balegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OOSTERZELE", "commune_niscode": "44052", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Batsheers", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9150", "name": "Bazel", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KRUIBEKE", "commune_niscode": "46013", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8904", "name": "Boezinge", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IEPER", "commune_niscode": "33011", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9552", "name": "Borsbeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HERZELE", "commune_niscode": "41027", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Bourseigne-Vieille", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1501", "name": "Buizingen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HALLE", "commune_niscode": "23027", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7870", "name": "Cambron-Saint-Vincent", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LENS", "commune_niscode": "53046", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5560", "name": "Ciergnon", "province": "Namur", "province_niscode": "90000", "commune_principale": "HOUYET", "commune_niscode": "91072", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7910", "name": "Cordes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6972", "name": "Erneuville", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "TENNEVILLE", "commune_niscode": "83049", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9940", "name": "Ertvelde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "EVERGEM", "commune_niscode": "44019", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9680", "name": "Etikhove", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MAARKEDAL", "commune_niscode": "45064", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Fagnolle", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9050", "name": "Gentbrugge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4834", "name": "Goé", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIMBOURG", "commune_niscode": "63046", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5030", "name": "Grand-Manil", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6560", "name": "Grand-Reng", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ERQUELINNES", "commune_niscode": "56022", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1702", "name": "Groot-Bijgaarden", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "DILBEEK", "commune_niscode": "23016", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5555", "name": "Gros-Fays", "province": "Namur", "province_niscode": "90000", "commune_principale": "BIÈVRE", "commune_niscode": "91015", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6950", "name": "Harsin", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NASSOGNE", "commune_niscode": "83040", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1701", "name": "Itterbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "DILBEEK", "commune_niscode": "23016", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8420", "name": "Klemskerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DE HAAN", "commune_niscode": "35029", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Kolmont", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8972", "name": "Krombeke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "POPERINGE", "commune_niscode": "33021", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7080", "name": "La Bouverie", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRAMERIES", "commune_niscode": "53028", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7890", "name": "Lahamaide", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ELLEZELLES", "commune_niscode": "51017", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6220", "name": "Lambusart", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FLEURUS", "commune_niscode": "52021", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2240", "name": "Massenhoven", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ZANDHOVEN", "commune_niscode": "11054", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5372", "name": "Méan", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAVELANGE", "commune_niscode": "91064", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3391", "name": "Meensel-Kiezegem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIELT-WINGE", "commune_niscode": "24135", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9120", "name": "Melsele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BEVEREN-WAAS", "commune_niscode": "46003", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4837", "name": "Membach", "province": "Liège", "province_niscode": "60000", "commune_principale": "BAELEN", "commune_niscode": "63004", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5560", "name": "Mesnil-Saint-Blaise", "province": "Namur", "province_niscode": "90000", "commune_principale": "HOUYET", "commune_niscode": "91072", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6031", "name": "Monceau-Sur-Sambre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7350", "name": "Montroeul-Sur-Haine", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HENSIES", "commune_niscode": "53039", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4850", "name": "Moresnet", "province": "Liège", "province_niscode": "60000", "commune_principale": "PLOMBIÈRES", "commune_niscode": "63088", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3350", "name": "Neerhespen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LINTER", "commune_niscode": "24133", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3350", "name": "Neerlinter", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LINTER", "commune_niscode": "24133", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9340", "name": "Oordegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LEDE", "commune_niscode": "41034", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3300", "name": "Oplinter", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIENEN", "commune_niscode": "24107", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3381", "name": "Kapellen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GLABBEEK", "commune_niscode": "24137", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6567", "name": "Labuissière", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MERBES-LE-CHÂTEAU", "commune_niscode": "56049", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6821", "name": "Lacuisine", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "FLORENVILLE", "commune_niscode": "85011", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2040", "name": "Lillo", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ANTWERPEN", "commune_niscode": "11002", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1348", "name": "Louvain-La-Neuve", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "OTTIGNIES-LOUVAIN-LA-NEUVE", "commune_niscode": "25121", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7540", "name": "Quartes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8647", "name": "Reninge", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "LO-RENINGE", "commune_niscode": "32030", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3202", "name": "Rillaar", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "AARSCHOT", "commune_niscode": "24001", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4347", "name": "Roloux", "province": "Liège", "province_niscode": "60000", "commune_principale": "FEXHE-LE-HAUT-CLOCHER", "commune_niscode": "64025", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9932", "name": "Ronsele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LIEVEGEM", "commune_niscode": "44085", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3650", "name": "Rotem", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "DILSEN-STOKKEM", "commune_niscode": "72041", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8020", "name": "Ruddervoorde", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "OOSTKAMP", "commune_niscode": "31022", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3400", "name": "Rumsdorp", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5640", "name": "Saint-Gérard", "province": "Namur", "province_niscode": "90000", "commune_principale": "METTET", "commune_niscode": "92087", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Sart-En-Fagne", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5361", "name": "Scy", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAMOIS", "commune_niscode": "91059", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6596", "name": "Seloignes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MOMIGNIES", "commune_niscode": "56051", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Sint-Blasius-Boekel", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7032", "name": "Spiennes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7110", "name": "Strépy-Bracquegnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LA LOUVIÈRE", "commune_niscode": "58001", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1402", "name": "Thines", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "NIVELLES", "commune_niscode": "25072", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4263", "name": "Tourinne", "province": "Liège", "province_niscode": "60000", "commune_principale": "BRAIVES", "commune_niscode": "64015", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6741", "name": "Vance", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ETALLE", "commune_niscode": "85009", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3370", "name": "Vertrijk", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BOUTERSEM", "commune_niscode": "24016", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2880", "name": "Weert", "province": "Anvers", "province_niscode": "10000", "commune_principale": "BORNEM", "commune_niscode": "12007", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6940", "name": "Wéris", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3730", "name": "Werm", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HOESELT", "commune_niscode": "73032", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Widooie", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3670", "name": "Wijshagen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "OUDSBERGEN", "commune_niscode": "72042", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Abolens", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7387", "name": "Angre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HONNELLES", "commune_niscode": "53083", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5500", "name": "Anseremme", "province": "Namur", "province_niscode": "90000", "commune_principale": "DINANT", "commune_niscode": "91034", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9800", "name": "Bachte-Maria-Leerne", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1470", "name": "Baisy-Thy", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GENAPPE", "commune_niscode": "25031", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6940", "name": "Barvaux-Sur-Ourthe", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1673", "name": "Beert", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "PEPINGEN", "commune_niscode": "23064", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6560", "name": "Bersillies-L'Abbaye", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ERQUELINNES", "commune_niscode": "56022", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4960", "name": "Bevercé", "province": "Liège", "province_niscode": "60000", "commune_principale": "MALMEDY", "commune_niscode": "63049", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7040", "name": "Blaregnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUÉVY", "commune_niscode": "53084", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3890", "name": "Boekhout", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "GINGELOM", "commune_niscode": "71017", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Bohan", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7604", "name": "Braffe", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PÉRUWELZ", "commune_niscode": "57064", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Broekom", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5561", "name": "Celles", "province": "Namur", "province_niscode": "90000", "commune_principale": "HOUYET", "commune_niscode": "91072", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7033", "name": "Cuesmes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1325", "name": "Dion-Valmont", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "CHAUMONT-GISTOUX", "commune_niscode": "25018", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7711", "name": "Dottignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MOUSCRON", "commune_niscode": "57096", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3650", "name": "Elen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "DILSEN-STOKKEM", "commune_niscode": "72041", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1790", "name": "Essene", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "AFFLIGEM", "commune_niscode": "23105", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5560", "name": "Finnevaux", "province": "Namur", "province_niscode": "90000", "commune_principale": "HOUYET", "commune_niscode": "91072", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7863", "name": "Ghoy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LESSINES", "commune_niscode": "51069", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8691", "name": "Gijverinkhove", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ALVERINGEM", "commune_niscode": "38002", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5621", "name": "Hanzinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLORENNES", "commune_niscode": "93022", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Haren", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5620", "name": "Hemptinne-Lez-Florennes", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLORENNES", "commune_niscode": "93022", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2940", "name": "Hoevenen", "province": "Anvers", "province_niscode": "10000", "commune_principale": "STABROEK", "commune_niscode": "11044", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6724", "name": "Houdemont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HABAY", "commune_niscode": "85046", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4540", "name": "Jehay", "province": "Liège", "province_niscode": "60000", "commune_principale": "AMAY", "commune_niscode": "61003", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6280", "name": "Joncret", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "GERPINNES", "commune_niscode": "52025", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6040", "name": "Jumet", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2500", "name": "Koningshooikt", "province": "Anvers", "province_niscode": "10000", "commune_principale": "LIER", "commune_niscode": "12021", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4987", "name": "La Gleize", "province": "Liège", "province_niscode": "60000", "commune_principale": "STOUMONT", "commune_niscode": "63075", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5170", "name": "Lesve", "province": "Namur", "province_niscode": "90000", "commune_principale": "PROFONDEVILLE", "commune_niscode": "92101", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3630", "name": "Leut", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "MAASMECHELEN", "commune_niscode": "73107", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8958", "name": "Loker", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HEUVELLAND", "commune_niscode": "33039", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5030", "name": "Lonzée", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9880", "name": "Lotenhulle", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALTER", "commune_niscode": "44084", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4920", "name": "Louveigné", "province": "Liège", "province_niscode": "60000", "commune_principale": "AYWAILLE", "commune_niscode": "62009", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6663", "name": "Mabompré", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HOUFFALIZE", "commune_niscode": "82014", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7387", "name": "Marchipont", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HONNELLES", "commune_niscode": "53083", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8720", "name": "Markegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DENTERGEM", "commune_niscode": "37002", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7050", "name": "Masnuy-Saint-Pierre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "JURBISE", "commune_niscode": "53044", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9170", "name": "Meerdonk", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-GILLIS-WAAS", "commune_niscode": "46020", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Mehaigne", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3560", "name": "Meldert", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "LUMMEN", "commune_niscode": "71037", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7540", "name": "Melles", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9660", "name": "Michelbeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BRAKEL", "commune_niscode": "45059", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4041", "name": "Milmort", "province": "Liège", "province_niscode": "60000", "commune_principale": "HERSTAL", "commune_niscode": "62051", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8470", "name": "Moere", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "GISTEL", "commune_niscode": "35005", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4400", "name": "Mons-Lez-Liège", "province": "Liège", "province_niscode": "60000", "commune_principale": "FLÉMALLE", "commune_niscode": "62120", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9310", "name": "Moorsel", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALST", "commune_niscode": "41002", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6600", "name": "Noville", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BASTOGNE", "commune_niscode": "82003", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1380", "name": "Ohain", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "LASNE", "commune_niscode": "25119", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9660", "name": "Opbrakel", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BRAKEL", "commune_niscode": "45059", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3680", "name": "Opoeteren", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "MAASEIK", "commune_niscode": "72021", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1350", "name": "Orp-Le-Grand", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "ORP-JAUCHE", "commune_niscode": "25120", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7804", "name": "Ostiches", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Pétigny", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6600", "name": "Villers-La-Bonne-Eau", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BASTOGNE", "commune_niscode": "82003", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Villers-Le-Gambon", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6833", "name": "Vivy", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BOUILLON", "commune_niscode": "84010", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2430", "name": "Vorst", "province": "Anvers", "province_niscode": "10000", "commune_principale": "LAAKDAL", "commune_niscode": "13053", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5564", "name": "Wanlin", "province": "Namur", "province_niscode": "90000", "commune_principale": "HOUYET", "commune_niscode": "91072", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7340", "name": "Wasmes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COLFONTAINE", "commune_niscode": "53082", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8978", "name": "Watou", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "POPERINGE", "commune_niscode": "33021", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5100", "name": "Wépion", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4452", "name": "Wihogne", "province": "Liège", "province_niscode": "60000", "commune_principale": "JUPRELLE", "commune_niscode": "62060", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3990", "name": "Wijchmaal", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "PEER", "commune_niscode": "72030", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3012", "name": "Wilsele", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LEUVEN", "commune_niscode": "24062", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4761", "name": "Rocherath", "province": "Liège", "province_niscode": "60000", "commune_principale": "BULLANGE", "commune_niscode": "63012", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4671", "name": "Saive", "province": "Liège", "province_niscode": "60000", "commune_principale": "BLÉGNY", "commune_niscode": "62119", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Sint-Denijs-Boekel", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9200", "name": "Sint-Gillis-Dendermonde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DENDERMONDE", "commune_niscode": "42006", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9572", "name": "Sint-Martens-Lierde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LIERDE", "commune_niscode": "45063", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6470", "name": "Sivry", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SIVRY-RANCE", "commune_niscode": "56088", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8490", "name": "Snellegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "JABBEKE", "commune_niscode": "31012", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5646", "name": "Stave", "province": "Namur", "province_niscode": "90000", "commune_principale": "METTET", "commune_niscode": "92087", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6661", "name": "Tailles", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HOUFFALIZE", "commune_niscode": "82014", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7950", "name": "Tongre-Saint-Martin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIÈVRES", "commune_niscode": "51014", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5537", "name": "Annevoie-Rouillon", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANHÉE", "commune_niscode": "91005", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9200", "name": "Appels", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DENDERMONDE", "commune_niscode": "42006", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4671", "name": "Barchon", "province": "Liège", "province_niscode": "60000", "commune_principale": "BLÉGNY", "commune_niscode": "62119", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2830", "name": "Blaasveld", "province": "Anvers", "province_niscode": "10000", "commune_principale": "WILLEBROEK", "commune_niscode": "12040", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1761", "name": "Borchtlombeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ROOSDAAL", "commune_niscode": "23097", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2520", "name": "Broechem", "province": "Anvers", "province_niscode": "10000", "commune_principale": "RANST", "commune_niscode": "11035", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1785", "name": "Brussegem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "MERCHTEM", "commune_niscode": "23052", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7602", "name": "Bury", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PÉRUWELZ", "commune_niscode": "57064", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4032", "name": "Chênée", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIÈGE", "commune_niscode": "62063", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6120", "name": "Cour-Sur-Heure", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HAM-SUR-HEURE", "commune_niscode": "56086", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3650", "name": "Dilsen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "DILSEN-STOKKEM", "commune_niscode": "72041", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9031", "name": "Drongen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7910", "name": "Ellignies-Lez-Frasnes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5580", "name": "Eprave", "province": "Namur", "province_niscode": "90000", "commune_principale": "ROCHEFORT", "commune_niscode": "91114", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5500", "name": "Falmignoul", "province": "Namur", "province_niscode": "90000", "commune_principale": "DINANT", "commune_niscode": "91034", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4367", "name": "Fize-Le-Marsal", "province": "Liège", "province_niscode": "60000", "commune_principale": "CRISNÉE", "commune_niscode": "64021", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5524", "name": "Gérin", "province": "Namur", "province_niscode": "90000", "commune_principale": "ONHAYE", "commune_niscode": "91103", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3300", "name": "Goetsenhoven", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIENEN", "commune_niscode": "24107", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4360", "name": "Grandville", "province": "Liège", "province_niscode": "60000", "commune_principale": "OREYE", "commune_niscode": "64056", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7830", "name": "Graty", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SILLY", "commune_niscode": "51068", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5640", "name": "Graux", "province": "Namur", "province_niscode": "90000", "commune_principale": "METTET", "commune_niscode": "92087", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3990", "name": "Grote-Brogel", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "PEER", "commune_niscode": "72030", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6720", "name": "Hachy", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HABAY", "commune_niscode": "85046", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4802", "name": "Heusy", "province": "Liège", "province_niscode": "60000", "commune_principale": "VERVIERS", "commune_niscode": "63079", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4162", "name": "Hody", "province": "Liège", "province_niscode": "60000", "commune_principale": "ANTHISNES", "commune_niscode": "61079", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6780", "name": "Hondelange", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MESSANCY", "commune_niscode": "81015", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5570", "name": "Honnay", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7022", "name": "Hyon", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2222", "name": "Itegem", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HEIST-OP-DEN-BERG", "commune_niscode": "12014", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Jamagne", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3510", "name": "Kermt", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HASSELT", "commune_niscode": "71022", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3945", "name": "Kwaadmechelen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HAM", "commune_niscode": "71069", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9690", "name": "Kwaremont", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KLUISBERGEN", "commune_niscode": "45060", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1380", "name": "Lasne-Chapelle-Saint-Lambert", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "LASNE", "commune_niscode": "25119", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Malvoisin", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5032", "name": "Mazy", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6211", "name": "Mellet", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LES BONS VILLERS", "commune_niscode": "52075", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9850", "name": "Merendree", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8340", "name": "Moerkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DAMME", "commune_niscode": "31006", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3640", "name": "Molenbeersel", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "KINROOI", "commune_niscode": "72018", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5621", "name": "Morialmé", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLORENNES", "commune_niscode": "93022", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5620", "name": "Morville", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLORENNES", "commune_niscode": "93022", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4190", "name": "My", "province": "Liège", "province_niscode": "60000", "commune_principale": "FERRIÈRES", "commune_niscode": "61019", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5670", "name": "Nismes", "province": "Namur", "province_niscode": "90000", "commune_principale": "VIROINVAL", "commune_niscode": "93090", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Omezée", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9790", "name": "Ooike", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "WORTEGEM-PETEGEM", "commune_niscode": "45061", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9255", "name": "Opdorp", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BUGGENHOUT", "commune_niscode": "42004", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3630", "name": "Opgrimbie", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "MAASMECHELEN", "commune_niscode": "73107", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9290", "name": "Overmere", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BERLARE", "commune_niscode": "42003", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3400", "name": "Overwinden", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1820", "name": "Perk", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "STEENOKKERZEEL", "commune_niscode": "23081", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7090", "name": "Petit-Roeulx-Lez-Braine", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRAINE-LE-COMTE", "commune_niscode": "55004", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7181", "name": "Petit-Roeulx-Lez-Nivelles", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SENEFFE", "commune_niscode": "55085", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6240", "name": "Pironchamps", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FARCIENNES", "commune_niscode": "52018", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2230", "name": "Ramsel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HERSELT", "commune_niscode": "13013", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4000", "name": "Rocourt", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIÈGE", "commune_niscode": "62063", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2870", "name": "Ruisbroek", "province": "Anvers", "province_niscode": "10000", "commune_principale": "PUURS-SINT-AMANDS", "commune_niscode": "12041", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3390", "name": "Sint-Joris-Winge", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIELT-WINGE", "commune_niscode": "24135", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5630", "name": "Soumoy", "province": "Namur", "province_niscode": "90000", "commune_principale": "CERFONTAINE", "commune_niscode": "93010", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4950", "name": "Sourbrodt", "province": "Liège", "province_niscode": "60000", "commune_principale": "WAIMES", "commune_niscode": "63080", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Thisnes", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5651", "name": "Thy-Le-Château", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3150", "name": "Tildonk", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HAACHT", "commune_niscode": "24033", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1570", "name": "Tollembeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GALMAARDEN", "commune_niscode": "23023", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3832", "name": "Ulbeek", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "WELLEN", "commune_niscode": "73098", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7536", "name": "Vaulx", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5060", "name": "Velaine-Sur-Sambre", "province": "Namur", "province_niscode": "90000", "commune_principale": "SAMBREVILLE", "commune_niscode": "92137", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Vencimont", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2240", "name": "Viersel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ZANDHOVEN", "commune_niscode": "11054", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4340", "name": "Villers-L'Evêque", "province": "Liège", "province_niscode": "60000", "commune_principale": "AWANS", "commune_niscode": "62006", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8720", "name": "Wakken", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DENTERGEM", "commune_niscode": "37002", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3400", "name": "Wange", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Waret-La-Chaussée", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8300", "name": "Westkapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KNOKKE-HEIST", "commune_niscode": "31043", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3501", "name": "Wimmertingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HASSELT", "commune_niscode": "71022", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8630", "name": "Wulveringem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VEURNE", "commune_niscode": "38025", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8680", "name": "Zande", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KOEKELARE", "commune_niscode": "32010", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9690", "name": "Zulzeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KLUISBERGEN", "commune_niscode": "45060", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5362", "name": "Achet", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAMOIS", "commune_niscode": "91059", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4601", "name": "Argenteau", "province": "Liège", "province_niscode": "60000", "commune_principale": "VISÉ", "commune_niscode": "62108", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3384", "name": "Attenrode", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GLABBEEK", "commune_niscode": "24137", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7040", "name": "Aulnois", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUÉVY", "commune_niscode": "53084", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Avernas-Le-Bauduin", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5555", "name": "Bellefontaine", "province": "Namur", "province_niscode": "90000", "commune_principale": "BIÈVRE", "commune_niscode": "91015", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3830", "name": "Berlingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "WELLEN", "commune_niscode": "73098", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5651", "name": "Berzée", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4560", "name": "Bois-Et-Borsu", "province": "Liège", "province_niscode": "60000", "commune_principale": "CLAVIER", "commune_niscode": "61012", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8583", "name": "Bossuit", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "AVELGEM", "commune_niscode": "34003", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7110", "name": "Boussoit", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LA LOUVIÈRE", "commune_niscode": "58001", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4020", "name": "Bressoux", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIÈGE", "commune_niscode": "62063", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3800", "name": "Brustem", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5555", "name": "Cornimont", "province": "Namur", "province_niscode": "90000", "commune_principale": "BIÈVRE", "commune_niscode": "91015", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6536", "name": "Donstiennes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "THUIN", "commune_niscode": "56078", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5530", "name": "Dorinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "YVOIR", "commune_niscode": "91141", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9320", "name": "Erembodegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALST", "commune_niscode": "41002", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7387", "name": "Erquennes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HONNELLES", "commune_niscode": "53083", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6856", "name": "Fays-Les-Veneurs", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "PALISEUL", "commune_niscode": "84050", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5680", "name": "Gochenée", "province": "Namur", "province_niscode": "90000", "commune_principale": "DOISCHE", "commune_niscode": "93018", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7160", "name": "Godarville", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHAPELLE-LEZ-HERLAIMONT", "commune_niscode": "52010", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3450", "name": "Grazen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GEETBETS", "commune_niscode": "24028", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6927", "name": "Grupont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "TELLIN", "commune_niscode": "84068", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6986", "name": "Halleux", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LA ROCHE-EN-ARDENNE", "commune_niscode": "83031", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6840", "name": "Hamipré", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NEUFCHÂTEAU", "commune_niscode": "84043", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6990", "name": "Hampteau", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HOTTON", "commune_niscode": "83028", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8587", "name": "Helchin", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ESPIERRES-HELCHIN", "commune_niscode": "34043", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5380", "name": "Hemptinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "FERNELMONT", "commune_niscode": "92138", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7050", "name": "Herchies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "JURBISE", "commune_niscode": "53044", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3770", "name": "Herderen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "RIEMST", "commune_niscode": "73066", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9070", "name": "Heusden", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DESTELBERGEN", "commune_niscode": "44013", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1435", "name": "Hévillers", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "MONT-SAINT-GUIBERT", "commune_niscode": "25068", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9550", "name": "Hillegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HERZELE", "commune_niscode": "41027", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4852", "name": "Hombourg", "province": "Liège", "province_niscode": "60000", "commune_principale": "PLOMBIÈRES", "commune_niscode": "63088", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2235", "name": "Houtvenne", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HULSHOUT", "commune_niscode": "13016", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1367", "name": "Huppaye", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "RAMILLIES", "commune_niscode": "25122", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1350", "name": "Jandrain-Jandrenouille", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "ORP-JAUCHE", "commune_niscode": "25120", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3472", "name": "Kersbeek-Miskom", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KORTENAKEN", "commune_niscode": "24054", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9130", "name": "Kieldrecht", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BEVEREN-WAAS", "commune_niscode": "46003", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3740", "name": "Kleine-Spouwen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1020", "name": "Laeken", "province": "Région de Bruxelles-Capitale", "province_niscode": "01000", "commune_principale": "BRUXELLES", "commune_niscode": "21004", "arrondissement_niscode": "21000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6530", "name": "Leers-Et-Fosteau", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "THUIN", "commune_niscode": "56078", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5590", "name": "Leignon", "province": "Namur", "province_niscode": "90000", "commune_principale": "CINEY", "commune_niscode": "91030", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4042", "name": "Liers", "province": "Liège", "province_niscode": "60000", "commune_principale": "HERSTAL", "commune_niscode": "62051", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7870", "name": "Lombise", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LENS", "commune_niscode": "53046", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9920", "name": "Lovendegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LIEVEGEM", "commune_niscode": "44085", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5170", "name": "Lustin", "province": "Namur", "province_niscode": "90000", "commune_principale": "PROFONDEVILLE", "commune_niscode": "92101", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6591", "name": "Macon", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MOMIGNIES", "commune_niscode": "56051", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5060", "name": "Moignelée", "province": "Namur", "province_niscode": "90000", "commune_principale": "SAMBREVILLE", "commune_niscode": "92137", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4420", "name": "Montegnée", "province": "Liège", "province_niscode": "60000", "commune_principale": "SAINT-NICOLAS", "commune_niscode": "62093", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7870", "name": "Montignies-Lez-Lens", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LENS", "commune_niscode": "53046", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3790", "name": "Mouland", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "FOURONS", "commune_niscode": "73109", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9636", "name": "Nederzwalm-Hermelgem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1390", "name": "Nethen", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GREZ-DOICEAU", "commune_niscode": "25037", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7063", "name": "Neufvilles", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SOIGNIES", "commune_niscode": "55040", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1930", "name": "Nossegem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZAVENTEM", "commune_niscode": "23094", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2260", "name": "Oevel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "WESTERLO", "commune_niscode": "13049", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5670", "name": "Olloy-Sur-Viroin", "province": "Namur", "province_niscode": "90000", "commune_principale": "VIROINVAL", "commune_niscode": "93090", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Oudekapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8880", "name": "Rollegem-Kapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "LEDEGEM", "commune_niscode": "36010", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5680", "name": "Romerée", "province": "Namur", "province_niscode": "90000", "commune_principale": "DOISCHE", "commune_niscode": "93018", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7601", "name": "Roucourt", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PÉRUWELZ", "commune_niscode": "57064", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1601", "name": "Ruisbroek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "SINT-PIETERS-LEEUW", "commune_niscode": "23077", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4672", "name": "Saint-Remy", "province": "Liège", "province_niscode": "60000", "commune_principale": "BLÉGNY", "commune_niscode": "62119", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9820", "name": "Schelderode", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MERELBEKE", "commune_niscode": "44043", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2960", "name": "Sint-Job-In-'T-Goor", "province": "Anvers", "province_niscode": "10000", "commune_principale": "BRECHT", "commune_niscode": "11009", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9620", "name": "Sint-Maria-Oudenhove", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZOTTEGEM", "commune_niscode": "41081", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8433", "name": "Sint-Pieters-Kapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MIDDELKERKE", "commune_niscode": "35011", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6890", "name": "Smuid", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBIN", "commune_niscode": "84035", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5523", "name": "Sommière", "province": "Namur", "province_niscode": "90000", "commune_principale": "ONHAYE", "commune_niscode": "91103", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8400", "name": "Stene", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "OOSTENDE", "commune_niscode": "35013", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5020", "name": "Suarlée", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1360", "name": "Thorembais-Les-Béguines", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "PERWEZ", "commune_niscode": "25084", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4630", "name": "Tignée", "province": "Liège", "province_niscode": "60000", "commune_principale": "SOUMAGNE", "commune_niscode": "62099", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3770", "name": "Val-Meer", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "RIEMST", "commune_niscode": "73066", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9620", "name": "Velzeke-Ruddershove", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZOTTEGEM", "commune_niscode": "41081", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4260", "name": "Ville-En-Hesbaye", "province": "Liège", "province_niscode": "60000", "commune_principale": "BRAIVES", "commune_niscode": "64015", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8421", "name": "Vlissegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DE HAAN", "commune_niscode": "35029", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Voort", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7548", "name": "Warchin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3290", "name": "Webbekom", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "DIEST", "commune_niscode": "24020", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7890", "name": "Wodecq", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ELLEZELLES", "commune_niscode": "51017", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1652", "name": "Alsemberg", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BEERSEL", "commune_niscode": "23003", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9890", "name": "Baaigem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GAVERE", "commune_niscode": "44020", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5555", "name": "Baillamont", "province": "Namur", "province_niscode": "90000", "commune_principale": "BIÈVRE", "commune_niscode": "91015", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6730", "name": "Bellefontaine", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "TINTIGNY", "commune_niscode": "85039", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7321", "name": "Blaton", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BERNISSART", "commune_niscode": "51009", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6760", "name": "Bleid", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VIRTON", "commune_niscode": "85045", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5170", "name": "Bois-De-Villers", "province": "Namur", "province_niscode": "90000", "commune_principale": "PROFONDEVILLE", "commune_niscode": "92101", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5004", "name": "Bouge", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4632", "name": "Cérexhe-Heuseux", "province": "Liège", "province_niscode": "60000", "commune_principale": "SOUMAGNE", "commune_niscode": "62099", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5650", "name": "Chastrès", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5032", "name": "Corroy-Le-Château", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5570", "name": "Dion", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5530", "name": "Durnal", "province": "Namur", "province_niscode": "90000", "commune_principale": "YVOIR", "commune_niscode": "91141", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2180", "name": "Ekeren", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ANTWERPEN", "commune_niscode": "11002", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4750", "name": "Elsenborn", "province": "Liège", "province_niscode": "60000", "commune_principale": "BUTGENBACH", "commune_niscode": "63013", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Ename", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3078", "name": "Everberg", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KORTENBERG", "commune_niscode": "24055", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4530", "name": "Fize-Fontaine", "province": "Liège", "province_niscode": "60000", "commune_principale": "VILLERS-LE-BOUILLET", "commune_niscode": "61068", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5380", "name": "Forville", "province": "Namur", "province_niscode": "90000", "commune_principale": "FERNELMONT", "commune_niscode": "92138", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8830", "name": "Gits", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HOOGLEDE", "commune_niscode": "36006", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5353", "name": "Goesnes", "province": "Namur", "province_niscode": "90000", "commune_principale": "OHEY", "commune_niscode": "92097", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Gors-Opleeuw", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Grand-Hallet", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6698", "name": "Grand-Halleux", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VIELSALM", "commune_niscode": "82032", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Gutshoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9120", "name": "Haasdonk", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BEVEREN-WAAS", "commune_niscode": "46003", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Heks", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1476", "name": "Houtain-Le-Val", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GENAPPE", "commune_niscode": "25031", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8531", "name": "Hulste", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HARELBEKE", "commune_niscode": "34013", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4650", "name": "Julémont", "province": "Liège", "province_niscode": "60000", "commune_principale": "HERVE", "commune_niscode": "63035", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5060", "name": "Keumiée", "province": "Namur", "province_niscode": "90000", "commune_principale": "SAMBREVILLE", "commune_niscode": "92137", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9770", "name": "Kruishoutem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KRUISEM", "commune_niscode": "45068", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4800", "name": "Lambermont", "province": "Liège", "province_niscode": "60000", "commune_principale": "VERVIERS", "commune_niscode": "63079", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4254", "name": "Ligney", "province": "Liège", "province_niscode": "60000", "commune_principale": "GEER", "commune_niscode": "64029", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3560", "name": "Linkhout", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "LUMMEN", "commune_niscode": "71037", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6840", "name": "Longlier", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NEUFCHÂTEAU", "commune_niscode": "84043", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1495", "name": "Marbais", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "VILLERS-LA-VILLE", "commune_niscode": "25107", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Henis", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3971", "name": "Heppen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "LEOPOLDSBURG", "commune_niscode": "71034", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5024", "name": "Marche-Les-Dames", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4219", "name": "Meeffe", "province": "Liège", "province_niscode": "60000", "commune_principale": "WASSEIGES", "commune_niscode": "64075", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9310", "name": "Meldert", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALST", "commune_niscode": "41002", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1495", "name": "Mellery", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "VILLERS-LA-VILLE", "commune_niscode": "25107", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Membre", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3770", "name": "Membruggen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "RIEMST", "commune_niscode": "73066", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9042", "name": "Mendonk", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3272", "name": "Messelbroek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "commune_niscode": "24134", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3461", "name": "Molenbeek-Wersbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BEKKEVOORT", "commune_niscode": "24008", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4350", "name": "Momalle", "province": "Liège", "province_niscode": "60000", "commune_principale": "REMICOURT", "commune_niscode": "64063", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6674", "name": "Montleban", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "GOUVY", "commune_niscode": "82037", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9500", "name": "Onkerzele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9200", "name": "Oudegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DENDERMONDE", "commune_niscode": "42006", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Overrepen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4287", "name": "Pellaines", "province": "Liège", "province_niscode": "60000", "commune_principale": "LINCENT", "commune_niscode": "64047", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6250", "name": "Presles", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "AISEAU-PRESLES", "commune_niscode": "52074", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6730", "name": "Rossignol", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "TINTIGNY", "commune_niscode": "85039", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6724", "name": "Rulles", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HABAY", "commune_niscode": "85046", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6820", "name": "Sainte-Cécile", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "FLORENVILLE", "commune_niscode": "85011", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6740", "name": "Sainte-Marie-Sur-Semois", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ETALLE", "commune_niscode": "85009", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2223", "name": "Schriek", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HEIST-OP-DEN-BERG", "commune_niscode": "12014", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5590", "name": "Serinchamps", "province": "Namur", "province_niscode": "90000", "commune_principale": "CINEY", "commune_niscode": "91030", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9667", "name": "Sint-Kornelis-Horebeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HOREBEKE", "commune_niscode": "45062", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1750", "name": "Sint-Kwintens-Lennik", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LENNIK", "commune_niscode": "23104", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9550", "name": "Sint-Lievens-Esse", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HERZELE", "commune_niscode": "41027", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6769", "name": "Sommethonne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MEIX-DEVANT-VIRTON", "commune_niscode": "85024", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5537", "name": "Sosoye", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANHÉE", "commune_niscode": "91005", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1790", "name": "Teralfene", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "AFFLIGEM", "commune_niscode": "23105", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7901", "name": "Thieulain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LEUZE-EN-HAINAUT", "commune_niscode": "57094", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7533", "name": "Thimougies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1360", "name": "Thorembais-Saint-Trond", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "PERWEZ", "commune_niscode": "25084", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7830", "name": "Thoricourt", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SILLY", "commune_niscode": "51068", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4500", "name": "Tihange", "province": "Liège", "province_niscode": "60000", "commune_principale": "HUY", "commune_niscode": "61031", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8630", "name": "Vinkem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VEURNE", "commune_niscode": "38025", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8640", "name": "Westvleteren", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VLETEREN", "commune_niscode": "33041", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9790", "name": "Wortegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "WORTEGEM-PETEGEM", "commune_niscode": "45061", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1370", "name": "Zétrud-Lumay", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "JODOIGNE", "commune_niscode": "25048", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7972", "name": "Quevaucamps", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BELOEIL", "commune_niscode": "51008", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4287", "name": "Racour", "province": "Liège", "province_niscode": "60000", "commune_principale": "LINCENT", "commune_niscode": "64047", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4987", "name": "Rahier", "province": "Liège", "province_niscode": "60000", "commune_principale": "STOUMONT", "commune_niscode": "63075", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6460", "name": "Robechies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Roborst", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7120", "name": "Rouveroy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTINNES", "commune_niscode": "58003", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9690", "name": "Ruien", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KLUISBERGEN", "commune_niscode": "45060", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5070", "name": "Sart-Eustache", "province": "Namur", "province_niscode": "90000", "commune_principale": "FOSSES-LA-VILLE", "commune_niscode": "92048", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9550", "name": "Sint-Antelinks", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HERZELE", "commune_niscode": "41027", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8900", "name": "Sint-Jan", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IEPER", "commune_niscode": "33011", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9550", "name": "Steenhuize-Wijnhuize", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HERZELE", "commune_niscode": "41027", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5651", "name": "Tarcienne", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4130", "name": "Tilff", "province": "Liège", "province_niscode": "60000", "commune_principale": "ESNEUX", "commune_niscode": "62032", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6700", "name": "Toernich", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ARLON", "commune_niscode": "81001", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6640", "name": "Vaux-Lez-Rosières", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VAUX-SUR-SÛRE", "commune_niscode": "82036", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6461", "name": "Virelles", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3300", "name": "Vissenaken", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIENEN", "commune_niscode": "24107", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9120", "name": "Vrasene", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BEVEREN-WAAS", "commune_niscode": "46003", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9506", "name": "Waarbeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4590", "name": "Warzée", "province": "Liège", "province_niscode": "60000", "commune_principale": "OUFFET", "commune_niscode": "61048", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9473", "name": "Welle", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DENDERLEEUW", "commune_niscode": "41011", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4190", "name": "Werbomont", "province": "Liège", "province_niscode": "60000", "commune_principale": "FERRIÈRES", "commune_niscode": "61019", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8954", "name": "Westouter", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HEUVELLAND", "commune_niscode": "33039", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2610", "name": "Wilrijk", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ANTWERPEN", "commune_niscode": "11002", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2260", "name": "Zoerle-Parwijs", "province": "Anvers", "province_niscode": "10000", "commune_principale": "WESTERLO", "commune_niscode": "13049", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4219", "name": "Ambresin", "province": "Liège", "province_niscode": "60000", "commune_principale": "WASSEIGES", "commune_niscode": "64075", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4031", "name": "Angleur", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIÈGE", "commune_niscode": "62063", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5190", "name": "Balâtre", "province": "Namur", "province_niscode": "90000", "commune_principale": "JEMEPPE-SUR-SAMBRE", "commune_niscode": "92140", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5370", "name": "Barvaux-Condroz", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAVELANGE", "commune_niscode": "91064", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8531", "name": "Bavikhove", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HARELBEKE", "commune_niscode": "34013", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8800", "name": "Beveren", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ROESELARE", "commune_niscode": "36015", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1430", "name": "Bierghes", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "REBECQ", "commune_niscode": "25123", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5377", "name": "Bonsin", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMME-LEUZE", "commune_niscode": "91120", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1390", "name": "Bossut-Gottechain", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GREZ-DOICEAU", "commune_niscode": "25037", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6671", "name": "Bovigny", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "GOUVY", "commune_niscode": "82037", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7903", "name": "Chapelle-À-Wattines", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LEUZE-EN-HAINAUT", "commune_niscode": "57094", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1450", "name": "Chastre-Villeroux-Blanmont", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "CHASTRE", "commune_niscode": "25117", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6673", "name": "Cherain", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "GOUVY", "commune_niscode": "82037", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4784", "name": "Crombach", "province": "Liège", "province_niscode": "60000", "commune_principale": "SAINT-VITH", "commune_niscode": "63067", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9280", "name": "Denderbelle", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LEBBEKE", "commune_niscode": "42011", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9831", "name": "Deurle", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-MARTENS-LATEM", "commune_niscode": "44064", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3941", "name": "Eksel", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HECHTEL-EKSEL", "commune_niscode": "72038", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9660", "name": "Elst", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BRAKEL", "commune_niscode": "45059", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7500", "name": "Ere", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6567", "name": "Fontaine-Valmont", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MERBES-LE-CHÂTEAU", "commune_niscode": "56049", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7504", "name": "Froidmont", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6929", "name": "Gembes", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DAVERDISSE", "commune_niscode": "84016", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1332", "name": "Genval", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "RIXENSART", "commune_niscode": "25091", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1367", "name": "Gérompont", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "RAMILLIES", "commune_niscode": "25122", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6280", "name": "Gougnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "GERPINNES", "commune_niscode": "52025", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5031", "name": "Grand-Leez", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4650", "name": "Grand-Rechain", "province": "Liège", "province_niscode": "60000", "commune_principale": "HERVE", "commune_niscode": "63035", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7973", "name": "Grandglise", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BELOEIL", "commune_niscode": "51008", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6840", "name": "Grapfontaine", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NEUFCHÂTEAU", "commune_niscode": "84043", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9200", "name": "Grembergen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DENDERMONDE", "commune_niscode": "42006", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7620", "name": "Guignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUNEHAUT", "commune_niscode": "57093", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6720", "name": "Habay-La-Neuve", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HABAY", "commune_niscode": "85046", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4351", "name": "Hodeige", "province": "Liège", "province_niscode": "60000", "commune_principale": "REMICOURT", "commune_niscode": "64063", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4250", "name": "Hollogne-Sur-Geer", "province": "Liège", "province_niscode": "60000", "commune_principale": "GEER", "commune_niscode": "64029", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4520", "name": "Huccorgne", "province": "Liège", "province_niscode": "60000", "commune_principale": "WANZE", "commune_niscode": "61072", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1370", "name": "Jauchelette", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "JODOIGNE", "commune_niscode": "25048", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7012", "name": "Jemappes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9120", "name": "Kallo", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BEVEREN-WAAS", "commune_niscode": "46003", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8650", "name": "Klerken", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HOUTHULST", "commune_niscode": "32006", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4210", "name": "Lamontzée", "province": "Liège", "province_niscode": "60000", "commune_principale": "BURDINNE", "commune_niscode": "61010", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6111", "name": "Landelies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONTIGNY-LE-TILLEUL", "commune_niscode": "52048", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8930", "name": "Lauwe", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MENEN", "commune_niscode": "34027", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5670", "name": "Le Mesnil", "province": "Namur", "province_niscode": "90000", "commune_principale": "VIROINVAL", "commune_niscode": "93090", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6811", "name": "Les Bulles", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "CHINY", "commune_niscode": "85007", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1342", "name": "Limelette", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "OTTIGNIES-LOUVAIN-LA-NEUVE", "commune_niscode": "25121", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6688", "name": "Longchamps", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BERTOGNE", "commune_niscode": "82005", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6600", "name": "Longvilly", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BASTOGNE", "commune_niscode": "82003", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7022", "name": "Mesvin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8552", "name": "Moen", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZWEVEGEM", "commune_niscode": "34042", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6997", "name": "Mormont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "EREZÉE", "commune_niscode": "83013", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3891", "name": "Muizen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "GINGELOM", "commune_niscode": "71017", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9500", "name": "Nederboelare", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9850", "name": "Nevele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Noville-Sur-Mehaigne", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7034", "name": "Obourg", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5670", "name": "Oignies-En-Thiérache", "province": "Namur", "province_niscode": "90000", "commune_principale": "VIROINVAL", "commune_niscode": "93090", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3945", "name": "Oostham", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HAM", "commune_niscode": "71069", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3360", "name": "Opvelp", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BIERBEEK", "commune_niscode": "24011", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7850", "name": "Petit-Enghien", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ENGHIEN", "commune_niscode": "51067", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9401", "name": "Pollare", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7760", "name": "Pottes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CELLES", "commune_niscode": "57018", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4219", "name": "Acosse", "province": "Liège", "province_niscode": "60000", "commune_principale": "WASSEIGES", "commune_niscode": "64075", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7750", "name": "Amougies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONT-DE-L'ENCLUS", "commune_niscode": "57095", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9800", "name": "Astene", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9968", "name": "Bassevelde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ASSENEDE", "commune_niscode": "43002", "arrondissement_niscode": "43000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7532", "name": "Béclers", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2580", "name": "Beerzel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "PUTTE", "commune_niscode": "12029", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8691", "name": "Beveren-Aan-De-Ijzer", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ALVERINGEM", "commune_niscode": "38002", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6690", "name": "Bihain", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VIELSALM", "commune_niscode": "82032", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9961", "name": "Boekhoute", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ASSENEDE", "commune_niscode": "43002", "arrondissement_niscode": "43000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5081", "name": "Bovesse", "province": "Namur", "province_niscode": "90000", "commune_principale": "LA BRUYÈRE", "commune_niscode": "92141", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4654", "name": "Charneux", "province": "Liège", "province_niscode": "60000", "commune_principale": "HERVE", "commune_niscode": "63035", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4890", "name": "Clermont", "province": "Liège", "province_niscode": "60000", "commune_principale": "THIMISTER-CLERMONT", "commune_niscode": "63089", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8890", "name": "Dadizele", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MOORSLEDE", "commune_niscode": "36012", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8630", "name": "De Moeren", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VEURNE", "commune_niscode": "38025", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6960", "name": "Dochamps", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MANHAY", "commune_niscode": "83055", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9130", "name": "Doel", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BEVEREN-WAAS", "commune_niscode": "46003", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5670", "name": "Dourbes", "province": "Namur", "province_niscode": "90000", "commune_principale": "VIROINVAL", "commune_niscode": "93090", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7191", "name": "Ecaussinnes-Lalaing", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ECAUSSINNES", "commune_niscode": "55050", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3630", "name": "Eisden", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "MAASMECHELEN", "commune_niscode": "73107", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9810", "name": "Eke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NAZARETH", "commune_niscode": "44048", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3800", "name": "Engelmanshoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7743", "name": "Esquelmes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PECQ", "commune_niscode": "57062", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7120", "name": "Estinnes-Au-Val", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTINNES", "commune_niscode": "58003", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5500", "name": "Falmagne", "province": "Namur", "province_niscode": "90000", "commune_principale": "DINANT", "commune_niscode": "91034", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6440", "name": "Fourbechies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FROIDCHAPELLE", "commune_niscode": "56029", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8980", "name": "Geluveld", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZONNEBEKE", "commune_niscode": "33037", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3770", "name": "Genoelselderen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "RIEMST", "commune_niscode": "73066", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9860", "name": "Gijzenzele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OOSTERZELE", "commune_niscode": "44052", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9500", "name": "Goeferdinge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6030", "name": "Goutroux", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4030", "name": "Grivegnée", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIÈGE", "commune_niscode": "62063", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8560", "name": "Gullegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "WEVELGEM", "commune_niscode": "34041", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6792", "name": "Halanzy", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "AUBANGE", "commune_niscode": "81004", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3930", "name": "Hamont", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HAMONT-ACHEL", "commune_niscode": "72037", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7822", "name": "Isières", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1755", "name": "Kester", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GOOIK", "commune_niscode": "23024", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7622", "name": "Laplaigne", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUNEHAUT", "commune_niscode": "57093", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1502", "name": "Lembeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HALLE", "commune_niscode": "23027", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9820", "name": "Lemberge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MERELBEKE", "commune_niscode": "44043", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6830", "name": "Les Hayons", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BOUILLON", "commune_niscode": "84010", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8647", "name": "Lo", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "LO-RENINGE", "commune_niscode": "32030", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8210", "name": "Loppem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZEDELGEM", "commune_niscode": "31040", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3742", "name": "Martenslinde", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Mater", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3670", "name": "Meeuwen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "OUDSBERGEN", "commune_niscode": "72042", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1820", "name": "Melsbroek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "STEENOKKERZEEL", "commune_niscode": "23081", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6567", "name": "Merbes-Sainte-Marie", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MERBES-LE-CHÂTEAU", "commune_niscode": "56049", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7911", "name": "Montroeul-Au-Bois", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5360", "name": "Natoye", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAMOIS", "commune_niscode": "91059", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9506", "name": "Nieuwenhove", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7866", "name": "Ollignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LESSINES", "commune_niscode": "51069", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4252", "name": "Omal", "province": "Liège", "province_niscode": "60000", "commune_principale": "GEER", "commune_niscode": "64029", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6900", "name": "On", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MARCHE-EN-FAMENNE", "commune_niscode": "83034", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8340", "name": "Oostkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DAMME", "commune_niscode": "31006", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6852", "name": "Opont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "PALISEUL", "commune_niscode": "84050", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4102", "name": "Ougrée", "province": "Liège", "province_niscode": "60000", "commune_principale": "SERAING", "commune_niscode": "62096", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3900", "name": "Overpelt", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "PELT", "commune_niscode": "72043", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4600", "name": "Richelle", "province": "Liège", "province_niscode": "60000", "commune_principale": "VISÉ", "commune_niscode": "62108", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7540", "name": "Rumillies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6460", "name": "Saint-Remy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1480", "name": "Saintes", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "TUBIZE", "commune_niscode": "25105", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3540", "name": "Schulen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HERK-DE-STAD", "commune_niscode": "71024", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6940", "name": "Septon", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9660", "name": "Sint-Maria-Oudenhove", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BRAKEL", "commune_niscode": "45059", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3220", "name": "Sint-Pieters-Rode", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HOLSBEEK", "commune_niscode": "24043", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4450", "name": "Slins", "province": "Liège", "province_niscode": "60000", "commune_principale": "JUPRELLE", "commune_niscode": "62060", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6182", "name": "Souvret", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COURCELLES", "commune_niscode": "52015", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3512", "name": "Stevoort", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HASSELT", "commune_niscode": "71022", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4577", "name": "Strée-Lez-Huy", "province": "Liège", "province_niscode": "60000", "commune_principale": "MODAVE", "commune_niscode": "61041", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6717", "name": "Thiaumont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ATTERT", "commune_niscode": "81003", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2260", "name": "Tongerlo", "province": "Anvers", "province_niscode": "10000", "commune_principale": "WESTERLO", "commune_niscode": "13049", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5670", "name": "Treignes", "province": "Namur", "province_niscode": "90000", "commune_principale": "VIROINVAL", "commune_niscode": "93090", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Upigny", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6462", "name": "Vaulx-Lez-Chimay", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7538", "name": "Vezon", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6769", "name": "Villers-La-Loue", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MEIX-DEVANT-VIRTON", "commune_niscode": "85024", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4453", "name": "Villers-Saint-Siméon", "province": "Liège", "province_niscode": "60000", "commune_principale": "JUPRELLE", "commune_niscode": "62060", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3724", "name": "Vliermaal", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "KORTESSEM", "commune_niscode": "73040", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Volkegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4711", "name": "Walhorn", "province": "Liège", "province_niscode": "60000", "commune_principale": "LONTZEN", "commune_niscode": "63048", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6780", "name": "Wolkrange", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MESSANCY", "commune_niscode": "81015", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9080", "name": "Zaffelare", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LOCHRISTI", "commune_niscode": "44034", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9840", "name": "Zevergem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DE PINTE", "commune_niscode": "44012", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9051", "name": "Afsnee", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5070", "name": "Aisemont", "province": "Namur", "province_niscode": "90000", "commune_principale": "FOSSES-LA-VILLE", "commune_niscode": "92048", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6721", "name": "Anlier", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HABAY", "commune_niscode": "85046", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5520", "name": "Anthée", "province": "Namur", "province_niscode": "90000", "commune_principale": "ONHAYE", "commune_niscode": "91103", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7181", "name": "Arquennes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SENEFFE", "commune_niscode": "55085", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7941", "name": "Attre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUGELETTE", "commune_niscode": "51012", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4300", "name": "Bettincourt", "province": "Liège", "province_niscode": "60000", "commune_principale": "WAREMME", "commune_niscode": "64074", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7620", "name": "Bléharies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUNEHAUT", "commune_niscode": "57093", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8680", "name": "Bovekerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KOEKELARE", "commune_niscode": "32010", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4650", "name": "Chaineux", "province": "Liège", "province_niscode": "60000", "commune_principale": "HERVE", "commune_niscode": "63035", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4860", "name": "Cornesse", "province": "Liège", "province_niscode": "60000", "commune_principale": "PEPINSTER", "commune_niscode": "63058", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5336", "name": "Courrière", "province": "Namur", "province_niscode": "90000", "commune_principale": "ASSESSE", "commune_niscode": "92006", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4218", "name": "Couthuin", "province": "Liège", "province_niscode": "60000", "commune_principale": "HÉRON", "commune_niscode": "61028", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1831", "name": "Diegem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "MACHELEN", "commune_niscode": "23047", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5500", "name": "Dréhance", "province": "Namur", "province_niscode": "90000", "commune_principale": "DINANT", "commune_niscode": "91034", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3350", "name": "Drieslinter", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LINTER", "commune_niscode": "24133", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4480", "name": "Ehein", "province": "Liège", "province_niscode": "60000", "commune_principale": "ENGIS", "commune_niscode": "61080", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9160", "name": "Eksaarde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LOKEREN", "commune_niscode": "46014", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5644", "name": "Ermeton-Sur-Biert", "province": "Namur", "province_niscode": "90000", "commune_principale": "METTET", "commune_niscode": "92087", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4400", "name": "Flémalle-Haute", "province": "Liège", "province_niscode": "60000", "commune_principale": "FLÉMALLE", "commune_niscode": "62120", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6464", "name": "Forges", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1367", "name": "Geest-Gérompont-Petit-Rosière", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "RAMILLIES", "commune_niscode": "25122", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1450", "name": "Gentinnes", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "CHASTRE", "commune_niscode": "25117", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7041", "name": "Givry", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUÉVY", "commune_niscode": "53084", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4690", "name": "Glons", "province": "Liège", "province_niscode": "60000", "commune_principale": "BASSENGE", "commune_niscode": "62011", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7900", "name": "Grandmetz", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LEUZE-EN-HAINAUT", "commune_niscode": "57094", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7100", "name": "Haine-Saint-Pierre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LA LOUVIÈRE", "commune_niscode": "58001", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9850", "name": "Hansbeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4730", "name": "Hauset", "province": "Liège", "province_niscode": "60000", "commune_principale": "RAEREN", "commune_niscode": "63061", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7334", "name": "Hautrage", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SAINT-GHISLAIN", "commune_niscode": "53070", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7041", "name": "Havay", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUÉVY", "commune_niscode": "53084", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4681", "name": "Hermalle-Sous-Argenteau", "province": "Liège", "province_niscode": "60000", "commune_principale": "OUPEYE", "commune_niscode": "62079", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2811", "name": "Hombeek", "province": "Anvers", "province_niscode": "10000", "commune_principale": "MECHELEN", "commune_niscode": "12025", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Houdremont", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8570", "name": "Ingooigem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ANZEGEM", "commune_niscode": "34002", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3370", "name": "Kerkom", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BOUTERSEM", "commune_niscode": "24016", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7522", "name": "Lamain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5651", "name": "Laneffe", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1370", "name": "Lathuy", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "JODOIGNE", "commune_niscode": "25048", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7621", "name": "Lesdain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUNEHAUT", "commune_niscode": "57093", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9521", "name": "Letterhoutem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-LIEVENS-HOUTEM", "commune_niscode": "41063", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5680", "name": "Matagne-La-Petite", "province": "Namur", "province_niscode": "90000", "commune_principale": "DOISCHE", "commune_niscode": "93018", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9820", "name": "Melsen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MERELBEKE", "commune_niscode": "44043", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3680", "name": "Neeroeteren", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "MAASEIK", "commune_niscode": "72021", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4870", "name": "Nessonvaux", "province": "Liège", "province_niscode": "60000", "commune_principale": "TROOZ", "commune_niscode": "62122", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8377", "name": "Nieuwmunster", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZUIENKERKE", "commune_niscode": "31042", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8730", "name": "Oedelem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BEERNEM", "commune_niscode": "31003", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7911", "name": "Oeudeghien", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4340", "name": "Othée", "province": "Liège", "province_niscode": "60000", "commune_principale": "AWANS", "commune_niscode": "62006", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7861", "name": "Papignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LESSINES", "commune_niscode": "51069", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4910", "name": "Polleur", "province": "Liège", "province_niscode": "60000", "commune_principale": "THEUX", "commune_niscode": "63076", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5530", "name": "Purnode", "province": "Namur", "province_niscode": "90000", "commune_principale": "YVOIR", "commune_niscode": "91141", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8620", "name": "Ramskapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "NIEUWPOORT", "commune_niscode": "38016", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6800", "name": "Remagne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBRAMONT-CHEVIGNY", "commune_niscode": "84077", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7090", "name": "Ronquières", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRAINE-LE-COMTE", "commune_niscode": "55004", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5330", "name": "Sart-Bernard", "province": "Namur", "province_niscode": "90000", "commune_principale": "ASSESSE", "commune_niscode": "92006", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3290", "name": "Schaffen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "DIEST", "commune_niscode": "24020", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9860", "name": "Scheldewindeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OOSTERZELE", "commune_niscode": "44052", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8880", "name": "Sint-Eloois-Winkel", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "LEDEGEM", "commune_niscode": "36010", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1742", "name": "Sint-Katherina-Lombeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TERNAT", "commune_niscode": "23086", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Taviers", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5020", "name": "Temploux", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7334", "name": "Villerot", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SAINT-GHISLAIN", "commune_niscode": "53070", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4020", "name": "Wandre", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIÈGE", "commune_niscode": "62063", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9340", "name": "Wanzele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LEDE", "commune_niscode": "41034", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2381", "name": "Weelde", "province": "Anvers", "province_niscode": "10000", "commune_principale": "RAVELS", "commune_niscode": "13035", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5650", "name": "Yves-Gomezée", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3930", "name": "Achel", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HAMONT-ACHEL", "commune_niscode": "72037", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6890", "name": "Anloy", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBIN", "commune_niscode": "84035", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3271", "name": "Averbode", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "commune_niscode": "24134", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9200", "name": "Baasrode", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DENDERMONDE", "commune_niscode": "42006", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5570", "name": "Baronville", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7534", "name": "Barry", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4520", "name": "Bas-Oha", "province": "Liège", "province_niscode": "60000", "commune_principale": "WANZE", "commune_niscode": "61072", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1730", "name": "Bekkerzeel", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ASSE", "commune_niscode": "23002", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5030", "name": "Beuzet", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2560", "name": "Bevel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "NIJLEN", "commune_niscode": "12026", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6543", "name": "Bienne-Lez-Happart", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LOBBES", "commune_niscode": "56044", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3850", "name": "Binderveld", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "NIEUWERKERKEN", "commune_niscode": "71045", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8501", "name": "Bissegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KORTRIJK", "commune_niscode": "34022", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1325", "name": "Bonlez", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "CHAUMONT-GISTOUX", "commune_niscode": "25018", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4300", "name": "Bovenistier", "province": "Liège", "province_niscode": "60000", "commune_principale": "WAREMME", "commune_niscode": "64074", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7604", "name": "Brasménil", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PÉRUWELZ", "commune_niscode": "57064", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4317", "name": "Celles", "province": "Liège", "province_niscode": "60000", "commune_principale": "FAIMES", "commune_niscode": "64076", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5650", "name": "Clermont", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4180", "name": "Comblain-Fairon", "province": "Liège", "province_niscode": "60000", "commune_principale": "HAMOIR", "commune_niscode": "61024", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5020", "name": "Daussoulx", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4590", "name": "Ellemelle", "province": "Liège", "province_niscode": "60000", "commune_principale": "OUFFET", "commune_niscode": "61048", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8906", "name": "Elverdinge", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IEPER", "commune_niscode": "33011", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7760", "name": "Escanaffles", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CELLES", "commune_niscode": "57018", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5650", "name": "Fontenelle", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7830", "name": "Fouleng", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SILLY", "commune_niscode": "51068", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5504", "name": "Foy-Notre-Dame", "province": "Namur", "province_niscode": "90000", "commune_principale": "DINANT", "commune_niscode": "91034", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5380", "name": "Franc-Waret", "province": "Namur", "province_niscode": "90000", "commune_principale": "FERNELMONT", "commune_niscode": "92138", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7040", "name": "Genly", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUÉVY", "commune_niscode": "53084", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4000", "name": "Glain", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIÈGE", "commune_niscode": "62063", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7100", "name": "Haine-Saint-Paul", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LA LOUVIÈRE", "commune_niscode": "58001", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6922", "name": "Halma", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "WELLIN", "commune_niscode": "84075", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6700", "name": "Heinsch", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ARLON", "commune_niscode": "81001", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Lampernisse", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9860", "name": "Landskouter", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OOSTERZELE", "commune_niscode": "44052", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Lauw", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Leke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9400", "name": "Lieferinge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1360", "name": "Malèves-Sainte-Marie-Wastinnes", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "PERWEZ", "commune_niscode": "25084", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7534", "name": "Maulde", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9992", "name": "Middelburg", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MALDEGEM", "commune_niscode": "43010", "arrondissement_niscode": "43000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3891", "name": "Mielen-Boven-Aalst", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "GINGELOM", "commune_niscode": "71017", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1730", "name": "Mollem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ASSE", "commune_niscode": "23002", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4607", "name": "Mortroux", "province": "Liège", "province_niscode": "60000", "commune_principale": "DALHEM", "commune_niscode": "62027", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6660", "name": "Nadrin", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HOUFFALIZE", "commune_niscode": "82014", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5100", "name": "Naninne", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1910", "name": "Nederokkerzeel", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KAMPENHOUT", "commune_niscode": "23038", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7862", "name": "Ogy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LESSINES", "commune_niscode": "51069", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4540", "name": "Ombret", "province": "Liège", "province_niscode": "60000", "commune_principale": "AMAY", "commune_niscode": "61003", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9968", "name": "Oosteeklo", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ASSENEDE", "commune_niscode": "43002", "arrondissement_niscode": "43000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3350", "name": "Orsmaal-Gussenhoven", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LINTER", "commune_niscode": "24133", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7640", "name": "Péronnes-Lez-Antoing", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ANTOING", "commune_niscode": "57003", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9310", "name": "Herdersem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALST", "commune_niscode": "41002", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8020", "name": "Hertsberge", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "OOSTKAMP", "commune_niscode": "31022", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5530", "name": "Houx", "province": "Namur", "province_niscode": "90000", "commune_principale": "YVOIR", "commune_niscode": "91141", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3640", "name": "Kessenich", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "KINROOI", "commune_niscode": "72018", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3511", "name": "Kuringen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HASSELT", "commune_niscode": "71022", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4560", "name": "Les Avins", "province": "Liège", "province_niscode": "60000", "commune_principale": "CLAVIER", "commune_niscode": "61012", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6953", "name": "Lesterny", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NASSOGNE", "commune_niscode": "83040", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4987", "name": "Lorcé", "province": "Liège", "province_niscode": "60000", "commune_principale": "STOUMONT", "commune_niscode": "63075", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6927", "name": "Resteigne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "TELLIN", "commune_niscode": "84068", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5080", "name": "Rhisnes", "province": "Namur", "province_niscode": "90000", "commune_principale": "LA BRUYÈRE", "commune_niscode": "92141", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7500", "name": "Saint-Maur", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9688", "name": "Schorisse", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MAARKEDAL", "commune_niscode": "45064", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9890", "name": "Semmerzake", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GAVERE", "commune_niscode": "44020", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8710", "name": "Sint-Baafs-Vijve", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "WIELSBEKE", "commune_niscode": "37017", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9940", "name": "Sleidinge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "EVERGEM", "commune_niscode": "44019", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3650", "name": "Stokkem", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "DILSEN-STOKKEM", "commune_niscode": "72041", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3511", "name": "Stokrooie", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HASSELT", "commune_niscode": "71022", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6813", "name": "Termes", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "CHINY", "commune_niscode": "85007", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6680", "name": "Tillet", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "SAINTE-ODE", "commune_niscode": "82038", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6767", "name": "Torgny", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ROUVROY", "commune_niscode": "85047", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8370", "name": "Uitkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BLANKENBERGE", "commune_niscode": "31004", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8210", "name": "Veldegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZEDELGEM", "commune_niscode": "31040", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7120", "name": "Vellereille-Les-Brayeux", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTINNES", "commune_niscode": "58003", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Veulen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4530", "name": "Vieux-Waleffe", "province": "Liège", "province_niscode": "60000", "commune_principale": "VILLERS-LE-BOUILLET", "commune_niscode": "61068", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1460", "name": "Virginal-Samme", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "ITTRE", "commune_niscode": "25044", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3770", "name": "Vlijtingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "RIEMST", "commune_niscode": "73066", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7971", "name": "Wadelincourt", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BELOEIL", "commune_niscode": "51008", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3401", "name": "Walsbets", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1741", "name": "Wambeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TERNAT", "commune_niscode": "23086", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9988", "name": "Waterland-Oudeman", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-LAUREINS", "commune_niscode": "43014", "arrondissement_niscode": "43000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2235", "name": "Westmeerbeek", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HULSHOUT", "commune_niscode": "13016", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3271", "name": "Zichem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "commune_niscode": "24134", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9420", "name": "Aaigem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ERPE-MERE", "commune_niscode": "41082", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Aische-En-Refail", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6870", "name": "Arville", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "SAINT-HUBERT", "commune_niscode": "84059", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6951", "name": "Bande", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NASSOGNE", "commune_niscode": "83040", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4651", "name": "Battice", "province": "Liège", "province_niscode": "60000", "commune_principale": "HERVE", "commune_niscode": "63035", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7130", "name": "Battignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BINCHE", "commune_niscode": "58002", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6672", "name": "Beho", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "GOUVY", "commune_niscode": "82037", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4960", "name": "Bellevaux-Ligneuville", "province": "Liège", "province_niscode": "60000", "commune_principale": "MALMEDY", "commune_niscode": "63049", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4607", "name": "Berneau", "province": "Liège", "province_niscode": "60000", "commune_principale": "DALHEM", "commune_niscode": "62027", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3740", "name": "Beverst", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Blehen", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5140", "name": "Boignée", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMBREFFE", "commune_niscode": "92114", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8630", "name": "Booitshoeke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VEURNE", "commune_niscode": "38025", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Boussu-En-Fagne", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7803", "name": "Bouvignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5590", "name": "Braibant", "province": "Namur", "province_niscode": "90000", "commune_principale": "CINEY", "commune_niscode": "91030", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Branchon", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7130", "name": "Bray", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BINCHE", "commune_niscode": "58002", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Brûly", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8630", "name": "Bulskamp", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VEURNE", "commune_niscode": "38025", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6742", "name": "Chantemelle", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ETALLE", "commune_niscode": "85009", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Dailly", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6020", "name": "Dampremy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5630", "name": "Daussois", "province": "Namur", "province_niscode": "90000", "commune_principale": "CERFONTAINE", "commune_niscode": "93010", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7912", "name": "Dergneau", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7190", "name": "Ecaussinnes-D'Enghien", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ECAUSSINNES", "commune_niscode": "55050", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7370", "name": "Elouges", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "DOUR", "commune_niscode": "53020", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5080", "name": "Emines", "province": "Namur", "province_niscode": "90000", "commune_principale": "LA BRUYÈRE", "commune_niscode": "92141", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7730", "name": "Evregnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTAIMPUIS", "commune_niscode": "57027", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4731", "name": "Eynatten", "province": "Liège", "province_niscode": "60000", "commune_principale": "RAEREN", "commune_niscode": "63061", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6820", "name": "Fontenoille", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "FLORENVILLE", "commune_niscode": "85011", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4340", "name": "Fooz", "province": "Liège", "province_niscode": "60000", "commune_principale": "AWANS", "commune_niscode": "62006", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1750", "name": "Gaasbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LENNIK", "commune_niscode": "23104", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7943", "name": "Gages", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUGELETTE", "commune_niscode": "51012", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7906", "name": "Gallaix", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LEUZE-EN-HAINAUT", "commune_niscode": "57094", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3620", "name": "Gellik", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "LANAKEN", "commune_niscode": "73042", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6181", "name": "Gouy-Lez-Piéton", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COURCELLES", "commune_niscode": "52015", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4460", "name": "Grâce-Berleur", "province": "Liège", "province_niscode": "60000", "commune_principale": "GRÂCE-HOLLOGNE", "commune_niscode": "62118", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3053", "name": "Haasrode", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "OUD-HEVERLEE", "commune_niscode": "24086", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4210", "name": "Hannêche", "province": "Liège", "province_niscode": "60000", "commune_principale": "BURDINNE", "commune_niscode": "61010", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7022", "name": "Harveng", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4771", "name": "Heppenbach", "province": "Liège", "province_niscode": "60000", "commune_principale": "AMBLÈVE", "commune_niscode": "63001", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5370", "name": "Jeneffe", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAVELANGE", "commune_niscode": "91064", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4020", "name": "Jupille-Sur-Meuse", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIÈGE", "commune_niscode": "62063", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8956", "name": "Kemmel", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HEUVELLAND", "commune_niscode": "33039", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5101", "name": "Lives-Sur-Meuse", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6924", "name": "Lomprez", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "WELLIN", "commune_niscode": "84075", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7850", "name": "Marcq", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ENGHIEN", "commune_niscode": "51067", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4210", "name": "Marneffe", "province": "Liège", "province_niscode": "60000", "commune_principale": "BURDINNE", "commune_niscode": "61010", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Merlemont", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5081", "name": "Meux", "province": "Namur", "province_niscode": "90000", "commune_principale": "LA BRUYÈRE", "commune_niscode": "92141", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5376", "name": "Miécret", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAVELANGE", "commune_niscode": "91064", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2322", "name": "Minderhout", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HOOGSTRATEN", "commune_niscode": "13014", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8560", "name": "Moorsele", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "WEVELGEM", "commune_niscode": "34041", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Moxhe", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Nafraiture", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1120", "name": "Neder-Over-Heembeek", "province": "Région de Bruxelles-Capitale", "province_niscode": "01000", "commune_principale": "BRUXELLES", "commune_niscode": "21004", "arrondissement_niscode": "21000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6717", "name": "Nothomb", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ATTERT", "commune_niscode": "81003", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6230", "name": "Obaix", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PONT-À-CELLES", "commune_niscode": "52055", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5555", "name": "Oizy", "province": "Namur", "province_niscode": "90000", "commune_principale": "BIÈVRE", "commune_niscode": "91015", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1760", "name": "Onze-Lieve-Vrouw-Lombeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ROOSDAAL", "commune_niscode": "23097", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Ooike", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4452", "name": "Paifve", "province": "Liège", "province_niscode": "60000", "commune_principale": "JUPRELLE", "commune_niscode": "62060", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1760", "name": "Pamel", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ROOSDAAL", "commune_niscode": "23097", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5650", "name": "Pry", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6880", "name": "Jehonville", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BERTRIX", "commune_niscode": "84009", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6044", "name": "Roux", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4606", "name": "Saint-André", "province": "Liège", "province_niscode": "60000", "commune_principale": "DALHEM", "commune_niscode": "62027", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6887", "name": "Saint-Médard", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HERBEUMONT", "commune_niscode": "84029", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3470", "name": "Sint-Margriete-Houtem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KORTENAKEN", "commune_niscode": "24054", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9800", "name": "Sint-Martens-Leerne", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8690", "name": "Sint-Rijkers", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ALVERINGEM", "commune_niscode": "38002", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6887", "name": "Straimont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HERBEUMONT", "commune_niscode": "84029", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Sugny", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3793", "name": "Teuven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "FOURONS", "commune_niscode": "73109", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4791", "name": "Thommen", "province": "Liège", "province_niscode": "60000", "commune_principale": "BURG-REULAND", "commune_niscode": "63087", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7760", "name": "Velaines", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CELLES", "commune_niscode": "57018", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5300", "name": "Vezin", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANDENNE", "commune_niscode": "92003", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6280", "name": "Villers-Poterie", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "GERPINNES", "commune_niscode": "52025", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7031", "name": "Villers-Saint-Ghislain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4520", "name": "Vinalmont", "province": "Liège", "province_niscode": "60000", "commune_principale": "WANZE", "commune_niscode": "61072", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2340", "name": "Vlimmeren", "province": "Anvers", "province_niscode": "10000", "commune_principale": "BEERSE", "commune_niscode": "13004", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4041", "name": "Vottem", "province": "Liège", "province_niscode": "60000", "commune_principale": "HERSTAL", "commune_niscode": "62051", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9950", "name": "Waarschoot", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LIEVEGEM", "commune_niscode": "44085", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6223", "name": "Wagnelée", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FLEURUS", "commune_niscode": "52021", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7861", "name": "Wannebecq", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LESSINES", "commune_niscode": "51069", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4217", "name": "Waret-L'Evêque", "province": "Liège", "province_niscode": "60000", "commune_principale": "HÉRON", "commune_niscode": "61028", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5523", "name": "Weillen", "province": "Namur", "province_niscode": "90000", "commune_principale": "ONHAYE", "commune_niscode": "91103", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8434", "name": "Westende", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MIDDELKERKE", "commune_niscode": "35011", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3803", "name": "Wilderen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Willerzie", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9506", "name": "Zandbergen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9500", "name": "Zarlardinge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8490", "name": "Zerkegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "JABBEKE", "commune_niscode": "31012", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8750", "name": "Zwevezele", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "WINGENE", "commune_niscode": "37018", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4621", "name": "Retinne", "province": "Liège", "province_niscode": "60000", "commune_principale": "FLÉRON", "commune_niscode": "62038", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4317", "name": "Aineffe", "province": "Liège", "province_niscode": "60000", "commune_principale": "FAIMES", "commune_niscode": "64076", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4821", "name": "Andrimont", "province": "Liège", "province_niscode": "60000", "commune_principale": "DISON", "commune_niscode": "63020", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9404", "name": "Aspelare", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7387", "name": "Athis", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HONNELLES", "commune_niscode": "53083", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7331", "name": "Baudour", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SAINT-GHISLAIN", "commune_niscode": "53070", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1401", "name": "Baulers", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "NIVELLES", "commune_niscode": "25072", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4831", "name": "Bilstain", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIMBOURG", "commune_niscode": "63046", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1367", "name": "Bomal", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "RAMILLIES", "commune_niscode": "25122", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3380", "name": "Bunsbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GLABBEEK", "commune_niscode": "24137", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6230", "name": "Buzet", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PONT-À-CELLES", "commune_niscode": "52055", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Diets-Heur", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Dikkele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Edelare", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8480", "name": "Eernegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ICHTEGEM", "commune_niscode": "35006", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9140", "name": "Elversele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "TEMSE", "commune_niscode": "46025", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9420", "name": "Erpe", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ERPE-MERE", "commune_niscode": "41082", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4950", "name": "Faymonville", "province": "Liège", "province_niscode": "60000", "commune_principale": "WAIMES", "commune_niscode": "63080", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5620", "name": "Flavion", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLORENNES", "commune_niscode": "93022", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7911", "name": "Frasnes-Lez-Buissenal", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5680", "name": "Gimnée", "province": "Namur", "province_niscode": "90000", "commune_principale": "DOISCHE", "commune_niscode": "93018", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9090", "name": "Gontrode", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MELLE", "commune_niscode": "44040", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3800", "name": "Groot-Gelmen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6782", "name": "Habergy", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MESSANCY", "commune_niscode": "81015", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7911", "name": "Hacquegnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5351", "name": "Haillot", "province": "Namur", "province_niscode": "90000", "commune_principale": "OHEY", "commune_niscode": "92097", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8551", "name": "Heestert", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZWEVEGEM", "commune_niscode": "34042", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2801", "name": "Heffen", "province": "Anvers", "province_niscode": "10000", "commune_principale": "MECHELEN", "commune_niscode": "12025", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4480", "name": "Hermalle-Sous-Huy", "province": "Liège", "province_niscode": "60000", "commune_principale": "ENGIS", "commune_niscode": "61080", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7911", "name": "Herquegies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8501", "name": "Heule", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KORTRIJK", "commune_niscode": "34022", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2880", "name": "Hingene", "province": "Anvers", "province_niscode": "10000", "commune_principale": "BORNEM", "commune_niscode": "12007", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3390", "name": "Houwaart", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIELT-WINGE", "commune_niscode": "24135", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1654", "name": "Huizingen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BEERSEL", "commune_niscode": "23003", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3800", "name": "Kerkom-Bij-Sint-Truiden", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6767", "name": "Lamorteau", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ROUVROY", "commune_niscode": "85047", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8340", "name": "Lapscheure", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DAMME", "commune_niscode": "31006", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3061", "name": "Leefdaal", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BERTEM", "commune_niscode": "24009", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Lens-Saint-Remy", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4360", "name": "Lens-Sur-Geer", "province": "Liège", "province_niscode": "60000", "commune_principale": "OREYE", "commune_niscode": "64056", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3545", "name": "Loksbergen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HALEN", "commune_niscode": "71020", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Longchamps", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7700", "name": "Luingne", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MOUSCRON", "commune_niscode": "57096", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7812", "name": "Mainvault", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5670", "name": "Mazée", "province": "Namur", "province_niscode": "90000", "commune_principale": "VIROINVAL", "commune_niscode": "93090", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Melden", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1370", "name": "Mélin", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "JODOIGNE", "commune_niscode": "25048", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4520", "name": "Moha", "province": "Liège", "province_niscode": "60000", "commune_principale": "WANZE", "commune_niscode": "61072", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6592", "name": "Monceau-Imbrechies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MOMIGNIES", "commune_niscode": "56051", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7542", "name": "Mont-Saint-Aubert", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5190", "name": "Mornimont", "province": "Namur", "province_niscode": "90000", "commune_principale": "JEMEPPE-SUR-SAMBRE", "commune_niscode": "92140", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5300", "name": "Namêche", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANDENNE", "commune_niscode": "92003", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3221", "name": "Nieuwrode", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HOLSBEEK", "commune_niscode": "24043", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7387", "name": "Onnezies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HONNELLES", "commune_niscode": "53083", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1315", "name": "Opprebais", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "INCOURT", "commune_niscode": "25043", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4360", "name": "Otrange", "province": "Liège", "province_niscode": "60000", "commune_principale": "OREYE", "commune_niscode": "64056", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4577", "name": "Outrelouxhe", "province": "Liège", "province_niscode": "60000", "commune_principale": "MODAVE", "commune_niscode": "61041", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Patignies", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7760", "name": "Popuelles", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CELLES", "commune_niscode": "57018", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8972", "name": "Proven", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "POPERINGE", "commune_niscode": "33021", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2242", "name": "Pulderbos", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ZANDHOVEN", "commune_niscode": "11054", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5081", "name": "Saint-Denis-Bovesse", "province": "Namur", "province_niscode": "90000", "commune_principale": "LA BRUYÈRE", "commune_niscode": "92141", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Sart-Custinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8554", "name": "Sint-Denijs", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZWEVEGEM", "commune_niscode": "34042", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6920", "name": "Sohier", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "WELLIN", "commune_niscode": "84075", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3510", "name": "Spalbeek", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HASSELT", "commune_niscode": "71022", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7350", "name": "Thulin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HENSIES", "commune_niscode": "53039", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7971", "name": "Thumaide", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BELOEIL", "commune_niscode": "51008", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5621", "name": "Thy-Le-Baudouin", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLORENNES", "commune_niscode": "93022", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4420", "name": "Tilleur", "province": "Liège", "province_niscode": "60000", "commune_principale": "SAINT-NICOLAS", "commune_niscode": "62093", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6637", "name": "Tintange", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "FAUVILLERS", "commune_niscode": "82009", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6941", "name": "Tohogne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7812", "name": "Villers-Saint-Amand", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5570", "name": "Vonêche", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3080", "name": "Vossem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TERVUREN", "commune_niscode": "24104", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7620", "name": "Wez-Velvain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUNEHAUT", "commune_niscode": "57093", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6666", "name": "Wibrin", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HOUFFALIZE", "commune_niscode": "82014", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7608", "name": "Wiers", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PÉRUWELZ", "commune_niscode": "57064", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1861", "name": "Wolvertem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "MEISE", "commune_niscode": "23050", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8470", "name": "Zevekote", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "GISTEL", "commune_niscode": "35005", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3800", "name": "Aalst", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8700", "name": "Aarsele", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "TIELT", "commune_niscode": "37015", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Alle", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6680", "name": "Amberloup", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "SAINTE-ODE", "commune_niscode": "82038", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3404", "name": "Attenhoven", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Bagimont", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7730", "name": "Bailleul", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTAIMPUIS", "commune_niscode": "57027", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Bertrée", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Boneffe", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5032", "name": "Bossière", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9420", "name": "Burst", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ERPE-MERE", "commune_niscode": "41082", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6824", "name": "Chassepierre", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "FLORENVILLE", "commune_niscode": "85011", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1480", "name": "Clabecq", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "TUBIZE", "commune_niscode": "25105", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4480", "name": "Clermont-Sous-Huy", "province": "Liège", "province_niscode": "60000", "commune_principale": "ENGIS", "commune_niscode": "61080", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8900", "name": "Dikkebus", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IEPER", "commune_niscode": "33011", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4920", "name": "Ernonheid", "province": "Liège", "province_niscode": "60000", "commune_principale": "AYWAILLE", "commune_niscode": "62009", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7120", "name": "Estinnes-Au-Mont", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTINNES", "commune_niscode": "58003", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5570", "name": "Feschaux", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4458", "name": "Fexhe-Slins", "province": "Liège", "province_niscode": "60000", "commune_principale": "JUPRELLE", "commune_niscode": "62060", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7643", "name": "Fontenoy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ANTOING", "commune_niscode": "57003", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6953", "name": "Forrières", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NASSOGNE", "commune_niscode": "83040", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6769", "name": "Gérouville", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MEIX-DEVANT-VIRTON", "commune_niscode": "85024", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1367", "name": "Grand-Rosière-Hottomont", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "RAMILLIES", "commune_niscode": "25122", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7742", "name": "Hérinnes-Lez-Pecq", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PECQ", "commune_niscode": "57062", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3001", "name": "Heverlee", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LEUVEN", "commune_niscode": "24062", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2660", "name": "Hoboken", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ANTWERPEN", "commune_niscode": "11002", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7620", "name": "Hollain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUNEHAUT", "commune_niscode": "57093", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7781", "name": "Houthem", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COMINES-WARNETON", "commune_niscode": "57097", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9506", "name": "Idegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4400", "name": "Ivoz-Ramet", "province": "Liège", "province_niscode": "60000", "commune_principale": "FLÉMALLE", "commune_niscode": "62120", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9190", "name": "Kemzeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "STEKENE", "commune_niscode": "46024", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9910", "name": "Knesselare", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALTER", "commune_niscode": "44084", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8000", "name": "Koolkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BRUGGE", "commune_niscode": "31005", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3850", "name": "Kozen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "NIEUWERKERKEN", "commune_niscode": "71045", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6670", "name": "Limerlé", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "GOUVY", "commune_niscode": "82037", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4783", "name": "Lommersweiler", "province": "Liège", "province_niscode": "60000", "commune_principale": "SAINT-VITH", "commune_niscode": "63067", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4431", "name": "Loncin", "province": "Liège", "province_niscode": "60000", "commune_principale": "ANS", "commune_niscode": "62003", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Louette-Saint-Denis", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9870", "name": "Machelen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZULTE", "commune_niscode": "44081", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4623", "name": "Magnée", "province": "Liège", "province_niscode": "60000", "commune_principale": "FLÉRON", "commune_niscode": "62038", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1380", "name": "Maransart", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "LASNE", "commune_niscode": "25119", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6990", "name": "Marenne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HOTTON", "commune_niscode": "83028", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9402", "name": "Meerbeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9220", "name": "Moerzeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HAMME", "commune_niscode": "42008", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6800", "name": "Moircy", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBRAMONT-CHEVIGNY", "commune_niscode": "84077", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7730", "name": "Néchin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTAIMPUIS", "commune_niscode": "57027", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Neuville", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Nieuwkapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1350", "name": "Noduwez", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "ORP-JAUCHE", "commune_niscode": "25120", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5377", "name": "Noiseux", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMME-LEUZE", "commune_niscode": "91120", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8710", "name": "Ooigem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "WIELSBEKE", "commune_niscode": "37017", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8840", "name": "Oostnieuwkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "STADEN", "commune_niscode": "36019", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8640", "name": "Oostvleteren", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VLETEREN", "commune_niscode": "33041", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4800", "name": "Petit-Rechain", "province": "Liège", "province_niscode": "60000", "commune_principale": "VERVIERS", "commune_niscode": "63079", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1370", "name": "Piétrain", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "JODOIGNE", "commune_niscode": "25048", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4122", "name": "Plainevaux", "province": "Liège", "province_niscode": "60000", "commune_principale": "NEUPRÉ", "commune_niscode": "62121", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5574", "name": "Pondrôme", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4557", "name": "Ramelot", "province": "Liège", "province_niscode": "60000", "commune_principale": "TINLOT", "commune_niscode": "61081", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9551", "name": "Ressegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HERZELE", "commune_niscode": "41027", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6250", "name": "Roselies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "AISEAU-PRESLES", "commune_niscode": "52074", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6800", "name": "Saint-Pierre", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBRAMONT-CHEVIGNY", "commune_niscode": "84077", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7912", "name": "Saint-Sauveur", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7100", "name": "Saint-Vaast", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LA LOUVIÈRE", "commune_niscode": "58001", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6800", "name": "Sainte-Marie-Chevigny", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBRAMONT-CHEVIGNY", "commune_niscode": "84077", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9200", "name": "Schoonaarde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DENDERMONDE", "commune_niscode": "42006", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Sint-Maria-Latem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8200", "name": "Sint-Michiels", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BRUGGE", "commune_niscode": "31005", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5150", "name": "Soye", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLOREFFE", "commune_niscode": "92045", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6812", "name": "Suxy", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "CHINY", "commune_niscode": "85007", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2840", "name": "Terhagen", "province": "Anvers", "province_niscode": "10000", "commune_principale": "RUMST", "commune_niscode": "11037", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9140", "name": "Tielrode", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "TEMSE", "commune_niscode": "46025", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5380", "name": "Tillier", "province": "Namur", "province_niscode": "90000", "commune_principale": "FERNELMONT", "commune_niscode": "92138", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1320", "name": "Tourinnes-La-Grosse", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "BEAUVECHAIN", "commune_niscode": "25005", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5630", "name": "Villers-Deux-Eglises", "province": "Namur", "province_niscode": "90000", "commune_principale": "CERFONTAINE", "commune_niscode": "93010", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4550", "name": "Villers-Le-Temple", "province": "Liège", "province_niscode": "60000", "commune_principale": "NANDRIN", "commune_niscode": "61043", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6740", "name": "Villers-Sur-Semois", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ETALLE", "commune_niscode": "85009", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9520", "name": "Vlierzele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-LIEVENS-HOUTEM", "commune_niscode": "41063", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3473", "name": "Waanrode", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KORTENAKEN", "commune_niscode": "24054", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2800", "name": "Walem", "province": "Anvers", "province_niscode": "10000", "commune_principale": "MECHELEN", "commune_niscode": "12025", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5080", "name": "Warisoulx", "province": "Namur", "province_niscode": "90000", "commune_principale": "LA BRUYÈRE", "commune_niscode": "92141", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4530", "name": "Warnant-Dreye", "province": "Liège", "province_niscode": "60000", "commune_principale": "VILLERS-LE-BOUILLET", "commune_niscode": "61068", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1440", "name": "Wauthier-Braine", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "BRAINE-LE-CHÂTEAU", "commune_niscode": "25015", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8953", "name": "Wijtschate", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HEUVELLAND", "commune_niscode": "33039", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3550", "name": "Zolder", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEUSDEN-ZOLDER", "commune_niscode": "71070", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Aublain", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6706", "name": "Autelbas", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ARLON", "commune_niscode": "81001", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1674", "name": "Bellingen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "PEPINGEN", "commune_niscode": "23064", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Berg", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Bourseigne-Neuve", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6800", "name": "Bras", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBRAMONT-CHEVIGNY", "commune_niscode": "84077", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5620", "name": "Corenne", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLORENNES", "commune_niscode": "93022", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5380", "name": "Cortil-Wodon", "province": "Namur", "province_niscode": "90000", "commune_principale": "FERNELMONT", "commune_niscode": "92138", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8630", "name": "Eggewaartskapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VEURNE", "commune_niscode": "38025", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1982", "name": "Elewijt", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZEMST", "commune_niscode": "23096", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8870", "name": "Emelgem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IZEGEM", "commune_niscode": "36008", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5060", "name": "Falisolle", "province": "Namur", "province_niscode": "90000", "commune_principale": "SAMBREVILLE", "commune_niscode": "92137", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4260", "name": "Fallais", "province": "Liège", "province_niscode": "60000", "commune_principale": "BRAIVES", "commune_niscode": "64015", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7181", "name": "Familleureux", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SENEFFE", "commune_niscode": "55085", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5340", "name": "Faulx-Les-Tombes", "province": "Namur", "province_niscode": "90000", "commune_principale": "GESVES", "commune_niscode": "92054", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5576", "name": "Froidfontaine", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3960", "name": "Gerdingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BREE", "commune_niscode": "72004", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9308", "name": "Gijzegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALST", "commune_niscode": "41002", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4140", "name": "Gomzé-Andoumont", "province": "Liège", "province_niscode": "60000", "commune_principale": "SPRIMONT", "commune_niscode": "62100", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6041", "name": "Gosselies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9620", "name": "Grotenberge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZOTTEGEM", "commune_niscode": "41081", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Heurne", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9308", "name": "Hofstade", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALST", "commune_niscode": "41002", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7830", "name": "Hoves", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SILLY", "commune_niscode": "51068", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7950", "name": "Huissignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIÈVRES", "commune_niscode": "51014", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7801", "name": "Irchonwelz", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8691", "name": "Izenberge", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ALVERINGEM", "commune_niscode": "38002", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3990", "name": "Kleine-Brogel", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "PEER", "commune_niscode": "72030", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8300", "name": "Knokke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KNOKKE-HEIST", "commune_niscode": "31043", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1320", "name": "L'Ecluse", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "BEAUVECHAIN", "commune_niscode": "25005", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4300", "name": "Lantremange", "province": "Liège", "province_niscode": "60000", "commune_principale": "WAREMME", "commune_niscode": "64074", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6142", "name": "Leernes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FONTAINE-L'EVÊQUE", "commune_niscode": "52022", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8434", "name": "Lombardsijde", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MIDDELKERKE", "commune_niscode": "35011", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1651", "name": "Lot", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BEERSEL", "commune_niscode": "23003", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5374", "name": "Maffe", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAVELANGE", "commune_niscode": "91064", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7190", "name": "Marche-Lez-Ecaussinnes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ECAUSSINNES", "commune_niscode": "55050", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3630", "name": "Meeswijk", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "MAASMECHELEN", "commune_niscode": "73107", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6470", "name": "Montbliart", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SIVRY-RANCE", "commune_niscode": "56088", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9790", "name": "Moregem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "WORTEGEM-PETEGEM", "commune_niscode": "45061", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Munkzwalm", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6820", "name": "Muno", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "FLORENVILLE", "commune_niscode": "85011", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3740", "name": "Munsterbilzen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7062", "name": "Naast", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SOIGNIES", "commune_niscode": "55040", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4121", "name": "Neuville-En-Condroz", "province": "Liège", "province_niscode": "60000", "commune_principale": "NEUPRÉ", "commune_niscode": "62121", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9320", "name": "Nieuwerkerken", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALST", "commune_niscode": "41002", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1755", "name": "Oetingen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GOOIK", "commune_niscode": "23024", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3640", "name": "Ophoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "KINROOI", "commune_niscode": "72018", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Orchimont", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4210", "name": "Oteppe", "province": "Liège", "province_niscode": "60000", "commune_principale": "BURDINNE", "commune_niscode": "61010", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9406", "name": "Outer", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4800", "name": "Polleur", "province": "Liège", "province_niscode": "60000", "commune_principale": "VERVIERS", "commune_niscode": "63079", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5370", "name": "Porcheresse", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAVELANGE", "commune_niscode": "91064", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Poucet", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8301", "name": "Ramskapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KNOKKE-HEIST", "commune_niscode": "31043", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3370", "name": "Roosbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BOUTERSEM", "commune_niscode": "24016", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9260", "name": "Schellebelle", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "WICHELEN", "commune_niscode": "42026", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9506", "name": "Schendelbeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8200", "name": "Sint-Andries", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BRUGGE", "commune_niscode": "31005", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3300", "name": "Sint-Margriete-Houtem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIENEN", "commune_niscode": "24107", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4557", "name": "Soheit-Tinlot", "province": "Liège", "province_niscode": "60000", "commune_principale": "TINLOT", "commune_niscode": "61081", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5190", "name": "Spy", "province": "Namur", "province_niscode": "90000", "commune_principale": "JEMEPPE-SUR-SAMBRE", "commune_niscode": "92140", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1840", "name": "Steenhuffel", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LONDERZEEL", "commune_niscode": "23045", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7090", "name": "Steenkerque", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRAINE-LE-COMTE", "commune_niscode": "55004", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9620", "name": "Strijpen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZOTTEGEM", "commune_niscode": "41081", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7951", "name": "Tongre-Notre-Dame", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIÈVRES", "commune_niscode": "51014", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5370", "name": "Verlée", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAVELANGE", "commune_niscode": "91064", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9500", "name": "Viane", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6460", "name": "Villers-La-Tour", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1602", "name": "Vlezenbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "SINT-PIETERS-LEEUW", "commune_niscode": "23077", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5650", "name": "Vogenée", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8581", "name": "Waarmaarde", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "AVELGEM", "commune_niscode": "34003", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8420", "name": "Wenduine", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DE HAAN", "commune_niscode": "35029", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9280", "name": "Wieze", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LEBBEKE", "commune_niscode": "42011", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3350", "name": "Wommersom", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LINTER", "commune_niscode": "24133", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8670", "name": "Wulpen", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KOKSIJDE", "commune_niscode": "38014", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8902", "name": "Zillebeke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IEPER", "commune_niscode": "33011", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2970", "name": "'S Gravenwezel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "SCHILDE", "commune_niscode": "11039", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6997", "name": "Amonines", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "EREZÉE", "commune_niscode": "83013", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7910", "name": "Arc-Ainières", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6860", "name": "Assenois", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LÉGLISE", "commune_niscode": "84033", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6500", "name": "Barbençon", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BEAUMONT", "commune_niscode": "56005", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7971", "name": "Basècles", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BELOEIL", "commune_niscode": "51008", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4983", "name": "Basse-Bodeux", "province": "Liège", "province_niscode": "60000", "commune_principale": "TROIS-PONTS", "commune_niscode": "63086", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8510", "name": "Bellegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KORTRIJK", "commune_niscode": "34022", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6834", "name": "Bellevaux", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BOUILLON", "commune_niscode": "84010", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2040", "name": "Berendrecht", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ANTWERPEN", "commune_niscode": "11002", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5380", "name": "Bierwart", "province": "Namur", "province_niscode": "90000", "commune_principale": "FERNELMONT", "commune_niscode": "92138", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1390", "name": "Biez", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GREZ-DOICEAU", "commune_niscode": "25037", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4300", "name": "Bleret", "province": "Liège", "province_niscode": "60000", "commune_principale": "WAREMME", "commune_niscode": "64074", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7866", "name": "Bois-De-Lessines", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LESSINES", "commune_niscode": "51069", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6941", "name": "Bomal-Sur-Ourthe", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2221", "name": "Booischot", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HEIST-OP-DEN-BERG", "commune_niscode": "12014", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7040", "name": "Bougnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUÉVY", "commune_niscode": "53084", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1910", "name": "Buken", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KAMPENHOUT", "commune_niscode": "23038", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6971", "name": "Champlon", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "TENNEVILLE", "commune_niscode": "83049", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7903", "name": "Chapelle-À-Oie", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LEUZE-EN-HAINAUT", "commune_niscode": "57094", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6200", "name": "Châtelineau", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHÂTELET", "commune_niscode": "52012", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4180", "name": "Comblain-La-Tour", "province": "Liège", "province_niscode": "60000", "commune_principale": "HAMOIR", "commune_niscode": "61024", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4253", "name": "Darion", "province": "Liège", "province_niscode": "60000", "commune_principale": "GEER", "commune_niscode": "64029", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9890", "name": "Dikkelvenne", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GAVERE", "commune_niscode": "44020", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3400", "name": "Eliksem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3071", "name": "Erps-Kwerps", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KORTENBERG", "commune_niscode": "24055", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4980", "name": "Fosse", "province": "Liège", "province_niscode": "60000", "commune_principale": "TROIS-PONTS", "commune_niscode": "63086", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4970", "name": "Francorchamps", "province": "Liège", "province_niscode": "60000", "commune_principale": "STAVELOT", "commune_niscode": "63073", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Frasnes", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6840", "name": "Grandvoir", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NEUFCHÂTEAU", "commune_niscode": "84043", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Groot-Loon", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3300", "name": "Hakendover", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIENEN", "commune_niscode": "24107", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5590", "name": "Haversin", "province": "Namur", "province_niscode": "90000", "commune_principale": "CINEY", "commune_niscode": "91030", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Jamiolle", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Jesseren", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3582", "name": "Koersel", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BERINGEN", "commune_niscode": "71004", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9620", "name": "Leeuwergem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZOTTEGEM", "commune_niscode": "41081", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7134", "name": "Leval-Trahegnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BINCHE", "commune_niscode": "58002", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6238", "name": "Liberchies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PONT-À-CELLES", "commune_niscode": "52055", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4600", "name": "Lixhe", "province": "Liège", "province_niscode": "60000", "commune_principale": "VISÉ", "commune_niscode": "62108", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1325", "name": "Longueville", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "CHAUMONT-GISTOUX", "commune_niscode": "25018", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6280", "name": "Loverval", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "GERPINNES", "commune_niscode": "52025", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9230", "name": "Massemen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "WETTEREN", "commune_niscode": "42025", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3404", "name": "Neerlanden", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8950", "name": "Nieuwkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HEUVELLAND", "commune_niscode": "33039", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6640", "name": "Nives", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VAUX-SUR-SÛRE", "commune_niscode": "82036", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6831", "name": "Noirfontaine", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BOUILLON", "commune_niscode": "84010", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2200", "name": "Noorderwijk", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HERENTALS", "commune_niscode": "13011", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5640", "name": "Oret", "province": "Namur", "province_niscode": "90000", "commune_principale": "METTET", "commune_niscode": "92087", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6792", "name": "Rachecourt", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "AUBANGE", "commune_niscode": "81004", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7520", "name": "Ramegnies-Chin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3621", "name": "Rekem", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "LANAKEN", "commune_niscode": "73042", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4624", "name": "Romsée", "province": "Liège", "province_niscode": "60000", "commune_principale": "FLÉRON", "commune_niscode": "62038", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6760", "name": "Ruette", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VIRTON", "commune_niscode": "85045", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Samart", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1495", "name": "Sart-Dames-Avelines", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "VILLERS-LA-VILLE", "commune_niscode": "25107", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9112", "name": "Sinaai-Waas", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-NIKLAAS", "commune_niscode": "46021", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3500", "name": "Sint-Lambrechts-Herk", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HASSELT", "commune_niscode": "71022", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8573", "name": "Tiegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ANZEGEM", "commune_niscode": "34002", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9910", "name": "Ursel", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALTER", "commune_niscode": "44084", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5670", "name": "Vierves-Sur-Viroin", "province": "Namur", "province_niscode": "90000", "commune_principale": "VIROINVAL", "commune_niscode": "93090", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4161", "name": "Villers-Aux-Tours", "province": "Liège", "province_niscode": "60000", "commune_principale": "ANTHISNES", "commune_niscode": "61079", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Villers-Le-Peuplier", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1570", "name": "Vollezele", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GALMAARDEN", "commune_niscode": "23023", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2531", "name": "Vremde", "province": "Anvers", "province_niscode": "10000", "commune_principale": "BOECHOUT", "commune_niscode": "11004", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Vreren", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6900", "name": "Waha", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MARCHE-EN-FAMENNE", "commune_niscode": "83034", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9772", "name": "Wannegem-Lede", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KRUISEM", "commune_niscode": "45068", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5540", "name": "Waulsort", "province": "Namur", "province_niscode": "90000", "commune_principale": "HASTIÈRE", "commune_niscode": "91142", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6210", "name": "Wayaux", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LES BONS VILLERS", "commune_niscode": "52075", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8840", "name": "Westrozebeke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "STADEN", "commune_niscode": "36019", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9800", "name": "Zeveren", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8630", "name": "Zoutenaaie", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VEURNE", "commune_niscode": "38025", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3831", "name": "Herten", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "WELLEN", "commune_niscode": "73098", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5380", "name": "Hingeon", "province": "Namur", "province_niscode": "90000", "commune_principale": "FERNELMONT", "commune_niscode": "92138", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4460", "name": "Hollogne-Aux-Pierres", "province": "Liège", "province_niscode": "60000", "commune_principale": "GRÂCE-HOLLOGNE", "commune_niscode": "62118", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7110", "name": "Houdeng-Aimeries", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LA LOUVIÈRE", "commune_niscode": "58001", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4990", "name": "Arbrefontaine", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIERNEUX", "commune_niscode": "63045", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5377", "name": "Baillonville", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMME-LEUZE", "commune_niscode": "91120", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1670", "name": "Bogaarden", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "PEPINGEN", "commune_niscode": "23064", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2140", "name": "Borgerhout", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ANTWERPEN", "commune_niscode": "11002", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6941", "name": "Borlon", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6440", "name": "Boussu-Lez-Walcourt", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FROIDCHAPELLE", "commune_niscode": "56029", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Bovelingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Brûly-De-Pesche", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6222", "name": "Brye", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FLEURUS", "commune_niscode": "52021", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5650", "name": "Castillon", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4602", "name": "Cheratte", "province": "Liège", "province_niscode": "60000", "commune_principale": "VISÉ", "commune_niscode": "62108", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7780", "name": "Comines", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COMINES-WARNETON", "commune_niscode": "57097", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1435", "name": "Corbais", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "MONT-SAINT-GUIBERT", "commune_niscode": "25068", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6010", "name": "Couillet", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5562", "name": "Custinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "HOUYET", "commune_niscode": "91072", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6767", "name": "Dampicourt", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ROUVROY", "commune_niscode": "85047", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7864", "name": "Deux-Acren", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LESSINES", "commune_niscode": "51069", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3540", "name": "Donk", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HERK-DE-STAD", "commune_niscode": "71024", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5350", "name": "Evelette", "province": "Namur", "province_niscode": "90000", "commune_principale": "OHEY", "commune_niscode": "92097", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5570", "name": "Felenne", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6060", "name": "Gilly", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7830", "name": "Gondregnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SILLY", "commune_niscode": "51068", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Hanret", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Haren", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5537", "name": "Haut-Le-Wastia", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANHÉE", "commune_niscode": "91005", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4841", "name": "Henri-Chapelle", "province": "Liège", "province_niscode": "60000", "commune_principale": "WELKENRAEDT", "commune_niscode": "63084", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5540", "name": "Hermeton-Sur-Meuse", "province": "Namur", "province_niscode": "90000", "commune_principale": "HASTIÈRE", "commune_niscode": "91142", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1981", "name": "Hofstade", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZEMST", "commune_niscode": "23096", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8902", "name": "Hollebeke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IEPER", "commune_niscode": "33011", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6640", "name": "Hompré", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VAUX-SUR-SÛRE", "commune_niscode": "82036", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7301", "name": "Hornu", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BOUSSU", "commune_niscode": "53014", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7624", "name": "Howardries", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUNEHAUT", "commune_niscode": "57093", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6900", "name": "Humain", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MARCHE-EN-FAMENNE", "commune_niscode": "83034", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5032", "name": "Isnes", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3060", "name": "Korbeek-Dijle", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BERTEM", "commune_niscode": "24009", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Laforêt", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4357", "name": "Limont", "province": "Liège", "province_niscode": "60000", "commune_principale": "DONCEEL", "commune_niscode": "64023", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6960", "name": "Malempré", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MANHAY", "commune_niscode": "83055", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4760", "name": "Manderfeld", "province": "Liège", "province_niscode": "60000", "commune_principale": "BULLANGE", "commune_niscode": "63012", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6001", "name": "Marcinelle", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3320", "name": "Meldert", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HOEGAARDEN", "commune_niscode": "24041", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5580", "name": "Mont-Gauthier", "province": "Namur", "province_niscode": "90000", "commune_principale": "ROCHEFORT", "commune_niscode": "91114", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9820", "name": "Munte", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MERELBEKE", "commune_niscode": "44043", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6120", "name": "Nalinnes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HAM-SUR-HEURE", "commune_niscode": "56086", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Neerrepen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1880", "name": "Nieuwenrode", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KAPELLE-OP-DEN-BOS", "commune_niscode": "23039", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6890", "name": "Ochamps", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBIN", "commune_niscode": "84035", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9870", "name": "Olsene", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZULTE", "commune_niscode": "44081", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9041", "name": "Oostakker", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9931", "name": "Oostwinkel", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LIEVEGEM", "commune_niscode": "44085", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1421", "name": "Ophain-Bois-Seigneur-Isaac", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "BRAINE-L'ALLEUD", "commune_niscode": "25014", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1357", "name": "Opheylissem", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "HÉLÉCINE", "commune_niscode": "25118", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1360", "name": "Orbais", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "PERWEZ", "commune_niscode": "25084", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7802", "name": "Ormeignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8553", "name": "Otegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZWEVEGEM", "commune_niscode": "34042", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9500", "name": "Overboelare", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7340", "name": "Pâturages", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COLFONTAINE", "commune_niscode": "53082", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9880", "name": "Poeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALTER", "commune_niscode": "44084", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6929", "name": "Porcheresse", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DAVERDISSE", "commune_niscode": "84016", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3470", "name": "Ransberg", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KORTENAKEN", "commune_niscode": "24054", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6800", "name": "Recogne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBRAMONT-CHEVIGNY", "commune_niscode": "84077", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2840", "name": "Reet", "province": "Anvers", "province_niscode": "10000", "commune_principale": "RUMST", "commune_niscode": "11037", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1315", "name": "Roux-Miroir", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "INCOURT", "commune_niscode": "25043", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6221", "name": "Saint-Amand", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FLEURUS", "commune_niscode": "52021", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6781", "name": "Sélange", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MESSANCY", "commune_niscode": "81015", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4537", "name": "Seraing-Le-Château", "province": "Liège", "province_niscode": "60000", "commune_principale": "VERLAINE", "commune_niscode": "61063", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2890", "name": "Sint-Amands", "province": "Anvers", "province_niscode": "10000", "commune_principale": "PUURS-SINT-AMANDS", "commune_niscode": "12041", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9981", "name": "Sint-Margriete", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-LAUREINS", "commune_niscode": "43014", "arrondissement_niscode": "43000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1700", "name": "Sint-Martens-Bodegem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "DILBEEK", "commune_niscode": "23016", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1541", "name": "Sint-Pieters-Kapelle", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HERNE", "commune_niscode": "23032", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Sluizen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5530", "name": "Spontin", "province": "Namur", "province_niscode": "90000", "commune_principale": "YVOIR", "commune_niscode": "91141", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4890", "name": "Thimister", "province": "Liège", "province_niscode": "60000", "commune_principale": "THIMISTER-CLERMONT", "commune_niscode": "63089", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3631", "name": "Uikhoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "MAASMECHELEN", "commune_niscode": "73107", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6210", "name": "Villers-Perwin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LES BONS VILLERS", "commune_niscode": "52075", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8460", "name": "Westkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "OUDENBURG", "commune_niscode": "35014", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3850", "name": "Wijer", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "NIEUWERKERKEN", "commune_niscode": "71045", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4652", "name": "Xhendelesse", "province": "Liège", "province_niscode": "60000", "commune_principale": "HERVE", "commune_niscode": "63035", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4520", "name": "Antheit", "province": "Liège", "province_niscode": "60000", "commune_principale": "WANZE", "commune_niscode": "61072", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7040", "name": "Asquillies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUÉVY", "commune_niscode": "53084", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Beerst", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6987", "name": "Beffe", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "RENDEUX", "commune_niscode": "83044", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4250", "name": "Boëlhe", "province": "Liège", "province_niscode": "60000", "commune_principale": "GEER", "commune_niscode": "64029", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4653", "name": "Bolland", "province": "Liège", "province_niscode": "60000", "commune_principale": "HERVE", "commune_niscode": "63035", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9820", "name": "Bottelare", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MERELBEKE", "commune_niscode": "44043", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1470", "name": "Bousval", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GENAPPE", "commune_niscode": "25031", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7141", "name": "Carnières", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MORLANWELZ", "commune_niscode": "58004", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1450", "name": "Cortil-Noirmont", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "CHASTRE", "commune_niscode": "25117", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6880", "name": "Cugnon", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BERTRIX", "commune_niscode": "84009", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8740", "name": "Egem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "PITTEM", "commune_niscode": "37011", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9620", "name": "Elene", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZOTTEGEM", "commune_niscode": "41081", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2520", "name": "Emblem", "province": "Anvers", "province_niscode": "10000", "commune_principale": "RANST", "commune_niscode": "11035", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5020", "name": "Flawinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4540", "name": "Flône", "province": "Liège", "province_niscode": "60000", "commune_principale": "AMAY", "commune_niscode": "61003", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5572", "name": "Focant", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6800", "name": "Freux", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBRAMONT-CHEVIGNY", "commune_niscode": "84077", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5500", "name": "Furfooz", "province": "Namur", "province_niscode": "90000", "commune_principale": "DINANT", "commune_niscode": "91034", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7530", "name": "Gaurain-Ramecroix", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5024", "name": "Gelbressée", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8570", "name": "Gijzelbrechtegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ANZEGEM", "commune_niscode": "34002", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6723", "name": "Habay-La-Vieille", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HABAY", "commune_niscode": "85046", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4357", "name": "Haneffe", "province": "Liège", "province_niscode": "60000", "commune_principale": "DONCEEL", "commune_niscode": "64023", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7021", "name": "Havré", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3740", "name": "Hees", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4682", "name": "Heure-Le-Romain", "province": "Liège", "province_niscode": "60000", "commune_principale": "OUPEYE", "commune_niscode": "62079", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3530", "name": "Houthalen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HOUTHALEN-HELCHTEREN", "commune_niscode": "72039", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9750", "name": "Huise", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KRUISEM", "commune_niscode": "45068", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5560", "name": "Hulsonniaux", "province": "Namur", "province_niscode": "90000", "commune_principale": "HOUYET", "commune_niscode": "91072", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1350", "name": "Jauche", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "ORP-JAUCHE", "commune_niscode": "25120", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8510", "name": "Kooigem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KORTRIJK", "commune_niscode": "34022", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4910", "name": "La Reid", "province": "Liège", "province_niscode": "60000", "commune_principale": "THEUX", "commune_niscode": "63076", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5580", "name": "Lavaux-Sainte-Anne", "province": "Namur", "province_niscode": "90000", "commune_principale": "ROCHEFORT", "commune_niscode": "91114", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1755", "name": "Leerbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GOOIK", "commune_niscode": "23024", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2811", "name": "Leest", "province": "Anvers", "province_niscode": "10000", "commune_principale": "MECHELEN", "commune_niscode": "12025", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5140", "name": "Ligny", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMBREFFE", "commune_niscode": "92114", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9680", "name": "Maarke-Kerkem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MAARKEDAL", "commune_niscode": "45064", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6120", "name": "Marbaix", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HAM-SUR-HEURE", "commune_niscode": "56086", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5380", "name": "Marchovelette", "province": "Namur", "province_niscode": "90000", "commune_principale": "FERNELMONT", "commune_niscode": "92138", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2880", "name": "Mariekerke", "province": "Anvers", "province_niscode": "10000", "commune_principale": "BORNEM", "commune_niscode": "12007", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7050", "name": "Masnuy-Saint-Jean", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "JURBISE", "commune_niscode": "53044", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2328", "name": "Meerle", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HOOGSTRATEN", "commune_niscode": "13014", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8377", "name": "Meetkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZUIENKERKE", "commune_niscode": "31042", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9400", "name": "Nederhasselt", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9771", "name": "Nokere", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KRUISEM", "commune_niscode": "45068", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4347", "name": "Noville", "province": "Liège", "province_niscode": "60000", "commune_principale": "FEXHE-LE-HAUT-CLOCHER", "commune_niscode": "64025", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4300", "name": "Oleye", "province": "Liège", "province_niscode": "60000", "commune_principale": "WAREMME", "commune_niscode": "64074", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Oostkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Opheers", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6880", "name": "Orgeo", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BERTRIX", "commune_niscode": "84009", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3040", "name": "Ottenburg", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HULDENBERG", "commune_niscode": "24045", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3321", "name": "Outgaarden", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HOEGAARDEN", "commune_niscode": "24041", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7322", "name": "Pommeroeul", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BERNISSART", "commune_niscode": "51009", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4610", "name": "Queue-Du-Bois", "province": "Liège", "province_niscode": "60000", "commune_principale": "BEYNE-HEUSAY", "commune_niscode": "62015", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7804", "name": "Rebaix", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4120", "name": "Rotheux-Rimière", "province": "Liège", "province_niscode": "60000", "commune_principale": "NEUPRÉ", "commune_niscode": "62121", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Rozebeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5190", "name": "Saint-Martin", "province": "Namur", "province_niscode": "90000", "commune_principale": "JEMEPPE-SUR-SAMBRE", "commune_niscode": "92140", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1370", "name": "Saint-Remy-Geest", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "JODOIGNE", "commune_niscode": "25048", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5300", "name": "Seilles", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANDENNE", "commune_niscode": "92003", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8730", "name": "Sint-Joris", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BEERNEM", "commune_niscode": "31003", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9042", "name": "Sint-Kruis-Winkel", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1750", "name": "Sint-Martens-Lennik", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LENNIK", "commune_niscode": "23104", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5340", "name": "Sorée", "province": "Namur", "province_niscode": "90000", "commune_principale": "GESVES", "commune_niscode": "92054", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5590", "name": "Sovet", "province": "Namur", "province_niscode": "90000", "commune_principale": "CINEY", "commune_niscode": "91030", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6997", "name": "Soy", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "EREZÉE", "commune_niscode": "83013", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2460", "name": "Tielen", "province": "Anvers", "province_niscode": "10000", "commune_principale": "KASTERLEE", "commune_niscode": "13017", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Villers-En-Fagne", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9890", "name": "Vurste", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GAVERE", "commune_niscode": "44020", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4980", "name": "Wanne", "province": "Liège", "province_niscode": "60000", "commune_principale": "TROIS-PONTS", "commune_niscode": "63086", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2390", "name": "Westmalle", "province": "Anvers", "province_niscode": "10000", "commune_principale": "MALLE", "commune_niscode": "11057", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9550", "name": "Woubrechtegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HERZELE", "commune_niscode": "41027", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Woumen", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9750", "name": "Zingem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KRUISEM", "commune_niscode": "45068", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8660", "name": "Adinkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DE PANNE", "commune_niscode": "38008", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9890", "name": "Asper", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GAVERE", "commune_niscode": "44020", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6870", "name": "Awenne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "SAINT-HUBERT", "commune_niscode": "84059", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7784", "name": "Bas-Warneton", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COMINES-WARNETON", "commune_niscode": "57097", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1852", "name": "Beigem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GRIMBERGEN", "commune_niscode": "23025", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6533", "name": "Biercée", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "THUIN", "commune_niscode": "56078", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3052", "name": "Blanden", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "OUD-HEVERLEE", "commune_niscode": "24086", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7370", "name": "Blaugies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "DOUR", "commune_niscode": "53020", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4537", "name": "Bodegnée", "province": "Liège", "province_niscode": "60000", "commune_principale": "VERLAINE", "commune_niscode": "61063", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7170", "name": "Bois-D'Haine", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MANAGE", "commune_niscode": "55086", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Bommershoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3891", "name": "Borlo", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "GINGELOM", "commune_niscode": "71017", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4990", "name": "Bra", "province": "Liège", "province_niscode": "60000", "commune_principale": "LIERNEUX", "commune_niscode": "63045", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3440", "name": "Budingen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZOUTLEEUW", "commune_niscode": "24130", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7061", "name": "Casteau", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SOIGNIES", "commune_niscode": "55040", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2100", "name": "Deurne", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ANTWERPEN", "commune_niscode": "11002", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8951", "name": "Dranouter", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HEUVELLAND", "commune_niscode": "33039", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8380", "name": "Dudzele", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BRUGGE", "commune_niscode": "31005", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3080", "name": "Duisburg", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TERVUREN", "commune_niscode": "24104", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4120", "name": "Ehein", "province": "Liège", "province_niscode": "60000", "commune_principale": "NEUPRÉ", "commune_niscode": "62121", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2430", "name": "Eindhout", "province": "Anvers", "province_niscode": "10000", "commune_principale": "LAAKDAL", "commune_niscode": "13053", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Eine", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1980", "name": "Eppegem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZEMST", "commune_niscode": "23096", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5101", "name": "Erpent", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8587", "name": "Espierres", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ESPIERRES-HELCHIN", "commune_niscode": "34043", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3798", "name": "Fouron-Le-Comte", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "FOURONS", "commune_niscode": "73109", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Franchimont", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4400", "name": "Gleixhe", "province": "Liège", "province_niscode": "60000", "commune_principale": "FLÉMALLE", "commune_niscode": "62120", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5530", "name": "Godinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "YVOIR", "commune_niscode": "91141", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9620", "name": "Godveerdegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZOTTEGEM", "commune_niscode": "41081", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5555", "name": "Graide", "province": "Namur", "province_niscode": "90000", "commune_principale": "BIÈVRE", "commune_niscode": "91015", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2220", "name": "Hallaar", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HEIST-OP-DEN-BERG", "commune_niscode": "12014", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Hendrieken", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7712", "name": "Herseaux", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MOUSCRON", "commune_niscode": "57096", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5377", "name": "Heure", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMME-LEUZE", "commune_niscode": "91120", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6637", "name": "Hollange", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "FAUVILLERS", "commune_niscode": "82009", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Horpmaal", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5563", "name": "Hour", "province": "Namur", "province_niscode": "90000", "commune_principale": "HOUYET", "commune_niscode": "91072", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9630", "name": "Hundelgem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZWALM", "commune_niscode": "45065", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5354", "name": "Jallet", "province": "Namur", "province_niscode": "90000", "commune_principale": "OHEY", "commune_niscode": "92097", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5570", "name": "Javingue", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Kaaskerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3293", "name": "Kaggevinne", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "DIEST", "commune_niscode": "24020", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9130", "name": "Kallo", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BEVEREN-WAAS", "commune_niscode": "46003", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8581", "name": "Kerkhove", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "AVELGEM", "commune_niscode": "34003", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8851", "name": "Koolskamp", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ARDOOIE", "commune_niscode": "37020", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7800", "name": "Lanquesaint", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6681", "name": "Lavacherie", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "SAINTE-ODE", "commune_niscode": "82038", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4250", "name": "Lens-Saint-Servais", "province": "Liège", "province_niscode": "60000", "commune_principale": "GEER", "commune_niscode": "64029", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2460", "name": "Lichtaart", "province": "Anvers", "province_niscode": "10000", "commune_principale": "KASTERLEE", "commune_niscode": "13017", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4141", "name": "Louveigné", "province": "Liège", "province_niscode": "60000", "commune_principale": "SPRIMONT", "commune_niscode": "62100", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5101", "name": "Loyers", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6852", "name": "Maissin", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "PALISEUL", "commune_niscode": "84050", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6030", "name": "Marchienne-Au-Pont", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6747", "name": "Meix-Le-Tige", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "SAINT-LÉGER", "commune_niscode": "85034", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4850", "name": "Montzen", "province": "Liège", "province_niscode": "60000", "commune_principale": "PLOMBIÈRES", "commune_niscode": "63088", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Mouzaive", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7332", "name": "Neufmaison", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SAINT-GHISLAIN", "commune_niscode": "53070", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6717", "name": "Nobressart", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ATTERT", "commune_niscode": "81003", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6960", "name": "Odeigne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MANHAY", "commune_niscode": "83055", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9400", "name": "Okegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6983", "name": "Ortho", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LA ROCHE-EN-ARDENNE", "commune_niscode": "83031", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7120", "name": "Peissant", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTINNES", "commune_niscode": "58003", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9850", "name": "Poesele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2382", "name": "Poppel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "RAVELS", "commune_niscode": "13035", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2870", "name": "Puurs", "province": "Anvers", "province_niscode": "10000", "commune_principale": "PUURS-SINT-AMANDS", "commune_niscode": "12041", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6500", "name": "Renlies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BEAUMONT", "commune_niscode": "56005", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8460", "name": "Roksem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "OUDENBURG", "commune_niscode": "35014", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4140", "name": "Rouvreux", "province": "Liège", "province_niscode": "60000", "commune_principale": "SPRIMONT", "commune_niscode": "62100", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7750", "name": "Russeignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONT-DE-L'ENCLUS", "commune_niscode": "57095", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6762", "name": "Saint-Mard", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VIRTON", "commune_niscode": "85045", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7030", "name": "Saint-Symphorien", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7080", "name": "Sars-La-Bruyère", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRAMERIES", "commune_niscode": "53028", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5521", "name": "Serville", "province": "Namur", "province_niscode": "90000", "commune_principale": "ONHAYE", "commune_niscode": "91103", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6560", "name": "Solre-Sur-Sambre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ERQUELINNES", "commune_niscode": "56022", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4163", "name": "Tavier", "province": "Liège", "province_niscode": "60000", "commune_principale": "ANTHISNES", "commune_niscode": "61079", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6536", "name": "Thuillies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "THUIN", "commune_niscode": "56078", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6717", "name": "Tontelange", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ATTERT", "commune_niscode": "81003", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6833", "name": "Ucimont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BOUILLON", "commune_niscode": "84010", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5020", "name": "Vedrin", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4460", "name": "Velroux", "province": "Liège", "province_niscode": "60000", "commune_principale": "GRÂCE-HOLLOGNE", "commune_niscode": "62118", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8570", "name": "Vichte", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ANZEGEM", "commune_niscode": "34002", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7070", "name": "Ville-Sur-Haine", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LE ROEULX", "commune_niscode": "55035", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Vodecée", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4451", "name": "Voroux-Lez-Liers", "province": "Liège", "province_niscode": "60000", "commune_principale": "JUPRELLE", "commune_niscode": "62060", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4570", "name": "Vyle-Et-Tharoul", "province": "Liège", "province_niscode": "60000", "commune_principale": "MARCHIN", "commune_niscode": "61039", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8020", "name": "Waardamme", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "OOSTKAMP", "commune_niscode": "31022", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2550", "name": "Waarloos", "province": "Anvers", "province_niscode": "10000", "commune_principale": "KONTICH", "commune_niscode": "11024", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6600", "name": "Wardin", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BASTOGNE", "commune_niscode": "82003", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8610", "name": "Zarren", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KORTEMARK", "commune_niscode": "32011", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9520", "name": "Zonnegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-LIEVENS-HOUTEM", "commune_niscode": "41063", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8510", "name": "Marke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KORTRIJK", "commune_niscode": "34022", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9800", "name": "Meigem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4633", "name": "Melen", "province": "Liège", "province_niscode": "60000", "commune_principale": "SOUMAGNE", "commune_niscode": "62099", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6860", "name": "Mellier", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LÉGLISE", "commune_niscode": "84033", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5560", "name": "Mesnil-Eglise", "province": "Namur", "province_niscode": "90000", "commune_principale": "HOUYET", "commune_niscode": "91072", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Mettekoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6540", "name": "Mont-Sainte-Geneviève", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LOBBES", "commune_niscode": "56044", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6061", "name": "Montignies-Sur-Sambre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6750", "name": "Mussy-La-Ville", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MUSSON", "commune_niscode": "85026", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9403", "name": "Neigem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1457", "name": "Nil-Saint-Vincent-Saint-Martin", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "WALHAIN", "commune_niscode": "25124", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7020", "name": "Nimy", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2390", "name": "Oostmalle", "province": "Anvers", "province_niscode": "10000", "commune_principale": "MALLE", "commune_niscode": "11057", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9750", "name": "Ouwegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KRUISEM", "commune_niscode": "45068", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5352", "name": "Perwez-Haillot", "province": "Namur", "province_niscode": "90000", "commune_principale": "OHEY", "commune_niscode": "92097", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6692", "name": "Petit-Thier", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VIELSALM", "commune_niscode": "82032", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5550", "name": "Pussemange", "province": "Namur", "province_niscode": "90000", "commune_principale": "VRESSE-SUR-SEMOIS", "commune_niscode": "91143", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1430", "name": "Quenast", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "REBECQ", "commune_niscode": "25123", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1880", "name": "Ramsdonk", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KAPELLE-OP-DEN-BOS", "commune_niscode": "23039", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4690", "name": "Roclenge-Sur-Geer", "province": "Liège", "province_niscode": "60000", "commune_principale": "BASSENGE", "commune_niscode": "62011", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4257", "name": "Rosoux-Crenwick", "province": "Liège", "province_niscode": "60000", "commune_principale": "BERLOZ", "commune_niscode": "64008", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Rukkelingen-Loon", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5620", "name": "Saint-Aubin", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLORENNES", "commune_niscode": "93022", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4851", "name": "Sippenaeken", "province": "Liège", "province_niscode": "60000", "commune_principale": "PLOMBIÈRES", "commune_niscode": "63088", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7973", "name": "Stambruges", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BELOEIL", "commune_niscode": "51008", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8691", "name": "Stavele", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ALVERINGEM", "commune_niscode": "38002", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5600", "name": "Surice", "province": "Namur", "province_niscode": "90000", "commune_principale": "PHILIPPEVILLE", "commune_niscode": "93056", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3054", "name": "Vaalbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "OUD-HEVERLEE", "commune_niscode": "24086", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3806", "name": "Velm", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4190", "name": "Vieuxville", "province": "Liège", "province_niscode": "60000", "commune_principale": "FERRIÈRES", "commune_niscode": "61019", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Welden", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3111", "name": "Wezemaal", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ROTSELAAR", "commune_niscode": "24094", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5060", "name": "Arsimont", "province": "Namur", "province_niscode": "90000", "commune_principale": "SAMBREVILLE", "commune_niscode": "92137", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7380", "name": "Baisieux", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUIÉVRAIN", "commune_niscode": "53068", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7870", "name": "Bauffe", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LENS", "commune_niscode": "53046", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9520", "name": "Bavegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-LIEVENS-HOUTEM", "commune_niscode": "41063", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3960", "name": "Beek", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BREE", "commune_niscode": "72004", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3540", "name": "Berbroek", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HERK-DE-STAD", "commune_niscode": "71024", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2600", "name": "Berchem", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ANTWERPEN", "commune_niscode": "11002", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8791", "name": "Beveren", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "WAREGEM", "commune_niscode": "34040", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4460", "name": "Bierset", "province": "Liège", "province_niscode": "60000", "commune_principale": "GRÂCE-HOLLOGNE", "commune_niscode": "62118", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5542", "name": "Blaimont", "province": "Namur", "province_niscode": "90000", "commune_principale": "HASTIÈRE", "commune_niscode": "91142", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7603", "name": "Bon-Secours", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PÉRUWELZ", "commune_niscode": "57064", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3631", "name": "Boorsem", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "MAASMECHELEN", "commune_niscode": "73107", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2288", "name": "Bouwel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "GROBBENDONK", "commune_niscode": "13010", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7133", "name": "Buvrinnes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BINCHE", "commune_niscode": "58002", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4257", "name": "Corswarem", "province": "Liège", "province_niscode": "60000", "commune_principale": "BERLOZ", "commune_niscode": "64008", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Dhuy", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7050", "name": "Erbaut", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "JURBISE", "commune_niscode": "53044", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7387", "name": "Fayt-Le-Franc", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HONNELLES", "commune_niscode": "53083", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6686", "name": "Flamierge", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BERTOGNE", "commune_niscode": "82005", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3792", "name": "Fouron-Saint-Pierre", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "FOURONS", "commune_niscode": "73109", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7040", "name": "Goegnies-Chaussée", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUÉVY", "commune_niscode": "53084", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9506", "name": "Grimminge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5340", "name": "Haltinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "GESVES", "commune_niscode": "92054", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5100", "name": "Jambes", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8700", "name": "Kanegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "TIELT", "commune_niscode": "37015", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3950", "name": "Kaulille", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BOCHOLT", "commune_niscode": "72003", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6500", "name": "Leval-Chaudeville", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BEAUMONT", "commune_niscode": "56005", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9450", "name": "Heldergem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HAALTERT", "commune_niscode": "41024", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7522", "name": "Hertain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8690", "name": "Hoogstade", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ALVERINGEM", "commune_niscode": "38002", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7812", "name": "Houtaing", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1851", "name": "Humbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GRIMBERGEN", "commune_niscode": "23025", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3360", "name": "Korbeek-Lo", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BIERBEEK", "commune_niscode": "24011", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3400", "name": "Laar", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3210", "name": "Linden", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LUBBEEK", "commune_niscode": "24066", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5501", "name": "Lisogne", "province": "Namur", "province_niscode": "90000", "commune_principale": "DINANT", "commune_niscode": "91034", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2990", "name": "Loenhout", "province": "Anvers", "province_niscode": "10000", "commune_principale": "WUUSTWEZEL", "commune_niscode": "11053", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3360", "name": "Lovenjoel", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BIERBEEK", "commune_niscode": "24011", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7811", "name": "Arbre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4260", "name": "Avennes", "province": "Liège", "province_niscode": "60000", "commune_principale": "BRAIVES", "commune_niscode": "64015", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3128", "name": "Baal", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TREMELO", "commune_niscode": "24109", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6464", "name": "Baileux", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6460", "name": "Bailièvre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8480", "name": "Bekegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ICHTEGEM", "commune_niscode": "35006", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Bevere", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Bolinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4317", "name": "Borlez", "province": "Liège", "province_niscode": "60000", "commune_principale": "FAIMES", "commune_niscode": "64076", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2870", "name": "Breendonk", "province": "Anvers", "province_niscode": "10000", "commune_principale": "PUURS-SINT-AMANDS", "commune_niscode": "12041", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7642", "name": "Calonne", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ANTOING", "commune_niscode": "57003", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1341", "name": "Céroux-Mousty", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "OTTIGNIES-LOUVAIN-LA-NEUVE", "commune_niscode": "25121", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4140", "name": "Dolembreux", "province": "Liège", "province_niscode": "60000", "commune_principale": "SPRIMONT", "commune_niscode": "62100", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1370", "name": "Dongelberg", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "JODOIGNE", "commune_niscode": "25048", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7972", "name": "Ellignies-Sainte-Anne", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BELOEIL", "commune_niscode": "51008", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Esen", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4870", "name": "Forêt", "province": "Liège", "province_niscode": "60000", "commune_principale": "TROOZ", "commune_niscode": "62122", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5641", "name": "Furnaux", "province": "Namur", "province_niscode": "90000", "commune_principale": "METTET", "commune_niscode": "92087", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4851", "name": "Gemmenich", "province": "Liège", "province_niscode": "60000", "commune_principale": "PLOMBIÈRES", "commune_niscode": "63088", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7120", "name": "Haulchin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTINNES", "commune_niscode": "58003", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6220", "name": "Heppignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FLEURUS", "commune_niscode": "52021", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6642", "name": "Juseret", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VAUX-SUR-SÛRE", "commune_niscode": "82036", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8572", "name": "Kaster", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ANZEGEM", "commune_niscode": "34002", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Kolmont", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4350", "name": "Lamine", "province": "Liège", "province_niscode": "60000", "commune_principale": "REMICOURT", "commune_niscode": "64063", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3201", "name": "Langdorp", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "AARSCHOT", "commune_niscode": "24001", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9971", "name": "Lembeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KAPRIJKE", "commune_niscode": "43007", "arrondissement_niscode": "43000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4317", "name": "Les Waleffes", "province": "Liège", "province_niscode": "60000", "commune_principale": "FAIMES", "commune_niscode": "64076", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6500", "name": "Leugnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BEAUMONT", "commune_niscode": "56005", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1357", "name": "Linsmeau", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "HÉLÉCINE", "commune_niscode": "25118", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8380", "name": "Lissewege", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BRUGGE", "commune_niscode": "31005", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7020", "name": "Maisières", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Mariembourg", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7760", "name": "Molenbaix", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CELLES", "commune_niscode": "57018", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6032", "name": "Mont-Sur-Marchienne", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHARLEROI", "commune_niscode": "52011", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2200", "name": "Morkhoven", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HERENTALS", "commune_niscode": "13011", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1320", "name": "Nodebais", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "BEAUVECHAIN", "commune_niscode": "25005", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2520", "name": "Oelegem", "province": "Anvers", "province_niscode": "10000", "commune_principale": "RANST", "commune_niscode": "11035", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8720", "name": "Oeselgem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DENTERGEM", "commune_niscode": "37002", "arrondissement_niscode": "37000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7750", "name": "Orroir", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONT-DE-L'ENCLUS", "commune_niscode": "57095", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4560", "name": "Pailhe", "province": "Liège", "province_niscode": "60000", "commune_principale": "CLAVIER", "commune_niscode": "61012", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1315", "name": "Piétrebais", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "INCOURT", "commune_niscode": "25043", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6830", "name": "Poupehan", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BOUILLON", "commune_niscode": "84010", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8972", "name": "Roesbrugge-Haringe", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "POPERINGE", "commune_niscode": "33021", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1370", "name": "Saint-Jean-Geest", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "JODOIGNE", "commune_niscode": "25048", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4550", "name": "Saint-Séverin", "province": "Liège", "province_niscode": "60000", "commune_principale": "NANDRIN", "commune_niscode": "61043", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4557", "name": "Seny", "province": "Liège", "province_niscode": "60000", "commune_principale": "TINLOT", "commune_niscode": "61081", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5630", "name": "Silenrieux", "province": "Namur", "province_niscode": "90000", "commune_principale": "CERFONTAINE", "commune_niscode": "93010", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9051", "name": "Sint-Denijs-Westrem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Sint-Jacobs-Kapelle", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8310", "name": "Sint-Kruis", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BRUGGE", "commune_niscode": "31005", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9170", "name": "Sint-Pauwels", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "SINT-GILLIS-WAAS", "commune_niscode": "46020", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1700", "name": "Sint-Ulriks-Kapelle", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "DILBEEK", "commune_niscode": "23016", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8490", "name": "Stalhille", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "JABBEKE", "commune_niscode": "31012", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8630", "name": "Steenkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VEURNE", "commune_niscode": "38025", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6511", "name": "Strée", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BEAUMONT", "commune_niscode": "56005", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1853", "name": "Strombeek-Bever", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GRIMBERGEN", "commune_niscode": "23025", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1457", "name": "Tourinnes-Saint-Lambert", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "WALHAIN", "commune_niscode": "25124", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3020", "name": "Veltem-Beisem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HERENT", "commune_niscode": "24038", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1472", "name": "Vieux-Genappe", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GENAPPE", "commune_niscode": "25031", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9420", "name": "Vlekkem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ERPE-MERE", "commune_niscode": "41082", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1457", "name": "Walhain-Saint-Paul", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "WALHAIN", "commune_niscode": "25124", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4608", "name": "Warsage", "province": "Liège", "province_niscode": "60000", "commune_principale": "DALHEM", "commune_niscode": "62027", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7604", "name": "Wasmes-Audemez-Briffoeil", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PÉRUWELZ", "commune_niscode": "57064", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1474", "name": "Ways", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GENAPPE", "commune_niscode": "25031", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1982", "name": "Weerde", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZEMST", "commune_niscode": "23096", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6980", "name": "Beausaint", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LA ROCHE-EN-ARDENNE", "commune_niscode": "83031", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3130", "name": "Betekom", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BEGIJNENDIJK", "commune_niscode": "24007", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3211", "name": "Binkom", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LUBBEEK", "commune_niscode": "24066", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5537", "name": "Bioul", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANHÉE", "commune_niscode": "91005", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7522", "name": "Blandain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8900", "name": "Brielen", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IEPER", "commune_niscode": "33011", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7911", "name": "Buissenal", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5580", "name": "Buissonville", "province": "Namur", "province_niscode": "90000", "commune_principale": "ROCHEFORT", "commune_niscode": "91114", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7604", "name": "Callenelle", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PÉRUWELZ", "commune_niscode": "57064", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6747", "name": "Châtillon", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "SAINT-LÉGER", "commune_niscode": "85034", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4260", "name": "Ciplet", "province": "Liège", "province_niscode": "60000", "commune_principale": "BRAIVES", "commune_niscode": "64015", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5590", "name": "Conneux", "province": "Namur", "province_niscode": "90000", "commune_principale": "CINEY", "commune_niscode": "91030", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3290", "name": "Deurne", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "DIEST", "commune_niscode": "24020", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3670", "name": "Ellikom", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "OUDSBERGEN", "commune_niscode": "72042", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7134", "name": "Epinois", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BINCHE", "commune_niscode": "58002", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9620", "name": "Erwetegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZOTTEGEM", "commune_niscode": "41081", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7502", "name": "Esplechin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6990", "name": "Fronville", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HOTTON", "commune_niscode": "83028", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7822", "name": "Ghislenghien", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7011", "name": "Ghlin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5660", "name": "Gonrieux", "province": "Namur", "province_niscode": "90000", "commune_principale": "COUVIN", "commune_niscode": "93014", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6960", "name": "Grandménil", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MANHAY", "commune_niscode": "83055", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6952", "name": "Grune", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NASSOGNE", "commune_niscode": "83040", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7350", "name": "Hainin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HENSIES", "commune_niscode": "53039", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1785", "name": "Hamme", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "MERCHTEM", "commune_niscode": "23052", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6560", "name": "Hantes-Wihéries", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ERQUELINNES", "commune_niscode": "56022", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6929", "name": "Haut-Fays", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DAVERDISSE", "commune_niscode": "84016", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7531", "name": "Havinnes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1670", "name": "Heikruis", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "PEPINGEN", "commune_niscode": "23064", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6941", "name": "Heyd", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "DURBUY", "commune_niscode": "83012", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5580", "name": "Jemelle", "province": "Namur", "province_niscode": "90000", "commune_principale": "ROCHEFORT", "commune_niscode": "91114", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3220", "name": "Kortrijk-Dutsel", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HOLSBEEK", "commune_niscode": "24043", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7611", "name": "La Glanerie", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "RUMES", "commune_niscode": "57072", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7170", "name": "La Hestre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MANAGE", "commune_niscode": "55086", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8691", "name": "Leisele", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ALVERINGEM", "commune_niscode": "38002", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6953", "name": "Masbourg", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NASSOGNE", "commune_niscode": "83040", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7110", "name": "Maurage", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LA LOUVIÈRE", "commune_niscode": "58001", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3350", "name": "Melkwezer", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LINTER", "commune_niscode": "24133", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6640", "name": "Morhet", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VAUX-SUR-SÛRE", "commune_niscode": "82036", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4560", "name": "Ocquier", "province": "Liège", "province_niscode": "60000", "commune_principale": "CLAVIER", "commune_niscode": "61012", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4367", "name": "Odeur", "province": "Liège", "province_niscode": "60000", "commune_principale": "CRISNÉE", "commune_niscode": "64021", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7501", "name": "Orcq", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3350", "name": "Overhespen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LINTER", "commune_niscode": "24133", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3583", "name": "Paal", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BERINGEN", "commune_niscode": "71004", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5590", "name": "Pessoux", "province": "Namur", "province_niscode": "90000", "commune_principale": "CINEY", "commune_niscode": "91030", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Piringen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7040", "name": "Quévy-Le-Petit", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "QUÉVY", "commune_niscode": "53084", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7971", "name": "Ramegnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BELOEIL", "commune_niscode": "51008", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8930", "name": "Rekkem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MENEN", "commune_niscode": "34027", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3950", "name": "Reppel", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BOCHOLT", "commune_niscode": "72003", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3740", "name": "Rijkhoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2820", "name": "Rijmenam", "province": "Anvers", "province_niscode": "10000", "commune_principale": "BONHEIDEN", "commune_niscode": "12005", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5170", "name": "Rivière", "province": "Namur", "province_niscode": "90000", "commune_principale": "PROFONDEVILLE", "commune_niscode": "92101", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6769", "name": "Robelmont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MEIX-DEVANT-VIRTON", "commune_niscode": "85024", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3730", "name": "Romershoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HOESELT", "commune_niscode": "73032", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3740", "name": "Rosmeer", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6900", "name": "Roy", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MARCHE-EN-FAMENNE", "commune_niscode": "83034", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "Rutten", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5030", "name": "Sauvenière", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEMBLOUX", "commune_niscode": "92142", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9260", "name": "Serskamp", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "WICHELEN", "commune_niscode": "42026", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3040", "name": "Sint-Agatha-Rode", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HULDENBERG", "commune_niscode": "24045", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8793", "name": "Sint-Eloois-Vijve", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "WAREGEM", "commune_niscode": "34040", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3051", "name": "Sint-Joris-Weert", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "OUD-HEVERLEE", "commune_niscode": "24086", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5333", "name": "Sorinne-La-Longue", "province": "Namur", "province_niscode": "90000", "commune_principale": "ASSESSE", "commune_niscode": "92006", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4920", "name": "Sougné-Remouchamps", "province": "Liège", "province_niscode": "60000", "commune_principale": "AYWAILLE", "commune_niscode": "62009", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4560", "name": "Terwagne", "province": "Liège", "province_niscode": "60000", "commune_principale": "CLAVIER", "commune_niscode": "61012", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4367", "name": "Thys", "province": "Liège", "province_niscode": "60000", "commune_principale": "CRISNÉE", "commune_niscode": "64021", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8490", "name": "Varsenare", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "JABBEKE", "commune_niscode": "31012", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6440", "name": "Vergnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FROIDCHAPELLE", "commune_niscode": "56029", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7322", "name": "Ville-Pommeroeul", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BERNISSART", "commune_niscode": "51009", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5070", "name": "Vitrival", "province": "Namur", "province_niscode": "90000", "commune_principale": "FOSSES-LA-VILLE", "commune_niscode": "92048", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4683", "name": "Vivegnis", "province": "Liège", "province_niscode": "60000", "commune_principale": "OUPEYE", "commune_niscode": "62079", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6220", "name": "Wangenies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FLEURUS", "commune_niscode": "52021", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7340", "name": "Warquignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COLFONTAINE", "commune_niscode": "53082", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7910", "name": "Wattripont", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3150", "name": "Wespelaar", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HAACHT", "commune_niscode": "24033", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2323", "name": "Wortel", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HOOGSTRATEN", "commune_niscode": "13014", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9052", "name": "Zwijnaarde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4780", "name": "Recht", "province": "Liège", "province_niscode": "60000", "commune_principale": "SAINT-VITH", "commune_niscode": "63067", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3791", "name": "Remersdaal", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "FOURONS", "commune_niscode": "73109", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3454", "name": "Rummen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "GEETBETS", "commune_niscode": "24028", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9150", "name": "Rupelmonde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "KRUIBEKE", "commune_niscode": "46013", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1703", "name": "Schepdaal", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "DILBEEK", "commune_niscode": "23016", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3270", "name": "Scherpenheuvel", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "commune_niscode": "24134", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6750", "name": "Signeulx", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MUSSON", "commune_niscode": "85026", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9620", "name": "Sint-Goriks-Oudenhove", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZOTTEGEM", "commune_niscode": "41081", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5503", "name": "Sorinnes", "province": "Namur", "province_niscode": "90000", "commune_principale": "DINANT", "commune_niscode": "91034", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4801", "name": "Stembert", "province": "Liège", "province_niscode": "60000", "commune_principale": "VERVIERS", "commune_niscode": "63079", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Stuivekenskerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6230", "name": "Thiméon", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PONT-À-CELLES", "commune_niscode": "52055", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7904", "name": "Tourpes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LEUZE-EN-HAINAUT", "commune_niscode": "57094", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6183", "name": "Trazegnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COURCELLES", "commune_niscode": "52015", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6960", "name": "Vaux-Chavanne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MANHAY", "commune_niscode": "83055", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6823", "name": "Villers-Devant-Orval", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "FLORENVILLE", "commune_niscode": "85011", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5080", "name": "Villers-Lez-Heest", "province": "Namur", "province_niscode": "90000", "commune_principale": "LA BRUYÈRE", "commune_niscode": "92141", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9921", "name": "Vinderhoute", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LIEVEGEM", "commune_niscode": "44085", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3890", "name": "Vorsen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "GINGELOM", "commune_niscode": "71017", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5377", "name": "Waillet", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMME-LEUZE", "commune_niscode": "91120", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5570", "name": "Wancennes", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Wansin", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7740", "name": "Warcoing", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PECQ", "commune_niscode": "57062", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8610", "name": "Werken", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KORTEMARK", "commune_niscode": "32011", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2222", "name": "Wiekevorst", "province": "Anvers", "province_niscode": "10000", "commune_principale": "HEIST-OP-DEN-BERG", "commune_niscode": "12014", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5571", "name": "Wiesme", "province": "Namur", "province_niscode": "90000", "commune_principale": "BEAURAING", "commune_niscode": "91013", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3370", "name": "Willebringen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "BOUTERSEM", "commune_niscode": "24016", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7506", "name": "Willemeau", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9032", "name": "Wondelgem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GENT", "commune_niscode": "44021", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4432", "name": "Xhendremael", "province": "Liège", "province_niscode": "60000", "commune_principale": "ANS", "commune_niscode": "62003", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4557", "name": "Abée", "province": "Liège", "province_niscode": "60000", "commune_principale": "TINLOT", "commune_niscode": "61081", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6280", "name": "Acoz", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "GERPINNES", "commune_niscode": "52025", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9991", "name": "Adegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MALDEGEM", "commune_niscode": "43010", "arrondissement_niscode": "43000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5060", "name": "Auvelais", "province": "Namur", "province_niscode": "90000", "commune_principale": "SAMBREVILLE", "commune_niscode": "92137", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7830", "name": "Bassilly", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SILLY", "commune_niscode": "51068", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7604", "name": "Baugnies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "PÉRUWELZ", "commune_niscode": "57064", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9080", "name": "Beervelde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LOCHRISTI", "commune_niscode": "44034", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6700", "name": "Bonnert", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ARLON", "commune_niscode": "81001", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3300", "name": "Bost", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIENEN", "commune_niscode": "24107", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2070", "name": "Burcht", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ZWIJNDRECHT", "commune_niscode": "11056", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1380", "name": "Couture-Saint-Germain", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "LASNE", "commune_niscode": "25119", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Crehen", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5332", "name": "Crupet", "province": "Namur", "province_niscode": "90000", "commune_principale": "ASSESSE", "commune_niscode": "92006", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6860", "name": "Ebly", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LÉGLISE", "commune_niscode": "84033", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4870", "name": "Fraipont", "province": "Liège", "province_niscode": "60000", "commune_principale": "TROOZ", "commune_niscode": "62122", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1315", "name": "Glimes", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "INCOURT", "commune_niscode": "25043", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3740", "name": "Grote-Spouwen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3800", "name": "Halmaal", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5190", "name": "Ham-Sur-Sambre", "province": "Namur", "province_niscode": "90000", "commune_principale": "JEMEPPE-SUR-SAMBRE", "commune_niscode": "92140", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3440", "name": "Helen-Bos", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZOUTLEEUW", "commune_niscode": "24130", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7090", "name": "Henripont", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRAINE-LE-COMTE", "commune_niscode": "55004", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6984", "name": "Hives", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LA ROCHE-EN-ARDENNE", "commune_niscode": "83031", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Hoepertingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4460", "name": "Horion-Hozémont", "province": "Liège", "province_niscode": "60000", "commune_principale": "GRÂCE-HOLLOGNE", "commune_niscode": "62118", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6120", "name": "Jamioulx", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HAM-SUR-HEURE", "commune_niscode": "56086", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1730", "name": "Kobbegem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ASSE", "commune_niscode": "23002", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Kuttekoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9850", "name": "Landegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5070", "name": "Le Roux", "province": "Namur", "province_niscode": "90000", "commune_principale": "FOSSES-LA-VILLE", "commune_niscode": "92048", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3040", "name": "Loonbeek", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HULDENBERG", "commune_niscode": "24045", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5300", "name": "Maizeret", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANDENNE", "commune_niscode": "92003", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8650", "name": "Merkem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "HOUTHULST", "commune_niscode": "32006", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9200", "name": "Mespelare", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DENDERMONDE", "commune_niscode": "42006", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6661", "name": "Mont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "HOUFFALIZE", "commune_niscode": "82014", "arrondissement_niscode": "82000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7911", "name": "Moustier", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5340", "name": "Mozet", "province": "Namur", "province_niscode": "90000", "commune_principale": "GESVES", "commune_niscode": "92054", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5680", "name": "Niverlée", "province": "Namur", "province_niscode": "90000", "commune_principale": "DOISCHE", "commune_niscode": "93018", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1600", "name": "Oudenaken", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "SINT-PIETERS-LEEUW", "commune_niscode": "23077", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5555", "name": "Petit-Fays", "province": "Namur", "province_niscode": "90000", "commune_principale": "BIÈVRE", "commune_niscode": "91015", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Petit-Hallet", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7904", "name": "Pipaix", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LEUZE-EN-HAINAUT", "commune_niscode": "57094", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8211", "name": "Aartrijke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZEDELGEM", "commune_niscode": "31040", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7387", "name": "Angreau", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HONNELLES", "commune_niscode": "53083", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7750", "name": "Anseroeul", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONT-DE-L'ENCLUS", "commune_niscode": "57095", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7910", "name": "Anvaing", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRASNES-LEZ-ANVAING", "commune_niscode": "51065", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8310", "name": "Assebroek", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BRUGGE", "commune_niscode": "31005", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4400", "name": "Awirs", "province": "Liège", "province_niscode": "60000", "commune_principale": "FLÉMALLE", "commune_niscode": "62120", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4052", "name": "Beaufays", "province": "Liège", "province_niscode": "60000", "commune_principale": "CHAUDFONTAINE", "commune_niscode": "62022", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4610", "name": "Bellaire", "province": "Liège", "province_niscode": "60000", "commune_principale": "BEYNE-HEUSAY", "commune_niscode": "62015", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7170", "name": "Bellecourt", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MANAGE", "commune_niscode": "55086", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1910", "name": "Berg", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "KAMPENHOUT", "commune_niscode": "23038", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8980", "name": "Beselare", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZONNEBEKE", "commune_niscode": "33037", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5640", "name": "Biesme", "province": "Namur", "province_niscode": "90000", "commune_principale": "METTET", "commune_niscode": "92087", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4100", "name": "Boncelles", "province": "Liège", "province_niscode": "60000", "commune_principale": "SERAING", "commune_niscode": "62096", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5300", "name": "Bonneville", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANDENNE", "commune_niscode": "92003", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6464", "name": "Bourlers", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7641", "name": "Bruyelle", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ANTOING", "commune_niscode": "57003", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3891", "name": "Buvingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "GINGELOM", "commune_niscode": "71017", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7024", "name": "Ciply", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1325", "name": "Corroy-Le-Grand", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "CHAUMONT-GISTOUX", "commune_niscode": "25018", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9400", "name": "Denderwindeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "NINOVE", "commune_niscode": "41048", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6836", "name": "Dohan", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BOUILLON", "commune_niscode": "84010", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Driekapellen", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6760", "name": "Ethe", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VIRTON", "commune_niscode": "85045", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9660", "name": "Everbeek", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BRAKEL", "commune_niscode": "45059", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7012", "name": "Flénu", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5150", "name": "Floriffoux", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLOREFFE", "commune_niscode": "92045", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1350", "name": "Folx-Les-Caves", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "ORP-JAUCHE", "commune_niscode": "25120", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6596", "name": "Forge-Philippe", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MOMIGNIES", "commune_niscode": "56051", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6210", "name": "Frasnes-Lez-Gosselies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LES BONS VILLERS", "commune_niscode": "52075", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6470", "name": "Grandrieu", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SIVRY-RANCE", "commune_niscode": "56088", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4684", "name": "Haccourt", "province": "Liège", "province_niscode": "60000", "commune_principale": "OUPEYE", "commune_niscode": "62079", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5377", "name": "Hogne", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMME-LEUZE", "commune_niscode": "91120", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9940", "name": "Kluizen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "EVERGEM", "commune_niscode": "44019", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3300", "name": "Kumtich", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "TIENEN", "commune_niscode": "24107", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3650", "name": "Lanklaar", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "DILSEN-STOKKEM", "commune_niscode": "72041", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1428", "name": "Lillois-Witterzée", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "BRAINE-L'ALLEUD", "commune_niscode": "25014", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5575", "name": "Louette-Saint-Pierre", "province": "Namur", "province_niscode": "90000", "commune_principale": "GEDINNE", "commune_niscode": "91054", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7522", "name": "Marquain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5680", "name": "Matagne-La-Grande", "province": "Namur", "province_niscode": "90000", "commune_principale": "DOISCHE", "commune_niscode": "93018", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3770", "name": "Millen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "RIEMST", "commune_niscode": "73066", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5555", "name": "Monceau-En-Ardenne", "province": "Namur", "province_niscode": "90000", "commune_principale": "BIÈVRE", "commune_niscode": "91015", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5555", "name": "Naomé", "province": "Namur", "province_niscode": "90000", "commune_principale": "BIÈVRE", "commune_niscode": "91015", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3910", "name": "Neerpelt", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "PELT", "commune_niscode": "72043", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4721", "name": "Neu-Moresnet", "province": "Liège", "province_niscode": "60000", "commune_principale": "LA CALAMINE", "commune_niscode": "63040", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9681", "name": "Nukerke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "MAARKEDAL", "commune_niscode": "45064", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8690", "name": "Oeren", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ALVERINGEM", "commune_niscode": "38002", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1480", "name": "Oisquercq", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "TUBIZE", "commune_niscode": "25105", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9620", "name": "Oombergen", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ZOTTEGEM", "commune_niscode": "41081", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9500", "name": "Ophasselt", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3800", "name": "Ordingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "SINT-TRUIDEN", "commune_niscode": "71053", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Pervijze", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1380", "name": "Plancenoit", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "LASNE", "commune_niscode": "25119", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2275", "name": "Poederlee", "province": "Anvers", "province_niscode": "10000", "commune_principale": "LILLE", "commune_niscode": "13019", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6250", "name": "Pont-De-Loup", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "AISEAU-PRESLES", "commune_niscode": "52074", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2243", "name": "Pulle", "province": "Anvers", "province_niscode": "10000", "commune_principale": "ZANDHOVEN", "commune_niscode": "11054", "arrondissement_niscode": "11000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8970", "name": "Reningelst", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "POPERINGE", "commune_niscode": "33021", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5651", "name": "Rognée", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8510", "name": "Rollegem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KORTRIJK", "commune_niscode": "34022", "arrondissement_niscode": "34000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6542", "name": "Sars-La-Buissière", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LOBBES", "commune_niscode": "56044", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4782", "name": "Schoenberg", "province": "Liège", "province_niscode": "60000", "commune_principale": "SAINT-VITH", "commune_niscode": "63067", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8433", "name": "Schore", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MIDDELKERKE", "commune_niscode": "35011", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5377", "name": "Sinsin", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMME-LEUZE", "commune_niscode": "91120", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3910", "name": "Sint-Huibrechts-Lille", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "PELT", "commune_niscode": "72043", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9570", "name": "Sint-Maria-Lierde", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LIERDE", "commune_niscode": "45063", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9506", "name": "Smeerebbe-Vloerzegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4861", "name": "Soiron", "province": "Liège", "province_niscode": "60000", "commune_principale": "PEPINSTER", "commune_niscode": "63058", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9140", "name": "Steendorp", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "TEMSE", "commune_niscode": "46025", "arrondissement_niscode": "46000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1760", "name": "Strijtem", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ROOSDAAL", "commune_niscode": "23097", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7520", "name": "Templeuve", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4670", "name": "Trembleur", "province": "Liège", "province_niscode": "60000", "commune_principale": "BLÉGNY", "commune_niscode": "62119", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4577", "name": "Vierset-Barse", "province": "Liège", "province_niscode": "60000", "commune_principale": "MODAVE", "commune_niscode": "61041", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8908", "name": "Vlamertinge", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IEPER", "commune_niscode": "33011", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7784", "name": "Warneton", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COMINES-WARNETON", "commune_niscode": "57097", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3118", "name": "Werchter", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ROTSELAAR", "commune_niscode": "24094", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5100", "name": "Wierde", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7904", "name": "Willaupuis", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "LEUZE-EN-HAINAUT", "commune_niscode": "57094", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6860", "name": "Witry", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LÉGLISE", "commune_niscode": "84033", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4690", "name": "Wonck", "province": "Liège", "province_niscode": "60000", "commune_principale": "BASSENGE", "commune_niscode": "62011", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6953", "name": "Ambly", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "NASSOGNE", "commune_niscode": "83040", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1390", "name": "Archennes", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "GREZ-DOICEAU", "commune_niscode": "25037", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6880", "name": "Auby-Sur-Semois", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "BERTRIX", "commune_niscode": "84009", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4630", "name": "Ayeneux", "province": "Liège", "province_niscode": "60000", "commune_principale": "SOUMAGNE", "commune_niscode": "62099", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9310", "name": "Baardegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "AALST", "commune_niscode": "41002", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9420", "name": "Bambrugge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ERPE-MERE", "commune_niscode": "41082", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6594", "name": "Beauwelz", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MOMIGNIES", "commune_niscode": "56051", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5000", "name": "Beez", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4360", "name": "Bergilers", "province": "Liège", "province_niscode": "60000", "commune_principale": "OREYE", "commune_niscode": "64056", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1301", "name": "Bierges", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "WAVRE", "commune_niscode": "25112", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6200", "name": "Bouffioulx", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHÂTELET", "commune_niscode": "52012", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5500", "name": "Bouvignes-Sur-Meuse", "province": "Namur", "province_niscode": "90000", "commune_principale": "DINANT", "commune_niscode": "91034", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7940", "name": "Cambron-Casteau", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "BRUGELETTE", "commune_niscode": "51012", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5590", "name": "Chevetogne", "province": "Namur", "province_niscode": "90000", "commune_principale": "CINEY", "commune_niscode": "91030", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Cras-Avernas", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5100", "name": "Dave", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7730", "name": "Estaimbourg", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTAIMPUIS", "commune_niscode": "57027", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8460", "name": "Ettelgem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "OUDENBURG", "commune_niscode": "35014", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4400", "name": "Flémalle-Grande", "province": "Liège", "province_niscode": "60000", "commune_principale": "FLÉMALLE", "commune_niscode": "62120", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5334", "name": "Florée", "province": "Namur", "province_niscode": "90000", "commune_principale": "ASSESSE", "commune_niscode": "92006", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7503", "name": "Froyennes", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4260", "name": "Fumal", "province": "Liège", "province_niscode": "60000", "commune_principale": "BRAIVES", "commune_niscode": "64015", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7823", "name": "Gibecq", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ATH", "commune_niscode": "51004", "arrondissement_niscode": "51000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4300", "name": "Grand-Axhe", "province": "Liège", "province_niscode": "60000", "commune_principale": "WAREMME", "commune_niscode": "64074", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3670", "name": "Gruitrode", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "OUDSBERGEN", "commune_niscode": "72042", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6704", "name": "Guirsch", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "ARLON", "commune_niscode": "81001", "arrondissement_niscode": "81000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5580", "name": "Han-Sur-Lesse", "province": "Namur", "province_niscode": "90000", "commune_principale": "ROCHEFORT", "commune_niscode": "91114", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8610", "name": "Handzame", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KORTEMARK", "commune_niscode": "32011", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4920", "name": "Harzé", "province": "Liège", "province_niscode": "60000", "commune_principale": "AYWAILLE", "commune_niscode": "62009", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1540", "name": "Herfelingen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HERNE", "commune_niscode": "23032", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4728", "name": "Hergenrath", "province": "Liège", "province_niscode": "60000", "commune_principale": "LA CALAMINE", "commune_niscode": "63040", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4682", "name": "Houtain-Saint-Siméon", "province": "Liège", "province_niscode": "60000", "commune_principale": "OUPEYE", "commune_niscode": "62079", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9270", "name": "Kalken", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LAARNE", "commune_niscode": "42010", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4450", "name": "Lantin", "province": "Liège", "province_niscode": "60000", "commune_principale": "JUPRELLE", "commune_niscode": "62060", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4261", "name": "Latinne", "province": "Liège", "province_niscode": "60000", "commune_principale": "BRAIVES", "commune_niscode": "64015", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4217", "name": "Lavoir", "province": "Liège", "province_niscode": "60000", "commune_principale": "HÉRON", "commune_niscode": "61028", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7730", "name": "Leers-Nord", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTAIMPUIS", "commune_niscode": "57027", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8432", "name": "Leffinge", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MIDDELKERKE", "commune_niscode": "35011", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1840", "name": "Malderen", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LONDERZEEL", "commune_niscode": "23045", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3870", "name": "Mechelen-Bovelingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEERS", "commune_niscode": "73022", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9420", "name": "Mere", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "ERPE-MERE", "commune_niscode": "41082", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4630", "name": "Micheroux", "province": "Liège", "province_niscode": "60000", "commune_principale": "SOUMAGNE", "commune_niscode": "62099", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6870", "name": "Mirwart", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "SAINT-HUBERT", "commune_niscode": "84059", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3740", "name": "Mopertingen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BILZEN", "commune_niscode": "73006", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7140", "name": "Morlanwelz-Mariemont", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MORLANWELZ", "commune_niscode": "58004", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4670", "name": "Mortier", "province": "Liège", "province_niscode": "60000", "commune_principale": "BLÉGNY", "commune_niscode": "62119", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3670", "name": "Neerglabbeek", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "OUDSBERGEN", "commune_niscode": "72042", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7080", "name": "Noirchain", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "FRAMERIES", "commune_niscode": "53028", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7022", "name": "Nouvelles", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6850", "name": "Offagne", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "PALISEUL", "commune_niscode": "84050", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5190", "name": "Onoz", "province": "Namur", "province_niscode": "90000", "commune_principale": "JEMEPPE-SUR-SAMBRE", "commune_niscode": "92140", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2861", "name": "Onze-Lieve-Vrouw-Waver", "province": "Anvers", "province_niscode": "10000", "commune_principale": "SINT-KATELIJNE-WAVER", "commune_niscode": "12035", "arrondissement_niscode": "12000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3660", "name": "Opglabbeek", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "OUDSBERGEN", "commune_niscode": "72042", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9661", "name": "Parike", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BRAKEL", "commune_niscode": "45059", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1800", "name": "Peutie", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "VILVOORDE", "commune_niscode": "23088", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5380", "name": "Pontillas", "province": "Namur", "province_niscode": "90000", "commune_principale": "FERNELMONT", "commune_niscode": "92138", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6890", "name": "Redu", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "LIBIN", "commune_niscode": "84035", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4950", "name": "Robertville", "province": "Liège", "province_niscode": "60000", "commune_principale": "WAIMES", "commune_niscode": "63080", "arrondissement_niscode": "63000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7387", "name": "Roisin", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HONNELLES", "commune_niscode": "53083", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1331", "name": "Rosières", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "RIXENSART", "commune_niscode": "25091", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1450", "name": "Saint-Géry", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "CHASTRE", "commune_niscode": "25117", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8340", "name": "Sijsele", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DAMME", "commune_niscode": "31006", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8620", "name": "Sint-Joris", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "NIEUWPOORT", "commune_niscode": "38016", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7332", "name": "Sirault", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SAINT-GHISLAIN", "commune_niscode": "53070", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5060", "name": "Tamines", "province": "Namur", "province_niscode": "90000", "commune_principale": "SAMBREVILLE", "commune_niscode": "92137", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7333", "name": "Tertre", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SAINT-GHISLAIN", "commune_niscode": "53070", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3272", "name": "Testelt", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "SCHERPENHEUVEL-ZICHEM", "commune_niscode": "24134", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5300", "name": "Thon", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANDENNE", "commune_niscode": "92003", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5502", "name": "Thynes", "province": "Namur", "province_niscode": "90000", "commune_principale": "DINANT", "commune_niscode": "91034", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5680", "name": "Vaucelles", "province": "Namur", "province_niscode": "90000", "commune_principale": "DOISCHE", "commune_niscode": "93018", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6870", "name": "Vesqueville", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "SAINT-HUBERT", "commune_niscode": "84059", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4317", "name": "Viemme", "province": "Liège", "province_niscode": "60000", "commune_principale": "FAIMES", "commune_niscode": "64076", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8600", "name": "Vladslo", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "DIKSMUIDE", "commune_niscode": "32003", "arrondissement_niscode": "32000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5537", "name": "Warnant", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANHÉE", "commune_niscode": "91005", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9230", "name": "Westrem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "WETTEREN", "commune_niscode": "42025", "arrondissement_niscode": "42000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8640", "name": "Woesten", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "VLETEREN", "commune_niscode": "33041", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9660", "name": "Zegelsem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "BRAKEL", "commune_niscode": "45059", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9500", "name": "Moerbeke", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "GERAARDSBERGEN", "commune_niscode": "41018", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1400", "name": "Monstreux", "province": "Brabant Wallon", "province_niscode": "20002", "commune_principale": "NIVELLES", "commune_niscode": "25072", "arrondissement_niscode": "25000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5530", "name": "Mont", "province": "Namur", "province_niscode": "90000", "commune_principale": "YVOIR", "commune_niscode": "91141", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7387", "name": "Montignies-Sur-Roc", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "HONNELLES", "commune_niscode": "53083", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9860", "name": "Moortsele", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OOSTERZELE", "commune_niscode": "44052", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Nederename", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5377", "name": "Nettinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMME-LEUZE", "commune_niscode": "91120", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3890", "name": "Niel-Bij-Sint-Truiden", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "GINGELOM", "commune_niscode": "71017", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "2491", "name": "Olmen", "province": "Anvers", "province_niscode": "10000", "commune_principale": "BALEN", "commune_niscode": "13003", "arrondissement_niscode": "13000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8670", "name": "Oostduinkerke", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "KOKSIJDE", "commune_niscode": "38014", "arrondissement_niscode": "38000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8980", "name": "Passendale", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "ZONNEBEKE", "commune_niscode": "33037", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7160", "name": "Piéton", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHAPELLE-LEZ-HERLAIMONT", "commune_niscode": "52010", "arrondissement_niscode": "52000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7782", "name": "Ploegsteert", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COMINES-WARNETON", "commune_niscode": "57097", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3840", "name": "Rijkel", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "BORGLOON", "commune_niscode": "73009", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5620", "name": "Rosée", "province": "Namur", "province_niscode": "90000", "commune_principale": "FLORENNES", "commune_niscode": "93022", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7034", "name": "Saint-Denis", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5003", "name": "Saint-Marc", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6460", "name": "Salles", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5364", "name": "Schaltin", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAMOIS", "commune_niscode": "91059", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "1932", "name": "Sint-Stevens-Woluwe", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZAVENTEM", "commune_niscode": "23094", "arrondissement_niscode": "23000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5651", "name": "Somzée", "province": "Namur", "province_niscode": "90000", "commune_principale": "WALCOURT", "commune_niscode": "93088", "arrondissement_niscode": "93000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7061", "name": "Thieusies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SOIGNIES", "commune_niscode": "55040", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5140", "name": "Tongrinne", "province": "Namur", "province_niscode": "90000", "commune_principale": "SOMBREFFE", "commune_niscode": "92114", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4280", "name": "Trognée", "province": "Liège", "province_niscode": "60000", "commune_principale": "HANNUT", "commune_niscode": "64034", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4347", "name": "Voroux-Goreux", "province": "Liège", "province_niscode": "60000", "commune_principale": "FEXHE-LE-HAUT-CLOCHER", "commune_niscode": "64025", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3770", "name": "Vroenhoven", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "RIEMST", "commune_niscode": "73066", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3401", "name": "Waasmont", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5580", "name": "Wavreille", "province": "Namur", "province_niscode": "90000", "commune_principale": "ROCHEFORT", "commune_niscode": "91114", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3020", "name": "Winksele", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "HERENT", "commune_niscode": "24038", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9800", "name": "Wontergem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4190", "name": "Xhoris", "province": "Liège", "province_niscode": "60000", "commune_principale": "FERRIÈRES", "commune_niscode": "61019", "arrondissement_niscode": "61000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8380", "name": "Zeebrugge", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "BRUGGE", "commune_niscode": "31005", "arrondissement_niscode": "31000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3770", "name": "Zichen-Zussen-Bolder", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "RIEMST", "commune_niscode": "73066", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3700", "name": "'S Herenelderen", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "TONGEREN", "commune_niscode": "73083", "arrondissement_niscode": "73000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4432", "name": "Alleur", "province": "Liège", "province_niscode": "60000", "commune_principale": "ANS", "commune_niscode": "62003", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5001", "name": "Belgrade", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8920", "name": "Bikschote", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "LANGEMARK-POELKAPELLE", "commune_niscode": "33040", "arrondissement_niscode": "33000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7783", "name": "Bizet", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "COMINES-WARNETON", "commune_niscode": "57097", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6921", "name": "Chanly", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "WELLIN", "commune_niscode": "84075", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7521", "name": "Chercq", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "TOURNAI", "commune_niscode": "57081", "arrondissement_niscode": "57000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4400", "name": "Chokier", "province": "Liège", "province_niscode": "60000", "commune_principale": "FLÉMALLE", "commune_niscode": "62120", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9570", "name": "Deftinge", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "LIERDE", "commune_niscode": "45063", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7050", "name": "Erbisoeul", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "JURBISE", "commune_niscode": "53044", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3400", "name": "Ezemaal", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "LANDEN", "commune_niscode": "24059", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7120", "name": "Fauroeulx", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "ESTINNES", "commune_niscode": "58003", "arrondissement_niscode": "58000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5370", "name": "Flostoy", "province": "Namur", "province_niscode": "90000", "commune_principale": "HAVELANGE", "commune_niscode": "91064", "arrondissement_niscode": "91000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6853", "name": "Framont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "PALISEUL", "commune_niscode": "84050", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3200", "name": "Gelrode", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "AARSCHOT", "commune_niscode": "24001", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9800", "name": "Gottem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "DEINZE", "commune_niscode": "44083", "arrondissement_niscode": "44000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3440", "name": "Halle-Booienhoven", "province": "Brabant Flamand", "province_niscode": "20001", "commune_principale": "ZOUTLEEUW", "commune_niscode": "24130", "arrondissement_niscode": "24000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6900", "name": "Hargimont", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "MARCHE-EN-FAMENNE", "commune_niscode": "83034", "arrondissement_niscode": "83000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7022", "name": "Harmignies", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "MONS", "commune_niscode": "53053", "arrondissement_niscode": "53000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6870", "name": "Hatrival", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "SAINT-HUBERT", "commune_niscode": "84059", "arrondissement_niscode": "84000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3940", "name": "Hechtel", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HECHTEL-EKSEL", "commune_niscode": "72038", "arrondissement_niscode": "72000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4680", "name": "Hermée", "province": "Liège", "province_niscode": "60000", "commune_principale": "OUPEYE", "commune_niscode": "62079", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "3550", "name": "Heusden", "province": "Limbourg", "province_niscode": "70000", "commune_principale": "HEUSDEN-ZOLDER", "commune_niscode": "71070", "arrondissement_niscode": "71000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4342", "name": "Hognoul", "province": "Liège", "province_niscode": "60000", "commune_principale": "AWANS", "commune_niscode": "62006", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "7060", "name": "Horrues", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "SOIGNIES", "commune_niscode": "55040", "arrondissement_niscode": "55000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4357", "name": "Jeneffe", "province": "Liège", "province_niscode": "60000", "commune_principale": "DONCEEL", "commune_niscode": "64023", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8870", "name": "Kachtem", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "IZEGEM", "commune_niscode": "36008", "arrondissement_niscode": "36000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4367", "name": "Kemexhe", "province": "Liège", "province_niscode": "60000", "commune_principale": "CRISNÉE", "commune_niscode": "64021", "arrondissement_niscode": "64000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9451", "name": "Kerksken", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "HAALTERT", "commune_niscode": "41024", "arrondissement_niscode": "41000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "4600", "name": "Lanaye", "province": "Liège", "province_niscode": "60000", "commune_principale": "VISÉ", "commune_niscode": "62108", "arrondissement_niscode": "62000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5300", "name": "Landenne", "province": "Namur", "province_niscode": "90000", "commune_principale": "ANDENNE", "commune_niscode": "92003", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6761", "name": "Latour", "province": "Luxembourg", "province_niscode": "80000", "commune_principale": "VIRTON", "commune_niscode": "85045", "arrondissement_niscode": "85000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "9700", "name": "Leupegem", "province": "Flandre-Orientale", "province_niscode": "40000", "commune_principale": "OUDENAARDE", "commune_niscode": "45035", "arrondissement_niscode": "45000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Leuze", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5310", "name": "Liernu", "province": "Namur", "province_niscode": "90000", "commune_principale": "EGHEZÉE", "commune_niscode": "92035", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "6463", "name": "Lompret", "province": "Hainaut", "province_niscode": "50000", "commune_principale": "CHIMAY", "commune_niscode": "56016", "arrondissement_niscode": "56000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "5020", "name": "Malonne", "province": "Namur", "province_niscode": "90000", "commune_principale": "NAMUR", "commune_niscode": "92094", "arrondissement_niscode": "92000", "sous_commune": "Oui", },
  { "type": "L", "code_postal": "8433", "name": "Mannekensvere", "province": "Flandre-Occidentale", "province_niscode": "30000", "commune_principale": "MIDDELKERKE", "commune_niscode": "35011", "arrondissement_niscode": "35000", "sous_commune": "Oui", },
];
export default Localities;