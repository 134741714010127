import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from '..';
import { Main as MainLayout, Minimal as MinimalLayout } from '../../layouts';

import {
  Account as AccountView,
  Properties as PropertiesView,
  ContactedProperties as ContactedPropertiesView,
  Company as CompanyView,
  Admin as AdminView,
  Calendar as CalendarView,
  // ProductList as ProductListView,
  // UserList as UserListView,
  // Typography as TypographyView,
  // Icons as IconsView,
  // Settings as SettingsView,
  Home as HomeView,
  Dashboard as DashboardView,
  RequestPassword as RequestPasswordView,
  ResetPassword as ResetPasswordView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  SuspendedAccount as SuspendedAccountView,
  UserRdvSumarries as UserRdvSumarriesView,
  Contact as ContactView,
  SignedMandat as SignedMandatView
} from '../../views';

import {
  AdminPrivateRoute,
  PrivateRoute,
  RightProtectedPrivateRoute
} from 'components';

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MinimalLayout}
        path="/"
      />
      <PrivateRoute
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <PrivateRoute
        component={PropertiesView}
        exact
        layout={MainLayout}
        path="/properties"
      />
      <PrivateRoute
        component={ContactedPropertiesView}
        exact
        layout={MainLayout}
        path="/contacted-properties"
      />
      <PrivateRoute
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <PrivateRoute
        component={CalendarView}
        exact
        layout={MainLayout}
        path="/calendar"
      />
      <RightProtectedPrivateRoute
        component={CompanyView}
        hasRight={userInfo => userInfo.isAdminOfCompany}
        exact
        layout={MainLayout}
        path="/my-company"
      />
      <PrivateRoute
        component={UserRdvSumarriesView}
        exact
        layout={MainLayout}
        path="/waiting-usr-rdv-summary"
      />
      <PrivateRoute
        component={SignedMandatView}
        exact
        layout={MainLayout}
        path="/signed"
      />
      <AdminPrivateRoute
        component={AdminView}
        exact
        layout={MainLayout}
        path="/admin"
      />
      <PrivateRoute
        component={ContactView}
        exact
        layout={MainLayout}
        path="/contact"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={RequestPasswordView}
        exact
        layout={MinimalLayout}
        path="/request-reset-password"
      />
      <RouteWithLayout
        component={ResetPasswordView}
        exact
        layout={MinimalLayout}
        path="/request-reset-password/:token"
      />
      <RouteWithLayout
        component={SuspendedAccountView}
        exact
        layout={MinimalLayout}
        path="/suspended-account"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
