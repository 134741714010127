import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, Container, Grid, Typography } from '@material-ui/core';

import Fade from 'react-reveal/Fade';

const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    height: '100vh'
  },
  imageOverlay: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: 1
  },
  computerImage: {
    right: '0',
    [theme.breakpoints.up('md')]: {
      right: '8%'
    },
    width: 'auto',
    height: '48vh',
    top: '0px',
    bottom: '0px',
    margin: 'auto',
    position: 'absolute',
    filter: 'drop-shadow(rgba(0, 0, 0, 0.48) 40px 80px 80px)',
    maxWidth: '100%'
  },
  textHero: {
    margin: 'unset',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10,
    maxWidth: '520px',
    textAlign: 'left',
    position: 'relative',
    paddingTop: '13%'
  },
  h1Hero: {
    fontSize: '4rem',
    margin: '0px',
    fontWeight: 700,
    lineHeight: 1.25,
    color: theme.palette.white
  },
  text: {
    margin: '0px',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    color: 'rgb(255, 255, 255)'
  },
  spanOrange: {
    color: theme.palette.primary.main
  },
  btnGo: {
    width: '40%'
  },
  ...theme.common
}));

const SectionHomeScreen = ({ history }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.section}>
      <img
        className={classes.imageOverlay}
        alt="overlay"
        src="/images/overlay.svg"
      />
      <Fade left>
        <img
          className={classes.computerImage}
          alt="overlay"
          src="/images/screen.png"
        />
      </Fade>
      <Container>
        <div className={classes.textHero}>
          <Typography className={classes.h1Hero} variant="h1">
            Home
            <br />
            <span className={classes.spanOrange}>Tracker</span>
          </Typography>
          <br />
          <br />
          <Typography className={classes.text} variant="body1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eget
            nunc ultricies, ullamcorper sapien nec, fringilla ante. Donec
            aliquam sagittis ultricies. Aenean condimentum congue congue.
          </Typography>
          <br />
          <br />
          <br />
          <Button
            className={classes.btnGo}
            color="primary"
            variant="contained"
            onClick={() => history.push('/dashboard')}
          >
            Les biens !
          </Button>
        </div>
      </Container>
    </Grid>
  );
};

export default withRouter(SectionHomeScreen);
