import React from 'react';
import Button from '@material-ui/core/Button';
import { ConnectPopUpContext } from '../../../../contexts/PopUpContext';
import { TranslationContext } from 'contexts/TranslationContext';

const ActionButton = props => {
  const { t } = React.useContext(TranslationContext);

  const { url, text, propertyId, closable, popUpContext } = props;
  const { openPopup, setPropertyId } = popUpContext;

  const handleClickOpen = () => {
    if (url) {
      window.open(url, '_blank');
    }

    // Highlight the clicked property
    document.getElementById(
      'properties-table-tr-' + propertyId
    ).style.backgroundColor = 'lightblue';

    setPropertyId(propertyId);
    openPopup(closable);
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleClickOpen}
      onContextMenu={handleClickOpen}
    >
      {text ?? t('article-card-see')}
    </Button>
  );
};

export default ConnectPopUpContext(ActionButton);
