import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { ProspectionPopUp, /* PropertyListAsTable, PropertyListAsCard */} from './components';
import { ConnectLoaderContext } from 'contexts/LoaderContext';
import CustomizedSnackbars from 'components/CustomizedSnackbars';
import { ConnectAuthContext } from '../../../../contexts/AuthContext';
import { getProperties } from 'myApi';

import GroupFilter from '../Filters/GroupFilter';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { PropertyCard } from './components/PropertyList/components';
import { TranslationContext } from 'contexts/TranslationContext';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';

const initFilterState = {
  source: null,
  service: null,
  date: null,
  category: [],
  minPrice: null,
  maxPrice: null,
  peb: null,
  zipCodes: [],
  stateIds: []
};

const useStyles = makeStyles(theme => ({
  noFoundImageContainer: {
    textAlign: "center",
  },
  noFoundImage: {
    maxWidth: "100%",
    maxHeight: "450px",
  },
  fullWidth: {
    width: "100%",
    margin: 0,
  },
  scrollableProperties: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const ReleaseTextToReload = () => {
  const { t } = React.useContext(TranslationContext);
  return (
    <Typography variant="subtitle1" align="center"><VerticalAlignTopIcon />{t("infinite-scroll-release")}</Typography>
  );
};

const PullDownTextToReload = () => {
  const { t } = React.useContext(TranslationContext);
  return (
    <Typography variant="subtitle1" align="center"><VerticalAlignBottomIcon />{t("infinite-scroll-pull-down")}</Typography>
  );
};

const LoadingMoreData = () => {
  const { t } = React.useContext(TranslationContext);
  return (
    <Typography variant="subtitle1" align="center"><CircularProgress />{t("properties-loading-data")}</Typography>
  );
};

export default ConnectLoaderContext(ConnectAuthContext((props) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [rowsPerPage] = useState(16);
  const [filter, setFilter] = useState(initFilterState);
  const [toast, setToast] = useState({
    open: false,
    message: null,
    type: null
  });

  useEffect(() => {
    props.loaderContext.hideLoader();
    return () => props.loaderContext.showLoader();
  }, []);

  const setInfosToast = (message,type) => {
    setToast({
      open: true,
      message: message,
      type: type
    });

    setTimeout(() => {
      setToast({
        open: false,
        message: "",
        type: ""
      });
    }, 3000);
  };

  const getData = async (pg, baseArray) => {
    try {
      const prop = await getProperties(
        props.alreadyHadAction,
        (pg ?? page) * rowsPerPage,
        rowsPerPage,
        filter.source,
        filter.service,
        filter.category,
        filter.zipCodes,
        filter.minPrice,
        filter.maxPrice,
        filter.stateIds,
        filter.peb,
        filter.date
      );

      const properties = prop.data.propertyGrid;
      if (baseArray) {
        setRows([...baseArray, ...properties.results]);
      } else {
        setRows([...rows, ...properties.results]);
      }
      setPage(page+1);
      setHasMore(properties.results.length === rowsPerPage);
    } catch (e) {
      if (e.response) {
        const error = e.response.status;
        let messageToToast = `Error  ${error}: `;
        let shouldLogOut = false;

        switch (error) {
          case 403:
            shouldLogOut = true;
            messageToToast += ` Action non autorisée, reconnectez-vous.`;
            break;
          case 500:
            messageToToast += ` Problème serveur, contactez un administrateur.`;
            break;
          default:
            messageToToast += ` Erreur inconnue`;
            break;
        }

        setInfosToast(messageToToast, 'error');

        if (shouldLogOut) {
          setTimeout(props.authContext.logOut, 3000);
        }
      } else {
        setInfosToast(e.toString(), 'error');
      }
    }
  };

  const refreshFunction = () => {
    setRows([]);
    setPage(0);
    getData(0, []);
  };

  useEffect(() => {
    refreshFunction();
  }, [filter, props.alreadyHadAction]);

  return (
    <div id="scrollable-properties-container">
        <GroupFilter filter={filter} setFilter={setFilter} displayIcons={props.alreadyHadAction} />
        {rows.length === 0 && !props.loaderContext.loading && (
          <div className={classes.noFoundImageContainer}>
            <img className={classes.noFoundImage} alt="no-data" src="/images/no-data.svg"/>
          </div>
        )}
          <div className={classes.content}>
            <InfiniteScroll
              className={classes.scrollableProperties}
              dataLength={rows.length}
              next={getData}
              hasMore={hasMore}
              loader={<LoadingMoreData />}
              pullDownToRefresh
              refreshFunction={refreshFunction}
              pullDownToRefreshThreshold={75}
              pullDownToRefreshContent={<PullDownTextToReload />}
              releaseToRefreshContent={<ReleaseTextToReload />}
            >
              <Grid
                className={classes.fullWidth}
                container
                spacing={3}
              >
                {rows.map(property => (
                  <Grid
                    item
                    key={property.id}
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <PropertyCard id={"properties-table-tr-" + property.id} property={property} />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </div>

        {/* <PropertyListAsCard rows={rows} rowsPerPage={rowsPerPage} columns={columns} page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} propertyCount={propertyCount} /> */}
      <ProspectionPopUp />
      <CustomizedSnackbars
        isOpen={toast.open}
        message={toast.message}
        type={toast.type}
      />
    </div>
  );
}));
