import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { PopUpContext } from 'contexts/PopUpContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { Badge, Button } from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import ActionsOfMembers from 'contexts/components/ActionsOfMembers';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  headerCell: {
    backgroundColor: theme.palette.white
  },
  tableRow: {
    backgroundColor: '#fefefe',
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa'
    }
  },
  alreadyInCompany: {
    backgroundColor: 'rgb(254, 243, 229) !important'
  },
  footerPaging: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.white
  }
}));

const PropertyListAsTable = ({
  rows,
  rowsPerPage,
  columns,
  page,
  setPage,
  setRowsPerPage,
  propertyCount
}) => {
  const classes = useStyles();
  const [actionOfOtherMembers, setActionOfOtherMembers] = React.useState(null);
  const { t } = React.useContext(TranslationContext);
  const { openPopup, setPropertyId } = React.useContext(PopUpContext);

  const handleClickOpen = (propertyId, url) => {
    if (url) {
      window.open(url, '_blank');
    }

    // Highlight the clicked property
    document.getElementById(
      'properties-table-tr-' + propertyId
    ).style.backgroundColor = 'lightblue';

    setPropertyId(propertyId);
    openPopup(false);
  };

  return (
    <React.Fragment>
      <TableContainer className={classes.root}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell className={classes.headerCell} key={index}>
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              const alreadyInAgency = row.actionsOfCompany?.some(
                x => x.state === 2
              );
              return (
                <TableRow
                  title={
                    alreadyInAgency
                      ? t('property-card-marked-as-already-in-agency')
                      : ''
                  }
                  className={{
                    [classes.tableRow]: true,
                    [classes.alreadyInCompany]: alreadyInAgency
                  }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  id={'properties-table-tr-' + row.id}
                  onClick={() => handleClickOpen(row.id, row.url)}
                >
                  {columns.map((column, index) => (
                    <TableCell key={index}>
                      {column.format
                        ? column.format(row[column.key], row)
                        : row[column.key]}
                      {column.name === 'Actions' && (
                        <Button
                          aria-label="reduce"
                          onClick={() => {
                            setActionOfOtherMembers(row.actionsOfCompany);
                          }}
                          disabled={row.actionsOfCompany?.length === 0}
                        >
                          <Badge
                            color="error"
                            badgeContent={row.actionsOfCompany?.length}
                          >
                            <MoreVertIcon />
                          </Badge>
                        </Button>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.footerPaging}
        rowsPerPageOptions={[6, 13, 25]}
        component="div"
        labelRowsPerPage="Nombre d'élément: "
        count={propertyCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_, newPage) => setPage(newPage)}
        onChangeRowsPerPage={event => {
          setRowsPerPage(+event.target.value);
          setPage(0);
        }}
      />
      <ActionsOfMembers
        actionOfOtherMembers={actionOfOtherMembers}
        setActionOfOtherMembers={setActionOfOtherMembers}
      />
    </React.Fragment>
  );
};

export default PropertyListAsTable;
