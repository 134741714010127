import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Divider
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ActionButton from '../../../../PopUp/ActionButton';
import PictureToZoom from 'components/Pictures/PictureToZoom';

import { TranslationContext } from 'contexts/TranslationContext';
import { getServiceText, getCategoryText } from 'common/common';
import PropertyCardHeader from './PropertyCardHeader';

// -------------------------------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {},
  cardTitle: {
    maxWidth: '60%'
  },
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  line: {
    textDecoration: 'line-through'
  },
  avatar: {
    width: 80,
    height: 80
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  }
}));

// -------------------------------------------------------------------------------------------------------

const PropertyCard = ({ property, className, ...rest }) => {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);

  let date = null;
  if (property.userStatus) {
    date = moment(property.userStatus.dateModified + 'Z').format('LLL');
  } else {
    date = new Date(property.date).toLocaleDateString();
  }
  const category = t(getCategoryText(property.propertyType));
  const service = t(getServiceText(property.serviceType));

  console.log('PropertyCard', property);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <PropertyCardHeader property={property} />
      <CardContent>
        <Grid container justify="space-between">
          <Grid className={classes.cardTitle} item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              {property.title}
            </Typography>
            <Typography variant="h3">{property.priceAsText}</Typography>
          </Grid>
          <Grid item>
            <div className="hover .zoomin">
              <PictureToZoom
                className={classes.avatar}
                src={property.pictureUrl}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              variant="h6"
              className={property.hasPEB ? '' : classes.line}
            >
              &nbsp;PEB&nbsp;
            </Typography>
            <Typography variant="h6">
              {t('common-category')}: {category}
            </Typography>
            <Typography variant="h6">
              {t('common-service')}: {service}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="right" variant="body1">
              {property.zipCode}
            </Typography>
            <Typography align="right" variant="body1">
              {property.city}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container justify="space-between">
          <Grid className={classes.statsItem} item>
            <AccessTimeIcon className={classes.statsIcon} />
            <Typography display="inline" variant="body2">
              {date}
            </Typography>
          </Grid>
          <Grid className={classes.statsItem} item>
            <ActionButton
              key={property.id}
              url={property.userStatus ? '' : property.url}
              propertyId={property.id}
              text={property.userStatus ? t('article-card-maj') : null}
              closable={property.userStatus}
            />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default PropertyCard;
