import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';import {
  TableCell,
  TableRow,
} from '@material-ui/core';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const SimpleRow = props => {
  const { classes, columns, row } = props;

  return (
    <StyledTableRow
      className={classes.tableRow}
      hover
      key={row.id}
    >
      {columns.map((column, index) => (
        <TableCell key={index}>
          {column.format
          ? column.format(row[column.key], row)
          : row[column.key]}
        </TableCell>
      ))}
    </StyledTableRow>
  );
};

SimpleRow.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired
};

export default SimpleRow;