import React from "react";
import { TableLoader } from "views/Properties/components/PropertiesTable/components";
// import { getCookie, setCookie, deleteCookie } from "variables/cookie";

export const LoaderContext = React.createContext({});

const Consumer = LoaderContext.Consumer;

const Provider = LoaderContext.Provider;

export function ConnectLoaderContext(Component) {
  return function WrapperComponent(props) {
    return (
      <Consumer>
        {state => <Component {...props} loaderContext={state} />}
      </Consumer>
    );
  };
}

// Dont forget to add this at the root of your project around your <App />
export default class ProvideLoaderContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      displayLoader: true,
      hideLoader: () => this.setState({ displayLoader: false }),
      showLoader: () => this.setState({ displayLoader: true }),
      toggleLoader: this.toggleLoader.bind(this)
    };
  }

  toggleLoader = async (val) => this.setState({ loading: val ?? !this.state.loading });

  render() {
    return (
      <React.Fragment>
        {this.state.displayLoader && this.state.loading && (
          <TableLoader />
        )}
        <Provider value={this.state}>{this.props.children}</Provider>
      </React.Fragment>
    );
  }
}
