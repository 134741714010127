import React from 'react';
import axios from 'axios';
import { getCookie, setCookie } from '../common/cookie';
import { login, resetPassword, requestPassword, logout, getMyUserInfo, getMyUserImage, saveMyUserImage } from 'myApi';
import { CustomizedSnackBarContext } from './CustomizedSnackBarContext';

export const AuthContext = React.createContext({});

const Consumer = AuthContext.Consumer;

const Provider = AuthContext.Provider;

export function ConnectAuthContext(Component) {
  return function WrapperComponent(props) {
    return (
      <Consumer>
        {state => <Component {...props} authContext={state} />}
      </Consumer>
    );
  };
}

// Dont forget to add this at the root of your project around your <App />
export default class ProvideAuthContext extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      tokenUser: null,
      myUserInfo: null,
      myUserImage: localStorage.getItem("HomeTracker-MyUserImage") ?? '/images/avatars/avatar_12.png',
      saveMyNewUserImage: (imageBase64) => {
        localStorage.setItem("HomeTracker-MyUserImage", imageBase64);
        saveMyUserImage(imageBase64);
        this.setState({ myUserImage: imageBase64 });
      },
      setTokenUser: async (token, boolAlreadyInCookies) => {
        axios.defaults.headers.common['Authorization'] = token;
        var isConnected = await this.getAndUpdateUserInfo();
        if (isConnected) {
          this.setState({ tokenUser: token });
          if (!boolAlreadyInCookies) {
            setCookie('tokenAuth', token, 7);
          }
        } else {
          setCookie('tokenAuth', null, -10);
          delete axios.defaults.headers.common['Authorization'];
        }
      },
      login: async (email, password) => {
        this.setState({ 
          myUserInfo: null,
          initialized: false
        });
        const response = await login(email, password);
        const token = response.data;
        this.state.setTokenUser(token, false);
      },
      resetPassword: async (token, newPassword) => resetPassword(token, newPassword),
      requestPassword: async email => requestPassword(email),
      logOut: async () => {
        this.setState({ tokenUser: null });
        setCookie('tokenAuth', null, -10);
        delete axios.defaults.headers.common['Authorization'];
        
        await logout(this.state.tokenUser);
      }
    };
  }

  /**
   * Retrieves information about the user connected and store them in the state
   */
  async getAndUpdateUserInfo() {
    try {
      const response = await getMyUserInfo();
      
      this.setState({ 
        myUserInfo: response.data.myUserInfo,
        initialized: true
      });
  
      // Even though the image is inside the user object, we retrieve it with another request
      // To make the get user request faster and unlock the waiting for the application
      getMyUserImage()
      .then(response2 => {
        const img = response2.data.getMyImage.image ?? '/images/avatars/avatar_12.png';
        localStorage.setItem("HomeTracker-MyUserImage", img);
        this.setState({ myUserImage: img });
      });

      return true;
    } catch (err) {
      this.setState({ 
        myUserInfo: null,
        initialized: true
      });
      return false;
    }
  }

  componentDidMount() {
    // ToDo(ibe): save in the backend the login (for history)
    const token = getCookie('tokenAuth');
    if (token) {
      this.state.setTokenUser(token, true);
    } else {
      this.setState({ initialized: true });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Provider value={this.state}>{this.props.children}</Provider>
      </React.Fragment>
    );
  }
}

ProvideAuthContext.contextType = CustomizedSnackBarContext;