import React from 'react';
import PictureToZoom from 'components/Pictures/PictureToZoom';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ActionButton from '../PopUp/ActionButton';

export default [
  {
    key: 'pictureUrl',
    format: (data, row) => {
      return (
        <div key={row.id} id={row.id}>
          <div key={row.id} className="hover .zoomin">
            <PictureToZoom src={data} />
          </div>
        </div>
      );
    }
  },
  {
    name: 'Title',
    key: 'title'
  },
  {
    name: 'Code Postal',
    key: 'zipCode'
  },
  {
    name: 'Ville',
    key: 'city'
  },
  {
    name: 'Date',
    key: 'date',
    format: (data, row) => {
      return new Date(data).toLocaleDateString();
    }
  },
  {
    name: 'Prix',
    key: 'priceAsText'
  },
  {
    name: 'Peb',
    key: 'hasPEB',
    format: (data, row) => {
      switch (data) {
        case true:
          return <CheckCircleOutlineOutlinedIcon htmlColor="green" />;
        case false:
          return <RemoveCircleIcon color="error" />;
        default:
          return 'N/A';
      }
    }
  },
  {
    name: 'Catégorie',
    key: 'propertyType',
    format: (data, row) => {
      switch (data) {
        case 1:
          return 'Maison';
        case 2:
          return 'Appartement';
        case 3:
          return 'Bureau';
        case 4:
          return 'Commerce';
        case 5:
          return 'Garage';
        case 6:
          return 'Immeuble de rapport';
        case 7:
          return 'Industrie';
        case 8:
          return 'Projet neuf';
        case 9:
          return 'Terrain';
        case 10:
        case 11:
        case 12:
        case 13:
          return 'Autre';

        default:
          return 'N/A';
      }
    }
  },
  {
    name: 'Service',
    key: 'serviceType',
    format: (data, row) => {
      switch (data) {
        case 1:
          return 'Vente';
        case 2:
          return 'Location';

        default:
          return 'N/A';
      }
    }
  },
  {
    name: 'Actions',
    key: 'url',
    format: function(data, row) {
      return <ActionButton key={row.id} url={data} propertyId={row.id} />;
    }
  }
];
