import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Grid
} from '@material-ui/core';
import { TranslationContext } from 'contexts/TranslationContext';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const colorList = [
  "#e91e63",
  "#9c27b0",
  "#f44336",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#9e9e9e",
  "#607d8b",
];

const CircleGraph = ({ className, title, data, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { t } = React.useContext(TranslationContext);

  const hPercentValue = data.reduce(function(prev, cur) {return prev + cur.value;}, 0);

  const doughnutData = {
    datasets: [
      {
        data: data.map(elem => (elem.value)),
        backgroundColor: colorList,
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: data.map(elem => (elem.label))
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  let dataExplanation = [];
  if (hPercentValue !== 0) {
    let totalCount = 0;
    for (let i = 0; i < data.length; i++) {
      const elem = data[i];
      if (i === data.length - 1) {
        dataExplanation.push({
          id: data.id,
          title: elem.label,
          value: 100 - totalCount,
          color: colorList[i]
        });
      } else {
        const perctage = Math.round(elem.value * 100 / hPercentValue);
        totalCount += perctage;
        dataExplanation.push({
          id: data.id,
          title: elem.label,
          value: perctage,
          color: colorList[i]
        });
      }
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={
        //   <IconButton size="small">
        //     <RefreshIcon />
        //   </IconButton>
        // }
        title={title}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {
            hPercentValue !== 0 
            ? <Doughnut
                data={doughnutData}
                options={options}
              />
            : t("company-chart-not-enough-data")
          }
          
        </div>
        <div className={classes.stats}>
          <Grid
            container
            justify={"space-around"}
            spacing={2}>
            {dataExplanation.map(device => (
              <Grid
                className={classes.device}
                key={device.id}
              >
                <span className={classes.deviceIcon}>{device.icon}</span>
                <Typography variant="body1">{device.title}</Typography>
                <Typography
                  style={{ color: device.color }}
                  variant="h2"
                >
                  {device.value}%
                </Typography>
              </Grid>
            ))}
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

CircleGraph.propTypes = {
  className: PropTypes.string
};

export default CircleGraph;
