import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { TranslationContext } from 'contexts/TranslationContext';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIconSame: {
    color: theme.palette.warning.dark
  },
  differenceValueSame: {
    color: theme.palette.warning.dark,
    marginRight: theme.spacing(1)
  },
  differenceIconPos: {
    color: theme.palette.success.dark
  },
  differenceValuePos: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  differenceIconNeg: {
    color: theme.palette.error.dark
  },
  differenceValueNeg: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const InfoCard = props => {

  const { t } = React.useContext(TranslationContext);

  const { className, title, value, Icon, iconBGColor, percentage, ...rest } = props;

  const classes = useStyles();

  const differenceIcon = percentage === 0 ? classes.differenceIconSame : percentage > 0 ? classes.differenceIconPos : classes.differenceIconNeg;
  const differenceValue = percentage === 0 ? classes.differenceValueSame : percentage > 0 ? classes.differenceValuePos : classes.differenceValueNeg;

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {title}
            </Typography>
            <Typography variant="h3">{value ?? 0}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar} style={{ backgroundColor: iconBGColor }}>
              <Icon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        {
          percentage !== undefined && (
            <div className={classes.difference}>
              {
                percentage === 0
                ? <ArrowForwardIcon />
                : percentage > 0 
                ? <ArrowUpwardIcon className={differenceIcon} />
                : <ArrowDownwardIcon className={differenceIcon} />
              }
              {
                percentage === 0
                ? (
                  <Typography
                    className={classes.caption}
                    variant="caption"
                  >
                    {t("stat-same-previous-week")}
                  </Typography>
                )
                : (
                  <React.Fragment>
                    <Typography
                      className={differenceValue}
                      variant="body2"
                    >
                      {Math.abs(percentage)}%
                    </Typography>
                    <Typography
                      className={classes.caption}
                      variant="caption"
                    >
                      {t("stat-since-last-week")}
                    </Typography>
                  </React.Fragment>
                )
              }
            </div>
          )
        }
      </CardContent>
    </Card>
  );
};

InfoCard.propTypes = {
  className: PropTypes.string
};

export default InfoCard;
