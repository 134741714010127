import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import { getLogins } from 'myApi';

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    padding: '10px'
  },
  red: {
    backgroundColor: '#f4f6f8',
    color: '#f44336'
  },
  green: {
    backgroundColor: '#f4f6f8',
    color: '#4caf50'
  },
  blue: {
    backgroundColor: '#f4f6f8',
    color: '#2196f3'
  },
  yellow: {
    backgroundColor: '#f4f6f8',
    color: '#ff9800'
  },
  ...theme.common
}));

const UsersLogins = () => {
  const classes = useStyles();

  const [skip, setSkip] = React.useState(0);
  const [hasMoreToLoad, setHasMoreToLoad] = React.useState(true);
  const [data, setData] = React.useState([]);

  const getMoreData = () => {
    if (hasMoreToLoad) {
      getLogins(skip).then(response => {
        const results = response.data.getLogins;

        setSkip(skip + 5);
        setData(data.concat(results));
        if (results.length < 5) {
          setHasMoreToLoad(false);
        }
      });
    }
  };

  useEffect(() => {
    getMoreData();
  }, []);

  const onClick = () => {
    getMoreData();
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader
          avatar={<Avatar className={classes.avatar}>C</Avatar>}
          title="Les derniers logins"
          subheader="Ci-dessous la liste des derniers login à l'application"
        />
        <CardContent>
          {data.map(element => (
            <React.Fragment key={element.id}>
              <Paper elevation={1} className={classes.paper}>
                <Grid container direction="row" justify="space-between">
                  <Grid>
                    <Typography variant="h6" component="h1">
                      {element.userName ?? 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid>{moment(element.dateCreated + 'Z').fromNow()}</Grid>
                </Grid>
              </Paper>
            </React.Fragment>
          ))}
        </CardContent>
        <CardActions disableSpacing>
          {hasMoreToLoad && (
            <Button color="primary" aria-label="save" onClick={onClick}>
              Voir plus
            </Button>
          )}
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default UsersLogins;
