import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Avatar } from '@material-ui/core'

const PictureToZoom = ({ src, className }) => (
  <Zoom>
    <Avatar className={className} src={src} />
  </Zoom>
);

export default PictureToZoom