import React from 'react';
import CustomizedSnackbars from 'components/CustomizedSnackbars';

export const CustomizedSnackBarContext = React.createContext({});

// Dont forget to add this at the root of your project around your <App />
export default class ProvideCustomizedSnackBarContext extends React.Component {
  timeoutVar = null;
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: null,
      type: null,
      errorMessage: message => this.state.setInfosToast(message, 'error'),
      successMessage: message => this.state.setInfosToast(message, 'success'),
      warningMessage: message => this.state.setInfosToast(message, 'warning'),
      setInfosToast: (message,type) => {
        this.setState({
          open: true,
          message: message,
          type: type
        });
    
        clearTimeout(this.timeoutVar);

        this.timeoutVar = setTimeout(() => {
          this.setState({
            open: false,
            message: "",
            type: ""
          });
        }, 3000);
      }
    };
  }

  componentDidUpdate() { }

  componentDidMount() { }

  closeDialog = () => this.setState({ isOpen: false });

  render() {
    return (
      <React.Fragment>
        <CustomizedSnackBarContext.Provider value={this.state}>{this.props.children}</CustomizedSnackBarContext.Provider>
        
        <CustomizedSnackbars
          isOpen={this.state.open}
          message={this.state.message}
          type={this.state.type}
        />
      </React.Fragment>
    );
  }
}