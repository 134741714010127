import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { TranslationContext } from 'contexts/TranslationContext';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);


export default function SelectFilter({ name, setFilter, label, options, val, multiple }) {

  const { t } = React.useContext(TranslationContext);

  let value = null;
  if (multiple) {
    value = val.length === 0 ? [] : val;
  } else {
    value = val == null ? 0 : val;
  }

  return (
    <FormControl fullWidth={true}>
      <InputLabel shrink={multiple} id={"select-filter-label"+name}>
        {label}
      </InputLabel>
      <Select
        labelId={"select-filter-label"+name}
        value={value}
        multiple={multiple}
        onChange={event => setFilter(name, event.target.value === 0 ? null : event.target.value)}
        renderValue={v => {
          if (multiple) {
            var result  = options.filter(o => v.includes(o.value));
            return (result && result.length > 0) ? result.map(e => t(e.label)).join(", ") : t('no-filter');
          }

          var result2  = options.filter(o => o.value === v);
          return (result2 && result2.length > 0) ? t(result2[0].label) : t('no-filter');
        }}
        input={<BootstrapInput />}>
          {
            !multiple
            && (
              <MenuItem disabled={multiple} value={0}>
                <em>{t('no-filter')}</em>
              </MenuItem>
            )
          }
        {
          options
            .map(option =>
              <MenuItem key={option.value} value={option.value}>
                {multiple && (
                  value.includes(option.value)
                  ? <RadioButtonCheckedIcon color="primary" />
                  : <RadioButtonUncheckedIcon />
                )}
                 &nbsp;{t(option.label)}
              </MenuItem>
            )
        }
      </Select>
    </FormControl>
  );
}
