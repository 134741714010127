import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TranslationContext } from 'contexts/TranslationContext';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const TableWithTitle = ({ className, title, btnText, columns, rows, onActionButton, ...rest }) => {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={onActionButton}
          >
            {btnText}
          </Button>
        }
        title={title}
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {
                    columns.map(column => (
                      <TableCell key={column.key}>{t(column.label)}</TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  rows.map((row) => (
                    <TableRow
                      hover
                      key={row.id}
                    >
                      {columns.map((column, index) => (
                        <TableCell key={index}>
                          {column.format
                          ? t(column.format(row[column.key], row))
                          : row[column.key]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

TableWithTitle.propTypes = {
  className: PropTypes.string
};

export default TableWithTitle;
