import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Typography } from '@material-ui/core';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import PausePresentationIcon from '@material-ui/icons/PausePresentation';
import TodayIcon from '@material-ui/icons/Today';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { Profile, SidebarNav } from './components';
import ResponsiveDialog from 'components/LogOutPopUp';
import LockIcon from '@material-ui/icons/Lock';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { TranslationContext } from 'contexts/TranslationContext';
import metadata from 'metadata.json';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  versionNbr: {
    marginTop: 'auto'
  }
}));

const Sidebar = props => {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);

  const { open, variant, onClose, className, ...rest } = props;

  const pages = [
    /*     {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    }, */
    {
      title: t('sidebar-menu-dashboard'),
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: t('sidebar-menu-article'),
      href: '/properties',
      icon: <HomeWorkIcon />
    },
    {
      title: t('sidebar-menu-already-contacted-article'),
      href: '/contacted-properties',
      icon: <ContactPhoneIcon />
    },
    {
      title: t('sidebar-menu-calendar'),
      href: '/calendar',
      icon: <TodayIcon />
    },
    {
      title: t('sidebar-menu-company'),
      href: '/my-company',
      // Only visible if we have a company admin id. Meaning we are admin of a company
      hasRight: myUserInfo => myUserInfo?.isAdminOfCompany,
      icon: <SupervisedUserCircleIcon />
    },
    {
      title: t('sidebar-menu-waiting-usr-summaries'),
      href: '/waiting-usr-rdv-summary',
      icon: <PausePresentationIcon />
    },
    {
      title: t('sidebar-menu-signed'),
      href: '/signed',
      icon: <BorderColorIcon />
    },
    {
      title: t('sidebar-menu-admin'),
      href: '/admin',
      // Only visible if we have a company admin id. Meaning we are admin of a company
      hasRight: myUserInfo => myUserInfo?.email === 'admin@niaur.com',
      icon: <LockIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <ResponsiveDialog />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
        <div className={classes.versionNbr}>
          <Typography variant="caption">
            <SidebarNav
              className={classes.nav}
              pages={[
                {
                  title: t('sidebar-menu-contact'),
                  href: '/contact',
                  icon: <LiveHelpIcon />
                }
              ]}
            />
            {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
          </Typography>
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
