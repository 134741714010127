import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Container, Grid, Typography } from '@material-ui/core';

import EuroIcon from '@material-ui/icons/Euro';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import PeopleIcon from '@material-ui/icons/People';

import Fade from 'react-reveal/Fade';

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  section: {
    minHeight: '100vh',
  },
  sectionTitle: {
    marginTop: "150px",
    marginBottom: "125px",
    fontWeight: 'bold',
  },
  gridItem: {
    padding: "32px",
  },
  text: {
    margin: "0px",
    fontFamily: "Public Sans, sans-serif",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    color: "rgb(255, 255, 255)"
  },
  card: {
    padding: theme.spacing(4),
    borderRadius: "14px"
  },
  card2: {
    padding: theme.spacing(4),
    borderRadius: "14px",

    [theme.breakpoints.up('md')]: {
      marginTop: "-80px",
    },
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "rgb(145 158 171 / 40%) -40px 40px 80px 0px"
  },
  cardIcon1: {
    width: "40px",
    height: "40px",
    margin: "auto",
    display: "block",
    marginBottom: theme.spacing(5),
    color: "rgba(24, 144, 255, 0.48)",
    filter: "drop-shadow(rgba(24, 144, 255, 0.48) 2px 2px 2px)"
  },
  cardIcon2: {
    width: "40px",
    height: "40px",
    margin: "auto",
    display: "block",
    marginBottom: theme.spacing(5),
    color: "rgba(255, 72, 66, 0.48)",
    filter: "drop-shadow(rgba(255, 72, 66, 0.48) 2px 2px 2px)",
  },
  cardIcon3: {
    width: "40px",
    height: "40px",
    margin: "auto",
    display: "block",
    marginBottom: theme.spacing(5),
    color: "rgba(0, 171, 85, 0.48)",
    filter: "drop-shadow(rgba(0, 171, 85, 0.48) 2px 2px 2px)"
  },
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
  cardText: {

  },
  ...theme.common,
}));

const MyCard = ({ cardClass, cardIconClass, Icon }) => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={cardClass}>
      <CardContent>
        <Icon className={cardIconClass} />
        <Typography className={classes.cardTitle} variant="h6" align="center">TEXT TITLE</Typography>
        <Typography variant="caption" align="center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eget nunc ultricies, ullamcorper sapien nec, fringilla ante. Donec aliquam sagittis ultricies. Aenean condimentum congue congue. 
        </Typography>
      </CardContent>
    </Card>
  );
};

const SectionCards = () => {
  const classes = useStyles();
  
  return (
    <Grid item xs={12} className={classes.section}>
      <Container>
        <Fade left>
          <Typography className={classes.sectionTitle} variant="h1" align="center">What HomeTracker helps you with?</Typography>
        </Fade>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid className={classes.gridItem} item xs={12} md={4}>
            <Fade right>
              <MyCard cardClass={classes.card} cardIconClass={classes.cardIcon1} Icon={EuroIcon} />
            </Fade>
          </Grid>
          <Grid className={classes.gridItem} item xs={12} md={4}>
            <Fade>
              <MyCard cardClass={classes.card2} cardIconClass={classes.cardIcon2} Icon={HourglassEmptyIcon} />
            </Fade>
          </Grid>
          <Grid className={classes.gridItem} item xs={12} md={4}>
            <Fade left>
              <MyCard cardClass={classes.card} cardIconClass={classes.cardIcon3} Icon={PeopleIcon} />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default SectionCards;