import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ConnectAuthContext } from 'contexts/AuthContext';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  Divider,
  LinearProgress,
  Button,
  CardActions
} from '@material-ui/core';
import { TranslationContext } from 'contexts/TranslationContext';

const Compress = require("client-compress");

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const AccountProfile = props => {
  const classes = useStyles();
  
  const { authContext, className } = props;

  const { t } = React.useContext(TranslationContext);

  const { myUserImage } = authContext;

  const user = {
    name: authContext.myUserInfo?.name,
    address: authContext.myUserInfo?.address,
    city: authContext.myUserInfo?.city,
    language: authContext.myUserInfo?.language,
    phoneNumber: authContext.myUserInfo?.phoneNumber,
    vAT: authContext.myUserInfo?.vAT,
    zipCode: authContext.myUserInfo?.zipCode,
    companyMember: authContext.myUserInfo?.companyMember ?? null,
    userIdentifier: authContext.myUserInfo?.userIdentifier ?? '',
    clientType: authContext.myUserInfo?.clientType ?? 0,
  };
  
  var properties = ["name", "address", "city", "language", "phoneNumber", "vAT", "zipCode", "userIdentifier", "clientType"];
  var count = properties.filter(k => {
    if (k in user) {
      if (user[k] != null && user[k] !== "") {
        return true;
      }
    }
    return false;
  }).length;
  var percentage = Math.round((100/properties.length)*count);

  console.log("User", user);

  // Returns the image to upload base64 encoded
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handleChange = async (event) => {
    event.persist();
    if (event.target.files.length > 0) {
      const options = {
        targetSize: 0.5,
        quality: 0.75,
        qualityStepSize: 0.05,
        minQuality: 0.25
      };
      const compress = new Compress(options);
      var file = await compress.compress([event.target.files[0]]);
      file = file[0].photo.data;
      var base64 = await getBase64(file);

      authContext.saveMyNewUserImage(base64);
    }
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h2"
            >
              {authContext.myUserInfo?.name}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {authContext.myUserInfo?.email}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body2"
            >
              {authContext.myUserInfo?.companyMember?.name}
            </Typography>
          </div>
          <Avatar
            className={classes.avatar}
            src={myUserImage}
          />
        </div>
        <div className={classes.progress}>
          <Typography variant="body1">{t("profile-complete-percentage", { percentage })}</Typography>
          <LinearProgress
            value={percentage}
            variant="determinate"
          />
        </div>
      </CardContent>
      <Divider />
      {
        authContext.myUserInfo?.isAdminOfCompany && (
          <CardActions>
            <Button
              className={classes.uploadButton}
              color="primary"
              variant="text"
              component="label"
            >
              {t("profile-change-picture")}
              <input
                type="file"
                hidden
                onChange={handleChange}
                accept="image/*"
              />
            </Button>
            {/* <Button variant="text">Remove picture</Button> */}
          </CardActions>
        )
      }
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default ConnectAuthContext(AccountProfile);
