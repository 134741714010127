const Arrondissements = [
  { "type": "A","niscode": "53000", "name": "Mons", "province_nis_code": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4452117687,3.87196255774], "name_fr": "Mons", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "41000", "name": "Aalst", "province_nis_code": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8749129971,3.94869095651], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "31000", "name": "Brugge", "province_nis_code": "30000", "region_nis_code": "02000", "geo_point_2d": [51.2028428474,3.22032513186], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "64000", "name": "Waremme", "province_nis_code": "60000", "region_nis_code": "03000", "geo_point_2d": [50.662789076,5.20650146039], "name_fr": "Waremme", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "72000", "name": "Maaseik", "province_nis_code": "70000", "region_nis_code": "02000", "geo_point_2d": [51.1367189197,5.5169249487], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "63000", "name": "Verviers", "province_nis_code": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4642129234,6.02207039122], "name_fr": "Verviers", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "37000", "name": "Tielt", "province_nis_code": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9996048078,3.31299774746], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "25000", "name": "Nivelles", "province_nis_code": "20002", "region_nis_code": "03000", "geo_point_2d": [50.6693315044,4.58378331504], "name_fr": "Nivelles", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "42000", "name": "Dendermonde", "province_nis_code": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0387715067,4.03554459096], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "45000", "name": "Audenarde", "province_nis_code": "40000", "region_nis_code": "02000", "geo_point_2d": [50.8329042705,3.63800410237], "name_fr": "Audenarde", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "11000", "name": "Antwerpen", "province_nis_code": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2806809908,4.50580868271], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "83000", "name": "Marche-en-Famenne", "province_nis_code": "80000", "region_nis_code": "03000", "geo_point_2d": [50.2300000633,5.4991861828], "name_fr": "Marche-en-Famenne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "24000", "name": "Leuven", "province_nis_code": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8902607886,4.84846231194], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "35000", "name": "Oostende", "province_nis_code": "30000", "region_nis_code": "02000", "geo_point_2d": [51.1764633468,2.952954949], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "91000", "name": "Dinant", "province_nis_code": "90000", "region_nis_code": "03000", "geo_point_2d": [50.1657814103,5.02552266487], "name_fr": "Dinant", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "92000", "name": "Namur", "province_nis_code": "90000", "region_nis_code": "03000", "geo_point_2d": [50.4609708848,4.85075035248], "name_fr": "Namur", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "85000", "name": "Virton", "province_nis_code": "80000", "region_nis_code": "03000", "geo_point_2d": [49.6656361607,5.4889733757], "name_fr": "Virton", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "56000", "name": "Thuin", "province_nis_code": "50000", "region_nis_code": "03000", "geo_point_2d": [50.1753754777,4.28036559637], "name_fr": "Thuin", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "81000", "name": "Arlon", "province_nis_code": "80000", "region_nis_code": "03000", "geo_point_2d": [49.6793022985,5.77966371817], "name_fr": "Arlon", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "51000", "name": "Ath", "province_nis_code": "50000", "region_nis_code": "03000", "geo_point_2d": [50.6404953791,3.76953965599], "name_fr": "Ath", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "82000", "name": "Bastogne", "province_nis_code": "80000", "region_nis_code": "03000", "geo_point_2d": [50.0843726009,5.74799495329], "name_fr": "Bastogne", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "46000", "name": "Sint-Niklaas", "province_nis_code": "40000", "region_nis_code": "02000", "geo_point_2d": [51.1947862659,4.14300971643], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "44000", "name": "Gent", "province_nis_code": "40000", "region_nis_code": "02000", "geo_point_2d": [51.0606595087,3.6641395333], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "71000", "name": "Hasselt", "province_nis_code": "70000", "region_nis_code": "02000", "geo_point_2d": [50.9514606242,5.27607862178], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "73000", "name": "Tongres", "province_nis_code": "70000", "region_nis_code": "02000", "geo_point_2d": [50.83737235,5.51646342746], "name_fr": "Tongres", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "34000", "name": "Courtrai", "province_nis_code": "30000", "region_nis_code": "02000", "geo_point_2d": [50.820070882,3.3179866484], "name_fr": "Courtrai", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "32000", "name": "Diksmuide", "province_nis_code": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0215546481,2.88129559968], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "36000", "name": "Roeselare", "province_nis_code": "30000", "region_nis_code": "02000", "geo_point_2d": [50.9402306957,3.11672335958], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "13000", "name": "Turnhout", "province_nis_code": "10000", "region_nis_code": "02000", "geo_point_2d": [51.2500937056,4.95042166855], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "52000", "name": "Charleroi", "province_nis_code": "50000", "region_nis_code": "03000", "geo_point_2d": [50.444787424,4.44294649487], "name_fr": "Charleroi", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "58000", "name": "La Louvière", "province_nis_code": "50000", "region_nis_code": "03000", "geo_point_2d": [50.4213892866,4.15176870283], "name_fr": "La Louvière", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "23000", "name": "Hal-Vilvorde", "province_nis_code": "20001", "region_nis_code": "02000", "geo_point_2d": [50.8511128614,4.27271499237], "name_fr": "Hal-Vilvorde", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "38000", "name": "Veurne", "province_nis_code": "30000", "region_nis_code": "02000", "geo_point_2d": [51.0475497834,2.67650736846], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "93000", "name": "Philippeville", "province_nis_code": "90000", "region_nis_code": "03000", "geo_point_2d": [50.1485199347,4.55057964469], "name_fr": "Philippeville", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "57000", "name": "Tournai et Mouscron", "province_nis_code": "50000", "region_nis_code": "03000", "geo_point_2d": [50.6338508883,3.39274581438], "name_fr": "Tournai et Mouscron", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "55000", "name": "Soignies", "province_nis_code": "50000", "region_nis_code": "03000", "geo_point_2d": [50.5633363353,4.13593643238], "name_fr": "Soignies", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "84000", "name": "Neufchâteau", "province_nis_code": "80000", "region_nis_code": "03000", "geo_point_2d": [49.910945607,5.29364540406], "name_fr": "Neufchâteau", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "21000", "name": "Bruxelles-Capitale", "province_nis_code": "01000", "region_nis_code": "01000", "geo_point_2d": [50.8360549337,4.37064954642], "name_fr": "Bruxelles-Capitale", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "43000", "name": "Eeklo", "province_nis_code": "40000", "region_nis_code": "02000", "geo_point_2d": [51.2201401451,3.59077665311], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "12000", "name": "Mechelen", "province_nis_code": "10000", "region_nis_code": "02000", "geo_point_2d": [51.0747191431,4.5262060152], "name_fr": "undefined", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "62000", "name": "Liège", "province_nis_code": "60000", "region_nis_code": "03000", "geo_point_2d": [50.6134329172,5.60777783004], "name_fr": "Liège", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "61000", "name": "Huy", "province_nis_code": "60000", "region_nis_code": "03000", "geo_point_2d": [50.4951347635,5.33912941281], "name_fr": "Huy", "name_nl": "undefined", "name_de": "undefined", },
  { "type": "A","niscode": "33000", "name": "Ypres", "province_nis_code": "30000", "region_nis_code": "02000", "geo_point_2d": [50.8497666652,2.85165015812], "name_fr": "Ypres", "name_nl": "undefined", "name_de": "undefined", },
];

export default Arrondissements;