import React from 'react';
import {
  Badge,
  CardHeader,
  Button,
  IconButton,
  Dialog,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import NoteIcon from '@material-ui/icons/Note';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { DialogContent, DialogTitle } from 'components';
import { TranslationContext } from 'contexts/TranslationContext';
import ActionsOfMembers from 'contexts/components/ActionsOfMembers';
import { getIcon, getSubTitle } from 'common/common';

const PropertyCardHeader = ({ property }) => {
  const [note, setNote] = React.useState(null);
  const [actionOfOtherMembers, setActionOfOtherMembers] = React.useState(null);
  const { t } = React.useContext(TranslationContext);

  const getTexts = (data) => {
    switch (data.state) {
      case 0:
        return [t("article-card-rdv")];
      case 1:
        return [t("article-card-no-resp"), (data.reminder ? t("article-card-no-resp-reminder") : '')];
      case 2:
        return [t("article-card-already-in-agency")];
      case 3:
        return [t("article-card-already-sold")];
      case 4:
        return [t("article-card-not-available")];
      case 5:
        return [t("article-card-not-interested"), (data.reminder ? t("article-card-not-interested-reminder") : '')];
      case 6:
        return [t("article-card-not-interesting")];
      default:
        return [t("article-card-na")];
    }
  };

  if (!property.userStatus) {
    // If we are not on the "Already contacted articles"
    // We display the actions of company (action of other member of the company on the displayed property)
    return (
      <React.Fragment>
        <CardHeader
          avatar={
            property.actionsOfCompany?.some(x => x.state === 2)
            ? <Alert severity="warning">{t("property-card-marked-as-already-in-agency")}</Alert>
            : (
                (
                  // The property was added in the last 3 hours
                  (new Date - property.date) < (3 * 60 * 60 * 1000)
                  && false // ToDo(ibe): Remove later to have a visual clue that the application is evolving
                ) && (
                  <Alert severity="info">{t("property-card-header-new")}</Alert>
                )
              )
          }
          action={
            <Button
              size="small"
              aria-label="reduce"
              onClick={() => {
                setActionOfOtherMembers(property.actionsOfCompany);
              }}
              disabled={property.actionsOfCompany?.length == 0}
            >
              <Badge color="error" badgeContent={property.actionsOfCompany?.length}>
                <MoreVertIcon />
              </Badge>
            </Button>
          }
        />
        <ActionsOfMembers actionOfOtherMembers={actionOfOtherMembers} setActionOfOtherMembers={setActionOfOtherMembers} />
      </React.Fragment>
    );
  }
  
  return (
    <React.Fragment>
      <CardHeader
        avatar={getIcon(property.userStatus)}
        title={getTexts(property.userStatus)}
        subheader={getSubTitle(property.userStatus)}
        action={
          (property.userStatus && property.userStatus.note) && (
            <IconButton aria-label="note" onClick={() => setNote(property.userStatus.note)}>
              <NoteIcon />
            </IconButton>
          )
        }
      />
      
      {
        note && (
          <Dialog
            open={true}
            onClose={() => setNote(null)}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{"Note"}</DialogTitle>
            <DialogContent>
              <DialogContentText> { property.userStatus.note } </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus color="primary" onClick={() => setNote(null)}>
                {t("common-close")}
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </React.Fragment>
  );
};

export default PropertyCardHeader;