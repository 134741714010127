import React, { Component } from 'react';
import axios from 'axios';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import validate from 'validate.js';

import { chartjs } from './helpers';
import validators from './common/validators';
import { Routes } from 'components';
import { ConnectLoaderContext } from 'contexts/LoaderContext';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import './assets/css/custom.css';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

class App extends Component {
  constructor(params) {
    super(params);

    axios.interceptors.request.use((config) => {
      this.props.loaderContext.toggleLoader(true);
      return config;
    });
    axios.interceptors.response.use((config) => {
      this.props.loaderContext.toggleLoader(false);
      return config;
    }, (error) => {
      this.props.loaderContext.toggleLoader(false);
      return Promise.reject(error);
    });

    window.addEventListener('EVENT EXAMPLE', function (e) {
      console.log("EVENT EXAMPLE >>> event", e);
    }, false);
  }

  render() {
    return (
      <Router history={browserHistory}>
        <Routes />
      </Router>
    );
  }
}

/// This can be used to identify why a component re render and it shows the prop that causes that re render
// function useTraceUpdate(props) {
//   const prev = React.useRef(props);
//   React.useEffect(() => {
//     const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
//       if (prev.current[k] !== v) {
//         ps[k] = [prev.current[k], v];
//       }
//       return ps;
//     }, {});
//     if (Object.keys(changedProps).length > 0) {
//       console.log('Changed props:', changedProps);
//     }
//     prev.current = props;
//   });
// }

export default ConnectLoaderContext(App);