import React from 'react';
import { Button, TextField } from '@material-ui/core';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  },
  textField: {
    marginTop: "12px"
  },
  submitButton: {
    marginTop: "25px",
  }
}));

const ImmoTrackerForm = ({ submitForm, fields, schema, hideSubmit, ...rest }) => {
  const classes = useStyles();

  const [formState, setFormState] = React.useState({
    isValid: false,
    loading: false,
    values: {},
    touched: {},
    errors: {}
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values, schema]);
  
  const onSubmit = e => {
    e.preventDefault();

    submitForm(formState.values);
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] && formState.errors[field].length > 0;
  };

  return (
    <form onSubmit={onSubmit} {...rest}>
      {
        fields.map(field => (
          <TextField
            key={field.name}
            required={field.required}
            className={classes.textField}
            error={hasError(field.name)}
            fullWidth
            helperText={
              hasError(field.name) ? formState.errors[field.name][0] : ''
            }
            label={field.label}
            name={field.name}
            onChange={handleChange}
            type={field.type}
            value={formState.values[field.name] || ''}
          />
        ))
      }
      <Button
        id="hometracker-submit-button"
        color="primary"
        className={hideSubmit ? classes.hidden : classes.submitButton}
        aria-label="save"
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
};

export default ImmoTrackerForm;