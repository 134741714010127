import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import metadata from 'metadata.json';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.black,
    padding: theme.spacing(3),
  },
  footerText: {
    color: theme.palette.white,
  },
  benimLink: {
    color: theme.palette.primary.main,
  },
  ...theme.common,
}));

const SectionFooter = () => {
  const classes = useStyles();
  
  return (
    <Grid item xs={12} className={classes.root}>
      <Typography className={classes.footerText} align="center">© All rights reserved</Typography>
      <Typography className={classes.footerText} align="center">made by <a target="_blank" href="https://www.benim.be" className={classes.benimLink}>Benim.be</a></Typography>
      <Typography className={classes.footerText} align="center">
        {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
      </Typography>
    </Grid>
  );
};

export default SectionFooter;