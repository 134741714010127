import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
  Link,
  ListItemText,
  Grid,
  Typography,
} from '@material-ui/core';
import { ExtendedButton } from 'components';
import PictureToZoom from 'components/Pictures/PictureToZoom';
import { saveRdvSummaryResponse } from 'myApi';
import { TranslationContext } from 'contexts/TranslationContext';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "100px"
  },
  savedResponseText: {
    color: theme.palette.success.main,
    fontWeight: 600
  }
}));

const RdvSummaryList = ({ rdvs, closeDialog, displayUsername }) => {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);

  const [loading, setLoading] = React.useState(false);
  const [respondedRdv, setRespondedRdv] = React.useState([]);

  const onClick = (rdvId, response) => {
    setLoading(true);

    saveRdvSummaryResponse(rdvId, response)
      .then(() => {
        const newRespondedRdv = [
          ...respondedRdv,
          rdvId
        ];
  
        setRespondedRdv(newRespondedRdv);
        if (newRespondedRdv.length === rdvs.length) {
          setTimeout(() => {
            if (closeDialog) {
              closeDialog();
            }
          }, 1250);
          return;
        }
      })
      .catch(() => {
        alert("An error occured. Re-try later !");
      })
      .finally(_ => setLoading(false));
  };

  

  return (
    <Grid container spacing={3} className={classes.root}>
      {
        rdvs.map((rdv) => (
          <Grid container justify="center" alignItems="center" key={rdv.id}>
            <Grid item>
              <Grid container direction="row">
                <PictureToZoom src={rdv.property?.pictureUrl ?? '/x72.png'} />
                &nbsp;&nbsp;
                <Link target='_blank' href={rdv.property?.url}>
                  <ListItemText
                    primary={displayUsername ? rdv.user.name : rdv.ownerAddress}
                    secondary={t("rdv-summary-rdv-date", { date: new Date(rdv.rdvDate + 'Z').toLocaleDateString() })}
                  />
                </Link>
              </Grid>
            </Grid>
            <Grid>
              {
                respondedRdv
                  .includes(rdv.id)
                  ? <Typography className={classes.savedResponseText} variant="subtitle2">{t("rdv-summary-response-saved")}</Typography>
                  : (
                    <React.Fragment>
                      <ExtendedButton color="success" onClick={() => onClick(rdv.id, 1)} disabled={loading}>
                        {t("rdv-summary-response-signed")}
                      </ExtendedButton>
                      <ExtendedButton color="danger" onClick={() => onClick(rdv.id, 0)} disabled={loading}>
                        {t("rdv-summary-response-not-signed")}
                      </ExtendedButton>
                      <ExtendedButton color="warning" onClick={() => onClick(rdv.id, 2)} disabled={loading}>
                        {t("rdv-summary-response-waiting")}
                      </ExtendedButton>
                    </React.Fragment>
                  )
              }
            </Grid>
          </Grid>
        ))
      }
    </Grid>
  );
};

export default RdvSummaryList;