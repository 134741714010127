import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import {
  AddCompanyForm,
  AddUserForm,
  UsersActions,
  UsersLogins
} from './components';
import { getAllUsers } from 'myApi';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Admin = () => {
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);

  const getMoreData = () => {
    getAllUsers().then(response => {
      const results = response.data.getAllUsers;

      setUsers(results);
    });
  };

  React.useEffect(() => {
    getMoreData();
  }, []);

  return (
    <div className={classes.root}>
      <Grid spacing={3} container>
        <Grid item md={4} xs={12}>
          <AddCompanyForm />
        </Grid>
        <Grid item md={4} xs={12}>
          <AddUserForm />
        </Grid>
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12} md={3}>
            <UsersLogins />
          </Grid>
          <Grid item xs={12} md={3}>
            <UsersActions users={users} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Admin;
