import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Container, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  container: {
    borderRadius: '16px',
    backgroundImage:
      'linear-gradient(135deg, rgb(239 124 53) 0%, rgb(239 74 53) 100%)'
  },
  gridItemImage: {
    padding: theme.spacing(6),
    textAlign: 'center'
  },
  image: {
    maxHeight: '125px'
  },
  gridItem: {
    padding: theme.spacing(6)
  },
  text: {
    color: theme.palette.white,
    fontSize: '2.3rem',
    fontWeight: 700,
    lineHeight: 1.33333
  },
  blackText: {
    color: theme.palette.black
  },
  btnGo: {
    marginTop: theme.spacing(4)
  },
  ...theme.common
}));

const SectionContact = ({ history }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.root}>
      <Container>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid className={classes.gridItemImage} item xs={12} sm={4}>
            <img className={classes.image} src="/images/avendreHT.jpg" />
          </Grid>
          <Grid className={classes.gridItem} item xs={12} sm={8}>
            <Typography className={classes.text} variant="h2">
              Get started with
              <br />
              Home <span className={classes.blackText}>Tracker</span> today
            </Typography>
            <Button
              className={classes.btnGo}
              variant="contained"
              onClick={() => history.push('/dashboard')}
            >
              Les biens !
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default withRouter(SectionContact);
