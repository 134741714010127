import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1)
  },
}));

const ClientNotInterestedClientForm = ({ updateProp }) => {
  const classes = useStyles();

  const [date, setDate] = React.useState(null);

  const onChange = (dt) => {
    setDate(dt);
    updateProp('reminder', dt);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6">Info du client</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl component="fieldset" fullWidth>
          <TextField
            id="name-required"
            label="Nom du Client"
            fullWidth
            onChange={e => updateProp('ownerName', e.target.value.trim())}
          />
          <TextField
            id="phone-required"
            label="Numéro de téléphone"
            fullWidth
            onChange={e => updateProp('ownerPhoneNumber', e.target.value.trim())}
          />
          <TextField
            id="standard-multiline-static"
            label="Notes Supplémentaires"
            multiline
            fullWidth
            rows={2}
            onChange={e => updateProp('note', e.target.value.trim())}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <React.Fragment>
              {
                date && (
                  <Typography style={{ fontSize: 'x-small', marginTop: '-5px' }}>
                      Date du rappel*
                  </Typography>
                )
              }
              <DateTimePicker
                style={{ marginTop: date ? 0 : '16px', width: '100%' }}
                emptyLabel='Programmer un rappel ?'
                value={date}
                ampm={false}
                autoOk={true}
                disablePast={true}
                required
                onChange={onChange}
              />
            </React.Fragment>
          </MuiPickersUtilsProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>

        <Button
          className={classes.button}
          variant="contained"
          type='submit'
          color="primary">
          Enregistrer
        </Button>
      </Grid>
    </React.Fragment>
  );
};


export default ClientNotInterestedClientForm;