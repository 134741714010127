import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1)
  },
}));

const ClientNotInterestedRadioGroup = ({ value, onChange, otherOnChange, goNextStep }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6">Réponse du client</Typography>
      </Grid>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="question"
          name="question"
          value={value}
          onChange={onChange}>
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={`"Je ne veux pas travailler avec un agent immobilier"`}
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
          label={`"Certainement pas votre agence."`}
          />
          <FormControlLabel
            value={3}
            control={<Radio />}
            label={`"Je veux essayer moi même d'abord"`}
          />
          <FormControlLabel
            value={4}
            control={<Radio />}
            label={`"Votre commission est trop chère"`}
          />
          <FormControlLabel
            value={5}
            control={<Radio />}
            label={`"Beaucoup de clients sont intéressés, j'ai déjà plein de visites de prévues"`}
          />
          <FormControlLabel
            value={6}
            control={<Radio />}
            label={`Il est indiqué "agence s'abstenir" sur l'annonce, vous ne savez pas lire`}
          />
          <FormControlLabel
            value={7}
            control={<Radio />}
            label={<TextField placeholder="Autre" type="text" onClick={e => onChange(e, 7)} onChange={evt => otherOnChange(evt.target.value)} />}
          />
        </RadioGroup>
        <Button
          disabled={!value}
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={goNextStep}>
          Suivant
        </Button>
      </FormControl>
    </React.Fragment>
  );
};


export default ClientNotInterestedRadioGroup;