import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { TranslationContext } from 'contexts/TranslationContext';
import { loadMyWaitingUserRdvSummaries } from 'myApi';
import { CustomizedSnackBarContext } from 'contexts/CustomizedSnackBarContext';
import { Redirect } from 'react-router';
import RdvSummaryList from 'contexts/components/RdvSummaryList';
import { AuthContext } from 'contexts/AuthContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  noFoundImageContainer: {
    textAlign: "center",
  },
  noFoundImage: {
    maxWidth: "100%",
    maxHeight: "450px",
  },
  btn: {
    marginTop: theme.spacing(2)
  },
  ...theme.common,
}));

const UserRdvSumarries = () => {
  const classes = useStyles();

  const { t } = React.useContext(TranslationContext);
  const { errorMessage } = React.useContext(CustomizedSnackBarContext);
  const { myUserInfo } = React.useContext(AuthContext);

  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    loadMyWaitingUserRdvSummaries()
    .then(response => {
      setRows(response.data.myWaitingUserRdvSummaries);
    })
    .catch(err => {
      errorMessage(t("common-an-error-occured"));
    })
    .finally(_ => {
      setLoading(false);
    });
  }, []);

  if (redirect) {
    return <Redirect to={'/properties'} />;
  }
  
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          {loading && (<div className={classes.noFoundImageContainer}><CircularProgress /></div>)}
          {rows.length === 0 && !loading && (
            <div className={classes.noFoundImageContainer}>
              <img className={classes.noFoundImage} alt="no-data" src="/images/noting-waiting.svg"/>
              <Typography variant="h2" align="center">{t("rdv-summary-no-waiting-data")}</Typography>
              <Button className={classes.btn} onClick={() => setRedirect(true)} variant="contained" color="primary">{t("rdv-summary-go-to-properties")}</Button>
            </div>
          )}
          <RdvSummaryList displayUsername={myUserInfo.isAdminOfCompany} rdvs={rows} />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserRdvSumarries;